import {
  GET_TIMESHEET_SETTINGS,
  UPDATE_TIMESHEET_SETTINGS,
  UPDATE_ORGANIZATION_TIMESHEET_APPROVAL_SETTINGS,
  GET_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS,
  UPDATE_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS,
} from "../../../modules/constants";

// ------------------------------------
// Actions
// ------------------------------------
export function getTimesheetSettings(payload) {
  return {
    type: GET_TIMESHEET_SETTINGS,
    payload,
  };
}

export function updateTimesheetSettings(payload) {
  return {
    type: UPDATE_TIMESHEET_SETTINGS,
    payload,
  };
}

export function getMemberwiseTimesheetApprovalSettings(payload) {
  return {
    type: GET_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS,
    payload,
  };
}
export function updateMemberwiseTimesheetApprovalSettings(payload) {
  return {
    type: UPDATE_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS,
    payload,
  };
}
export function updateOrganizationTimesheetApprovalSettings(payload) {
  return {
    type: UPDATE_ORGANIZATION_TIMESHEET_APPROVAL_SETTINGS,
    payload,
  };
}
