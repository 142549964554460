import { useState, useEffect, useRef, memo } from "react";
import PropTypes from "prop-types";
import { ActivityTextSection } from "../../../../styledComponents/members";
import { CommonText } from "../../../../styledComponents/common";
import {
  TimeSlotCard,
  TimeSlotInnerCard,
  TimesheetTooltipContainer,
  DeleteButton,
  InfoButton,
  EditButton,
} from "../../../../styledComponents/timesheet";
import CommonTooltip from "../../../../components/Tooltip/CommonTooltip";

import { getZeroHourTime } from "../helperFunctions";

import deleteWhite from "../../../../assets/img/icons/delete_white.svg";
import idleTimeIcon from "../../../../assets/img/icons/idle-time.svg";
import editIcon from "../../../../assets/img/icons/edit-button.svg";

const TimesheetDetailsPopup = memo(
  ({
    index,
    data,
    startPosition,
    toggleDeleteTimeModal,
    setInfoPopup,
    toggleIdleTimePopup,
    toggleManualTimeModal,
  }) => {
    const [hover, setHover] = useState(false);
    const popUpRef = useRef(null);

    useEffect(() => {
      function handleClickOutside(event) {
        if (popUpRef.current && !popUpRef.current.contains(event.target)) {
          setInfoPopup(-1);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [setInfoPopup]);

    const onHoverChange = (value) => {
      setHover(value);
    };

    return (
      <TimeSlotCard
        index={index}
        top={data.top - 85}
        height={75}
        startPosition={startPosition}
        style={{
          left: "200px",
          zIndex: "5",
        }}
        ref={popUpRef}
      >
        <TimeSlotInnerCard
          onMouseEnter={() => onHoverChange(true)}
          onMouseLeave={() => onHoverChange(false)}
        >
          <ActivityTextSection>
            <CommonText name fontSize="14px" fontWeight="600">
              {data.project ? data.project.name : "No project"}
            </CommonText>
            <CommonText fontSize="12px">
              {data.task ? data.task.name : "No task"}
            </CommonText>
          </ActivityTextSection>
          <ActivityTextSection>
            <CommonText name fontSize="14px" fontWeight="600">
              {data.start_time.format("h:mm a")}
            </CommonText>
            <CommonText fontSize="12px" fontWeight="600">
              Start
            </CommonText>
          </ActivityTextSection>
          <ActivityTextSection>
            <CommonText name fontSize="14px" fontWeight="600">
              {data.end_time.format("h:mm a")}
            </CommonText>
            <CommonText fontSize="12px" fontWeight="600">
              End
            </CommonText>
          </ActivityTextSection>
          <ActivityTextSection>
            <CommonText name fontSize="14px" fontWeight="600">
              {getZeroHourTime(data.duration)}
            </CommonText>
            <CommonText fontSize="12px" fontWeight="600">
              Total
            </CommonText>
          </ActivityTextSection>

          {hover && (
            <>
              {data.idleTimes && data.idleTimes.length > 0 && (
                <InfoButton
                  onClick={() => toggleIdleTimePopup(index)}
                  bgColor="#7F9DBF"
                  right="35px"
                  index={index}
                >
                  <img src={idleTimeIcon} alt="" />
                  <TimesheetTooltipContainer>
                    <CommonTooltip
                      tooltipText="Idle Time"
                      left="-55px"
                      top="-46px"
                      width="95px"
                      height="25px"
                    />
                  </TimesheetTooltipContainer>
                </InfoButton>
              )}
              <EditButton
                index={index}
                onClick={() => toggleManualTimeModal("edit", data)}
              >
                <img
                  src={editIcon}
                  style={{
                    width: "16px",
                    height: "16px",
                  }}
                  alt="edit"
                />
                <TimesheetTooltipContainer>
                  <CommonTooltip
                    tooltipText="Edit"
                    left="-50px"
                    top="-46px"
                    width="85px"
                    height="25px"
                  />
                </TimesheetTooltipContainer>
              </EditButton>
              <DeleteButton onClick={() => toggleDeleteTimeModal(data)}>
                <img
                  src={deleteWhite}
                  style={{ width: "16px", height: "16px" }}
                  alt="delete"
                />
                <TimesheetTooltipContainer>
                  <CommonTooltip
                    tooltipText="Delete"
                    left="-50px"
                    top="-50px"
                    width="85px"
                    height="25px"
                  />
                </TimesheetTooltipContainer>
              </DeleteButton>
            </>
          )}
        </TimeSlotInnerCard>
      </TimeSlotCard>
    );
  }
);

TimesheetDetailsPopup.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.shape({
    top: PropTypes.number.isRequired,
    project: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    task: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    start_time: PropTypes.object.isRequired,
    end_time: PropTypes.object.isRequired,
    duration: PropTypes.number.isRequired,
    idleTimes: PropTypes.array,
  }).isRequired,
  startPosition: PropTypes.number.isRequired,
  toggleDeleteTimeModal: PropTypes.func.isRequired,
  setInfoPopup: PropTypes.func.isRequired,
  toggleIdleTimePopup: PropTypes.func.isRequired,
  toggleManualTimeModal: PropTypes.func.isRequired,
};

TimesheetDetailsPopup.displayName = "TimesheetDetailsPopup";

export default TimesheetDetailsPopup;
