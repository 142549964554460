import styled from "styled-components";

export const ChangePasswordContainer = styled.div``;
export const CollapseContainer = styled.div`
  display: grid;
  grid-template-columns: auto 25px;
  align-items: center;
  justify-content: space-between;
`;

export const ChangePasswordForm = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || `repeat(2, 50%)`};
  grid-gap: 12px 20px;
  align-items: ${({ alignItems }) => alignItems || "center"};
  margin: ${({ margin }) => margin || "0"};
  max-width: 600px;
  @media (max-width: 647px) {
    grid-template-columns: ${({ columns }) => columns || "repeat(1, 80%)"};
  }
`;
export const DivPassword = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 100%);
  align-items: center;
  justify-items: stretch;
  max-width: 300px;
  @media (max-width: 493px) {
    display: block;
  }
`;

export const ShowPasswordIcon = styled.img`
  width: ${(props) => (props.active ? "32px" : "25px")};
  cursor: pointer;
  transition: width 0.3s ease-in-out;
  margin-left: -40px;
`;
export const ArrowButton = styled.img`
  transform: ${({ collapsed }) =>
    collapsed ? `rotate(90deg)` : `rotate(0deg)`};
  transition: transform 0.8s ease;
`;
export const GreenLabel = styled.label`
  text-align: left;
  color: #3bae7c;
  font-size: 13px;
`;
export const ChangePassDiv = styled.div`
  margin-top: 30px;
`;
