import { memo } from "react";
import { ActivityTextSection } from "../../../../styledComponents/members";
import { CommonText } from "../../../../styledComponents/common";
import { TimeSlotInnerCard } from "../../../../styledComponents/timesheet";

const ContentTitle = memo(() => {
  return (
    <TimeSlotInnerCard>
      <ActivityTextSection justify="flex-start">
        <CommonText $label fontSize="12.5px">
          Project & Task
        </CommonText>
      </ActivityTextSection>
      <ActivityTextSection>
        <CommonText $label fontSize="12.5px">
          Start
        </CommonText>
      </ActivityTextSection>
      <ActivityTextSection>
        <CommonText $label fontSize="12.5px">
          End
        </CommonText>
      </ActivityTextSection>
      <ActivityTextSection justify="flex-end" margin="0 8px 0 0">
        <CommonText $label fontSize="12.5px">
          Total
        </CommonText>
      </ActivityTextSection>
    </TimeSlotInnerCard>
  );
});

ContentTitle.displayName = "ContentTitle";

export default ContentTitle;
