import { Container, CardTitle } from "../../../styledComponents/common";

import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";

import HistoryTable from "./HistoryTable";

const ApprovalHistory = (props) => {
  const {
    selectedOrganization,
    organizationMembersList,
    isLoading,
    seeMoreIsLoading,
    historyList,

    // pagination
    handleSeeMore,
    paginationSize,
    allLoadedDates,
    clearLoadMoreTimesheetApprovalList,
  } = props;

  return (
    <Container padding="30px 0" style={{ gridTemplateColumns: "100%" }}>
      {isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <>
          <CardTitle margin="0 0 20px 30px">Approval History</CardTitle>
          <HistoryTable
            selectedOrganization={selectedOrganization}
            organizationMembersList={organizationMembersList}
            historyList={historyList}
            seeMoreIsLoading={seeMoreIsLoading}
            handleSeeMore={handleSeeMore}
            paginationSize={paginationSize}
            allLoadedDates={allLoadedDates}
            clearLoadMoreTimesheetApprovalList={
              clearLoadMoreTimesheetApprovalList
            }
          />
        </>
      )}
    </Container>
  );
};

export default ApprovalHistory;
