import { all, put, takeLatest } from "redux-saga/effects";

import { defaultApi } from "../../../utils/axiosApi";

import {
  GET_GEOFENCE_CLOCK_IN_OUT_LIST,
  GET_GEOFENCE_CLOCK_IN_OUT_LIST_SUCCESS,
  GET_GEOFENCE_CLOCK_IN_OUT_LIST_FAILURE,
} from "../../../modules/constants";

export function* getGeofenceClockInOutListRequest({ payload }) {
  const { organization_id, date, name, page } = payload;
  try {
    const url = `${organization_id}/geofence/attendances/?start=${date}&end=${date}${
      page ? `&page=${page}` : ``
    }${name ? `&name=${name}` : ``}`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_GEOFENCE_CLOCK_IN_OUT_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_GEOFENCE_CLOCK_IN_OUT_LIST_FAILURE,
      payload: err,
    });
  }
}

export default function* clockInOutSagas() {
  yield all([
    takeLatest(
      GET_GEOFENCE_CLOCK_IN_OUT_LIST,
      getGeofenceClockInOutListRequest
    ),
  ]);
}
