import { connect } from "react-redux";

// Component
import CommonFilter from "./CommonFilter";
// Actions
import {
  getOrganizationMembersList,
  getProjectAssigneeList,
  getTaskAssigneeList,
} from "../../custom_modules/Projects/projectsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  organizationMembersList: state.project.orgMembersList,
  projectsList: state.project.projectShortList,
  projectAssigneeList: state.project.projectAssigneeList,
  projectTaskList: state.project.projectTaskList,
  taskAssigneeList: state.project.taskAssigneeList,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getOrganizationMembersList: (details) =>
    dispatch(getOrganizationMembersList({ ...details })),
  getProjectAssigneeList: (details) =>
    dispatch(getProjectAssigneeList({ ...details })),
  getTaskAssigneeList: (details) =>
    dispatch(getTaskAssigneeList({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(CommonFilter);
