import { connect } from "react-redux";

// Component
import VerifyUser from "./VerifyUser";
// Actions
import { verify, resetVerify } from "./verifyActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  verifyUser: state.verify,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  verify: (details) => dispatch(verify({ ...details })),
  resetVerify: () => dispatch(resetVerify()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(VerifyUser);
