import { useState, useEffect } from "react";
import Select from "../../components/ReactSelectDropdown";
import AddSingleMemberDropdown from "../../components/CustomDropdown/AddSingleMemberDropdown";

import {
  InputWithLabelSection,
  FilterLabel,
  InputField,
} from "../../styledComponents/common";
import { FilterContainer } from "../../styledComponents/tasks";

import ProjectDropdown from "../../components/CommonFilter/ProjectDropdown";

import SearchWithButton from "../../components/Search/SearchWithButton";

import FilterDropDownStyle from "../../components/DropdownStyle/FilterDropDownStyle";

import avatar5 from "../../assets/img/avatar_5.svg";

const TaskFilter = (props) => {
  const [memberOptions, setMemberOptions] = useState([]);
  const statusOptions = [
    {
      value: "to do",
      label: "To Do",
    },
    {
      value: "complete",
      label: "Completed",
    },
  ];

  const {
    selectedOrganization,
    getOrganizationMembersList,
    organizationMembersList,
    projectAssigneeList,
    selectedProject,
    selectedMember,
    getProjectAssigneeList,
    selectProject,
    selectMember,
    clearMember,
    taskStatus,
    handleStatusChange,
    handleSearchByName,
    handleClearSearch,
    searchTerm,
    onSearchTermChange,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      let payload = {
        organization_id: selectedOrganization.id,
      };
      getOrganizationMembersList(payload);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (organizationMembersList && organizationMembersList.length > 0) {
      setMemberOptions(organizationMembersList);
    } else if (
      organizationMembersList &&
      organizationMembersList.length === 0
    ) {
      setMemberOptions([]);
    }
  }, [organizationMembersList]);

  useEffect(() => {
    if (projectAssigneeList && projectAssigneeList.length > 0) {
      let assigneeList = [];
      projectAssigneeList.map((assignee) => {
        assigneeList.push({
          id: assignee.user.id,
          name: `${assignee.user.first_name}${
            assignee.user.last_name ? ` ${assignee.user.last_name}` : ``
          }`,
          avatar: assignee.user.avatar ?? null,
          color: assignee.user.color ?? null,
        });
      });
      setMemberOptions(assigneeList);
    } else if (projectAssigneeList && projectAssigneeList.length === 0) {
      setMemberOptions([]);
    }
  }, [projectAssigneeList]);

  useEffect(() => {
    if ((selectedProject && !selectedProject.value) || !selectedProject) {
      setMemberOptions(organizationMembersList);
    }
  }, [selectedProject]);

  const onProjectSelect = (e) => {
    if (selectedOrganization && selectedOrganization.id && e && e.value) {
      let payload = {
        organization_id: selectedOrganization.id,
        project_id: e.value,
      };
      if (selectedMember) {
        payload.user_id = selectedMember.id;
        getProjectAssigneeList(payload);
      } else {
        getProjectAssigneeList(payload);
      }
    }
    if (selectProject) {
      selectProject(e);
    }
  };

  const onMemberSelect = (person) => {
    if (selectMember) {
      selectMember(person);
    }
  };

  const clearSelectedMember = () => {
    clearMember();
  };

  

  return (
    <FilterContainer>
      <InputWithLabelSection>
        <FilterLabel>Projects</FilterLabel>
        <ProjectDropdown
          selectedOrganization={selectedOrganization}
          selectedProject={selectedProject}
          selectProject={onProjectSelect}
          selectedMember={selectedMember}
          clearable
        />
      </InputWithLabelSection>
      <InputWithLabelSection>
        <FilterLabel>Assignee</FilterLabel>
        <div style={{ width: `40px` }}>
          <AddSingleMemberDropdown
            assigneeManage={selectedMember}
            updateState={onMemberSelect}
            membersList={memberOptions}
            clearAssignee={() => clearSelectedMember()}
          />
        </div>
      </InputWithLabelSection>
      <SearchWithButton
        itemName="Task"
        searchTermName="task name"
        searchInput={searchTerm}
        onInputChange={onSearchTermChange}
        handleSearch={handleSearchByName}
        handleClearSearch={handleClearSearch}
      />
      <InputWithLabelSection>
        <FilterLabel>Status</FilterLabel>
        <Select
          isSearchable={false}
          value={taskStatus}
          options={statusOptions}
          placeholder="Status"
          onChange={(e) => {
            handleStatusChange(e);
          }}
          styles={FilterDropDownStyle({
            height: "40px",
            width: "130px",
            menuWidth: "130px",
          })}
        />
      </InputWithLabelSection>
    </FilterContainer>
  );
};

export default TaskFilter;
