import {
  CREATE_CLIENT,
  GET_CLIENTS_LIST,
  GET_CLIENT_DETAILS,
  UPDATE_CLIENT,
  DELETE_CLIENT,
} from "../../modules/constants";

export function getClientList(value) {
  return {
    type: GET_CLIENTS_LIST,
    payload: value,
  };
}

export function getClientDetails(value) {
  return {
    type: GET_CLIENT_DETAILS,
    payload: value,
  };
}

export function createClient(data) {
  return {
    type: CREATE_CLIENT,
    payload: data,
  };
}

export function updateClient(data) {
  return {
    type: UPDATE_CLIENT,
    payload: data,
  };
}

export function deleteClient(value) {
  return {
    type: DELETE_CLIENT,
    payload: value,
  };
}

export const actions = {
  createClient,
  updateClient,
  deleteClient,
  getClientList,
  getClientDetails,
};
