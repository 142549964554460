import styled from "styled-components";
import { Modal, ModalBody } from "reactstrap";

import { InputWithLabelSection } from "../../styledComponents/common";

export const CustomModal = styled(Modal)`
  max-width: 420px;
  margin-top: 5vh;

  @media screen and (min-width: 1400px) {
    margin-top: 16vh;
  }
`;

export const ApplyLeaveModalBody = styled(ModalBody)`
  padding: 30px;
`;

export const ModalInputSection = styled(InputWithLabelSection)`
  margin-top: 15px;
`;

export const DateTimeContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  justify-content: space-between;
`;

export const CommentTextArea = styled.textarea`
  min-height: 98px;
  background: ${({ theme }) => theme.colors.main.whiteBg};
  border: 1px solid
    ${({ borderColor, theme, error }) =>
      error
        ? theme.input.borderColor.error
        : borderColor
        ? borderColor
        : theme.input.borderColor.default};
  border-radius: ${({ theme }) => theme.input.borderRadius};
  padding: 10px;
  color: ${({ textColor, theme }) => textColor || theme.input.textColor};
  font-size: ${({ fontSize, theme }) => fontSize || theme.input.fontSize};
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.input.placeholder.color};
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.input.placeholder.color};
  }
  &:hover {
    border-color: ${({ hoverBorderColor, theme, error }) =>
      !error && (hoverBorderColor || theme.input.borderColor.hover)};
    box-shadow: ${({ theme, error }) => !error && theme.input.focusShadow};
  }
  &:focus {
    outline: none;
    border-color: ${({ focusBorderColor, theme, error }) =>
      !error && (focusBorderColor || theme.input.borderColor.focus)};
    box-shadow: ${({ theme, error }) => !error && theme.input.focusShadow};
  }
`;

export const ModalButtonSection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  grid-column-gap: 12px;
  margin-top: 20px;
`;
