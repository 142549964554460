import moment from "moment";

export const getHours = (time) => {
  const times = time.split(":");
  const hours = parseInt(times[0]);
  const minutes = parseInt(times[1]);
  return `${hours} h ${minutes} m`;
};

export const getTotal = (prev, next) => {
  const splittedPrev = prev.split(":");
  const splittedNext = next.split(":");

  const prevHour = parseInt(splittedPrev[0]);
  const prevMin = parseInt(splittedPrev[1]);
  const prevSec = parseInt(splittedPrev[2]);

  const nextHour = parseInt(splittedNext[0]);
  const nextMin = parseInt(splittedNext[1]);
  const nextSec = parseInt(splittedNext[2]);

  const totalHour = prevHour + nextHour;
  const totalMin = prevMin + nextMin;
  const totalSec = prevSec + nextSec;

  if (totalSec > 60) {
    totalMin += parseInt(totalSec / 60);
    totalSec = parseInt(totalSec % 60);
  }
  if (totalMin >= 60) {
    totalHour += parseInt(totalMin / 60);
    totalMin = parseInt(totalMin % 60);
  }
  return `${totalHour}:${totalMin}:${totalSec}`;
};

export const toHHMMSS = (secs) => {
  const getSeconds = `0${secs % 60}`.slice(-2);
  const minutes = `${Math.floor(secs / 60)}`;
  const getMinutes = `0${minutes % 60}`.slice(-2);
  const getHours =
    secs < 36000
      ? `0${Math.floor(secs / 3600)}`.slice(-2)
      : `${Math.floor(secs / 3600)}`;

  return `${getHours}:${getMinutes}:${getSeconds}`;
};

/**
 * Formats seconds into HH:MM format.
 * If `rounded` is true, minutes are rounded up if seconds >= 30.
 *
 * @param {number} secs - Total seconds to format.
 * @param {boolean} [rounded=false] - Whether to round minutes based on seconds.
 * @returns {string} Formatted time string in "HH:MM" format.
 */
export const toHHMM = (secs, rounded = false) => {
  if (secs <= 0) {
    return "00:00";
  }

  const totalMinutes = Math.floor(secs / 60);
  const remainingSeconds = secs % 60;

  let displayMinutes = totalMinutes;
  if (rounded && remainingSeconds >= 30) {
    displayMinutes += 1;
  }

  const hours = Math.floor(displayMinutes / 60);
  let minutes = displayMinutes % 60;

  // Handle case where rounding minutes affects hours
  let formattedHours = hours;
  let formattedMinutes = minutes;

  // If minutes reach 60 after rounding, increment hours
  if (minutes === 60) {
    formattedHours += 1;
    formattedMinutes = 0;
  }

  // Format to always show two digits
  const formattedHH = `0${formattedHours}`.slice(-2);
  const formattedMM = `0${formattedMinutes}`.slice(-2);

  return `${formattedHH}:${formattedMM}`;
};

/**
 * Formats seconds into h m format.
 * If `rounded` is true, minutes are rounded up if seconds >= 30.
 *
 * @param {number} secs - Total seconds to format.
 * @param {boolean} [rounded=false] - Whether to round minutes based on seconds.
 * @returns {string} Formatted time string in "h m" format.
 */
export const formatDurationToHoursMinutes = (duration, rounded = false) => {
  if (duration <= 0) {
    return "0s";
  }

  let totalSeconds = duration;
  let hours = Math.floor(totalSeconds / 3600);
  let remainingSeconds = totalSeconds % 3600;

  let minutes = Math.floor(remainingSeconds / 60);
  let seconds = remainingSeconds % 60;

  if (rounded) {
    if (seconds >= 30) {
      minutes += 1;
    }
    // Handle cases where rounding minutes affects hours
    if (minutes >= 60) {
      hours += 1;
      minutes = 0;
    }
  }

  let formattedDuration = "";
  if (hours > 0) {
    formattedDuration += `${hours}h `;
  }

  if (minutes > 0 || hours > 0) {
    formattedDuration += `${minutes}m`;
  }

  if (!hours && !minutes) {
    formattedDuration = `${seconds}s`;
  }

  return formattedDuration.trim();
};

export const validateEmail = (email) => {
  // eslint-disable-next-line
  let emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

// Validate password
export function password_validate(p) {
  return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(p);
}

function isASCII(str) {
  // eslint-disable-next-line
  return /^[\x00-\x7F]*$/.test(str);
}

function isValidPhone(phone) {
  const regex = /^(?:[0-9] ?){6,14}[0-9]$/;
  return regex.test(phone);
}

export function checkUrl(url) {
  const regex = /https?:\/\//gi;
  return regex.test(url);
}

export function checkHtml(value) {
  const regex = /<\/?[a-z][^>]*>/gi;
  return regex.test(value);
}

export function handleValidation(name, type, value, options) {
  let res = { isValid: true, fieldName: name, message: "" };
  if (options && options.required && !value) {
    res.message = "Field is required!";
    res.isValid = false;
    res.required = options.required;
  } else if (
    options &&
    options.required &&
    typeof value !== "undefined" &&
    value.length === 0
  ) {
    res.message = "Field is required!";
    res.isValid = false;
    res.required = options.required;
  } else if (
    name !== "email" &&
    options &&
    options.required &&
    typeof value === "string" &&
    value.toLowerCase().includes("select")
  ) {
    res.message = "Choose Valid Option!";
    res.isValid = false;
    res.required = options.required;
  } else if (
    options &&
    options.required &&
    options.multiSelect &&
    value.length === 0
  ) {
    res.message = "Choose At Least One Option!";
    res.isValid = false;
    res.required = options.required;
  } else if (options && options.minLength && value.length < options.minLength) {
    res.message = "Minimum length should be " + options.minLength + "!";
    res.isValid = false;
    res.required = options.required;
  } else if (options && options.maxLength && value.length > options.maxLength) {
    res.message = "Maximum length should be " + options.maxLength + "!";
    res.isValid = false;
    res.required = options.required;
  } else if (options && options.isASCII && !isASCII(value)) {
    res.message = "Only ASCII characters are allowed!";
    res.isValid = false;
    res.required = options.required;
  } else if (options && options.phone && !isValidPhone(value)) {
    res.message = "Not valid phone number!";
    res.isValid = false;
  } else if (options && options.contact && !isValidPhone(value)) {
    res.message = "Not valid phone number!";
    res.isValid = false;
    res.required = options.required;
  } else if (options && name === "phone" && !isValidPhone(value)) {
    res.message = "Not valid phone number!";
    res.isValid = false;
    res.required = options.required;
  } else if (options && name === "contact" && !isValidPhone(value)) {
    res.message = "Not valid phone number!";
    res.isValid = false;
    res.required = options.required;
  } else {
    switch (name) {
      case "email":
        if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
          res.message = "Not valid email!";
          res.isValid = false;
          res.required = options.required;
        }
        break;
      case "password":
        if (value.length < 6) {
          res.message = "Min 6 characters!";
          res.isValid = false;
          res.required = options.required;
        }
        break;
      default:
        break;
    }
  }
  return res;
}

const rad = (x) => {
  return (x * Math.PI) / 180;
};

export const getDistanceFromCoords = (p1, p2) => {
  const R = 6378137; // Earth’s mean radius in meter
  const dLat = rad(p2.lat - p1.lat);
  const dLong = rad(p2.lng - p1.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) *
      Math.cos(rad(p2.lat)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d; // returns the distance in meter
};

export const getFullName = (user) => {
  const { first_name, last_name } = user;

  let fullName = first_name;

  if (last_name) {
    fullName += ` ${last_name}`;
  }

  return fullName;
};

export const arrayInRange = (start, stop, step) => {
  return Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  );
};

export const getDateLabel = ({ startDate, endDate, onlyToday }) => {
  const diff = Math.ceil(
    moment(endDate)
      .endOf("day")
      .diff(moment(startDate).endOf("day"), "days", true)
  );
  if (
    diff === 0 &&
    moment().format("YYYY-MM-DD") === moment(startDate).format("YYYY-MM-DD")
  ) {
    return ` (Today selected)`;
  }
  if (onlyToday) return null;
  return ` (${diff + 1 + " day" + (diff > 0 ? "s" : "") + " " + "selected"})`;
};

export const capitalizeFirst = (word) => {
  if (word && word[0] !== word[0].toUpperCase()) {
    return word[0].toUpperCase() + word.slice(1);
  }
  return word;
};
