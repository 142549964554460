export const currenciesWithSymbols = [
  // important
  { value: "USD", label: "USD ($) - United States dollar", symbol: "$" },
  { value: "EUR", label: "EUR (€) - Euro", symbol: "€" },
  { value: "GBP", label: "GBP (£) - Pound sterling", symbol: "£" },
  { value: "CAD", label: "CAD ($) - Canadian dollar", symbol: "$" },
  { value: "AUD", label: "AUD ($) - Australian dollar", symbol: "$" },
  { value: "CHF", label: "CHF (Fr.) - Swiss franc", symbol: "Fr." },
  { value: "AED", label: "AED (د.إ) - UAE dirham", symbol: "د.إ" },
  { value: "BDT", label: "BDT (৳) - Bangladeshi taka", symbol: "৳" },
  { value: "JPY", label: "JPY (¥) - Japanese yen", symbol: "¥" },
  { value: "CNY", label: "CNY (¥) - Chinese/Yuan renminbi", symbol: "¥" },
  { value: "SAR", label: "SAR (SR) - Saudi riyal", symbol: "SR" },
  // important
  { value: "AFN", label: "AFN (Afs) - Afghan afghani", symbol: "Afs" },
  { value: "ALL", label: "ALL (L) - Albanian lek", symbol: "L" },
  { value: "AMD", label: "AMD (AMD) - Armenian dram", symbol: "AMD" },
  {
    value: "ANG",
    label: "ANG (NAƒ) - Netherlands Antillean gulden",
    symbol: "NAƒ",
  },
  { value: "AOA", label: "AOA (Kz) - Angolan kwanza", symbol: "Kz" },
  { value: "ARS", label: "ARS ($) - Argentine peso", symbol: "$" },

  { value: "AWG", label: "AWG (ƒ) - Aruban florin", symbol: "ƒ" },
  { value: "AZN", label: "AZN (AZN) - Azerbaijani manat", symbol: "AZN" },
  {
    value: "BAM",
    label: "BAM (KM) - Bosnia and Herzegovina konvertibilna marka",
    symbol: "KM",
  },
  { value: "BBD", label: "BBD (Bds$) - Barbadian dollar", symbol: "Bds$" },
  { value: "BGN", label: "BGN (BGN) - Bulgarian lev", symbol: "BGN" },
  { value: "BHD", label: "BHD (.د.ب) - Bahraini dinar", symbol: ".د.ب" },
  { value: "BIF", label: "BIF (FBu) - Burundi franc", symbol: "FBu" },
  { value: "BMD", label: "BMD (BD$) - Bermudian dollar", symbol: "BD$" },
  { value: "BND", label: "BND (B$) - Brunei dollar", symbol: "B$" },
  { value: "BOB", label: "BOB (Bs.) - Bolivian boliviano", symbol: "Bs." },
  { value: "BRL", label: "BRL (R$) - Brazilian real", symbol: "R$" },
  { value: "BSD", label: "BSD (B$) - Bahamian dollar", symbol: "B$" },
  { value: "BTN", label: "BTN (Nu.) - Bhutanese ngultrum", symbol: "Nu." },
  { value: "BWP", label: "BWP (P) - Botswana pula", symbol: "P" },
  { value: "BYR", label: "BYR (Br) - Belarusian ruble", symbol: "Br" },
  { value: "BZD", label: "BZD (BZ$) - Belize dollar", symbol: "BZ$" },
  { value: "CDF", label: "CDF (F) - Congolese franc", symbol: "F" },
  { value: "CLP", label: "CLP ($) - Chilean peso", symbol: "$" },
  { value: "COP", label: "COP (Col$) - Colombian peso", symbol: "Col$" },
  { value: "CRC", label: "CRC (₡) - Costa Rican colon", symbol: "₡" },
  { value: "CUC", label: "CUC ($) - Cuban peso", symbol: "$" },
  { value: "CVE", label: "CVE (Esc) - Cape Verdean escudo", symbol: "Esc" },
  { value: "CZK", label: "CZK (Kč) - Czech koruna", symbol: "Kč" },
  { value: "DJF", label: "DJF (Fdj) - Djiboutian franc", symbol: "Fdj" },
  { value: "DKK", label: "DKK (Kr) - Danish krone", symbol: "Kr" },
  { value: "DOP", label: "DOP (RD$) - Dominican peso", symbol: "RD$" },
  { value: "DZD", label: "DZD (د.ج) - Algerian dinar", symbol: "د.ج" },
  { value: "EEK", label: "EEK (KR) - Estonian kroon", symbol: "KR" },
  { value: "EGP", label: "EGP (£) - Egyptian pound", symbol: "£" },
  { value: "ERN", label: "ERN (Nfa) - Eritrean nakfa", symbol: "Nfa" },
  { value: "ETB", label: "ETB (Br) - Ethiopian birr", symbol: "Br" },
  { value: "FJD", label: "FJD (FJ$) - Fijian dollar", symbol: "FJ$" },
  { value: "FKP", label: "FKP (£) - Falkland Islands pound", symbol: "£" },
  { value: "GEL", label: "GEL (GEL) - Georgian lari", symbol: "GEL" },
  { value: "GHS", label: "GHS (GH₵) - Ghanaian cedi", symbol: "GH₵" },
  { value: "GIP", label: "GIP (£) - Gibraltar pound", symbol: "£" },
  { value: "GMD", label: "GMD (D) - Gambian dalasi", symbol: "D" },
  { value: "GNF", label: "GNF (FG) - Guinean franc", symbol: "FG" },
  {
    value: "GQE",
    label: "GQE (CFA) - Central African CFA franc",
    symbol: "CFA",
  },
  { value: "GTQ", label: "GTQ (Q) - Guatemalan quetzal", symbol: "Q" },
  { value: "GYD", label: "GYD (GY$) - Guyanese dollar", symbol: "GY$" },
  { value: "HKD", label: "HKD (HK$) - Hong Kong dollar", symbol: "HK$" },
  { value: "HNL", label: "HNL (L) - Honduran lempira", symbol: "L" },
  { value: "HRK", label: "HRK (kn) - Croatian kuna", symbol: "kn" },
  { value: "HTG", label: "HTG (G) - Haitian gourde", symbol: "G" },
  { value: "HUF", label: "HUF (Ft) - Hungarian forint", symbol: "Ft" },
  { value: "IDR", label: "IDR (Rp) - Indonesian rupiah", symbol: "Rp" },
  { value: "ILS", label: "ILS (₪) - Israeli new sheqel", symbol: "₪" },
  { value: "INR", label: "INR (₹) - Indian rupee", symbol: "₹" },
  { value: "IQD", label: "IQD (د.ع) - Iraqi dinar", symbol: "د.ع" },
  { value: "IRR", label: "IRR (IRR) - Iranian rial", symbol: "IRR" },
  { value: "ISK", label: "ISK (kr) - Icelandic króna", symbol: "kr" },
  { value: "JMD", label: "JMD (J$) - Jamaican dollar", symbol: "J$" },
  { value: "JOD", label: "JOD (JOD) - Jordanian dinar", symbol: "JOD" },
  { value: "KES", label: "KES (KSh) - Kenyan shilling", symbol: "KSh" },
  { value: "KGS", label: "KGS (сом) - Kyrgyzstani som", symbol: "сом" },
  { value: "KHR", label: "KHR (៛) - Cambodian riel", symbol: "៛" },
  { value: "KMF", label: "KMF (KMF) - Comorian franc", symbol: "KMF" },
  { value: "KPW", label: "KPW (W) - North Korean won", symbol: "W" },
  { value: "KRW", label: "KRW (W) - South Korean won", symbol: "W" },
  { value: "KWD", label: "KWD (KWD) - Kuwaiti dinar", symbol: "KWD" },
  { value: "KYD", label: "KYD (KY$) - Cayman Islands dollar", symbol: "KY$" },
  { value: "KZT", label: "KZT (T) - Kazakhstani tenge", symbol: "T" },
  { value: "LAK", label: "LAK (KN) - Lao kip", symbol: "KN" },
  { value: "LBP", label: "LBP (£) - Lebanese lira", symbol: "£" },
  { value: "LKR", label: "LKR (Rs) - Sri Lankan rupee", symbol: "Rs" },
  { value: "LRD", label: "LRD (L$) - Liberian dollar", symbol: "L$" },
  { value: "LSL", label: "LSL (M) - Lesotho loti", symbol: "M" },
  { value: "LTL", label: "LTL (Lt) - Lithuanian litas", symbol: "Lt" },
  { value: "LVL", label: "LVL (Ls) - Latvian lats", symbol: "Ls" },
  { value: "LYD", label: "LYD (LD) - Libyan dinar", symbol: "LD" },
  { value: "MAD", label: "MAD (MAD) - Moroccan dirham", symbol: "MAD" },
  { value: "MDL", label: "MDL (MDL) - Moldovan leu", symbol: "MDL" },
  { value: "MGA", label: "MGA (FMG) - Malagasy ariary", symbol: "FMG" },
  { value: "MKD", label: "MKD (MKD) - Macedonian denar", symbol: "MKD" },
  { value: "MMK", label: "MMK (K) - Myanma kyat", symbol: "K" },
  { value: "MNT", label: "MNT (₮) - Mongolian tugrik", symbol: "₮" },
  { value: "MOP", label: "MOP (P) - Macanese pataca", symbol: "P" },
  { value: "MRO", label: "MRO (UM) - Mauritanian ouguiya", symbol: "UM" },
  { value: "MUR", label: "MUR (Rs) - Mauritian rupee", symbol: "Rs" },
  { value: "MVR", label: "MVR (Rf) - Maldivian rufiyaa", symbol: "Rf" },
  { value: "MWK", label: "MWK (MK) - Malawian kwacha", symbol: "MK" },
  { value: "MXN", label: "MXN ($) - Mexican peso", symbol: "$" },
  { value: "MYR", label: "MYR (RM) - Malaysian ringgit", symbol: "RM" },
  { value: "MZM", label: "MZM (MTn) - Mozambican metical", symbol: "MTn" },
  { value: "NAD", label: "NAD (N$) - Namibian dollar", symbol: "N$" },
  { value: "NGN", label: "NGN (₦) - Nigerian naira", symbol: "₦" },
  { value: "NIO", label: "NIO (C$) - Nicaraguan córdoba", symbol: "C$" },
  { value: "NOK", label: "NOK (kr) - Norwegian krone", symbol: "kr" },
  { value: "NPR", label: "NPR (NRs) - Nepalese rupee", symbol: "NRs" },
  { value: "NZD", label: "NZD (NZ$) - New Zealand dollar", symbol: "NZ$" },
  { value: "OMR", label: "OMR (OMR) - Omani rial", symbol: "OMR" },
  { value: "PAB", label: "PAB (B./) - Panamanian balboa", symbol: "B./" },
  { value: "PEN", label: "PEN (S/.) - Peruvian nuevo sol", symbol: "S/." },
  { value: "PGK", label: "PGK (K) - Papua New Guinean kina", symbol: "K" },
  { value: "PHP", label: "PHP (₱) - Philippine peso", symbol: "₱" },
  { value: "PKR", label: "PKR (Rs.) - Pakistani rupee", symbol: "Rs." },
  { value: "PLN", label: "PLN (zł) - Polish zloty", symbol: "zł" },
  { value: "PYG", label: "PYG (₲) - Paraguayan guarani", symbol: "₲" },
  { value: "QAR", label: "QAR (QR) - Qatari riyal", symbol: "QR" },
  { value: "RON", label: "RON (L) - Romanian leu", symbol: "L" },
  { value: "RSD", label: "RSD (din.) - Serbian dinar", symbol: "din." },
  { value: "RUB", label: "RUB (R) - Russian ruble", symbol: "R" },
  { value: "SBD", label: "SBD (SI$) - Solomon Islands dollar", symbol: "SI$" },
  { value: "SCR", label: "SCR (SR) - Seychellois rupee", symbol: "SR" },
  { value: "SDG", label: "SDG (SDG) - Sudanese pound", symbol: "SDG" },
  { value: "SEK", label: "SEK (kr) - Swedish krona", symbol: "kr" },
  { value: "SGD", label: "SGD (S$) - Singapore dollar", symbol: "S$" },
  { value: "SHP", label: "SHP (£) - Saint Helena pound", symbol: "£" },
  { value: "SLL", label: "SLL (Le) - Sierra Leonean leone", symbol: "Le" },
  { value: "SOS", label: "SOS (Sh.) - Somali shilling", symbol: "Sh." },
  { value: "SRD", label: "SRD ($) - Surinamese dollar", symbol: "$" },
  { value: "SYP", label: "SYP (LS) - Syrian pound", symbol: "LS" },
  { value: "SZL", label: "SZL (E) - Swazi lilangeni", symbol: "E" },
  { value: "THB", label: "THB (฿) - Thai baht", symbol: "฿" },
  { value: "TJS", label: "TJS (TJS) - Tajikistani somoni", symbol: "TJS" },
  { value: "TMT", label: "TMT (m) - Turkmen manat", symbol: "m" },
  { value: "TND", label: "TND (DT) - Tunisian dinar", symbol: "DT" },
  { value: "TRY", label: "TRY (TRY) - Turkish new lira", symbol: "TRY" },
  {
    value: "TTD",
    label: "TTD (TT$) - Trinidad and Tobago dollar",
    symbol: "TT$",
  },
  { value: "TWD", label: "TWD (NT$) - New Taiwan dollar", symbol: "NT$" },
  { value: "TZS", label: "TZS (TZS) - Tanzanian shilling", symbol: "TZS" },
  { value: "UAH", label: "UAH (UAH) - Ukrainian hryvnia", symbol: "UAH" },
  { value: "UGX", label: "UGX (USh) - Ugandan shilling", symbol: "USh" },
  { value: "UYU", label: "UYU ($U) - Uruguayan peso", symbol: "$U" },
  { value: "UZS", label: "UZS (UZS) - Uzbekistani som", symbol: "UZS" },
  { value: "VEB", label: "VEB (Bs) - Venezuelan bolivar", symbol: "Bs" },
  { value: "VND", label: "VND (₫) - Vietnamese dong", symbol: "₫" },
  { value: "VUV", label: "VUV (VT) - Vanuatu vatu", symbol: "VT" },
  { value: "WST", label: "WST (WS$) - Samoan tala", symbol: "WS$" },
  {
    value: "XAF",
    label: "XAF (CFA) - Central African CFA franc",
    symbol: "CFA",
  },
  { value: "XCD", label: "XCD (EC$) - East Caribbean dollar", symbol: "EC$" },
  { value: "XDR", label: "XDR (SDR) - Special Drawing Rights", symbol: "SDR" },
  { value: "XOF", label: "XOF (CFA) - West African CFA franc", symbol: "CFA" },
  { value: "XPF", label: "XPF (F) - CFP franc", symbol: "F" },
  { value: "YER", label: "YER (YER) - Yemeni rial", symbol: "YER" },
  { value: "ZAR", label: "ZAR (R) - South African rand", symbol: "R" },
  { value: "ZMK", label: "ZMK (ZK) - Zambian kwacha", symbol: "ZK" },
];

export const currencies = [
  { value: "USD", label: "USD - US Dollar" },
  { value: "AED", label: "AED - UAE Dirham" },
  { value: "AFN", label: "AFN - Afghani" },
  { value: "ALL", label: "ALL - Lek" },
  { value: "AMD", label: "AMD - Armenian Dram" },
  { value: "ANG", label: "ANG - Netherlands Antillean Guilder" },
  { value: "AOA", label: "AOA - Kwanza" },
  { value: "ARS", label: "ARS - Argentine Peso" },
  { value: "AUD", label: "AUD - Australian Dollar" },
  { value: "AWG", label: "AWG - Aruban Florin" },
  { value: "AZN", label: "AZN - Azerbaijanian Manat" },
  { value: "BAM", label: "BAM - Convertible Mark" },
  { value: "BBD", label: "BBD - Barbados Dollar" },
  { value: "BDT", label: "BDT - Bangladeshi Taka" },
  { value: "BGN", label: "BGN - Bulgarian Lev" },
  { value: "BHD", label: "BHD - Bahraini Dinar" },
  { value: "BIF", label: "BIF - Burundi Franc" },
  { value: "BMD", label: "BMD - Bermudian Dollar" },
  { value: "BND", label: "BND - Brunei Dollar" },
  { value: "BOB", label: "BOB - Boliviano" },
  { value: "BRL", label: "BRL - Brazilian Real" },
  { value: "BSD", label: "BSD - Bahamian Dollar" },
  { value: "BTN", label: "BTN - Ngultrum" },
  { value: "BWP", label: "BWP - Pula" },
  { value: "BYN", label: "BYN - Belarusian Ruble" },
  { value: "BZD", label: "BZD - Belize Dollar" },
  { value: "CAD", label: "CAD - Canadian Dollar" },
  { value: "CDF", label: "CDF - Congolese Franc" },
  { value: "CHF", label: "CHF - Swiss Franc" },
  { value: "CLP", label: "CLP - Chilean Peso" },
  { value: "CNY", label: "CNY - Yuan Renminbi" },
  { value: "COP", label: "COP - Colombian Peso" },
  { value: "CRC", label: "CRC - Costa Rican Colon" },
  { value: "CUC", label: "CUC - Peso Convertible" },
  { value: "CUP", label: "CUP - Cuban Peso" },
  { value: "CVE", label: "CVE - Cabo Verde Escudo" },
  { value: "CZK", label: "CZK - Czech Koruna" },
  { value: "DJF", label: "DJF - Djibouti Franc" },
  { value: "DKK", label: "DKK - Danish Krone" },
  { value: "DOP", label: "DOP - Dominican Peso" },
  { value: "DZD", label: "DZD - Algerian Dinar" },
  { value: "EGP", label: "EGP - Egyptian Pound" },
  { value: "ERN", label: "ERN - Nakfa" },
  { value: "ETB", label: "ETB - Ethiopian Birr" },
  { value: "EUR", label: "EUR - Euro" },
  { value: "FJD", label: "FJD - Fiji Dollar" },
  { value: "FKP", label: "FKP - Falkland Islands Pound" },
  { value: "GBP", label: "GBP - Pound Sterling" },
  { value: "GEL", label: "GEL - Lari" },
  { value: "GHS", label: "GHS - Ghana Cedi" },
  { value: "GIP", label: "GIP - Gibraltar Pound" },
  { value: "GMD", label: "GMD - Dalasi" },
  { value: "GNF", label: "GNF - Guinea Franc" },
  { value: "GTQ", label: "GTQ - Quetzal" },
  { value: "GYD", label: "GYD - Guyana Dollar" },
  { value: "HKD", label: "HKD - Hong Kong Dollar" },
  { value: "HNL", label: "HNL - Lempira" },
  { value: "HRK", label: "HRK - Kuna" },
  { value: "HTG", label: "HTG - Gourde" },
  { value: "HUF", label: "HUF - Forint" },
  { value: "IDR", label: "IDR - Rupiah" },
  { value: "ILS", label: "ILS - New Israeli Sheqel" },
  { value: "INR", label: "INR - Indian Rupee" },
  { value: "IQD", label: "IQD - Iraqi Dinar" },
  { value: "IRR", label: "IRR - Iranian Rial" },
  { value: "ISK", label: "ISK - Iceland Krona" },
  { value: "JMD", label: "JMD - Jamaican Dollar" },
  { value: "JOD", label: "JOD - Jordanian Dinar" },
  { value: "JPY", label: "JPY - Yen" },
  { value: "KES", label: "KES - Kenyan Shilling" },
  { value: "KGS", label: "KGS - Som" },
  { value: "KHR", label: "KHR - Riel" },
  { value: "KMF", label: "KMF - Comoro Franc" },
  { value: "KPW", label: "KPW - North Korean Won" },
  { value: "KRW", label: "KRW - Won" },
  { value: "KWD", label: "KWD - Kuwaiti Dinar" },
  { value: "KYD", label: "KYD - Cayman Islands Dollar" },
  { value: "KZT", label: "KZT - Tenge" },
  { value: "LAK", label: "LAK - Kip" },
  { value: "LBP", label: "LBP - Lebanese Pound" },
  { value: "LKR", label: "LKR - Sri Lanka Rupee" },
  { value: "LRD", label: "LRD - Liberian Dollar" },
  { value: "LSL", label: "LSL - Loti" },
  { value: "LYD", label: "LYD - Libyan Dinar" },
  { value: "MAD", label: "MAD - Moroccan Dirham" },
  { value: "MDL", label: "MDL - Moldovan Leu" },
  { value: "MGA", label: "MGA - Malagasy Ariary" },
  { value: "MKD", label: "MKD - Denar" },
  { value: "MMK", label: "MMK - Kyat" },
  { value: "MNT", label: "MNT - Tugrik" },
  { value: "MOP", label: "MOP - Pataca" },
  { value: "MRO", label: "MRO - Ouguiya" },
  { value: "MUR", label: "MUR - Mauritius Rupee" },
  { value: "MVR", label: "MVR - Rufiyaa" },
  { value: "MWK", label: "MWK - Malawi Kwacha" },
  { value: "MXN", label: "MXN - Mexican Peso" },
  { value: "MYR", label: "MYR - Malaysian Ringgit" },
  { value: "MZN", label: "MZN - Mozambique Metical" },
  { value: "NAD", label: "NAD - Namibia Dollar" },
  { value: "NGN", label: "NGN - Naira" },
  { value: "NIO", label: "NIO - Cordoba Oro" },
  { value: "NOK", label: "NOK - Norwegian Krone" },
  { value: "NPR", label: "NPR - Nepalese Rupee" },
  { value: "NZD", label: "NZD - New Zealand Dollar" },
  { value: "OMR", label: "OMR - Rial Omani" },
  { value: "PAB", label: "PAB - Balboa" },
  { value: "PEN", label: "PEN - Sol" },
  { value: "PGK", label: "PGK - Kina" },
  { value: "PHP", label: "PHP - Philippine Peso" },
  { value: "PKR", label: "PKR - Pakistan Rupee" },
  { value: "PLN", label: "PLN - Zloty" },
  { value: "PYG", label: "PYG - Guarani" },
  { value: "QAR", label: "QAR - Qatari Rial" },
  { value: "RON", label: "RON - Romanian Leu" },
  { value: "RSD", label: "RSD - Serbian Dinar" },
  { value: "RUB", label: "RUB - Russian Ruble" },
  { value: "RWF", label: "RWF - Rwanda Franc" },
  { value: "SAR", label: "SAR - Saudi Riyal" },
  { value: "SBD", label: "SBD - Solomon Islands Dollar" },
  { value: "SCR", label: "SCR - Seychelles Rupee" },
  { value: "SDG", label: "SDG - Sudanese Pound" },
  { value: "SEK", label: "SEK - Swedish Krona" },
  { value: "SGD", label: "SGD - Singapore Dollar" },
  { value: "SHP", label: "SHP - Saint Helena Pound" },
  { value: "SLL", label: "SLL - Leone" },
  { value: "SOS", label: "SOS - Somali Shilling" },
  { value: "SRD", label: "SRD - Surinam Dollar" },
  { value: "SSP", label: "SSP - South Sudanese Pound" },
  { value: "STD", label: "STD - Dobra" },
  { value: "SVC", label: "SVC - El Salvador Colon" },
  { value: "SYP", label: "SYP - Syrian Pound" },
  { value: "SZL", label: "SZL - Lilangeni" },
  { value: "THB", label: "THB - Baht" },
  { value: "TJS", label: "TJS - Somoni" },
  { value: "TMT", label: "TMT - Turkmenistan New Manat" },
  { value: "TND", label: "TND - Tunisian Dinar" },
  { value: "TOP", label: "TOP - Pa\u2019anga" },
  { value: "TRY", label: "TRY - Turkish Lira" },
  { value: "TTD", label: "TTD - Trinidad and Tobago Dollar" },
  { value: "TWD", label: "TWD - New Taiwan Dollar" },
  { value: "TZS", label: "TZS - Tanzanian Shilling" },
  { value: "UAH", label: "UAH - Hryvnia" },
  { value: "UGX", label: "UGX - Uganda Shilling" },
  { value: "UYU", label: "UYU - Peso Uruguayo" },
  { value: "UZS", label: "UZS - Uzbekistan Sum" },
  { value: "VEF", label: "VEF - Bol\u00edvar" },
  { value: "VND", label: "VND - Dong" },
  { value: "VUV", label: "VUV - Vatu" },
  { value: "WST", label: "WST - Tala" },
  { value: "XAF", label: "XAF - CFA Franc BEAC" },
  { value: "XAG", label: "XAG - Silver" },
  { value: "XAU", label: "XAU - Gold" },
  { value: "XCD", label: "XCD - East Caribbean Dollar" },
  { value: "XOF", label: "XOF - CFA Franc BCEAO" },
  { value: "XPD", label: "XPD - Palladium" },
  { value: "XPF", label: "XPF - CFP Franc" },
  { value: "XPT", label: "XPT - Platinum" },
  { value: "XSU", label: "XSU - Sucre" },
  { value: "YER", label: "YER - Yemeni Rial" },
  { value: "ZAR", label: "ZAR - Rand" },
  { value: "ZMW", label: "ZMW - Zambian Kwacha" },
];

export const currencySymbolMap = {
  USD: "$",
  EUR: "€",
  GBP: "£",
  CAD: "$",
  AUD: "$",
  CHF: "Fr.",
  AED: "د.إ",
  BDT: "৳",
  JPY: "¥",
  CNY: "¥",
  SAR: "SR",
  AFN: "Afs",
  ALL: "L",
  AMD: "AMD",
  ANG: "NAƒ",
  AOA: "Kz",
  ARS: "$",
  AWG: "ƒ",
  AZN: "AZN",
  BAM: "KM",
  BBD: "Bds$",
  BGN: "BGN",
  BHD: ".د.ب",
  BIF: "FBu",
  BMD: "BD$",
  BND: "B$",
  BOB: "Bs.",
  BRL: "R$",
  BSD: "B$",
  BTN: "Nu.",
  BWP: "P",
  BYR: "Br",
  BZD: "BZ$",
  CDF: "F",
  CLP: "$",
  COP: "Col$",
  CRC: "₡",
  CUC: "$",
  CVE: "Esc",
  CZK: "Kč",
  DJF: "Fdj",
  DKK: "Kr",
  DOP: "RD$",
  DZD: "د.ج",
  EEK: "KR",
  EGP: "£",
  ERN: "Nfa",
  ETB: "Br",
  FJD: "FJ$",
  FKP: "£",
  GEL: "GEL",
  GHS: "GH₵",
  GIP: "£",
  GMD: "D",
  GNF: "FG",
  GQE: "CFA",
  GTQ: "Q",
  GYD: "GY$",
  HKD: "HK$",
  HNL: "L",
  HRK: "kn",
  HTG: "G",
  HUF: "Ft",
  IDR: "Rp",
  ILS: "₪",
  INR: "₹",
  IQD: "د.ع",
  IRR: "IRR",
  ISK: "kr",
  JMD: "J$",
  JOD: "JOD",
  KES: "KSh",
  KGS: "сом",
  KHR: "៛",
  KMF: "KMF",
  KPW: "W",
  KRW: "W",
  KWD: "KWD",
  KYD: "KY$",
  KZT: "T",
  LAK: "KN",
  LBP: "£",
  LKR: "Rs",
  LRD: "L$",
  LSL: "M",
  LTL: "Lt",
  LVL: "Ls",
  LYD: "LD",
  MAD: "MAD",
  MDL: "MDL",
  MGA: "FMG",
  MKD: "MKD",
  MMK: "K",
  MNT: "₮",
  MOP: "P",
  MRO: "UM",
  MUR: "Rs",
  MVR: "Rf",
  MWK: "MK",
  MXN: "$",
  MYR: "RM",
  MZM: "MTn",
  NAD: "N$",
  NGN: "₦",
  NIO: "C$",
  NOK: "kr",
  NPR: "NRs",
  NZD: "NZ$",
  OMR: "OMR",
  PAB: "B./",
  PEN: "S/.",
  PGK: "K",
  PHP: "₱",
  PKR: "Rs.",
  PLN: "zł",
  PYG: "₲",
  QAR: "QR",
  RON: "L",
  RSD: "din.",
  RUB: "R",
  SBD: "SI$",
  SCR: "SR",
  SDG: "SDG",
  SEK: "kr",
  SGD: "S$",
  SHP: "£",
  SLL: "Le",
  SOS: "Sh.",
  SRD: "$",
  SYP: "LS",
  SZL: "E",
  THB: "฿",
  TJS: "TJS",
  TMT: "m",
  TND: "DT",
  TRY: "TRY",
  TTD: "TT$",
  TWD: "NT$",
  TZS: "TZS",
  UAH: "UAH",
  UGX: "USh",
  UYU: "$U",
  UZS: "UZS",
  VEB: "Bs",
  VND: "₫",
  VUV: "VT",
  WST: "WS$",
  XAF: "CFA",
  XCD: "EC$",
  XDR: "SDR",
  XOF: "CFA",
  XPF: "F",
  YER: "YER",
  ZAR: "R",
  ZMK: "ZK",
};
