//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  VERIFY_REQUEST,
  VERIFY_REQUEST_SUCCESS,
  VERIFY_REQUEST_FAILURE,
} from "../../modules/constants";

//Handle login request
export function* verifyRequest({ payload }) {
  try {
    let url = "authentication/confirm/";
    let method = "POST";
    const response = yield defaultApi(url, method, payload);
    yield put({
      type: VERIFY_REQUEST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: VERIFY_REQUEST_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([takeLatest(VERIFY_REQUEST, verifyRequest)]);
}
