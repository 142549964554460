import { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import DatePicker from "../../components/SingleDatePicker/SingleDatePicker";
import moment from "moment";
import { FormAlert } from "../../styledComponents/createProject";
import {
  CardTitle,
  CommonText,
  InputWithLabelSection,
  InputLabel,
  InputField,
  InputTextArea,
  RedStar,
  CommonGrid,
} from "../../styledComponents/common";
import { WhiteButton, PrimaryButton } from "../../styledComponents/buttons";
import {
  ModalGreenSection,
  PreviewDetailsElement,
  ModalButtonSection,
} from "../../styledComponents/invoice";
import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";

import { currencySymbolMap } from "../../utils/currencies";

const RecordPaymentModal = ({
  isOpen,
  isLoading,
  toggle,
  invoice,
  selectedOrganization,
  recordPaymentInvoice,
  localCallback,
  onRecordPay,
}) => {
  const [paidAmount, setPaidAmount] = useState(0);
  const [paymentDate, setPaymentDate] = useState(moment());
  const [description, setDescription] = useState("");
  const [saveErrors, setSaveErrors] = useState({});

  useEffect(() => {
    if (isOpen) {
      setPaidAmount(0);
      setPaymentDate(moment());
      setDescription("");
      setSaveErrors({});
    }
  }, [isOpen]);

  const onPaidAmountChange = (e) => {
    setSaveErrors({});
    setPaidAmount(e.target.value);
  };

  const onPaymentDateChange = (date) => {
    setSaveErrors({});
    setPaymentDate(date);
  };

  const onProjectDescriptionChange = (e) => {
    setSaveErrors({});
    setDescription(e.target.value);
  };

  const checkError = () => {
    let formIsValid = true;
    let saveErrors = {};
    if (paidAmount === 0 || paidAmount === null || paidAmount === "") {
      saveErrors["paidAmountEmpty"] = "Paid amount can not be empty";
      formIsValid = false;
    } else if (paidAmount < 0) {
      saveErrors["PaidAmounNegative"] = "Paid amount value can't be negative.";
      formIsValid = false;
    }
    if (paymentDate === null) {
      saveErrors["paymentDateEmpty"] = "Payment date can't be empty";
      formIsValid = false;
    }
    if (description && description.length > 512) {
      saveErrors["description"] = "Description can have maximum 512 characters";
      formIsValid = false;
    }

    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleSave = () => {
    if (checkError()) {
      async function sequence() {
        try {
          const payload = {
            organization_id: selectedOrganization.id,
            invoice_id: invoice.id,
            amount: paidAmount,
            date: paymentDate.format("YYYY-MM-DD"),
            notes: description,
          };

          await recordPaymentInvoice(payload);
          (await localCallback) && onRecordPay();

          setTimeout(() => {
            setPaidAmount(0);
            setPaymentDate(null);
            setDescription("");
            setSaveErrors({});
            toggle();
          }, 450);
        } catch (error) {
          console.log(error);
        }
      }
      sequence();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ marginTop: `5vh`, maxWidth: `520px` }}
    >
      <ModalBody style={{ padding: `35px 0 15px 0` }}>
        <div style={{ padding: `0 30px` }}>
          <CardTitle fontSize="16px">Record Payment</CardTitle>
          <ModalGreenSection>
            <PreviewDetailsElement>
              <CommonText $label>Invoice Number</CommonText>
              <CommonText title>{invoice.invoice_number}</CommonText>
            </PreviewDetailsElement>
            <PreviewDetailsElement>
              <CommonText $label>Total Amount</CommonText>
              <CommonText title>
                {invoice.currency} ({currencySymbolMap[invoice.currency]}){" "}
                {invoice.discounted_amount.toFixed(2)}
              </CommonText>
            </PreviewDetailsElement>
            <PreviewDetailsElement>
              <CommonText $label>Amount Due</CommonText>
              <CommonText title>
                {invoice.currency} ({currencySymbolMap[invoice.currency]}){" "}
                {(invoice.discounted_amount - invoice.paid_amount).toFixed(2)}
              </CommonText>
            </PreviewDetailsElement>
          </ModalGreenSection>

          <CommonGrid columns="1fr 1fr">
            <InputWithLabelSection>
              <InputLabel>
                Amount paid<RedStar>*</RedStar>
              </InputLabel>
              <InputField
                type="number"
                placeholder="00.0"
                value={paidAmount}
                onChange={(e) => onPaidAmountChange(e)}
                style={
                  saveErrors &&
                  saveErrors["PaidAmounNegative"] && {
                    border: `1px solid #fe5969`,
                    backgroundColor: `rgba(252, 87, 104, 0.05)`,
                  }
                }
              />
              {saveErrors && saveErrors["paidAmountEmpty"] ? (
                <FormAlert>{saveErrors["paidAmountEmpty"]}</FormAlert>
              ) : (
                saveErrors &&
                saveErrors["PaidAmounNegative"] && (
                  <FormAlert>{saveErrors["PaidAmounNegative"]}</FormAlert>
                )
              )}
            </InputWithLabelSection>
            <InputWithLabelSection>
              <InputLabel>
                Payment Date<RedStar>*</RedStar>
              </InputLabel>
              <DatePicker
                id="createPaymentDate"
                date={paymentDate}
                onDateChange={onPaymentDateChange}
                dateDisplayFormat={"DD/MM/YYYY"}
                allowPreviousDates
              />
              {saveErrors && saveErrors["paymentDateEmpty"] && (
                <FormAlert>{saveErrors["paymentDateEmpty"]}</FormAlert>
              )}
            </InputWithLabelSection>
          </CommonGrid>
          <InputLabel style={{ margin: `20px 0 10px 0` }}>Note</InputLabel>
          <InputTextArea
            height="100px"
            text="note"
            cols="40"
            rows="5"
            value={description}
            placeholder="Anything you want to note..."
            onChange={(e) => onProjectDescriptionChange(e)}
          ></InputTextArea>
          {saveErrors && saveErrors["description"] && (
            <FormAlert>{saveErrors["description"]}</FormAlert>
          )}
        </div>
        <ModalButtonSection>
          <WhiteButton type="cancel" onClick={toggle}>
            Cancel
          </WhiteButton>
          <PrimaryButton
            padding={isLoading ? "0 15px" : "0 35px"}
            onClick={() => handleSave()}
          >
            {isLoading ? (
              <ButtonTextLoader loadingText="Saving" fontSize="13px" />
            ) : (
              "Save"
            )}
          </PrimaryButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default RecordPaymentModal;
