import React, { useState, useEffect } from "react";
import moment from "moment";
import DateRangePicker from "../../components/ReactDateRangePicker/ReactDateRangePicker";
import AddSingleMemberDropdown from "../../components/CustomDropdown/AddSingleMemberDropdown";
import {
  TabButton,
  TabButtonContainer,
  ColoredButton,
} from "../../styledComponents/buttons";

import {
  FilterLabel,
  HeaderContainer,
  InputWithLabelSection,
  CommonGrid,
} from "../../styledComponents/common";

import { TabSearchGrid } from "styledComponents/ProjectsNew";
import { getDateLabel } from "../../utils/helper";

const LeaveFilter = (props) => {
  const [selectedMember, setSelectedMember] = useState("");

  const [memberOptions, setMemberOptions] = useState([]);

  const {
    selectedOrganization,
    organizationMembersList,
    getOrganizationMembersList,
    history,
    toggle,

    tabSelect,
    onTabSwitch,
    startDate,
    endDate,
    onDateSelect,
    changeDateRange,
    selectLastWeek,
    selectLastMonth,
    onMemberSelect,
    onClearMember,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      setSelectedMember("");
      const payload = {
        organization_id: selectedOrganization.id,
        start_date: startDate,
        end_date: endDate,
      };
      getOrganizationMembersList({
        organization_id: selectedOrganization.id,
      });
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (organizationMembersList && organizationMembersList.length > 0) {
      setMemberOptions(organizationMembersList);
    } else if (
      organizationMembersList &&
      organizationMembersList.length === 0
    ) {
      setMemberOptions([]);
    }
  }, [organizationMembersList]);

  const selectMember = (e) => {
    if (e && e.id) {
      setSelectedMember(e);
    }

    if (onMemberSelect) {
      onMemberSelect(e);
    }
  };

  const clearSelectedMember = () => {
    setSelectedMember("");
    if (onClearMember) {
      onClearMember();
    }
  };

  const openApplyLeaveModal = () => {
    toggle();
  };

  return (
    <HeaderContainer>
      <TabSearchGrid columns="auto auto auto">
        <InputWithLabelSection>
          <FilterLabel>Type</FilterLabel>
          <TabButtonContainer columns="auto auto auto">
            <TabButton
              onClick={() => onTabSwitch("pending")}
              selected={tabSelect}
              index={"pending"}
            >
              Pending
            </TabButton>
            <TabButton
              onClick={() => onTabSwitch("accepted")}
              selected={tabSelect}
              index={"accepted"}
            >
              Accepted
            </TabButton>
            <TabButton
              onClick={() => onTabSwitch("rejected")}
              selected={tabSelect}
              index={"rejected"}
            >
              Rejected
            </TabButton>
          </TabButtonContainer>
        </InputWithLabelSection>
        <InputWithLabelSection>
          <FilterLabel>
            Application Date
            {getDateLabel({ startDate, endDate })}
          </FilterLabel>
          <DateRangePicker
            startDate={startDate ? moment(startDate) : null}
            startDateId="report-date-range-start-date"
            endDate={endDate ? moment(endDate) : null}
            endDateId="report-date-range-end-date"
            onDatesChange={changeDateRange}
            dateDisplayFormat="DD/MM/YYYY"
            onDateSelect={onDateSelect}
            selectLastWeek={selectLastWeek}
            selectLastMonth={selectLastMonth}
          />
        </InputWithLabelSection>
        <InputWithLabelSection>
          <FilterLabel>Members</FilterLabel>
          <div style={{ width: `40px` }}>
            <AddSingleMemberDropdown
              assigneeManage={selectedMember}
              updateState={selectMember}
              membersList={memberOptions}
              clearAssignee={() => clearSelectedMember()}
            />
          </div>
        </InputWithLabelSection>
      </TabSearchGrid>
      <CommonGrid alignItem="center">
        {/* <ColoredButton
          type="blue"
          onClick={() => history.push("/user/leave-calender")}
        >
          Leaves
        </ColoredButton> */}
        <ColoredButton type="black" onClick={() => openApplyLeaveModal()}>
          Apply For Leave
        </ColoredButton>
      </CommonGrid>
    </HeaderContainer>
  );
};

export default LeaveFilter;
