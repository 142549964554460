import {
  GET_TEAM_LIST,
  GET_TEAM_SHORT_LIST,
  GET_TEAM_DETAILS,
  ADD_TEAM_REQUEST,
  CLEAR_CREATED_TEAM_ID,
  UPDATE_TEAM_REQUEST,
  DELETE_TEAM_REQUEST,
  GET_TEAM_MEMBERS_LIST,
  CLEAR_TEAM_MEMBERS_LIST,
  ADD_TEAM_MEMBER,
  UPDATE_TEAM_MEMBER,
  REMOVE_TEAM_MEMBER,
  GET_TEAM_PROJECTS_LIST,
} from "../../modules/constants";

// ------------------------------------
// Actions
// ------------------------------------

export function getTeamList(value) {
  return {
    type: GET_TEAM_LIST,
    payload: value,
  };
}

export function getTeamShortList(value) {
  return {
    type: GET_TEAM_SHORT_LIST,
    payload: value,
  };
}

export function getTeamDetails(value) {
  return {
    type: GET_TEAM_DETAILS,
    payload: value,
  };
}

export function addTeam(value) {
  return {
    type: ADD_TEAM_REQUEST,
    payload: value,
  };
}

export function clearCreatedTeamId() {
  return {
    type: CLEAR_CREATED_TEAM_ID,
  };
}

export function updateTeam(value) {
  return {
    type: UPDATE_TEAM_REQUEST,
    payload: value,
  };
}

export function deleteTeam(value) {
  return {
    type: DELETE_TEAM_REQUEST,
    payload: value,
  };
}

export function getTeamMembersList(value) {
  return {
    type: GET_TEAM_MEMBERS_LIST,
    payload: value,
  };
}

export function clearTeamMembersList() {
  return {
    type: CLEAR_TEAM_MEMBERS_LIST,
  };
}

export function addTeamMember(value) {
  return {
    type: ADD_TEAM_MEMBER,
    payload: value,
  };
}

export function updateTeamMember(value) {
  return {
    type: UPDATE_TEAM_MEMBER,
    payload: value,
  };
}

export function removeTeamMember(value) {
  return {
    type: REMOVE_TEAM_MEMBER,
    payload: value,
  };
}

export function getTeamProjectsList(value) {
  return {
    type: GET_TEAM_PROJECTS_LIST,
    payload: value,
  };
}
