import { connect } from "react-redux";

import CreateProject from "./CreateProject";

// Actions
import {
  createProject,
  clearCreatedProject,
  updateProject,
  getOrganizationMembersList,
  addProjectAssignee,
  updateProjectAssignee,
  deleteProjectAssignee,
  getProjectAssigneeList,
  clearProjectAssigneeList,
  getProjectTaskList,
  projectAddTask,
  projectEditTask,
  projectDeleteTask,
  getTaskAssigneeList,
  addTaskAssignee,
  deleteTaskAssignee,
  addTeamToProject,
  getProjectBilling,
  addProjectBilling,
  updateProjectBilling,
  clearProjectBilling,
  getProjectBudget,
  addProjectBudget,
  updateProjectBudget,
  clearProjectBudget,
} from "../projectsActions";
import { getTaskList } from "../../Tasks/tasksActions";
import { getClientList } from "../../Clients/clientsActions";
import { getTeamShortList } from "../../Teams/teamsActions";
import { setPaymentConfiguration } from "../../Members/membersActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  isLoading: state.project.isLoading,
  createProjectIsLoading: state.project.createProjectIsLoading,
  updateProjectIsLoading: state.project.updateProjectIsLoading,
  saveBillingLoading: state.project.saveBillingLoading,
  saveBudgetLoading: state.project.saveBudgetLoading,
  projectAssigneeIsLoading: state.project.projectAssigneeIsLoading,
  loadingAssigneeId: state.project.loadingAssigneeId,
  projectTeamIsLoading: state.project.projectTeamIsLoading,
  loadingTeamId: state.project.loadingTeamId,
  taskListLoading: state.task.isLoading,
  taskIsLoading: state.project.taskIsLoading,
  loadingTaskId: state.project.loadingTaskId,
  taskAssigneeIsLoading: state.project.taskAssigneeIsLoading,
  loadingTaskAssigneeId: state.project.loadingTaskAssigneeId,
  clientList: state.client.clientList,
  createdProjectId: state.project.createdProjectId,
  selectedOrganization: state.organization.selectedOrganization,
  orgMembersList: state.project.orgMembersList,
  teamList: state.team.teamsShortList,
  projectAssigneeList: state.project.projectAssigneeList,
  addedAssigneeData: state.project.addedAssigneeData,
  projectAssigneeUpdated: state.project.projectAssigneeUpdated,
  updatedAssigneeData: state.project.updatedAssigneeData,
  projectBilling: state.project.projectBilling,
  projectBudget: state.project.projectBudget,
  // projectTaskList: state.project.projectTaskList,
  projectTaskList: state.task.tasks,

  // pagination
  projectTaskCount: state.task.projectTaskCount,
  projectTaskPageSize: state.task.projectTaskPageSize,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  createProject: (details) => dispatch(createProject({ ...details })),
  clearCreatedProject: () => dispatch(clearCreatedProject()),
  updateProject: (details) => dispatch(updateProject({ ...details })),
  getOrganizationMembersList: (details) =>
    dispatch(getOrganizationMembersList({ ...details })),
  getTeamList: (details) => dispatch(getTeamShortList({ ...details })),
  addProjectAssignee: (details) => dispatch(addProjectAssignee({ ...details })),
  updateProjectAssignee: (details) =>
    dispatch(updateProjectAssignee({ ...details })),
  deleteProjectAssignee: (details) =>
    dispatch(deleteProjectAssignee({ ...details })),
  getProjectAssigneeList: (details) =>
    dispatch(getProjectAssigneeList({ ...details })),
  clearProjectAssigneeList: () => dispatch(clearProjectAssigneeList()),
  // getProjectTaskList: (details) => dispatch(getProjectTaskList({ ...details })),
  getProjectTaskList: (details) => dispatch(getTaskList({ ...details })),
  projectAddTask: (details) => dispatch(projectAddTask({ ...details })),
  projectEditTask: (details) => dispatch(projectEditTask({ ...details })),
  projectDeleteTask: (details) => dispatch(projectDeleteTask({ ...details })),
  getTaskAssigneeList: (details) =>
    dispatch(getTaskAssigneeList({ ...details })),
  addTaskAssignee: (details) => dispatch(addTaskAssignee({ ...details })),
  deleteTaskAssignee: (details) => dispatch(deleteTaskAssignee({ ...details })),
  getClientList: (details) => dispatch(getClientList({ ...details })),
  addTeamToProject: (details) => dispatch(addTeamToProject({ ...details })),
  getProjectBilling: (details) => dispatch(getProjectBilling({ ...details })),
  addProjectBilling: (details) => dispatch(addProjectBilling({ ...details })),
  updateProjectBilling: (details) =>
    dispatch(updateProjectBilling({ ...details })),
  clearProjectBilling: () => dispatch(clearProjectBilling()),
  getProjectBudget: (details) => dispatch(getProjectBudget({ ...details })),
  addProjectBudget: (details) => dispatch(addProjectBudget({ ...details })),
  updateProjectBudget: (details) =>
    dispatch(updateProjectBudget({ ...details })),
  clearProjectBudget: () => dispatch(clearProjectBudget()),
  setPaymentConfiguration: (details) =>
    dispatch(setPaymentConfiguration({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(CreateProject);
