import { useState } from "react";

import {
  BillingTextTooltip,
  BillingQues,
  BillingTooltip,
  BillingTooltipText,
} from "../../styledComponents/createProject";
import { CommonText, RedStar } from "../../styledComponents/common";

const InfoTooltip = (props) => {
  const [active, setActive] = useState(false);
  return (
    <BillingTextTooltip padding={props.padding} marginTop={props.marginTop}>
      <CommonText
        title={props.title}
        $label={props.label}
        fontSize={props.fontSize}
      >
        {props.mainText}
        {props.redStar && <RedStar>*</RedStar>}
      </CommonText>
      <BillingQues
        size={props.infoSize}
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
      >
        ?
        {active && (
          <BillingTooltip
            top={props.top}
            left={props.left}
            width={props.width}
            padding={props.tooltipPadding}
            bgColor={props.bgColor}
          >
            <BillingTooltipText>{props.toolTipText}</BillingTooltipText>
            {props.moreText && (
              <BillingTooltipText>{props.moreText}</BillingTooltipText>
            )}
          </BillingTooltip>
        )}
      </BillingQues>
    </BillingTextTooltip>
  );
};

export default InfoTooltip;
