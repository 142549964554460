import {
  GET_ORGANIZATION_SUBSCRIPTION,
  UPDATE_ORGANIZATION_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_PAYMENT_METHOD,
  APPLY_COUPON,
  CLEAR_CURRENT_SUBSCRIPTION,
  CLEAR_SUBSCRIPTION_UPDATED,
  CLEAR_PAYMENT_METHOD_UPDATED,
  CLEAR_DISCOUNT_INFO,
  CLEAR_DISCOUNT_ERROR_MESSAGE,
  ACTIVATE_ORGANIZATION_SUBSCRIPTION,
} from "../../modules/constants";

export function getOrganizationSubscription(payload) {
  return {
    type: GET_ORGANIZATION_SUBSCRIPTION,
    payload,
  };
}

export function updateOrganizationSubscription(payload) {
  return {
    type: UPDATE_ORGANIZATION_SUBSCRIPTION,
    payload,
  };
}

export function updateSubscriptionPaymentMethod(payload) {
  return {
    type: UPDATE_SUBSCRIPTION_PAYMENT_METHOD,
    payload,
  };
}

export function activateOrganizationSubscription(payload) {
  return {
    type: ACTIVATE_ORGANIZATION_SUBSCRIPTION,
    payload,
  };
}

export function applyCoupon(payload) {
  return {
    type: APPLY_COUPON,
    payload,
  };
}

export function clearCurrentSubscription() {
  return {
    type: CLEAR_CURRENT_SUBSCRIPTION,
  };
}

export function clearSubscriptionUpdated() {
  return {
    type: CLEAR_SUBSCRIPTION_UPDATED,
  };
}

export function clearPaymentMethodUpdated() {
  return {
    type: CLEAR_PAYMENT_METHOD_UPDATED,
  };
}

export function clearDiscountInfo() {
  return {
    type: CLEAR_DISCOUNT_INFO,
  };
}

export function clearDiscountErrorMessage() {
  return {
    type: CLEAR_DISCOUNT_ERROR_MESSAGE,
  };
}
