import React, { useState, useEffect } from "react";
import styled from "styled-components";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { Modal, ModalBody } from "reactstrap";

import { checkUrl, checkHtml } from "../../utils/helper";
import { timezones } from "../../utils/timezone";
import { currenciesWithSymbols } from "../../utils/currencies";

import Select from "../../components/ReactSelectDropdown";
import DropdownStyles from "../../components/DropdownStyle/DropdownStyle";

import { WhiteButton, PrimaryButton } from "../../styledComponents/buttons";
import {
  CardTitle,
  InputWithLabelSection,
  InputLabel,
  InputField,
  RedStar,
  FormAlert,
} from "../../styledComponents/common";
import alertLightIcon from "../../assets/img/icons/alert-light.svg";

import { currencySymbolMap } from "../../utils/currencies";

import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";
import {
  CurrencyAlertIcon,
  CurrencyAlertSection,
  CurrencyAlertText,
} from "../AddOrganization/AddOrganizationForm";

const CustomModal = styled(Modal)`
  max-width: 620px;
  margin-top: 0;

  @media screen and (min-width: 1400px) {
    margin-top: 10vh;
  }
`;
const CustomModalBody = styled(ModalBody)`
  padding: 30px;
`;
const ModalInputSection = styled(InputWithLabelSection)`
  margin-top: ${({ marginTop }) => marginTop || "15px"};
`;
const ModalButtonSection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-end;
  grid-column-gap: 12px;
  margin-top: 25px;
`;

const filterOptions = (options, filterString, values) => {
  return options.filter(
    (x) => x.label.includes(filterString) || x.metadata.includes(filterString)
  );
};

const EditOrganizationModal = (props) => {
  const [orgName, setOrgName] = useState("");
  const [address, setAddress] = useState("");
  const [type, setType] = useState(null);
  const [timezone, setTimezone] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [currency, setCurrency] = useState(null);

  const [saveErrors, setSaveErrors] = useState({});

  const { organization, isOpen, toggle, types, isLoading, updateOrganization } =
    props;

  useEffect(() => {
    if (organization && organization.id) {
      setOrgName(organization.name);
      if (organization.address) {
        setAddress(organization.address);
      }
      if (organization.organization_type) {
        setType(organization.organization_type);
      }
      if (organization.timezone) {
        setTimezone(organization.timezone);
      }
      if (organization.phone) {
        setPhoneNumber(organization.phone);
      }
      if (organization.configuration && organization.configuration.currency) {
        setCurrency(organization.configuration.currency);
      }
    }
  }, [organization]);

  const resetState = () => {
    setOrgName("");
    setAddress("");
    setType(null);
    setTimezone(null);
    setCurrency(null);

    setSaveErrors({});
  };

  const toggleModal = () => {
    toggle();
    resetState();
  };

  const handleNameChange = (e) => {
    setOrgName(e.target.value);
    if (saveErrors["name"]) {
      delete saveErrors["name"];
    }
  };
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
    if (saveErrors["address"]) {
      delete saveErrors["address"];
    }
  };
  const handleTypeChange = (e) => {
    setType({
      name: e.label,
      id: e.value,
    });
  };
  const handleTimezoneChange = (e) => {
    setTimezone(e.value);
    if (saveErrors["timezone"]) {
      delete saveErrors["timezone"];
    }
  };
  const handleCurrencyChange = (e) => {
    setCurrency(e.value);
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!orgName.trim()) {
      formIsValid = false;
      saveErrors["name"] = "Organization name is required.";
    } else if (orgName.trim().length > 65) {
      formIsValid = false;
      saveErrors["name"] =
        "Organization name is too long.";
    } else if (checkUrl(orgName)) {
      formIsValid = false;
      saveErrors["name"] = "Organization name can not contain url.";
    } else if (checkHtml(orgName)) {
      formIsValid = false;
      saveErrors["name"] = "Invalid input in organization name.";
    }

    if (address && checkUrl(address)) {
      formIsValid = false;
      saveErrors["address"] = "Organization address can not contain url.";
    } else if (address && checkHtml(address)) {
      formIsValid = false;
      saveErrors["address"] = "Invalid input in organization address.";
    } else if (address.length > 1056) {
      formIsValid = false;
      saveErrors["address"] = "Please enter address within 1056 characters.";
    }

    if (timezone === "") {
      formIsValid = false;
      saveErrors["timezone"] = "Please select a timezone.";
    }

    if (phoneNumber && phoneNumber.length > 20) {
      formIsValid = false;
      saveErrors["phone"] = "Phone number can't be more than 20 characters.";
    }

    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleUpdate = () => {
    if (organization && organization.id && checkError()) {
      const payload = {
        id: organization.id,
        name: orgName,
        timezone,
      };
      if (address) {
        payload.address = address;
      }
      if (phoneNumber) {
        payload.phone = phoneNumber ? `+${phoneNumber}` : null;
      }
      if (type && type.id) {
        payload.organization_type_id = type.id;
      }
      if (currency) {
        payload.currency = currency;
      }
      updateOrganization(payload);
    }
  };

  return (
    <CustomModal isOpen={isOpen} toggle={toggleModal}>
      <CustomModalBody>
        <CardTitle>Update Organization</CardTitle>
        <ModalInputSection gap="8px" marginTop="20px">
          <InputLabel>
            Organization Name <RedStar>*</RedStar>
          </InputLabel>
          <InputField
            type="text"
            id="orgName"
            placeholder="SpaceX"
            value={orgName}
            onChange={(e) => handleNameChange(e)}
            error={
              saveErrors &&
              saveErrors["name"] &&
              saveErrors["name"] !== undefined
            }
          />
          {saveErrors &&
            saveErrors["name"] &&
            saveErrors["name"] !== undefined && (
              <FormAlert margin="0 0 0 5px">{saveErrors["name"]}</FormAlert>
            )}
        </ModalInputSection>
        <ModalInputSection gap="8px">
          <InputLabel>Address</InputLabel>
          <InputField
            type="text"
            id="address"
            placeholder="New York"
            value={address}
            onChange={(e) => handleAddressChange(e)}
            error={
              saveErrors &&
              saveErrors["address"] &&
              saveErrors["address"] !== undefined
            }
          />
          {saveErrors &&
            saveErrors["address"] &&
            saveErrors["address"] !== undefined && (
              <FormAlert margin="0 0 0 5px">{saveErrors["address"]}</FormAlert>
            )}
        </ModalInputSection>
        <ModalInputSection gap="8px">
          <InputLabel>Phone</InputLabel>
          <PhoneInput
            enableSearch
            country="us"
            value={phoneNumber}
            onChange={(phone) => setPhoneNumber(phone)}
            inputClass="phone-number-input"
            buttonClass="phone-number-flag-dropdown"
          />
          {saveErrors &&
            saveErrors["phone"] &&
            saveErrors["phone"] !== undefined && (
              <FormAlert margin="0 0 0 5px">{saveErrors["phone"]}</FormAlert>
            )}
        </ModalInputSection>
        <ModalInputSection gap="8px">
          <InputLabel>Organization Type</InputLabel>
          <Select
            isSearchable
            value={
              type && type.id
                ? {
                    label: type.name,
                    value: type.id,
                  }
                : ""
            }
            name="organization_type_id"
            id="organizationType"
            placeholder="Select type"
            options={
              types &&
              types.map((item) => {
                return {
                  label: `${item.name}`,
                  value: `${item.id}`,
                };
              })
            }
            onChange={(e) => handleTypeChange(e)}
            styles={DropdownStyles()}
          />
        </ModalInputSection>
        <ModalInputSection gap="8px">
          <InputLabel>
            Timezone <RedStar>*</RedStar>
          </InputLabel>
          <Select
            isSearchable
            value={
              timezone
                ? {
                    label: timezone,
                    value: timezone,
                  }
                : ""
            }
            name="timezone"
            id="timezone"
            placeholder="Select Timezone"
            options={timezones}
            filterOptions={filterOptions}
            onChange={(e) => handleTimezoneChange(e)}
            styles={DropdownStyles()}
            error={
              saveErrors &&
              saveErrors["timezone"] &&
              saveErrors["timezone"] !== undefined
            }
          />
          {saveErrors &&
            saveErrors["timezone"] &&
            saveErrors["timezone"] !== undefined && (
              <FormAlert margin="0">{saveErrors["timezone"]}</FormAlert>
            )}
        </ModalInputSection>
        <ModalInputSection gap="8px">
          <InputLabel>Currency</InputLabel>
          <Select
            isSearchable
            value={
              currency
                ? {
                    // label: `${currency} (${currencySymbolMap[currency]})`,
                    label: currenciesWithSymbols.find(
                      (currencyObj) => currencyObj.value === currency
                    )?.label,
                    value: currency,
                  }
                : ""
            }
            // value={currency}
            name="currency"
            id="currency"
            placeholder="Select Currency"
            options={currenciesWithSymbols}
            filterOptions={filterOptions}
            onChange={(e) => handleCurrencyChange(e)}
            styles={DropdownStyles()}
          />
        </ModalInputSection>
        <CurrencyAlertSection>
          <CurrencyAlertIcon src={alertLightIcon} alt="" />
          <CurrencyAlertText>
            Reminder: Please don't forget to change member's pay rate & bill
            rate after currency changes.
          </CurrencyAlertText>
        </CurrencyAlertSection>
        <ModalButtonSection>
          <WhiteButton type="cancel" onClick={toggleModal}>
            Cancel
          </WhiteButton>
          <PrimaryButton
            padding={isLoading ? "0 40px" : "0 20px"}
            onClick={() => handleUpdate()}
          >
            {isLoading ? (
              <ButtonTextLoader loadingText="Updating" fontSize="13px" />
            ) : (
              "Update Organization"
            )}
          </PrimaryButton>
        </ModalButtonSection>
      </CustomModalBody>
    </CustomModal>
  );
};

export default EditOrganizationModal;
