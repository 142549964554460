import {
  GET_PAYABLES_DATA,
  SEND_ONE_TIME_PAYMENT,
  SEND_HOURLY_PAYMENT,
  GET_HOURLY_PAYMENT_HISTORY,
  GET_ONETIME_PAYMENT_HISTORY,
  CLEAR_ONE_TIME_PAYMENT_DATA,
  EXPORT_PAYABLES_DATA,
} from "../../modules/constants";

export function getPayablesData(value) {
  return {
    type: GET_PAYABLES_DATA,
    payload: value,
  };
}
export function exportPayablesData(value) {
  return {
    type: EXPORT_PAYABLES_DATA,
    payload: value,
  };
}

export function sendOneTimePayment(value) {
  return {
    type: SEND_ONE_TIME_PAYMENT,
    payload: value,
  };
}

export function sendHourlyPayment(value) {
  return {
    type: SEND_HOURLY_PAYMENT,
    payload: value,
  };
}

export function getHourlyPaymentHistory(value) {
  return {
    type: GET_HOURLY_PAYMENT_HISTORY,
    payload: value,
  };
}

export function getOnetimePaymentHistory(value) {
  return {
    type: GET_ONETIME_PAYMENT_HISTORY,
    payload: value,
  };
}

export function clearOneTimePaymentData() {
  return {
    type: CLEAR_ONE_TIME_PAYMENT_DATA,
  };
}
