export class AuthLinkOpener {
  constructor(linkData) {
    this.linkData = linkData;
  }

  open() {
    try {
      if (this.linkData && this.linkData.serial) {
        if (this.linkData.serial === 1 && this.linkData.type === "http-link") {
          // open the link in a popup
          window.open(this.linkData.to, "_self");
        }
      }
    } catch (err) {
      return `Could not open link. Got the error: ${err}`;
    }
  }
}
