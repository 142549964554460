import { connect } from "react-redux";

import Projects from "./ProjectsNew";

// Actions
import {
  getProjectList,
  duplicateProject,
  updateProject,
  deleteProject,

  //Budget and Billing
  getProjectBudgetAndBillable,
  addProjectBudget,
  updateProjectBudget,
  addProjectBilling,
  updateProjectBilling,
} from "./projectsActions";

import { getClientList } from "../Clients/clientsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  isLoading: state.project.isLoading,
  updateProjectIsLoading: state.project.updateProjectIsLoading,
  deleteProjectIsLoading: state.project.deleteProjectIsLoading,
  projectBudgetAndBillableIsLoading:
    state.project.projectBudgetAndBillableIsLoading,
  selectedOrganization: state.organization.selectedOrganization,
  projectList: state.project.projectList,
  clientList: state.client.clientList,
  projectBudgetAndBillable: state.project.projectBudgetAndBillable,

  duplicateProjectId: state.project.duplicateProjectId,
  duplicateProjectIsLoading: state.project.duplicateProjectIsLoading,

  // pagination
  totalProjectCount: state.project.totalProjectCount,
  projectPageSize: state.project.projectPageSize,

  reloadProjects: state.project.reloadProjects,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  //Get project
  getProjectList: (details) => dispatch(getProjectList({ ...details })),
  duplicateProject: (details) => dispatch(duplicateProject({ ...details })),
  updateProject: (details) => dispatch(updateProject({ ...details })),
  deleteProject: (details) => dispatch(deleteProject({ ...details })),
  getClientList: (details) => dispatch(getClientList({ ...details })),
  //Budget and Billing
  getProjectBudgetAndBillable: (details) =>
    dispatch(getProjectBudgetAndBillable({ ...details })),
  addProjectBudget: (details) => dispatch(addProjectBudget({ ...details })),
  updateProjectBudget: (details) =>
    dispatch(updateProjectBudget({ ...details })),
  addProjectBilling: (details) => dispatch(addProjectBilling({ ...details })),
  updateProjectBilling: (details) =>
    dispatch(updateProjectBilling({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Projects);
