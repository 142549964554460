import styled from "styled-components";
import { Navbar } from "reactstrap";

export const NavbarContainer = styled(Navbar)`
  padding-top: ${({ $notificationbaropen }) =>
    $notificationbaropen && `calc(40px + 0.625rem)`};
  z-index: 5;

  @media (min-width: 991px) {
    border-bottom: none !important;
    height: ${({ $notificationbaropen }) =>
      $notificationbaropen ? "50px" : 0};
    padding: 0;
    min-height: 0;
  }
`;

export const NavbarWrapper = styled.div``;

export const NavbarToggle = styled.div``;

export const NavbarTogglerButton = styled.button``;

export const NavbarTogglerBar = styled.span``;

// Organization Section

export const OrganizationSectionWrapper = styled.div`
  display: flex;
  min-width: 230px;
  height: 38px;
  @media screen and (max-width: 991px) {
    display: ${({ collapseOpen }) => (collapseOpen ? "flex" : "none")};
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    margin-top: 10px;
    transition: all 0.35s ease;
  }
`;

export const OrganizationDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 230px;
  height: 38px;
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: 8px;
  padding: 4px 8px;
  cursor: pointer;
  @media screen and (max-width: 991px) {
    width: 100%;
  }
`;
export const IconAndNameContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const OrganizationIcon = styled.div`
  position: relative;
  width: ${({ width }) => width || `26px`};
  height: ${({ height }) => height || `26px`};
  border-radius: 50%;
  font-size: ${({ fontSize }) => fontSize || `13px`};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ archived, bgColor, theme }) =>
    archived ? theme.colors.main.grayMedium : bgColor ? bgColor : `#3b3f48`};
  color: ${({ theme }) => theme.colors.main.white};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
export const ActiveIndicator = styled.div`
  position: absolute;
  height: 9px;
  width: 9px;
  border-radius: 50%;
  bottom: -2px;
  right: -2px;
  background-color: ${({ theme }) => theme.colors.main.success};
  border: 1px solid ${({ theme }) => theme.colors.main.white};
`;
export const OrganizationName = styled.div`
  margin-left: 10px;
  font-size: ${({ fontSize }) => fontSize || `13px`};
  color: ${({ color, archived, theme }) =>
    color
      ? color
      : archived
      ? theme.colors.main.labelText
      : theme.colors.main.textSecondary};
`;
export const OrgArrowIcon = styled.img`
  margin-left: auto;
  transform: ${({ dropdownOpen }) =>
    dropdownOpen ? `rotate(180deg)` : `rotate(0deg)`};
  transition: transform 0.35s ease;
`;
