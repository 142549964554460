import styled, { css, keyframes } from "styled-components";

export const DropdownWrapper = styled.div``;

export const DropdownContainer = styled.div`
  min-width: ${({ width }) => width || "200px"};
  height: 40px;
  border: 1px solid
    ${({ dropdownOpen, theme }) =>
    dropdownOpen
      ? theme.dropdown.borderColor.focus
      : theme.dropdown.borderColor.default};
  box-shadow: ${({ dropdownOpen, theme }) =>
    dropdownOpen ? theme.dropdown.focusShadow : "none"};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.dropdown.borderRadius};
  padding: 6px 8px;
  background: ${({ theme }) => theme.dropdown.bgColor};
  cursor: ${({disabled}) => disabled ? 'not-allowed': 'pointer'};
 
    &:hover {
      border-color: ${({ dropdownOpen, theme }) =>
      !dropdownOpen && theme.dropdown.borderColor.hover};
      box-shadow: ${({ theme }) => theme.dropdown.focusShadow};
    };
    
`;
export const DropdownOptionsContainer = styled.div``;

export const DropdownBackgroundOverlay = styled.div`
  position: fixed;
  display: ${({ dropdownOpen }) => (dropdownOpen ? `block` : `none`)};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
`;
export const DropdownOptionsWrapper = styled.div`
  display: ${({ dropdownOpen }) => (dropdownOpen ? `block` : `none`)};
  position: ${({ dropdownOpen }) => (dropdownOpen ? `relative` : `absolute`)};
  left: ${({ dropdownOpen }) => (dropdownOpen ? `0` : `9999px`)};
  z-index: 2001;
`;
export const DropdownContent = styled.div`
  position: absolute;
  width: 100%;
  z-index: inherit;
  margin-bottom: 30px;
`;
const dropDown = keyframes`
  0% {
    top: -10px;
  }
  100% {
    top: 10px;
  }
`;
export const DropdownItemsContainer = styled.div`
  background-color: #fcfdfe;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  width: 200px;
  border: 1px solid ${({ theme }) => theme.dropdown.borderColor.focus};
  position: absolute;
  right: 0px;
  /* transform: translateX(100%); */
  top: 10px;
  max-height: 250px;
  animation: 0.2s ${dropDown} ease;
  padding: 10px 0px;
`;
export const SelectedTimezoneDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
export const SelectedTimezoneText = styled.div`
  margin-left: 0px;
  font-size: ${({ fontSize, theme }) => fontSize || theme.dropdown.fontSize};
  color: ${({ color, theme }) => color || theme.dropdown.textColor};
`;

export const ArrowIcon = styled.img`
  margin-left: auto;
  transform: ${({ dropdownOpen }) =>
    dropdownOpen ? `rotate(180deg)` : `rotate(0deg)`};
  transition: transform 0.35s ease;
`;


export const SingleOptionDiv = styled.div`
  color: ${({ selected, theme }) =>
    selected
      ? theme.dropdown.option.selectedTextColor
      : theme.dropdown.option.textColor};
  background-color: ${({ selected, theme }) =>
    selected && theme.dropdown.option.selectedBgColor};
  display: grid;
  grid-row-gap: 5px;
  padding: 5px 15px;
  cursor: ${({disabled}) => disabled == true ? 'not-allowed': 'pointer'};

  &:hover {
    background-color: ${({ selected, theme }) =>
    !selected && theme.dropdown.option.hoverBgColor};
    /* background: #f0f2f3; */
  }
  &:not(:first-child) {
    margin-top: 10px;
  }
`;

export const SingleOptionTitle = styled.div`
  font-size: 10px;
`;
export const SingleOptionText = styled.div`
  font-size: ${({ fontSize, theme }) => fontSize || theme.dropdown.fontSize};
`;
