import {
  capitalizeFirst,
  formatDurationToHoursMinutes,
} from "../../utils/helper";
import moment from "moment";
import AppLists from "../ActivityNew/Apps/AppLists";
import UrlLists from "../ActivityNew/URLs/UrlLists";

// Daily Time Activity
const getDailyRowData = (date, info, plan) =>
  plan === "monitored"
    ? [
        date,
        info.member_name,
        formatDurationToHoursMinutes(info.time_worked_in_seconds),
        info.idle_time
          ? formatDurationToHoursMinutes(info.idle_time, true)
          : "-",
        `${parseInt(info.member_average_activity)}%`,
        formatDurationToHoursMinutes(
          info.time_worked_in_seconds * (info.member_average_activity / 100),
          true
        ),
        formatDurationToHoursMinutes(
          parseInt(
            info.time_worked_in_seconds -
              info.time_worked_in_seconds *
                (info.member_average_activity / 100) -
              (info.idle_time ?? 0)
          )
        ),
      ]
    : [
        date,
        info.member_name,
        formatDurationToHoursMinutes(info.time_worked_in_seconds),
      ];
export const generateDailyTimeActivityCSV = (averageActivity, plan) => {
  const headers =
    plan != "monitored"
      ? ["Date", "Member", "Time Worked"]
      : [
          "Date",
          "Member",
          "Time Worked",
          "Idle Time",
          "Average Activity",
          "Active Time",
          "Neutral Time",
        ];
  const data = averageActivity.flatMap((report) =>
    report.data.map((info) => getDailyRowData(report.date, info, plan))
  );
  return [headers, ...data];
};

// Weekly Time Activity
const getWeeklyHeaders = (selectedProject, plan) => {
  const monitoredHeaders = [
    "Idle Time",
    "Average Activity",
    "Active Time",
    "Neutral Time",
  ];

  const baseHeaders =
    plan === "monitored"
      ? ["Member", "Time Worked", ...monitoredHeaders.slice(1)]
      : ["Member", "Time Worked"];
  return selectedProject && selectedProject !== "All Projects"
    ? ["Member", "Project Name", ...baseHeaders.slice(1)]
    : baseHeaders;
};
const getWeeklyRowData = (info, selectedProject, plan) => {
  const baseData =
    plan === "monitored"
      ? [
          info.member_name,
          formatDurationToHoursMinutes(info.time_worked_in_seconds),
          info.idle_time
            ? formatDurationToHoursMinutes(info.idle_time, true)
            : "-",
          `${parseInt(info.average_weekly_activity)}%`,
          formatDurationToHoursMinutes(info.active_time, true),
          formatDurationToHoursMinutes(info.neutral_time),
        ]
      : [
          info.member_name,
          formatDurationToHoursMinutes(info.time_worked_in_seconds),
        ];
  return selectedProject && selectedProject !== "All Projects"
    ? [info.member_name, selectedProject, ...baseData.slice(1)]
    : baseData;
};
export const generateWeeklyTimeActivityCSV = (
  averageActivity,
  selectedProject,
  plan
) => {
  const headers = getWeeklyHeaders(selectedProject, plan);
  const data = averageActivity.map((info) =>
    getWeeklyRowData(info, selectedProject, plan)
  );
  return [headers, ...data];
};

// Manual Time
export const getManualTimeRowData = (date, info) => [
  date,
  info.member_name,
  info.member_name === info.edited_by_name
    ? `member on ${moment(info.edited_time).format("D-M-YYYY")}`
    : `${info.edited_by_name} on ${moment(info.edited_time).format(
        "D-M-YYYY"
      )}`,
  info.project_name,
  info.task_name ?? "-",
  getChangeInTimesheet(info),
  info.reason,
];
const getChangeInTimesheet = (info) => {
  const duration = formatDurationToHoursMinutes(info.old_time, true);
  switch (info.type_of_change) {
    case "add":
      return `added ${duration}`;
    case "delete":
    case "update":
      return `${info.type_of_change}d ${duration}`;
    default:
      return `${info.type_of_change}ted ${formatDurationToHoursMinutes(
        info.deleted_time,
        true
      )}`;
  }
};

// App & URL
const trimUrl = (url) => {
  return url.replace("http://", "").replace("https://", "").split(/[/?#]/)[0];
};
const getAppData = (appList) => {
  if (!appList || !appList.length) return [];
  const appMap = AppLists.reduce((map, item) => {
    [item.winId, item.macId, item.linuxId].forEach((id) => {
      if (id) map[id] = { name: item.name, icon: item.icon };
    });
    return map;
  }, {});

  // Update appList in place
  return appList.map((app) => ({
    ...app,
    ...(appMap[app.app] || {}),
  }));
};
const getUrlData = (urlList) => {
  if (!urlList || !urlList.length) return [];
  const urlMap = UrlLists.reduce((map, item) => {
    map[item.name] = { name: item.name, icon: item.icon };
    return map;
  }, {});

  // Update urlList in place
  return urlList.map((url) => ({
    ...url,
    ...(urlMap[trimUrl(url.url)] || {}),
  }));
};
const getAppUrlRowData = (date, memberData, item, isUrl = false) => [
  date,
  memberData.member_name,
  isUrl ? item.name || trimUrl(item.url) : item.name || item.app,
  moment.utc(item.time * 1000).format("HH:mm:ss"),
  `${parseInt((item.time / memberData.duration) * 100)}%`,
];
export const getAppUrlData = (date, report) => {
  if (!report.hasData) return [];

  return Object.entries(report).flatMap(([memberId, memberData]) => [
    ...getAppData(memberData.app_data).map((app) =>
      getAppUrlRowData(date, memberData, app)
    ),
    ...getUrlData(memberData.url_data).map((url) =>
      getAppUrlRowData(date, memberData, url, true)
    ),
  ]);
};

// Custom Report
export const getCustomReportHeaders = (features) => {
  const baseHeaders = ["Member Name", "Member Email", "Member Role"];
  const additionalHeaders = [
    { key: "project", value: "Project" },
    { key: "task", value: "Task" },
    { key: "total_time", value: "Time Worked" },
    { key: "idle_time", value: "Idle Time" },
    { key: "idle_time", value: "Idle Time %" },
    { key: "average_activity", value: "Average Activity" },
    { key: "active_time", value: "Active Time" },
    { key: "neutral_time", value: "Neutral Time" },
    { key: "manual_time", value: "Manual Time" },
    { key: "manual_time", value: "Manual Time %" },
    { key: "notes", value: "Notes" },
  ];

  return [
    ...baseHeaders,
    ...additionalHeaders
      .filter((header) => features?.includes(header.key))
      .map((header) => header.value),
  ];
};
export const getCustomReportRowData = (info, features) => {
  const baseData = [
    info.memberName,
    info.memberEmail,
    capitalizeFirst(info.memberRole),
  ];
  const additionalData = [
    { key: "project", value: info.projects },
    {
      key: "task",
      value: features.includes("task_wise")
        ? info.taskName?.length > 0
          ? info.taskName
          : "-"
        : info.tasks,
    },
    {
      key: "total_time",
      value: formatDurationToHoursMinutes(info.totalTimeWorked),
    },
    {
      key: "idle_time",
      value: info.idleTime ? formatDurationToHoursMinutes(info.idleTime) : "-",
    },
    {
      key: "idle_time",
      value:
        info.idleTimePercentage > 0.0 ? `${info.idleTimePercentage}%` : "-",
    },
    { key: "average_activity", value: `${info.averageActivity}%` },
    {
      key: "active_time",
      value: formatDurationToHoursMinutes(info.activeTime),
    },
    {
      key: "neutral_time",
      value: formatDurationToHoursMinutes(info.neutralTime),
    },
    {
      key: "manual_time",
      value: info.manualTime
        ? formatDurationToHoursMinutes(info.manualTime)
        : "-",
    },
    {
      key: "manual_time",
      value:
        info.manualTimePercentage > 0.0 ? `${info.manualTimePercentage}%` : "-",
    },
    { key: "notes", value: info.notes.join(", ") },
  ];

  return [
    ...baseData,
    ...additionalData
      .filter((data) => features?.includes(data.key))
      .map((data) => data.value),
  ];
};
export const getCustomReportTotalRow = (
  customReportData,
  customReportSums,
  features
) => {
  const baseTotal = [`${customReportData?.length ?? 0} Members`, "", ""];
  const additionalTotals = [
    { key: "project", value: `${customReportSums.uniqueProjects} Projects` },
    {
      key: "task",
      value:
        customReportSums.uniqueTasks > 0
          ? `${customReportSums.uniqueTasks} Task${
              customReportSums.uniqueTasks > 1 ? "s" : ""
            }`
          : "-",
    },
    {
      key: "total_time",
      value: formatDurationToHoursMinutes(customReportSums.totalTime ?? 0),
    },
    {
      key: "idle_time",
      value: customReportSums.totalIdleTime
        ? formatDurationToHoursMinutes(customReportSums.totalIdleTime ?? 0)
        : "-",
    },
    {
      key: "idle_time",
      value:
        customReportSums.idleTimePercentage > 0.0
          ? `${customReportSums.idleTimePercentage}%`
          : "-",
    },
    { key: "average_activity", value: "-" },
    {
      key: "active_time",
      value: formatDurationToHoursMinutes(
        customReportSums.totalActiveTime ?? 0
      ),
    },
    {
      key: "neutral_time",
      value: formatDurationToHoursMinutes(
        customReportSums.totalNeutralTime ?? 0
      ),
    },
    {
      key: "manual_time",
      value: customReportSums.totalManualTime
        ? formatDurationToHoursMinutes(customReportSums.totalManualTime ?? 0)
        : "-",
    },
    {
      key: "manual_time",
      value:
        customReportSums.manualTimePercentage > 0.0
          ? `${customReportSums.manualTimePercentage}%`
          : "-",
    },
    { key: "notes", value: `${customReportSums.totalNotes ?? 0} Notes` },
  ];

  return [
    ...baseTotal,
    ...additionalTotals
      .filter((total) => features?.includes(total.key))
      .map((total) => total.value),
  ];
};
