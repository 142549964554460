import styled, { keyframes, css } from "styled-components";

// pulsating dot
export const DotContainer = styled.div`
  // position: absolute;
`;
const pulsate = (active) => keyframes`
  0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 ${
      active ? "rgba(255,255,255, 0.7)" : "rgba(254,89,105, 0.7)"
    } ;
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 7px ${
      active ? "rgba(255,255,255, 0)" : "rgba(254,89,105, 0)"
    };
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 ${active ? "rgba(255,255,255, 0)" : "rgba(254,89,105, 0)"};
	}
`;
export const DotCircle = styled.div`
  background: ${({ theme, active }) =>
    active ? theme.colors.main.white : theme.colors.main.error};
  border-radius: 50%;
  box-shadow: 0 0 0 0
    ${({ active }) => (active ? "rgba(255,255,255, 1)" : "rgba(254,89,105, 1)")};
  margin: ${({ margin }) => margin || "7px"};
  height: ${({ size }) => size || "15px"};
  width: ${({ size }) => size || "15px"};
  transform: scale(1);
  animation: ${({ active }) => pulsate(active)} 1.8s infinite;
`;

const rotate = (active) => keyframes`
  0% {
		transform: rotate(0deg)
	}
  20% {
    transform: rotate(45deg);
  }
	50% {
    transform: rotate(180deg)
  }
	80% {
		transform: rotate(340deg)
	}
	100% {
    transform: rotate(360deg);
	}
`;

export const LiveMembersTableHeadContainer = styled.div`
  display: grid;
  grid-template-columns: 25% 18% 18% 18% 15%;
  grid-column-gap: 8px;
  justify-content: start;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
  @media (max-width: 1250px) {
    grid-template-columns: 250px 200px 150px 200px 150px;
    grid-column-gap: 10px;
  }
`;
export const LiveMembersTableContainer = styled.div`
  display: grid;
  grid-template-columns: 25% 18% 18% 18% 15%;
  grid-column-gap: 8px;
  justify-content: start;
  padding: 0 20px;
  :nth-of-type(even) {
    background-color: #f8f9fc;
  }
  @media (max-width: 1250px) {
    grid-template-columns: 250px 200px 150px 200px 150px;
    grid-column-gap: 10px;
  }
`;

// temporary info div for updating app
export const InfoDiv = styled.div`
  width: 100%;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.main.warningLight};
  border: 1px solid ${({ theme }) => theme.colors.main.warning};
  border-radius: ${({ theme }) => theme.borderRadii.card};
  margin: 15px 0 0 0;
`;

// user dot
export const UserDotDiv = styled.div`
  position: absolute;
  left: 22px;
  bottom: -5px;
`;
const userPulsate = keyframes`
  0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(65, 203, 143, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 5px rgba(65, 203, 143, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(65, 203, 143, 0);
	}
`;
export const UserDotCircle = styled.div`
  background: ${({ theme }) => theme.colors.main.success};
  border: 2px solid ${({ theme }) => theme.colors.main.white};
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(65, 203, 143, 1);
  margin: ${({ margin }) => margin || "7px"};
  height: ${({ size }) => size || "12px"};
  width: ${({ size }) => size || "12px"};
  transform: scale(1);
  animation: ${userPulsate} 1.8s infinite;
`;
