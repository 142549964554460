import { useMemo } from "react";
import { Modal, ModalBody } from "reactstrap";
import { CSVLink } from "react-csv";
import moment from "moment";
import styled from "styled-components";

import ExportPDF from "./ExportPDF";

import {
  generateWeeklyTimeActivityCSV,
  generateDailyTimeActivityCSV,
  getManualTimeRowData,
  getAppUrlData,
  getCustomReportHeaders,
  getCustomReportRowData,
  getCustomReportTotalRow,
} from "./exportUtils";

import {
  CommonIconWhButton,
  CommonGrid,
  CommonText,
} from "../../styledComponents/common";

import csv from "../../assets/img/icons/csv.svg";
import pdf from "../../assets/img/icons/pdf.svg";
import crossIcon from "../../assets/img/icons/cross_black.svg";

const ModalCrossIcon = styled.img`
  position: absolute;
  height: 25px;
  width: 25px;
  right: 15px;
  top: 15px;
  cursor: pointer;
  &:hover {
    transform: scale(1.15);
    transition: transform 0.15s ease;
  }
`;

const IconButton = styled(CommonIconWhButton)`
  border-color: #e4e7eb;
  padding: 14px 25px;
  grid-column-gap: 13px;
  &:hover {
    border-color: #20bead;
  }
`;
const CSVLinkMod = styled(CSVLink)`
  &:hover {
    text-decoration: none;
  }
`;

const ExportModal = ({
  isOpen,
  toggle,
  title,
  appsUrlState,
  averageActivity,
  manualTimeData,
  selectedProject,
  selectedTab,
  weeklyDates,
  customReportData,
  customReportSums,
  reportName,
  startDate,
  endDate,
  features,
  plan,
}) => {
  const togglePdfExport = () => {
    ExportPDF(
      title,
      appsUrlState,
      averageActivity,
      manualTimeData,
      selectedProject,
      selectedTab,
      weeklyDates,
      plan
    );
    toggle();
  };

  const generateTimeActivityCSV = () => {
    if (!averageActivity || averageActivity.length === 0) return [];

    return selectedTab === "weekly"
      ? generateWeeklyTimeActivityCSV(averageActivity, selectedProject, plan)
      : generateDailyTimeActivityCSV(averageActivity, plan);
  };
  const generateManualTimeCSV = () => {
    if (!manualTimeData || manualTimeData.length === 0) return [];

    const headers = [
      "Date",
      "Member",
      "Edited By",
      "Project",
      "Task",
      "Change in TimeSheet",
      "Reason",
    ];
    const data = manualTimeData.flatMap((report) =>
      Object.entries(report).flatMap(([date, infos]) =>
        infos.map((info) => getManualTimeRowData(date, info))
      )
    );
    return [headers, ...data];
  };
  const generateAppUrlCSV = () => {
    if (!appsUrlState || Object.keys(appsUrlState).length === 0) return [];

    const headers = [
      "Date",
      "Member",
      "Apps or Site",
      "time spent (hrs)",
      "Percent used",
    ];

    const data = Object.entries(appsUrlState).flatMap(([date, report]) =>
      getAppUrlData(date, report)
    );
    return [headers, ...data];
  };
  const generateCustomReportCSV = () => {
    if (!customReportData || customReportData.length === 0) return [];

    const headers = getCustomReportHeaders(features);
    const data = customReportData.map((info) =>
      getCustomReportRowData(info, features)
    );
    const totalRow = getCustomReportTotalRow(
      customReportData,
      customReportSums,
      features
    );

    return [headers, ...data, Array(headers.length).fill(""), totalRow];
  };

  const getCSVData = () => {
    const csvGenerators = {
      timeActivity: generateTimeActivityCSV,
      manualTime: generateManualTimeCSV,
      appUrl: generateAppUrlCSV,
      customReport: generateCustomReportCSV,
    };

    const generator = csvGenerators[title];
    return generator ? generator() : [];
  };

  const csvData = useMemo(
    () => getCSVData(),
    [
      title,
      averageActivity,
      selectedTab,
      selectedProject,
      manualTimeData,
      appsUrlState,
      customReportData,
      customReportSums,
      features,
    ]
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ marginTop: `30vh`, maxWidth: `450px` }}
    >
      <ModalBody style={{ padding: `35px 0 15px 0`, position: "relative" }}>
        <ModalCrossIcon src={crossIcon} alt="" onClick={() => toggle()} />
        <div style={{ padding: `0 30px` }}>
          <CommonText fontSize="18px" fontWeight="600" name="true">
            Export {title === "customReport" ? "Custom" : "Time & Activity"}{" "}
            Report
          </CommonText>

          <CommonText style={{ marginTop: `30px` }} fontWeight="600">
            Choose Format
          </CommonText>
          <CommonGrid columns="1fr 1fr" padding="20px 0 25px 0">
            {title !== "customReport" && (
              <IconButton onClick={() => togglePdfExport()}>
                <div>
                  <img src={pdf} alt="pdf" width="28px" />
                </div>
                <div>PDF</div>
              </IconButton>
            )}
            <CSVLinkMod
              data={csvData}
              filename={`${
                title === "timeActivity"
                  ? selectedTab === "weekly" && weeklyDates
                    ? `time_activity_report_${weeklyDates.firstDay}_${weeklyDates.lastDay} `
                    : `time_activity_report_${moment().format("DD-MM-YYYY")}`
                  : title === "manualTime"
                  ? `manual_time_report_${moment().format("DD-MM-YYYY")}`
                  : title === "customReport"
                  ? `${reportName}_${startDate}_${endDate}`
                  : `apps_urls_usage_report_${moment().format("DD-MM-YYYY")}`
              }.csv`}
            >
              <IconButton onClick={() => toggle()}>
                <div>
                  <img src={csv} alt="excel" width="28px" padding="12px 25px" />
                </div>
                <div>CSV</div>
              </IconButton>
            </CSVLinkMod>
          </CommonGrid>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ExportModal;
