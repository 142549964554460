import { CHANGE_PASSWORD_REQUEST } from "modules/constants";

// ------------------------------------
// Actions
// ------------------------------------
export function changePasswordAction(value) {
  return {
    type: CHANGE_PASSWORD_REQUEST,
    payload: value
  };
}

export const actions = {
  changePasswordAction
};
