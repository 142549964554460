import { connect } from "react-redux";

// Component
import CustomReportDetails from "./CustomReportDetails";
import {
  deleteCustomReports,
  getCustomReportDetails,
} from "../../reportsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  customReportDetails: state.report.customReportDetails,
  organization: state.organization.selectedOrganization,
  isLoading: state.report.isLoading,
  isDeleteLoading: state.report.isDeleteLoading,
  deletedReports: state.report.deletedReports,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomReportDetails: (params) => dispatch(getCustomReportDetails(params)),
  deleteCustomReports: (payload) => dispatch(deleteCustomReports(payload)),
});

// connect states and dispatchers with components
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomReportDetails);
