import styled from "styled-components";

export const OnboardingStepsContainer = styled.div`
  padding: 6vh 0 20vh 10%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding-left: 13%;
  }
  @media screen and (max-width: 500px) {
    padding: ${({ addOrJoin }) =>
      addOrJoin ? "5vh 20px 10vh" : "5vh 0 10vh 15%"};
  }
`;

export const OnboardingArtworkDiv = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: 26%;
  max-width: 500px;
  height: 100%;
  background: #9fdbd6;
  transform: ${({ hide }) => (hide ? `translateX(100%)` : `translateX(0)`)};
  transition: transform 0.4s ease;
  transition-delay: ${({ hide }) => hide && `0.2s`};

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
export const OnboardingArtwork = styled.img`
  position: absolute;
  bottom: 0;
  transform: ${({ hide }) => (hide ? `translateX(100%)` : `translateX(0)`)};
  transition: transform 0.4s ease;
  transition-delay: ${({ hide }) => !hide && `0.2s`};
`;

export const SelectRoleContainer = styled.div``;
export const RoleOption = styled.div`
  width: 210px;
  height: 235px;
  padding: 0px 15px;
  margin: 0;
  background: ${({ theme }) => theme.colors.main.white};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadii.card};
  cursor: pointer;
  &:hover {
    border-color: ${({ theme }) => theme.colors.border.primary};
  }
`;
export const RoleOptionImage = styled.img`
  width: 25px;
  height: 25px;
`;
export const RoleOptionBadge = styled.div`
  width: 20px;
  height: 3px;
  background: ${({ color }) => color};
  border-radius: 10px;
  margin: 25px 0px 15px;
`;

export const OnboardingPreviousButton = styled.div`
  position: absolute;
  left: -85px;
  top: 50%;
  width: 50px;
  height: 50px;
  border-radius: ${({ theme }) => theme.borderRadii.button};
  background-color: #f6f8f9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #ecf1f4;
  }

  @media screen and (max-width: 500px) {
    left: -45px;
    width: 32px;
    height: 32px;
  }
`;
export const PreviousButtonIcon = styled.img`
  @media screen and (max-width: 500px) {
    width: 16px;
    height: 16px;
  }
`;

export const AddOrganizationContainer = styled.div`
  margin-top: 2vh;
  position: relative;

  @media screen and (min-width: 1400px) {
    margin-top: 5vh;
  }
`;
export const OnboardingForm = styled.form``;
export const OnboardingFormAlert = styled.div`
  color: ${({ theme }) => theme.colors.main.error};
  font-size: 15px;
  text-align: left;
  margin-top: 10px;
`;

export const OnboardingInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 700px;
  margin-top: 50px;
  grid-row-gap: ${({ gap }) => gap || "20px"};
  &:first-child {
    margin-top: 0;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;
export const OnboardingInputLabel = styled.label`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.main.textMain};
  margin: ${({ margin }) => margin || "0"};

  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
  @media screen and (max-width: 500px) {
    font-size: 16px;
  }
`;
export const InputRequiredStar = styled.span`
  color: ${({ theme }) => theme.colors.main.error};
`;

export const OnboardingInputWithCrossButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const InputCrossIconDiv = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e4e7eb;
  background: #fcfdfe;
  cursor: pointer;
  margin-top: 12px;
  &:hover {
    border-color: #ff4d4d;
    img {
      filter: brightness(0) saturate(100%) invert(44%) sepia(67%)
        saturate(3506%) hue-rotate(334deg) brightness(107%) contrast(100%);
    }
  }

  @media screen and (max-width: 500px) {
    width: 30px;
    height: 30px;
  }
`;
export const InputCrossIcon = styled.img``;

export const OnboardingInput = styled.input`
  margin: ${({ margin }) => margin || "0"};
  border: none;
  border-bottom: 1px solid
    ${({ alert, theme }) =>
      alert ? theme.colors.main.error : theme.colors.border.secondary};
  padding: 9px 4px;
  font-size: 32px;
  color: ${({ theme }) => theme.colors.main.textMain};
  &::placeholder {
    font-size: 30px;
    color: rgba(61, 77, 105, 0.5);
  }
  &:focus {
    outline: none;
    border-bottom: ${({ alert, theme }) =>
      !alert && `1px solid ${theme.colors.border.primary}`};
  }

  @media screen and (max-width: 768px) {
    font-size: 28px;
    &::placeholder {
      font-size: 26px;
    }
  }
  @media screen and (max-width: 500px) {
    font-size: 18px;
    &::placeholder {
      font-size: 18px;
    }
  }
`;

export const NextButtonText = styled.span`
  color: ${({ theme }) => theme.colors.main.primary};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;
export const NextButtonIcon = styled.img`
  margin-left: 8px;
  width: 18px;
`;
export const OnboardingNextButton = styled.div`
  height: 50px;
  width: 150px;
  background: ${({ theme }) => theme.colors.main.white};
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  border-radius: ${({ theme }) => theme.button.borderRadius};
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.main.primary};
    ${NextButtonText} {
      color: ${({ theme }) => theme.colors.main.white};
    }
    ${NextButtonIcon} {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
        hue-rotate(317deg) brightness(103%) contrast(102%);
    }
  }

  @media screen and (max-width: 500px) {
    height: 40px;
    width: 120px;
  }
`;

export const AddProjectContainer = styled.div`
  margin-top: 25vh;
  position: relative;
  @media screen and (min-width: 1400px) {
    margin-top: 35vh;
  }
`;

export const InputSubLabel = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.main.labelText};
  margin-left: 20px;

  @media screen and (max-width: 500px) {
    display: none;
  }
`;

export const AddMoreProjectsDiv = styled.div`
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  cursor: pointer;
  margin-top: 40px;
`;
export const PlusIcon = styled.img``;
export const AddMoreProjectsText = styled.span`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.main.primary};

  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
`;

export const PricingPlanContainer = styled.div`
  margin-top: 33vh;
  position: relative;
  @media screen and (min-width: 1400px) {
    margin-top: 50vh;
  }
`;

export const InviteMembersContainer = styled.div`
  margin: 30vh 0;
  position: relative;
`;
