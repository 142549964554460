import { useState, useEffect } from "react";
import GeneralDetails from "./GeneralDetails";
import AddPeoplePermissions from "./AddPeoplePermissions";
import AddBiilingHours from "./AddBillingsHours";
import Addtasks from "./AddTasks";
import {
  SaveAndProceedContainer,
  SaveAndProceedButton,
} from "../../../styledComponents/createProject";
import {
  Container,
  PageTitle,
  ContentHeader,
} from "../../../styledComponents/common";
import { PrimaryButton } from "../../../styledComponents/buttons";

import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";

const CreateProject = (props) => {
  const [generalDetailsActive, setGeneralDetailsActive] = useState(true);
  const [addPeopleActive, setAddPeopleActive] = useState(false);
  const [billingsActive, setBillingsActive] = useState(false);
  const [addTasksActive, setAddTasksActive] = useState(false);

  const [currency, setCurrency] = useState("");

  const toggleGeneralDetails = () => {
    setGeneralDetailsActive(!generalDetailsActive);
    setAddPeopleActive(false);
    setBillingsActive(false);
    setAddTasksActive(false);
  };
  const toggleAddPeople = () => {
    setAddPeopleActive(!addPeopleActive);
    // setGeneralDetailsActive(false);
    setBillingsActive(false);
    setAddTasksActive(false);
  };
  const toggleBillings = () => {
    setBillingsActive(!billingsActive);
    // setGeneralDetailsActive(false);
    setAddPeopleActive(false);
    setAddTasksActive(false);
  };
  const toggleAddTasks = () => {
    setAddTasksActive(!addTasksActive);
    // setGeneralDetailsActive(false);
    setAddPeopleActive(false);
    setBillingsActive(false);
  };

  useEffect(() => {
    // if (props.selectedOrganization && props.selectedOrganization.id) {
    //   let payload = {
    //     organization_id: props.selectedOrganization.id,
    //   };
    //   props.getOrganizationMembersList(payload);
    // }
    return () => {
      props.clearCreatedProject();
    };
  }, []);

  useEffect(() => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      if (
        props.selectedOrganization.configuration &&
        props.selectedOrganization.configuration.currency
      ) {
        setCurrency(props.selectedOrganization.configuration.currency);
      }

      let payload = {
        organization_id: props.selectedOrganization.id,
      };
      props.getOrganizationMembersList(payload);
    }
  }, [props.selectedOrganization]);

  useEffect(() => {
    if (props.createdProjectId) {
      setGeneralDetailsActive(false);
    }
  }, [props.createdProjectId]);

  return (
    <div className="content">
      <PageTitle>Projects</PageTitle>
      <ContentHeader margin="30px 0 0 0">
        <BackButtonComponent
          onClick={() => props.history.push("/user/projects")}
          subTitle="Back to all projects"
        />
      </ContentHeader>
      <Container padding="20px 0">
        <GeneralDetails
          createProjectIsLoading={props.createProjectIsLoading}
          updateProjectIsLoading={props.updateProjectIsLoading}
          createProject={props.createProject}
          updateProject={props.updateProject}
          projectId={props.createdProjectId}
          selectedOrganization={props.selectedOrganization}
          getClientList={props.getClientList}
          clientList={props.clientList}
          history={props.history}
          generalDetailsActive={generalDetailsActive}
          toggleGeneralDetails={toggleGeneralDetails}
          toggleAddPeople={toggleAddPeople}
        />
        <AddPeoplePermissions
          getMembersList={props.getOrganizationMembersList}
          membersList={props.orgMembersList}
          getTeamList={props.getTeamList}
          teamList={props.teamList}
          projectId={props.createdProjectId}
          selectedOrganization={props.selectedOrganization}
          projectAssigneeList={props.projectAssigneeList}
          clearProjectAssigneeList={props.clearProjectAssigneeList}
          addProjectAssignee={props.addProjectAssignee}
          updateProjectAssignee={props.updateProjectAssignee}
          projectAssigneeUpdated={props.projectAssigneeUpdated}
          updatedAssigneeData={props.updatedAssigneeData}
          deleteProjectAssignee={props.deleteProjectAssignee}
          addTeamToProject={props.addTeamToProject}
          addPeopleActive={addPeopleActive}
          toggleAddPeople={toggleAddPeople}
          projectAssigneeIsLoading={props.projectAssigneeIsLoading}
          loadingAssigneeId={props.loadingAssigneeId}
          projectTeamIsLoading={props.projectTeamIsLoading}
          loadingTeamId={props.loadingTeamId}
        />
        <AddBiilingHours
          getProjectAssigneeList={props.getProjectAssigneeList}
          projectAssigneeList={props.projectAssigneeList}
          projectId={props.createdProjectId}
          selectedOrganization={props.selectedOrganization}
          currency={currency}
          projectBilling={props.projectBilling}
          addProjectBilling={props.addProjectBilling}
          updateProjectBilling={props.updateProjectBilling}
          clearProjectBilling={props.clearProjectBilling}
          projectBudget={props.projectBudget}
          addProjectBudget={props.addProjectBudget}
          updateProjectBudget={props.updateProjectBudget}
          clearProjectBudget={props.clearProjectBudget}
          setPaymentConfiguration={props.setPaymentConfiguration}
          billingsActive={billingsActive}
          setBillingsActive={setBillingsActive}
          toggleBillings={toggleBillings}
          toggleAddTasks={toggleAddTasks}
          saveBudgetLoading={props.saveBudgetLoading}
          saveBillingLoading={props.saveBillingLoading}
        />
        <Addtasks
          selectedOrganization={props.selectedOrganization}
          projectId={props.createdProjectId}
          getProjectTaskList={props.getProjectTaskList}
          projectTaskList={props.projectTaskList}
          getProjectAssigneeList={props.getProjectAssigneeList}
          projectAssigneeList={props.projectAssigneeList}
          addTask={props.projectAddTask}
          editTask={props.projectEditTask}
          deleteTask={props.projectDeleteTask}
          addTaskAssignee={props.addTaskAssignee}
          deleteTaskAssignee={props.deleteTaskAssignee}
          addTasksActive={addTasksActive}
          toggleAddTasks={toggleAddTasks}
          taskListLoading={props.taskListLoading}
          taskIsLoading={props.taskIsLoading}
          loadingTaskId={props.loadingTaskId}
          taskAssigneeIsLoading={props.taskAssigneeIsLoading}
          loadingTaskAssigneeId={props.loadingTaskAssigneeId}
        />
        <SaveAndProceedContainer>
          <PrimaryButton
            padding="0 25px"
            onClick={() => props.history.push("/user/projects")}
          >
            Save & Proceed
          </PrimaryButton>
        </SaveAndProceedContainer>
      </Container>
    </div>
  );
};

export default CreateProject;
