import styled from "styled-components";

export const LeaveTableRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 5.5fr auto;
  grid-column-gap: 10px;
  // justify-content: space-between;
  align-items: flex-start;
  padding-bottom: ${(props) => props.title && `10px`};
  @media (max-width: 1366px) {
    grid-template-columns: 2fr 5.3fr auto;
  }
`;

export const ApproveRejectBadge = styled.div`
  border: 1px solid
    ${({ tabSelect, theme }) =>
      tabSelect === "pending"
        ? theme.colors.main.primary
        : tabSelect === "rejected"
        ? theme.colors.main.error
        : null};
  max-width: max-content;
  border-radius: 15px;
  padding: 3px 10px;
  display:flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: ${({ tabSelect, theme }) =>
    tabSelect === "accepted"
      ? theme.colors.main.primary
      : tabSelect === "rejected"
      ? theme.colors.main.error
      : null};
  background-color: ${({ tabSelect, theme }) =>
    tabSelect === "accepted"
      ? theme.colors.main.primaryLight
      : tabSelect === "rejected"
      ? theme.colors.main.errorLight
      : null};
`;
