import { createNotification } from "../../modules/notificationManager";
import {
  GET_LIVE_MEMBERS_LIST,
  GET_LIVE_MEMBERS_LIST_SUCCESS,
  GET_LIVE_MEMBERS_LIST_FAILURE,
  GET_LAST_FIVE_MIN_ACTIVE_LIST,
  GET_LAST_FIVE_MIN_ACTIVE_LIST_SUCCESS,
  GET_LAST_FIVE_MIN_ACTIVE_LIST_FAILURE,
  GET_INSTANT_SCREENSHOTS_LIST,
  GET_INSTANT_SCREENSHOTS_LIST_SUCCESS,
  GET_INSTANT_SCREENSHOTS_LIST_FAILURE,
  TAKE_INSTANT_SCREENSHOT,
  TAKE_INSTANT_SCREENSHOT_SUCCESS,
  TAKE_INSTANT_SCREENSHOT_FAILURE,
  LOGIN_PROFILE_CREATED_RESET,
} from "../../modules/constants";

export const liveMembersListRequestHandler = (state, action) => {
  if (
    !state.liveMembersList ||
    (state.liveMembersList && state.liveMembersList.length === 0)
  ) {
    return {
      ...state,
      memberListLoading: true,
      buttonLoading: true,
    };
  }
  return {
    ...state,
    buttonLoading: true,
  };
};
export const liveMembersListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    memberListLoading: false,
    buttonLoading: false,
    liveMembersList: data.data,
  };
};
export const liveMembersListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    memberListLoading: false,
    buttonLoading: false,
    liveMembersList: null,
  };
};

export const lastFiveMinutesActiveListRequestHandler = (state, action) => {
  if (
    !state.lastFiveMinActiveList ||
    (state.lastFiveMinActiveList && state.lastFiveMinActiveList.length === 0)
  ) {
    return {
      ...state,
      memberListLoading: true,
      buttonLoading: true,
    };
  }
  return {
    ...state,
    buttonLoading: true,
  };
};
export const lastFiveMinutesActiveListRequestSuccessHandler = (
  state,
  action
) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    memberListLoading: false,
    buttonLoading: false,
    lastFiveMinActiveList: data.data,
  };
};
export const lastFiveMinutesActiveListRequestFailureHandler = (
  state,
  action
) => {
  return {
    ...state,
    memberListLoading: false,
    buttonLoading: false,
    lastFiveMinActiveList: null,
  };
};

export const instantScreenshotsListRequestHandler = (state, action) => {
  // if (
  //   !state.instantScreenshotsList ||
  //   (state.instantScreenshotsList && state.instantScreenshotsList.length === 0)
  // ) {
  //   return {
  //     ...state,
  //     isLoading: true,
  //     buttonLoading: true,
  //     instantScreenshotsList: [],
  //   };
  // }
  return {
    ...state,
    isLoading: true,
    buttonLoading: true,
    instantScreenshotsList: [],
  };
};
export const instantScreenshotsListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  const list = JSON.parse(JSON.stringify(data.data));
  list.sort((a, b) => b.timestamp - a.timestamp);
  return {
    ...state,
    isLoading: false,
    buttonLoading: false,
    instantScreenshotsList: [...list],
  };
};
export const instantScreenshotsListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    buttonLoading: false,
    instantScreenshotsList: null,
  };
};

export const takeInstantScreenshotRequestHandler = (state, action) => {
  return {
    ...state,
    instantScreenshotLoading: true,
  };
};
export const takeInstantScreenshotRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;

  createNotification("success", "Instant screenshot requested.", 2000);

  return {
    ...state,
    instantScreenshotLoading: false,
    requestedScreenshotData: data.data,
  };
};
export const takeInstantScreenshotRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification(
      "error",
      "Could not take instant screenshot! Please try again.",
      3000
    );
  } else {
    let errorMsg;
    switch (response.status) {
      case 400:
        if (
          response.data.non_field_errors &&
          response.data.non_field_errors[0] === "user is not tracking time now"
        ) {
          errorMsg = "This user is offline now!";
        }
        break;
      default:
        errorMsg = "Could not take instant screenshot! Please try again.";
    }
    if (errorMsg) {
      createNotification("error", errorMsg, 4000);
    }
  }
  return {
    ...state,
    instantScreenshotLoading: false,
    requestedScreenshotData: null,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...initialState,
  };
};

const ACTION_HANDLERS = {
  [GET_LIVE_MEMBERS_LIST]: liveMembersListRequestHandler,
  [GET_LIVE_MEMBERS_LIST_SUCCESS]: liveMembersListRequestSuccessHandler,
  [GET_LIVE_MEMBERS_LIST_FAILURE]: liveMembersListRequestFailureHandler,

  [GET_LAST_FIVE_MIN_ACTIVE_LIST]: lastFiveMinutesActiveListRequestHandler,
  [GET_LAST_FIVE_MIN_ACTIVE_LIST_SUCCESS]:
    lastFiveMinutesActiveListRequestSuccessHandler,
  [GET_LAST_FIVE_MIN_ACTIVE_LIST_FAILURE]:
    lastFiveMinutesActiveListRequestFailureHandler,

  [GET_INSTANT_SCREENSHOTS_LIST]: instantScreenshotsListRequestHandler,
  [GET_INSTANT_SCREENSHOTS_LIST_SUCCESS]:
    instantScreenshotsListRequestSuccessHandler,
  [GET_INSTANT_SCREENSHOTS_LIST_FAILURE]:
    instantScreenshotsListRequestFailureHandler,

  [TAKE_INSTANT_SCREENSHOT]: takeInstantScreenshotRequestHandler,
  [TAKE_INSTANT_SCREENSHOT_SUCCESS]: takeInstantScreenshotRequestSuccessHandler,
  [TAKE_INSTANT_SCREENSHOT_FAILURE]: takeInstantScreenshotRequestFailureHandler,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

const initialState = {
  isLoading: false,
  memberListLoading: false,
  buttonLoading: false,
  instantScreenshotLoading: false,

  liveMembersList: null,
  lastFiveMinActiveList: null,
  instantScreenshotsList: null,
  requestedScreenshotData: null,
};

export default function liveFeedReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
