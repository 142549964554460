import {
  CommonText,
  ArrowBox,
  BackLinkSection,
} from "../../styledComponents/common";

import Back from "../../assets/img/icons/back.svg";

const BackButtonComponent = (props) => (
  <BackLinkSection onClick={props.onClick} gap={props.gap}>
    <ArrowBox size={props.size}>
      <img src={Back} alt="back" width="100%" />
    </ArrowBox>
    <CommonText name fontSize={props.font ? props.font : "13px"}>
      {props.subTitle}
    </CommonText>
  </BackLinkSection>
);

export default BackButtonComponent;
