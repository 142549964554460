import {
  // DONT_SHOW_ORGANIZATION_WIZARD,
  // DONT_SHOW_ORGANIZATION_WIZARD_SUCCESS,
  // DONT_SHOW_ORGANIZATION_WIZARD_FAILURE,
  // ADD_MULTIPLE_PROJECTS,
  // ADD_MULTIPLE_PROJECTS_SUCCESS,
  // ADD_MULTIPLE_PROJECTS_FAILURE,
  // INVITE_MULTIPLE_MEMBERS,
  // INVITE_MULTIPLE_MEMBERS_SUCCESS,
  // INVITE_MULTIPLE_MEMBERS_FAILURE,
  CHECK_ONBOARDING_APPLICABLE,
  CHECK_ONBOARDING_APPLICABLE_SUCCESS,
  CHECK_ONBOARDING_APPLICABLE_FAILURE,
  SAVE_ONBOARDING_DATA,
  SAVE_ONBOARDING_DATA_SUCCESS,
  SAVE_ONBOARDING_DATA_FAILURE,
  LOGIN_PROFILE_CREATED_RESET,
} from "../../../modules/constants";
import { createNotification } from "../../../modules/notificationManager";

// ------------------------------------
// Action handler methods
// ------------------------------------

// export const handleDontShowWizardAgain = (state, action) => {
//   return {
//     ...state,
//     isLoading: true,
//     redirect: false,
//   };
// };
// export const handleDontShowWizardAgainSuccess = (state, action) => {
//   createNotification("success", "This page won't be shown again", 3000);
//   return {
//     ...state,
//     isLoading: false,
//     redirect: true,
//   };
// };
// export const handleDontShowWizardAgainFailure = (state, action) => {
//   createNotification("error", "Something went wrong", 3000);
//   return {
//     ...state,
//     isLoading: false,
//     redirect: false,
//   };
// };

// export const handleMultipleProjectRequest = (state, action) => {
//   return {
//     ...state,
//     isLoading: true,
//     redirect: false,
//   };
// };
// export const handleMultipleProjectRequestSuccess = (state, action) => {
//   createNotification("success", "Projects added", 3000);
//   return {
//     ...state,
//     isLoading: false,
//     redirect: false,
//     projects_added: true,
//   };
// };
// export const handleMultipleProjectRequestFailure = (state, action) => {
//   createNotification("error", "Something went wrong", 3000);
//   return {
//     ...state,
//     isLoading: false,
//     redirect: false,
//   };
// };

// export const handleMultipleInviteRequest = (state, action) => {
//   return {
//     ...state,
//     isLoading: true,
//     redirect: false,
//   };
// };
// export const handleMultipleInviteRequestSuccess = (state, action) => {
//   createNotification("success", "Members invited", 3000);
//   return {
//     ...state,
//     isLoading: false,
//     // redirect: true,
//     members_invited: true,
//   };
// };
// export const handleMultipleInviteRequestFailure = (state, action) => {
//   createNotification("error", "Something went wrong", 3000);
//   return {
//     ...state,
//     isLoading: false,
//     redirect: false,
//   };
// };

export const handleCheckOnboardingApplicable = (state, action) => {
  return {
    ...state,
    isLoading: true,
    redirect: false,
  };
};
export const handleCheckOnboardingApplicableSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    redirect: true,
  };
};
export const handleCheckOnboardingApplicableFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    redirect: false,
  };
};

export const handleSaveOnboardingData = (state, action) => {
  return {
    ...state,
    isLoading: true,
    redirect: false,
  };
};
export const handleSaveOnboardingDataSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    redirect: true,
  };
};
export const handleSaveOnboardingDataFailure = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification(
      "error",
      "Could not connect to the internet. Please check your connection.",
      5000
    );
  } else if (
    response.data &&
    response.data.non_field_errors &&
    response.data.non_field_errors[0] ===
      "user is not allowed to view onboarding"
  ) {
    createNotification(
      "error",
      "You are not allowed to create organization from here.",
      5000
    );
  } else {
    createNotification(
      "error",
      "Something went wrong! Please try again.",
      5000
    );
  }
  return {
    ...state,
    isLoading: false,
    redirect: false,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  // [DONT_SHOW_ORGANIZATION_WIZARD]: handleDontShowWizardAgain,
  // [DONT_SHOW_ORGANIZATION_WIZARD_SUCCESS]: handleDontShowWizardAgainSuccess,
  // [DONT_SHOW_ORGANIZATION_WIZARD_FAILURE]: handleDontShowWizardAgainFailure,

  // [ADD_MULTIPLE_PROJECTS]: handleMultipleProjectRequest,
  // [ADD_MULTIPLE_PROJECTS_SUCCESS]: handleMultipleProjectRequestSuccess,
  // [ADD_MULTIPLE_PROJECTS_FAILURE]: handleMultipleProjectRequestFailure,

  // [INVITE_MULTIPLE_MEMBERS]: handleMultipleInviteRequest,
  // [INVITE_MULTIPLE_MEMBERS_SUCCESS]: handleMultipleInviteRequestSuccess,
  // [INVITE_MULTIPLE_MEMBERS_FAILURE]: handleMultipleInviteRequestFailure,

  [CHECK_ONBOARDING_APPLICABLE]: handleCheckOnboardingApplicable,
  [CHECK_ONBOARDING_APPLICABLE_SUCCESS]: handleCheckOnboardingApplicableSuccess,
  [CHECK_ONBOARDING_APPLICABLE_FAILURE]: handleCheckOnboardingApplicableFailure,

  [SAVE_ONBOARDING_DATA]: handleSaveOnboardingData,
  [SAVE_ONBOARDING_DATA_SUCCESS]: handleSaveOnboardingDataSuccess,
  [SAVE_ONBOARDING_DATA_FAILURE]: handleSaveOnboardingDataFailure,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  isLoading: false,
  redirect: false,
  // projects_added: false,
  // members_invited: false,
  onboard_user: false,
};

export default function appsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
