import { useState, useEffect, Fragment, useMemo } from "react";
import moment from "moment";
import ReportsFilter from "../reportsFilterContainer";
import TimeActivityTable from "./TimeActivityTable";
import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";
import ExportModal from "../ExportModal";
import {
  PageTitle,
  CardTitle,
  CommonGrid,
  Container,
  CommonFlex,
} from "../../../styledComponents/common";
import {
  ColoredButton,
  TabButton,
  TabButtonContainer,
} from "../../../styledComponents/buttons";
import { ActivityTextSection } from "../../../styledComponents/members";
import { ShowNotesModal } from "../../ActivityNew/Screenshots/ScreenshotsModals";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import TimeActivityWeeklyTable from "./TimeActivityWeeklyTable";

const getDates = (startDate, endDate) => {
  const dates = [];
  let end = endDate;
  while (moment(end).isSameOrAfter(moment(startDate))) {
    dates.push(moment(end).format("YYYY-MM-DD"));
    end = moment(end).subtract(1, "days");
  }
  return dates;
};

const TimeActivity = ({
  averageActivity,
  selectedOrganization,
  getReportNotes,
  isLoading,
  notes,
  reportNotesLoading,
  noData,
  weeklyData,
  isWeeklyDataLoading,
}) => {
  const [showGraph, setShowGraph] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [sortType, setSortType] = useState({
    label: "Date (New to old)",
    value: "NewToOld",
  });
  const [pdfExport, setPdfExport] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedTab, setSelectedTab] = useState("daily");

  const [plan, setPlan] = useState("");

  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [noteMember, setNoteMember] = useState(null);

  const toggleNotesModal = () => {
    if (isNotesModalOpen) {
      setNoteMember(null);
    }
    setIsNotesModalOpen(!isNotesModalOpen);
  };
  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };
  const changeSortType = (type) => {
    setSortType(type);
  };

  const changeProjectInTable = (value) => {
    setSelectedProject(value);
  };

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  const changeDisplay = () => {
    setShowGraph(!showGraph);
  };

  const onClickExport = () => {
    setPdfExport(!pdfExport);
  };

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      setSelectedProject("");
      setSortType({
        label: "Date (New to old)",
        value: "NewToOld",
      });
      if (
        selectedOrganization.configuration &&
        // selectedOrganization.configuration.screenshot_track &&
        (selectedOrganization.configuration.mouse_track ||
          selectedOrganization.configuration.keystroke_track)
      ) {
        setPlan("monitored");
      } else {
        setPlan("unmonitored");
      }
    }
  }, [selectedOrganization]);

  const dateWiseReport = useMemo(() => {
    if (
      averageActivity &&
      averageActivity.data &&
      Object.keys(averageActivity.data).length > 0
    ) {
      const { data, startDate, endDate } = averageActivity;
      const dates = getDates(startDate, endDate);
      const reportsData = [];

      if (dates && dates.length) {
        dates.forEach((date) => {
          Object.keys(data).some((reportDate) => {
            if (reportDate === date) {
              reportsData.push({ date: reportDate, data: data[reportDate] });
              return true;
            }
            return false;
          });
        });
      }

      return reportsData;
    }
    return [];
  }, [averageActivity]);

  const sortedReport = useMemo(() => {
    return [...dateWiseReport].reverse();
  }, [dateWiseReport]);

  const calculateWeeklyReportData = (data) => {
    const userTotals = {};

    for (const date in data) {
      data[date]?.forEach((entry) => {
        const { user_id } = entry;
        if (!userTotals[user_id]) {
          userTotals[user_id] = {
            ...entry,
            time_worked_in_seconds: 0,
            idle_time: 0,
            total: 0,
            member_average_activity: 0,
          };
        }
        userTotals[user_id].time_worked_in_seconds +=
          entry.time_worked_in_seconds;
        userTotals[user_id].idle_time += entry.idle_time;
        userTotals[user_id].member_average_activity +=
          entry.time_worked_in_seconds * entry.member_average_activity;
        userTotals[user_id].total += 1;
      });
    }

    return Object.values(userTotals);
  };

  // Memoize weeklyReport
  const weeklyReport = useMemo(() => {
    if (
      weeklyData &&
      weeklyData.data &&
      Object.keys(weeklyData.data).length > 0
    ) {
      const weeklyReportData = calculateWeeklyReportData(weeklyData.data);
      if (weeklyReportData.length > 0) {
        const processedData = weeklyReportData.map((item) => {
          const active_time = item.member_average_activity / 100;
          const neutral_time = parseInt(
            item.time_worked_in_seconds - active_time - item.idle_time
          );
          return {
            ...item,
            average_weekly_activity:
              item.member_average_activity / item.time_worked_in_seconds,
            active_time: active_time,
            neutral_time: neutral_time,
          };
        });
        return processedData.sort((a, b) =>
          a.member_name.localeCompare(b.member_name)
        );
      }
    }
    return [];
  }, [weeklyData]);

  useEffect(() => {}, [noData]);

  return (
    <div className="content">
      <ShowNotesModal
        isOpen={isNotesModalOpen}
        toggle={toggleNotesModal}
        notes={notes}
        noteMember={noteMember}
        notesType="all"
        isLoading={reportNotesLoading}
      />
      <PageTitle>Time & Activity Report</PageTitle>
      <CommonFlex>
        <TabButtonContainer width="max-content" margin="35px 0 0">
          <TabButton
            selected={selectedTab}
            index={"daily"}
            onClick={() => handleTabSelect("daily")}
          >
            Daily
          </TabButton>
          <TabButton
            selected={selectedTab}
            index={"weekly"}
            onClick={() => handleTabSelect("weekly")}
          >
            Weekly
          </TabButton>
        </TabButtonContainer>
        <ReportsFilter
          title="timeActivity"
          sortType={sortType}
          selectedTab={selectedTab}
          changeSortType={changeSortType}
          changeProjectInTable={changeProjectInTable}
        />
      </CommonFlex>
      <Container
        style={{ padding: `30px`, marginTop: `20px`, background: `#FCFDFE` }}
      >
        {(isLoading && selectedTab === "daily") ||
        (isWeeklyDataLoading && selectedTab === "weekly") ? (
          <ComponentCircleLoader />
        ) : (
          <>
            {dateWiseReport && dateWiseReport.length > 0 && !isLoading && (
              <CommonGrid alignItem="center">
                <ActivityTextSection
                  gap="5px"
                  style={{ alignContent: `start` }}
                >
                  <CardTitle>
                    {selectedProject === "" ? "All Projects" : selectedProject}
                  </CardTitle>
                </ActivityTextSection>
                <CommonGrid>
                  {plan && plan === "monitored" && selectedTab !== "weekly" && (
                    <ColoredButton type="blue" onClick={() => changeDisplay()}>
                      {showGraph ? `Show Table` : `Show Graph`}
                    </ColoredButton>
                  )}
                  <ColoredButton type="gray" onClick={() => popupToggle()}>
                    Export
                  </ColoredButton>
                </CommonGrid>
              </CommonGrid>
            )}
            {sortType.value === "NewToOld" ? (
              (dateWiseReport &&
                dateWiseReport.length > 0 &&
                selectedTab === "daily") ||
              (weeklyReport &&
                weeklyReport.length > 0 &&
                selectedTab === "weekly") ? (
                <Fragment>
                  <ExportModal
                    isOpen={popupOpen}
                    toggle={popupToggle}
                    onClickExport={onClickExport}
                    title="timeActivity"
                    selectedProject={selectedProject}
                    selectedTab={selectedTab}
                    plan={plan}
                    weeklyDates={{
                      firstDay: weeklyData?.startDate,
                      lastDay: weeklyData?.endDate,
                    }}
                    averageActivity={
                      selectedTab === "daily" ? dateWiseReport : weeklyReport
                    }
                  />
                  {selectedTab === "daily" ? (
                    <>
                      {dateWiseReport.map((report, index) => (
                        <TimeActivityTable
                          report={report}
                          key={index}
                          index={index}
                          showGraph={showGraph}
                          plan={plan}
                          getNotes={getReportNotes}
                          setNoteMember={setNoteMember}
                          setIsNotesModalOpen={setIsNotesModalOpen}
                          selectedOrganization={selectedOrganization}
                        />
                      ))}
                    </>
                  ) : (
                    <TimeActivityWeeklyTable
                      report={weeklyReport}
                      showGraph={false}
                      plan={plan}
                      getNotes={getReportNotes}
                      setNoteMember={setNoteMember}
                      setIsNotesModalOpen={setIsNotesModalOpen}
                      selectedOrganization={selectedOrganization}
                    />
                  )}
                </Fragment>
              ) : (
                <NoDataComponent title="Sorry! there is no Time & Activity Report within the selected dates." />
              )
            ) : sortedReport && sortedReport.length > 0 ? (
              <Fragment>
                <ExportModal
                  isOpen={popupOpen}
                  toggle={popupToggle}
                  onClickExport={onClickExport}
                  title="timeActivity"
                  selectedProject={selectedProject}
                  selectedTab={selectedTab}
                  plan={plan}
                  averageActivity={
                    selectedTab === "daily" ? sortedReport : weeklyReport
                  }
                />
                {selectedTab === "daily" ? (
                  <>
                    {" "}
                    {sortedReport.map((report, index) => (
                      <TimeActivityTable
                        report={report}
                        key={index}
                        index={index}
                        showGraph={showGraph}
                        plan={plan}
                        getNotes={getReportNotes}
                        setNoteMember={setNoteMember}
                        setIsNotesModalOpen={setIsNotesModalOpen}
                        selectedOrganization={selectedOrganization}
                      />
                    ))}
                  </>
                ) : (
                  <TimeActivityWeeklyTable
                    report={weeklyReport}
                    showGraph={false}
                    plan={plan}
                    getNotes={getReportNotes}
                    setNoteMember={setNoteMember}
                    setIsNotesModalOpen={setIsNotesModalOpen}
                    selectedOrganization={selectedOrganization}
                  />
                )}
              </Fragment>
            ) : (
              <NoDataComponent title="Sorry! there is no Time & Activity Report within the selected dates." />
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default TimeActivity;
