
import {
  OnboardingPreviousButton,
  PreviousButtonIcon,
} from "../onboardingStepsStyles";

import previousIcon from "../../../../assets/img/onboardingImg/up_arrow.svg";

export default function PreviousButton(props) {
  return (
    <OnboardingPreviousButton onClick={() => props.handleClick(props.type)}>
      <PreviousButtonIcon src={previousIcon} alt="" />
    </OnboardingPreviousButton>
  );
}
