import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  PageTitle,
  ContentHeader,
  CommonFlex,
  CommonImage,
  CommonText,
} from "../../../styledComponents/common";

import {
  PrimaryButton,
  WhiteButton,
  ColoredButtonWithIcon,
  ButtonIcon,
  RefreshButton,
} from "../../../styledComponents/buttons";
import { IntegrationNameContainer } from "../Setup/integrationSetupStyles";

import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";

import PMDetails from "./ProjectManagement";
import { WorkflowsSection } from "../Setup/IntegrationSetup";

import refreshIcon from "../../../assets/img/icons/refresh_white.svg";

const IntegrationSetup = (props) => {
  const [integration, setIntegration] = useState(null);

  const [workflowsList, setWorkflowsList] = useState([]);

  const { enabled_integration_id } = useParams();

  const {
    history,
    location,
    selectedOrganization,

    isLoading,
    syncIsLoading,

    getIntegrationWorkflowsList,
    integrationWorkflowsList,

    getIntegrationEnabledWorkflowsList,
    enabledWorkflowsList,

    enableIntegrationWorkflow,
    enabledWorkflowData,

    runIntegrationWorkflow,
  } = props;

  useEffect(() => {
    if (location && location.state && location.state.enabledIntegration) {
      setIntegration(location.state.enabledIntegration);
    }
  }, []);

  useEffect(() => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      integration &&
      integration.id
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        integration_id: integration.integration_id,
        enabled_integration_id: integration.id,
      };
      getIntegrationWorkflowsList(payload);
      getIntegrationEnabledWorkflowsList(payload);
    }
  }, [integration, selectedOrganization]);

  useEffect(() => {
    if (integrationWorkflowsList && integrationWorkflowsList.length > 0) {
      const currentList = JSON.parse(JSON.stringify(integrationWorkflowsList));
      if (enabledWorkflowsList && enabledWorkflowsList.length > 0) {
        currentList.forEach((workflow) => {
          enabledWorkflowsList.some((enabled) => {
            if (enabled.name === workflow.name) {
              workflow.is_enabled = true;
              return true;
            }
            return false;
          });
        });
      }
      setWorkflowsList([...currentList]);
    } else {
      setWorkflowsList([]);
    }
  }, [integrationWorkflowsList, enabledWorkflowsList]);

  const handleEnableWorkflow = (workflowId) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      integration &&
      integration.id &&
      workflowId
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        enabled_integration_id: integration.id,
        workflow_id: workflowId,
      };

      enableIntegrationWorkflow(payload);
    }
  };

  const runWorkflows = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const activated = workflowsList.filter((workflow) => workflow.is_enabled);

      if (activated && activated.length > 0) {
        activated.forEach((workflow) => {
          const { tag, name, associated_resource } = workflow;
          const payload = {
            organization_id: selectedOrganization.id,
            tag,
            name,
            associated_resource,
          };
          runIntegrationWorkflow(payload);
        });
      }
    }
  };

  return (
    <div className="content">
      <PageTitle>{integration && integration.display_name} Details</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          subTitle="Back to all integrations"
          onClick={() => history.push("/user/integrations")}
        />
      </ContentHeader>
      <CommonFlex justifyContent="space-between" margin="30px 0 0">
        <IntegrationNameContainer>
          <CommonFlex gap="12px">
            <CommonImage size="40px" src={integration && integration.icon} />
            <CommonText fontWeight="600" fontSize="16px">
              {integration && integration.display_name}
            </CommonText>
          </CommonFlex>
        </IntegrationNameContainer>
        {/* <CommonFlex gap="12px">
          <WhiteButton type="delete">Delete</WhiteButton>
          <PrimaryButton>Re-Authenticate</PrimaryButton>
        </CommonFlex> */}
        <RefreshButton
          isLoading={syncIsLoading}
          onClick={() => {
            runWorkflows();
          }}
          padding="0 15px"
        >
          <ButtonIcon src={refreshIcon} alt="" size="22px" />
          Sync Projects & Tasks
        </RefreshButton>
      </CommonFlex>
      {workflowsList && workflowsList.length > 0 ? (
        <WorkflowsSection
          integrationWorkflowsList={workflowsList}
          handleEnableWorkflow={handleEnableWorkflow}
        />
      ) : null}

      {/* <PMDetails integration={integration} /> */}
    </div>
  );
};

export default IntegrationSetup;
