import { Modal, ModalBody } from "reactstrap";
import { ModalButtonSection } from "../../styledComponents/members";
import { CommonText } from "../../styledComponents/common";
import { WhiteButton, ColoredButton } from "../../styledComponents/buttons";

import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";

const ApproveLeaveModal = ({
  isOpen,
  toggle,
  handleLeaveApproveReject,
  isLoading,
  actionType,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ marginTop: `25vh` }}>
      <ModalBody style={{ padding: `20px` }}>
        <CommonText style={{ paddingLeft: `15px` }}>
          Are you sure you want to{" "}
          {actionType === "accept"
            ? "Accept"
            : actionType === "reject"
            ? "Reject"
            : actionType === "withdraw"
            ? "Withdraw"
            : "Delete"}{" "}
          this leave application?
        </CommonText>
        <ModalButtonSection>
          <WhiteButton type="cancel" onClick={toggle}>
            Cancel
          </WhiteButton>

          {actionType && actionType === "accept" ? (
            <ColoredButton
              padding={isLoading ? "0 15px" : "0 30px"}
              onClick={() => handleLeaveApproveReject()}
            >
              {isLoading ? (
                <ButtonTextLoader loadingText="Accepting" fontSize="13px" />
              ) : (
                "Accept"
              )}
            </ColoredButton>
          ) : actionType === "reject" ? (
            <ColoredButton
              type="delete"
              padding={isLoading ? "0 15px" : "0 30px"}
              onClick={() => handleLeaveApproveReject()}
            >
              {isLoading ? (
                <ButtonTextLoader loadingText="Rejecting" fontSize="13px" />
              ) : (
                "Reject"
              )}
            </ColoredButton>
          ) : actionType === "withdraw" ? (
            <ColoredButton
              type="black"
              padding={isLoading ? "0 15px" : "0 30px"}
              onClick={() => handleLeaveApproveReject()}
            >
              {isLoading ? (
                <ButtonTextLoader loadingText="Withdrawing" fontSize="13px" />
              ) : (
                "Withdraw"
              )}
            </ColoredButton>
          ) : (
            <ColoredButton
              type="delete"
              padding={isLoading ? "0 15px" : "0 30px"}
              onClick={() => handleLeaveApproveReject()}
            >
              {isLoading ? (
                <ButtonTextLoader loadingText="Deleting" fontSize="13px" />
              ) : (
                "Delete"
              )}
            </ColoredButton>
          )}
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default ApproveLeaveModal;
