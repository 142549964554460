
import { css } from "@emotion/core";
import PulseLoader from "react-spinners/PulseLoader";

const customCss = css`
  margin: 5px;
  text-align: center;
`;

const TextLoader = (props) => {
  return (
    <div className="sweet-loading">
      <PulseLoader
        size={10}
        color={"#2f394e"}
        loading={props.loading}
        css={customCss}
      />
    </div>
  );
};
const LoginLoader = (props) => {
  return <TextLoader loading />;
};

export default LoginLoader;
