import { useState, useEffect } from "react";
import moment from "moment";

import { Container, CardTitle } from "../../../styledComponents/common";

import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";

// import ApprovalSummary from "../ApprovalSummary";
import AwaitingTable from "./AwaitingTable";

const AwaitingApprovals = (props) => {
  const {
    selectedOrganization,
    organizationMembersList,
    awaitingList,
    isLoading,
    seeMoreIsLoading,
    approveIsLoading,
    rejectIsLoading,
    approveOrRejectTimesheets,

    // pagination
    handleSeeMore,
    paginationSize,
    allLoadedDates,
    clearLoadMoreTimesheetApprovalList,
  } = props;

  return (
    <Container padding="30px 0" style={{ gridTemplateColumns: "100%" }}>
      {isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <>
          <CardTitle margin="0 0 20px 30px">All Awaiting Timesheets</CardTitle>
          {/* <ApprovalSummary timeData={awaitingList} summaryData="data" /> */}
          <AwaitingTable
            selectedOrganization={selectedOrganization}
            organizationMembersList={organizationMembersList}
            awaitingList={awaitingList}
            approveOrRejectTimesheets={approveOrRejectTimesheets}
            seeMoreIsLoading={seeMoreIsLoading}
            approveIsLoading={approveIsLoading}
            rejectIsLoading={rejectIsLoading}
            handleSeeMore={handleSeeMore}
            paginationSize={paginationSize}
            allLoadedDates={allLoadedDates}
            clearLoadMoreTimesheetApprovalList={
              clearLoadMoreTimesheetApprovalList
            }
          />
        </>
      )}
    </Container>
  );
};

export default AwaitingApprovals;
