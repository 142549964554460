import styled, { css } from "styled-components";
import { Modal, ModalBody } from "reactstrap";
import { CenteredTooltip } from "../../../styledComponents/common";

const timesheetBoxColors = [
  "#98c3ff",
  "#fad59d",
  "#a5a58d",
  "#becbdb",
  "#b4b0fa",
];
const timesheetBoxHoverColors = [
  "#7bacf0",
  "#f0c17a",
  "#848471",
  "#a4b5cc",
  "#9993f2",
];
const timesheetBoxHoverBorderColors = [
  "#3472c7",
  "#ce943b",
  "#7c7c6a",
  "#5c7aa2",
  "#5e58c1",
];

export const SelectAllDateMemberSection = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || `24px auto 1fr 1fr`};
  grid-column-gap: 15px;
  align-items: center;
`;
export const ButtonContainer = styled.div`
  display: grid;
  justify-content: start;
  margin: ${({ index }) => (index === 0 ? "10px" : "15px")} 0 20px;
  padding-left: 30px;
`;
export const CheckBox = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  grid-template-columns: auto;
  border: 1px solid
    ${({ bgColor, theme }) =>
      bgColor
        ? theme.colors.border.primary
        : theme.colors.border.secondaryLight};
  border-radius: 4px;
  width: 22px;
  height: 22px;
  cursor: pointer;
  background-color: ${({ bgColor, theme }) =>
    bgColor && theme.colors.main.primary};
  &:hover {
    border-color: ${({ theme }) => theme.colors.border.primary};
  }
`;

export const AwaitingTableRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 250px 100px 1fr;
  grid-column-gap: 10px;
  justify-content: space-between;
  padding: 0 15px;
  :nth-of-type(even) {
    background-color: #f8f9fc;
  }
  border-top: ${({ header, theme }) =>
    header && `1px solid ${theme.colors.border.secondary}`};
  border-bottom: ${({ header, theme }) =>
    header && `1px solid ${theme.colors.border.secondary}`};
`;

export const CheckBoxTextContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: ${({ columns }) => columns || "24px auto"};
  grid-column-gap: 15px;
`;
export const PaymentButtonContainer = styled.div`
  display: grid;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
`;

export const FixedPaymentContainer = styled.div`
  display: grid;
  justify-content: start;
  grid-template-columns: 1fr;
  grid-row-gap: 15px;
  min-width: 220px;
  max-width: 330px;
`;

// Payroll Summary

export const SummaryContainer = styled.div`
  width: ${({ width }) => width || "65%"};
  overflow-x: auto;
  background: ${({ theme }) => theme.colors.main.whiteBg};
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.borderRadii.card};
  margin: 20px 30px;
  padding: 20px;
`;

export const SummarySectionContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: ${({ columns }) => columns || "auto auto auto"};
`;
export const SummaryTextSection = styled.div`
  display: grid;
  grid-row-gap: ${({ multiple }) => (multiple ? `10px` : `8px`)};
`;
export const SummaryText = styled.div`
  font-size: ${({ multiple }) => (multiple ? "14px" : "15px")};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ color, total, theme }) =>
    color
      ? color
      : total
      ? theme.colors.main.primary
      : theme.colors.main.textMain};
`;
export const SummaryLabel = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.main.labelText};
`;

export const SummaryMultipleTableSection = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: ${({ columns }) => columns || "2fr 1fr 1fr"};
`;

// idle time duration
export const IdleTimeDurationDiv = styled.div`
  position: relative;
  &:hover {
    ${CenteredTooltip} {
      opacity: 1;
      visibility: visible;
    }
  }
`;
export const IdleTimeDurationBox = styled.div`
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding ?? "3px 12px"};
  background-color: ${({ theme }) => theme.colors.main.errorLight};
  border: 1px solid ${({ theme }) => theme.colors.border.error};
  color: ${({ theme }) => theme.colors.main.error};
  font-size: 11px;
  border-radius: 4px;
`;

// timesheet box

export const TimesheetBoxContainer = styled.div`
  width: 100%;
  display: flex;
  grid-column-gap: ${({ gap }) => gap || "5px"};
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  padding: ${({ padding }) => padding || "0 0 0 120px"};
  ::-webkit-scrollbar {
    height: ${({ theme }) => theme.dropdown.scrollbar.width};
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.dropdown.scrollbar.trackBgColor};
    border-radius: ${({ theme }) => theme.dropdown.scrollbar.thumbBorderRadius};
    margin-left: 120px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.dropdown.scrollbar.thumbBgColor};
    border-radius: ${({ theme }) => theme.dropdown.scrollbar.thumbBorderRadius};
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.dropdown.scrollbar.thumbHoverBgColor};
  }
`;
export const TimesheetBox = styled.div`
  position: relative;
  min-width: ${({ duration }) =>
    duration < 400 ? "5px" : `${parseInt(duration * 0.0125)}px`};
  height: 34px;
  border-radius: 6px;
  background-color: ${({ index }) => timesheetBoxColors[index % 5]};
  border: 1px solid ${({ index }) => timesheetBoxColors[index % 5]};
  cursor: ${({ cursor }) => cursor || "pointer"};
  &:hover {
    background-color: ${({ index }) => timesheetBoxHoverColors[index % 5]};
    border-color: ${({ index }) => timesheetBoxHoverBorderColors[index % 5]};
    ${CenteredTooltip} {
      opacity: 1;
      visibility: visible;
    }
  }
  ${({ rejectedDuration }) =>
    rejectedDuration &&
    rejectedDuration > 0 &&
    css`
      &::after {
        content: "";
        position: absolute;
        right: -1px;
        top: -1px;
        width: ${`${parseInt(rejectedDuration * 0.0125)}px`};
        height: 34px;
        background-color: ${({ index }) =>
          timesheetBoxHoverBorderColors[index % 5]};
        opacity: 0.4;
        border-radius: 0 6px 6px 0;
      }
    `}
`;

// reject timesheet
export const RejectButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 18px;
  width: 18px;
  top: -10px;
  right: 4px;
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  background-color: ${({ theme }) => theme.colors.main.error};
  &:hover {
    background-color: ${({ theme }) => theme.colors.hover.error};
  }
`;
export const RejectButtonIcon = styled.div`
  width: 8px;
  height: 2px;
  background-color: #fff;
`;

// reject modal
export const RejectModalContainer = styled(Modal)`
  max-width: 520px;
  margin-top: 24vh;
`;
export const RejectModalBody = styled(ModalBody)`
  background-color: ${({ theme }) => theme.colors.main.white};
  border-radius: ${({ theme }) => theme.borderRadii.card};
  // border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  padding: 20px 0;
`;
// time input
export const TimeInputContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.main.whiteBg};
  border-radius: ${({ theme }) => theme.borderRadii.input};
  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.colors.border.error : theme.colors.border.secondaryLight};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: ${({ padding }) => padding || "0 5px"};
  &:hover {
    border-color: ${({ hoverBorderColor, theme }) =>
      hoverBorderColor || theme.input.borderColor.hover};
    box-shadow: ${({ theme }) => theme.input.focusShadow};
  }
  &:focus-within {
    outline: none;
    border-color: ${({ focusBorderColor, theme }) =>
      focusBorderColor || theme.input.borderColor.focus};
    box-shadow: ${({ theme }) => theme.input.focusShadow};
  }
`;
export const TimeInputDiv = styled.input`
  position: relative;
  margin: 0;
  padding: 0 2px;
  border-radius: 2px;
  color: ${({ theme }) => theme.input.textColor};
  font-size: ${({ theme }) => theme.input.fontSize};
  background: transparent;
  width: max-content;
  min-width: 26px;
  max-width: 28px;
  height: 85%;
  text-align: center;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  &::placeholder {
    font-weight: inherit;
    color: ${({ theme }) => theme.input.placeholder.color};
  }
  &:focus {
    caret-color: transparent;
    background-color: ${({ theme }) => theme.colors.main.focusShadow};
  }
  &::selection {
    background: transparent;
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  appearance: textfield;
`;
