import { useState, useEffect } from "react";

import moment from "moment-timezone";

import { WhiteButton, PrimaryButton } from "../../styledComponents/buttons";

import DateRangePicker from "../../components/ReactDateRangePicker/ReactDateRangePicker";

import {
  CardTitle,
  InputLabel,
  RedStar,
  FormAlert,
  CommonFlex,
  ColoredText,
} from "../../styledComponents/common";

import {
  CustomModal,
  ApplyLeaveModalBody,
  ModalInputSection,
  DateTimeContainer,
  CommentTextArea,
  ModalButtonSection,
} from "./applyLeaveModalStyles";

import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";
import { checkUrl, checkHtml } from "../../utils/helper";

const ApplyLeaveModal = (props) => {
  const [comment, setComment] = useState("");
  const [saveErrors, setSaveErrors] = useState({});

  const [startDate, setStartDate] = useState(moment().format("YYYYY-MM-DD"));

  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const { isOpen, toggle, isLoading, selectedOrganization, handleApplyLeave } =
    props;

  const toggleModal = () => {
    toggle();
    clearData();
  };

  const onDateSelect = () => {
    // let organizationId = selectedOrganization ? selectedOrganization.id : "";
    // if (organizationId) {
    //   const payload = {
    //     organization_id: organizationId,
    //     start_date: startDate,
    //     end_date: endDate,
    //   };
    // }
  };

  const changeDateRange = (e) => {
    const { startDate, endDate } = e;
    if (startDate) {
      setStartDate(moment(startDate).format("YYYY-MM-DD"));
      if (!endDate) {
        setEndDate(moment(startDate).format("YYYY-MM-DD"));
      }
    }
    if (endDate) {
      setEndDate(moment(endDate).format("YYYY-MM-DD"));
      if (!startDate) {
        setStartDate(moment(endDate).format("YYYY-MM-DD"));
      }
    }
  };

  const checkCommentError = () => {
    let formIsValid = true;
    const saveErrors = {};

    if (checkUrl(comment)) {
      formIsValid = false;
      saveErrors["comment"] = "Comment can not contain url.";
    } else if (checkHtml(comment)) {
      formIsValid = false;
      saveErrors["comment"] = "Invalid input in comment.";
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const checkError = () => {
    let formIsValid = true;

    if (!comment.trim()) {
      formIsValid = false;
    }
    return formIsValid;
  };

  const clearData = () => {
    setStartDate(moment().format("YYYYY-MM-DD"));
    setEndDate(moment().format("YYYYY-MM-DD"));
    setComment("");
  };

  const handleAddLeave = () => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      checkCommentError()
    ) {
      let start = moment(startDate).startOf("day");
      let end = moment(endDate).endOf("day");

      if (selectedOrganization.timezone) {
        start = moment
          .tz(start, selectedOrganization.timezone)
          .format("YYYY-MM-DDTHH:mm:ss");

        end = moment
          .tz(end, selectedOrganization.timezone)
          .format("YYYY-MM-DDTHH:mm:ss");
      }

      const payload = {
        organization_id: selectedOrganization.id,
        start_time: start,
        end_time: end,
        comment: comment,
      };
      handleApplyLeave(payload);
      setTimeout(() => {
        clearData();
      }, 400);
    }
  };

  return (
    <CustomModal isOpen={isOpen} toggle={toggleModal}>
      <ApplyLeaveModalBody>
        <CardTitle>Apply for leave</CardTitle>
        <DateTimeContainer>
          <ModalInputSection gap="8px">
            <CommonFlex justifyContent="space-between">
              <InputLabel>
                Select Date Range <RedStar>*</RedStar>
              </InputLabel>
              <ColoredText type="blue" fontSize="13px" semibold>
                {moment(endDate).diff(moment(startDate), "days") === 0
                  ? "1 Day"
                  : ` ${
                      moment(endDate).diff(moment(startDate), "days") + 1
                    } Days`}
              </ColoredText>
            </CommonFlex>

            <DateRangePicker
              startDate={startDate ? moment(startDate) : null}
              stateDateId="leave-date-range-start-date"
              endDate={endDate ? moment(endDate) : null}
              endDateId="leave-date-range-end-date"
              onDatesChange={changeDateRange}
              dateDisplayFormat="DD/MM/YYYY"
              onDateSelect={onDateSelect}
              showFooter={false}
            />
          </ModalInputSection>
        </DateTimeContainer>

        <ModalInputSection gap="8px">
          <InputLabel>
            Reason <RedStar>*</RedStar>
          </InputLabel>

          <CommentTextArea
            id="comment"
            placeholder="Type your reason here..."
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
              setSaveErrors({});
            }}
            error={
              saveErrors &&
              saveErrors["comment"] &&
              saveErrors["comment"] !== undefined
            }
          />
          {saveErrors &&
            saveErrors["comment"] &&
            saveErrors["comment"] !== undefined && (
              <FormAlert margin="0 0 0 5px">{saveErrors["comment"]}</FormAlert>
            )}
        </ModalInputSection>

        <ModalButtonSection>
          <WhiteButton type="cancel" onClick={toggleModal}>
            Cancel
          </WhiteButton>
          <PrimaryButton
            padding={isLoading ? "0 15px" : "0 35px"}
            disabled={!checkError()}
            onClick={() => handleAddLeave()}
          >
            {isLoading ? (
              <ButtonTextLoader loadingText="Saving" fontSize="13px" />
            ) : (
              "Submit Leave Application"
            )}
          </PrimaryButton>
        </ModalButtonSection>
      </ApplyLeaveModalBody>
    </CustomModal>
  );
};

export default ApplyLeaveModal;
