import { useTheme } from "styled-components";
import {
  commonContainerStyles,
  commonMenuListStyles,
  commonMenuStyles,
  commonSingleValueStyles,
  commonPlaceholderStyles,
  commonDropdownIndicatorStyles,
  commonInputStyles,
} from "../../../../components/DropdownStyle/commonDropdownStyles";

const TimezoneDropdownStyle = () => {
  const theme = useTheme();
  return {
    container: (styles) => {
      return {
        ...commonContainerStyles(styles, theme),
        width: "100%",
      };
    },
    control: (styles, state) => {
      return {
        ...styles,
        width: "100%",
        height: "66px",
        border: "none",
        borderBottom: "1px solid #c2cce1",
        borderRadius: "0px",
        boxShadow: "none",
        ":hover": {
          cursor: "pointer",
          borderBottom: "1px solid #c2cce1",
        },
        padding: "9px 4px",
      };
    },
    option: (styles, { isDisabled, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected
          ? theme.dropdown.option.selectedBgColor
          : theme.dropdown.bgColor,
        color: isSelected
          ? theme.dropdown.option.selectedTextColor
          : theme.dropdown.option.textColor,
        cursor: isDisabled ? "not-allowed" : "pointer",
        fontFamily: theme.defaultFontFamily,
        fontSize: "24px",
        fontWeight: isSelected && theme.dropdown.option.selectedFontWeight,
        "&:hover": {
          backgroundColor: !isSelected && theme.dropdown.option.hoverBgColor,
        },
        "@media screen and (max-width: 768px)": {
          fontSize: "22px",
        },
        "@media screen and (max-width: 500px)": {
          fontSize: "16px",
        },
      };
    },
    menuList: (styles) => {
      return {
        ...commonMenuListStyles(styles, theme),
      };
    },
    menu: (styles, state) => {
      return {
        ...commonMenuStyles(styles, state, theme),
      };
    },
    valueContainer: (styles) => ({
      ...styles,
      padding: "0",
    }),
    singleValue: (styles) => ({
      ...commonSingleValueStyles(styles, theme),
      fontSize: "32px",
      "@media screen and (max-width: 768px)": {
        fontSize: "28px",
      },
      "@media screen and (max-width: 500px)": {
        fontSize: "18px",
      },
    }),
    input: (styles, state) => ({
      ...commonInputStyles(styles, state, theme),
      fontSize: "32px",
      "@media screen and (max-width: 768px)": {
        fontSize: "28px",
      },
      "@media screen and (max-width: 500px)": {
        fontSize: "18px",
      },
    }),
    placeholder: (styles) => ({
      ...commonPlaceholderStyles(styles, theme),
      fontSize: "30px",
      color: "rgba(61, 77, 105, 0.5)",
      "@media screen and (max-width: 768px)": {
        fontSize: "26px",
      },
      "@media screen and (max-width: 500px)": {
        fontSize: "18px",
      },
    }),
    dropdownIndicator: (styles, state) => {
      return {
        ...commonDropdownIndicatorStyles(styles, state, theme),
      };
    },
  };
};

export default TimezoneDropdownStyle;
