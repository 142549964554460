export const timezones = [
  {
    value: "Pacific/Midway",
    label: "(UTC-11:00) Pacific/Midway",
    metadata: "",
    offset: "-1100",
  },
  {
    value: "Pacific/Niue",
    label: "(UTC-11:00) Pacific/Niue",
    metadata: "",
    offset: "-1100",
  },
  {
    value: "Pacific/Pago_Pago",
    label: "(UTC-11:00) Pacific/Pago_Pago",
    metadata: "",
    offset: "-1100",
  },
  {
    value: "Pacific/Honolulu",
    label: "(UTC-10:00) Pacific/Honolulu",
    metadata: "",
    offset: "-1000",
  },
  {
    value: "Pacific/Rarotonga",
    label: "(UTC-10:00) Pacific/Rarotonga",
    metadata: "",
    offset: "-1000",
  },
  {
    value: "Pacific/Tahiti",
    label: "(UTC-10:00) Pacific/Tahiti",
    metadata: "",
    offset: "-1000",
  },
  {
    value: "US/Hawaii",
    label: "(UTC-10:00) US/Hawaii",
    metadata: "",
    offset: "-1000",
  },
  {
    value: "Pacific/Marquesas",
    label: "(UTC-09:30) Pacific/Marquesas",
    metadata: "",
    offset: "-0930",
  },
  {
    value: "America/Adak",
    label: "(UTC-09:00) America/Adak",
    metadata: "",
    offset: "-0900",
  },
  {
    value: "Pacific/Gambier",
    label: "(UTC-09:00) Pacific/Gambier",
    metadata: "",
    offset: "-0900",
  },
  {
    value: "America/Anchorage",
    label: "(UTC-08:00) America/Anchorage",
    metadata: "",
    offset: "-0800",
  },
  {
    value: "America/Juneau",
    label: "(UTC-08:00) America/Juneau",
    metadata: "",
    offset: "-0800",
  },
  {
    value: "America/Metlakatla",
    label: "(UTC-08:00) America/Metlakatla",
    metadata: "",
    offset: "-0800",
  },
  {
    value: "America/Nome",
    label: "(UTC-08:00) America/Nome",
    metadata: "",
    offset: "-0800",
  },
  {
    value: "America/Sitka",
    label: "(UTC-08:00) America/Sitka",
    metadata: "",
    offset: "-0800",
  },
  {
    value: "America/Yakutat",
    label: "(UTC-08:00) America/Yakutat",
    metadata: "",
    offset: "-0800",
  },
  {
    value: "Pacific/Pitcairn",
    label: "(UTC-08:00) Pacific/Pitcairn",
    metadata: "",
    offset: "-0800",
  },
  {
    value: "US/Alaska",
    label: "(UTC-08:00) US/Alaska",
    metadata: "",
    offset: "-0800",
  },
  {
    value: "America/Creston",
    label: "(UTC-07:00) America/Creston",
    metadata: "",
    offset: "-0700",
  },
  {
    value: "America/Dawson",
    label: "(UTC-07:00) America/Dawson",
    metadata: "",
    offset: "-0700",
  },
  {
    value: "America/Dawson_Creek",
    label: "(UTC-07:00) America/Dawson_Creek",
    metadata: "",
    offset: "-0700",
  },
  {
    value: "America/Fort_Nelson",
    label: "(UTC-07:00) America/Fort_Nelson",
    metadata: "",
    offset: "-0700",
  },
  {
    value: "America/Hermosillo",
    label: "(UTC-07:00) America/Hermosillo",
    metadata: "",
    offset: "-0700",
  },
  {
    value: "America/Los_Angeles",
    label: "(UTC-07:00) America/Los_Angeles",
    metadata: "",
    offset: "-0700",
  },
  {
    value: "America/Phoenix",
    label: "(UTC-07:00) America/Phoenix",
    metadata: "",
    offset: "-0700",
  },
  {
    value: "America/Tijuana",
    label: "(UTC-07:00) America/Tijuana",
    metadata: "",
    offset: "-0700",
  },
  {
    value: "America/Vancouver",
    label: "(UTC-07:00) America/Vancouver",
    metadata: "",
    offset: "-0700",
  },
  {
    value: "America/Whitehorse",
    label: "(UTC-07:00) America/Whitehorse",
    metadata: "",
    offset: "-0700",
  },
  {
    value: "Canada/Pacific",
    label: "(UTC-07:00) Canada/Pacific",
    metadata: "",
    offset: "-0700",
  },
  {
    value: "US/Arizona",
    label: "(UTC-07:00) US/Arizona",
    metadata: "",
    offset: "-0700",
  },
  {
    value: "US/Pacific",
    label: "(UTC-07:00) US/Pacific",
    metadata: "",
    offset: "-0700",
  },
  {
    value: "America/Belize",
    label: "(UTC-06:00) America/Belize",
    metadata: "",
    offset: "-0600",
  },
  {
    value: "America/Boise",
    label: "(UTC-06:00) America/Boise",
    metadata: "",
    offset: "-0600",
  },
  {
    value: "America/Cambridge_Bay",
    label: "(UTC-06:00) America/Cambridge_Bay",
    metadata: "",
    offset: "-0600",
  },
  {
    value: "America/Chihuahua",
    label: "(UTC-06:00) America/Chihuahua",
    metadata: "",
    offset: "-0600",
  },
  {
    value: "America/Costa_Rica",
    label: "(UTC-06:00) America/Costa_Rica",
    metadata: "",
    offset: "-0600",
  },
  {
    value: "America/Denver",
    label: "(UTC-06:00) America/Denver",
    metadata: "",
    offset: "-0600",
  },
  {
    value: "America/Edmonton",
    label: "(UTC-06:00) America/Edmonton",
    metadata: "",
    offset: "-0600",
  },
  {
    value: "America/El_Salvador",
    label: "(UTC-06:00) America/El_Salvador",
    metadata: "",
    offset: "-0600",
  },
  {
    value: "America/Guatemala",
    label: "(UTC-06:00) America/Guatemala",
    metadata: "",
    offset: "-0600",
  },
  {
    value: "America/Inuvik",
    label: "(UTC-06:00) America/Inuvik",
    metadata: "",
    offset: "-0600",
  },
  {
    value: "America/Managua",
    label: "(UTC-06:00) America/Managua",
    metadata: "",
    offset: "-0600",
  },
  {
    value: "America/Mazatlan",
    label: "(UTC-06:00) America/Mazatlan",
    metadata: "",
    offset: "-0600",
  },
  {
    value: "America/Ojinaga",
    label: "(UTC-06:00) America/Ojinaga",
    metadata: "",
    offset: "-0600",
  },
  {
    value: "America/Regina",
    label: "(UTC-06:00) America/Regina",
    metadata: "",
    offset: "-0600",
  },
  {
    value: "America/Swift_Current",
    label: "(UTC-06:00) America/Swift_Current",
    metadata: "",
    offset: "-0600",
  },
  {
    value: "America/Tegucigalpa",
    label: "(UTC-06:00) America/Tegucigalpa",
    metadata: "",
    offset: "-0600",
  },
  {
    value: "America/Yellowknife",
    label: "(UTC-06:00) America/Yellowknife",
    metadata: "",
    offset: "-0600",
  },
  {
    value: "Canada/Mountain",
    label: "(UTC-06:00) Canada/Mountain",
    metadata: "",
    offset: "-0600",
  },
  {
    value: "Pacific/Easter",
    label: "(UTC-06:00) Pacific/Easter",
    metadata: "",
    offset: "-0600",
  },
  {
    value: "Pacific/Galapagos",
    label: "(UTC-06:00) Pacific/Galapagos",
    metadata: "",
    offset: "-0600",
  },
  {
    value: "US/Mountain",
    label: "(UTC-06:00) US/Mountain",
    metadata: "",
    offset: "-0600",
  },
  {
    value: "America/Atikokan",
    label: "(UTC-05:00) America/Atikokan",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/Bahia_Banderas",
    label: "(UTC-05:00) America/Bahia_Banderas",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/Bogota",
    label: "(UTC-05:00) America/Bogota",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/Cancun",
    label: "(UTC-05:00) America/Cancun",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/Cayman",
    label: "(UTC-05:00) America/Cayman",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/Chicago",
    label: "(UTC-05:00) America/Chicago",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/Eirunepe",
    label: "(UTC-05:00) America/Eirunepe",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/Guayaquil",
    label: "(UTC-05:00) America/Guayaquil",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/Indiana/Knox",
    label: "(UTC-05:00) America/Indiana/Knox",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/Indiana/Tell_City",
    label: "(UTC-05:00) America/Indiana/Tell_City",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/Jamaica",
    label: "(UTC-05:00) America/Jamaica",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/Lima",
    label: "(UTC-05:00) America/Lima",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/Matamoros",
    label: "(UTC-05:00) America/Matamoros",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/Menominee",
    label: "(UTC-05:00) America/Menominee",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/Merida",
    label: "(UTC-05:00) America/Merida",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/Mexico_City",
    label: "(UTC-05:00) America/Mexico_City",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/Monterrey",
    label: "(UTC-05:00) America/Monterrey",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/North_Dakota/Beulah",
    label: "(UTC-05:00) America/North_Dakota/Beulah",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/North_Dakota/Center",
    label: "(UTC-05:00) America/North_Dakota/Center",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/North_Dakota/New_Salem",
    label: "(UTC-05:00) America/North_Dakota/New_Salem",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/Panama",
    label: "(UTC-05:00) America/Panama",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/Rainy_River",
    label: "(UTC-05:00) America/Rainy_River",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/Rankin_Inlet",
    label: "(UTC-05:00) America/Rankin_Inlet",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/Resolute",
    label: "(UTC-05:00) America/Resolute",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/Rio_Branco",
    label: "(UTC-05:00) America/Rio_Branco",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/Winnipeg",
    label: "(UTC-05:00) America/Winnipeg",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "Canada/Central",
    label: "(UTC-05:00) Canada/Central",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "US/Central",
    label: "(UTC-05:00) US/Central",
    metadata: "",
    offset: "-0500",
  },
  {
    value: "America/Anguilla",
    label: "(UTC-04:00) America/Anguilla",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Antigua",
    label: "(UTC-04:00) America/Antigua",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Aruba",
    label: "(UTC-04:00) America/Aruba",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Asuncion",
    label: "(UTC-04:00) America/Asuncion",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Barbados",
    label: "(UTC-04:00) America/Barbados",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Blanc-Sablon",
    label: "(UTC-04:00) America/Blanc-Sablon",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Boa_Vista",
    label: "(UTC-04:00) America/Boa_Vista",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Campo_Grande",
    label: "(UTC-04:00) America/Campo_Grande",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Caracas",
    label: "(UTC-04:00) America/Caracas",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Cuiaba",
    label: "(UTC-04:00) America/Cuiaba",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Curacao",
    label: "(UTC-04:00) America/Curacao",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Detroit",
    label: "(UTC-04:00) America/Detroit",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Dominica",
    label: "(UTC-04:00) America/Dominica",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Grand_Turk",
    label: "(UTC-04:00) America/Grand_Turk",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Grenada",
    label: "(UTC-04:00) America/Grenada",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Guadeloupe",
    label: "(UTC-04:00) America/Guadeloupe",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Guyana",
    label: "(UTC-04:00) America/Guyana",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Havana",
    label: "(UTC-04:00) America/Havana",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Indiana/Indianapolis",
    label: "(UTC-04:00) America/Indiana/Indianapolis",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Indiana/Marengo",
    label: "(UTC-04:00) America/Indiana/Marengo",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Indiana/Petersburg",
    label: "(UTC-04:00) America/Indiana/Petersburg",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Indiana/Vevay",
    label: "(UTC-04:00) America/Indiana/Vevay",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Indiana/Vincennes",
    label: "(UTC-04:00) America/Indiana/Vincennes",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Indiana/Winamac",
    label: "(UTC-04:00) America/Indiana/Winamac",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Iqaluit",
    label: "(UTC-04:00) America/Iqaluit",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Kentucky/Louisville",
    label: "(UTC-04:00) America/Kentucky/Louisville",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Kentucky/Monticello",
    label: "(UTC-04:00) America/Kentucky/Monticello",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Kralendijk",
    label: "(UTC-04:00) America/Kralendijk",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/La_Paz",
    label: "(UTC-04:00) America/La_Paz",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Lower_Princes",
    label: "(UTC-04:00) America/Lower_Princes",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Manaus",
    label: "(UTC-04:00) America/Manaus",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Marigot",
    label: "(UTC-04:00) America/Marigot",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Martinique",
    label: "(UTC-04:00) America/Martinique",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Montserrat",
    label: "(UTC-04:00) America/Montserrat",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Nassau",
    label: "(UTC-04:00) America/Nassau",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/New_York",
    label: "(UTC-04:00) America/New_York",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Nipigon",
    label: "(UTC-04:00) America/Nipigon",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Pangnirtung",
    label: "(UTC-04:00) America/Pangnirtung",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Port-au-Prince",
    label: "(UTC-04:00) America/Port-au-Prince",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Port_of_Spain",
    label: "(UTC-04:00) America/Port_of_Spain",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Porto_Velho",
    label: "(UTC-04:00) America/Porto_Velho",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Puerto_Rico",
    label: "(UTC-04:00) America/Puerto_Rico",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Santiago",
    label: "(UTC-04:00) America/Santiago",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Santo_Domingo",
    label: "(UTC-04:00) America/Santo_Domingo",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/St_Barthelemy",
    label: "(UTC-04:00) America/St_Barthelemy",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/St_Kitts",
    label: "(UTC-04:00) America/St_Kitts",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/St_Lucia",
    label: "(UTC-04:00) America/St_Lucia",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/St_Thomas",
    label: "(UTC-04:00) America/St_Thomas",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/St_Vincent",
    label: "(UTC-04:00) America/St_Vincent",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Thunder_Bay",
    label: "(UTC-04:00) America/Thunder_Bay",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Toronto",
    label: "(UTC-04:00) America/Toronto",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Tortola",
    label: "(UTC-04:00) America/Tortola",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "Canada/Eastern",
    label: "(UTC-04:00) Canada/Eastern",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "US/Eastern",
    label: "(UTC-04:00) US/Eastern",
    metadata: "",
    offset: "-0400",
  },
  {
    value: "America/Araguaina",
    label: "(UTC-03:00) America/Araguaina",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Argentina/Buenos_Aires",
    label: "(UTC-03:00) America/Argentina/Buenos_Aires",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Argentina/Catamarca",
    label: "(UTC-03:00) America/Argentina/Catamarca",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Argentina/Cordoba",
    label: "(UTC-03:00) America/Argentina/Cordoba",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Argentina/Jujuy",
    label: "(UTC-03:00) America/Argentina/Jujuy",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Argentina/La_Rioja",
    label: "(UTC-03:00) America/Argentina/La_Rioja",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Argentina/Mendoza",
    label: "(UTC-03:00) America/Argentina/Mendoza",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Argentina/Rio_Gallegos",
    label: "(UTC-03:00) America/Argentina/Rio_Gallegos",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Argentina/Salta",
    label: "(UTC-03:00) America/Argentina/Salta",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Argentina/San_Juan",
    label: "(UTC-03:00) America/Argentina/San_Juan",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Argentina/San_Luis",
    label: "(UTC-03:00) America/Argentina/San_Luis",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Argentina/Tucuman",
    label: "(UTC-03:00) America/Argentina/Tucuman",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Argentina/Ushuaia",
    label: "(UTC-03:00) America/Argentina/Ushuaia",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Bahia",
    label: "(UTC-03:00) America/Bahia",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Belem",
    label: "(UTC-03:00) America/Belem",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Cayenne",
    label: "(UTC-03:00) America/Cayenne",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Fortaleza",
    label: "(UTC-03:00) America/Fortaleza",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Glace_Bay",
    label: "(UTC-03:00) America/Glace_Bay",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Goose_Bay",
    label: "(UTC-03:00) America/Goose_Bay",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Halifax",
    label: "(UTC-03:00) America/Halifax",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Maceio",
    label: "(UTC-03:00) America/Maceio",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Moncton",
    label: "(UTC-03:00) America/Moncton",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Montevideo",
    label: "(UTC-03:00) America/Montevideo",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Paramaribo",
    label: "(UTC-03:00) America/Paramaribo",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Punta_Arenas",
    label: "(UTC-03:00) America/Punta_Arenas",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Recife",
    label: "(UTC-03:00) America/Recife",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Santarem",
    label: "(UTC-03:00) America/Santarem",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Sao_Paulo",
    label: "(UTC-03:00) America/Sao_Paulo",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/Thule",
    label: "(UTC-03:00) America/Thule",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "Antarctica/Palmer",
    label: "(UTC-03:00) Antarctica/Palmer",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "Antarctica/Rothera",
    label: "(UTC-03:00) Antarctica/Rothera",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "Atlantic/Bermuda",
    label: "(UTC-03:00) Atlantic/Bermuda",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "Atlantic/Stanley",
    label: "(UTC-03:00) Atlantic/Stanley",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "Canada/Atlantic",
    label: "(UTC-03:00) Canada/Atlantic",
    metadata: "",
    offset: "-0300",
  },
  {
    value: "America/St_Johns",
    label: "(UTC-02:30) America/St_Johns",
    metadata: "",
    offset: "-0230",
  },
  {
    value: "Canada/Newfoundland",
    label: "(UTC-02:30) Canada/Newfoundland",
    metadata: "",
    offset: "-0230",
  },
  {
    value: "America/Godthab",
    label: "(UTC-02:00) America/Godthab",
    metadata: "",
    offset: "-0200",
  },
  {
    value: "America/Miquelon",
    label: "(UTC-02:00) America/Miquelon",
    metadata: "",
    offset: "-0200",
  },
  {
    value: "America/Noronha",
    label: "(UTC-02:00) America/Noronha",
    metadata: "",
    offset: "-0200",
  },
  {
    value: "Atlantic/South_Georgia",
    label: "(UTC-02:00) Atlantic/South_Georgia",
    metadata: "",
    offset: "-0200",
  },
  {
    value: "Atlantic/Cape_Verde",
    label: "(UTC-01:00) Atlantic/Cape_Verde",
    metadata: "",
    offset: "-0100",
  },
  {
    value: "Africa/Abidjan",
    label: "(UTC+00:00) Africa/Abidjan",
    metadata: "",
    offset: "+0000",
  },
  {
    value: "Africa/Accra",
    label: "(UTC+00:00) Africa/Accra",
    metadata: "",
    offset: "+0000",
  },
  {
    value: "Africa/Bamako",
    label: "(UTC+00:00) Africa/Bamako",
    metadata: "",
    offset: "+0000",
  },
  {
    value: "Africa/Banjul",
    label: "(UTC+00:00) Africa/Banjul",
    metadata: "",
    offset: "+0000",
  },
  {
    value: "Africa/Bissau",
    label: "(UTC+00:00) Africa/Bissau",
    metadata: "",
    offset: "+0000",
  },
  {
    value: "Africa/Conakry",
    label: "(UTC+00:00) Africa/Conakry",
    metadata: "",
    offset: "+0000",
  },
  {
    value: "Africa/Dakar",
    label: "(UTC+00:00) Africa/Dakar",
    metadata: "",
    offset: "+0000",
  },
  {
    value: "Africa/Freetown",
    label: "(UTC+00:00) Africa/Freetown",
    metadata: "",
    offset: "+0000",
  },
  {
    value: "Africa/Lome",
    label: "(UTC+00:00) Africa/Lome",
    metadata: "",
    offset: "+0000",
  },
  {
    value: "Africa/Monrovia",
    label: "(UTC+00:00) Africa/Monrovia",
    metadata: "",
    offset: "+0000",
  },
  {
    value: "Africa/Nouakchott",
    label: "(UTC+00:00) Africa/Nouakchott",
    metadata: "",
    offset: "+0000",
  },
  {
    value: "Africa/Ouagadougou",
    label: "(UTC+00:00) Africa/Ouagadougou",
    metadata: "",
    offset: "+0000",
  },
  {
    value: "America/Danmarkshavn",
    label: "(UTC+00:00) America/Danmarkshavn",
    metadata: "",
    offset: "+0000",
  },
  {
    value: "America/Scoresbysund",
    label: "(UTC+00:00) America/Scoresbysund",
    metadata: "",
    offset: "+0000",
  },
  {
    value: "Atlantic/Azores",
    label: "(UTC+00:00) Atlantic/Azores",
    metadata: "",
    offset: "+0000",
  },
  {
    value: "Atlantic/Reykjavik",
    label: "(UTC+00:00) Atlantic/Reykjavik",
    metadata: "",
    offset: "+0000",
  },
  {
    value: "Atlantic/St_Helena",
    label: "(UTC+00:00) Atlantic/St_Helena",
    metadata: "",
    offset: "+0000",
  },
  {
    value: "GMT",
    label: "(UTC+00:00) GMT",
    metadata: "",
    offset: "+0000",
  },
  {
    value: "UTC",
    label: "(UTC+00:00) UTC",
    metadata: "",
    offset: "+0000",
  },
  {
    value: "Africa/Algiers",
    label: "(UTC+01:00) Africa/Algiers",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Africa/Bangui",
    label: "(UTC+01:00) Africa/Bangui",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Africa/Brazzaville",
    label: "(UTC+01:00) Africa/Brazzaville",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Africa/Casablanca",
    label: "(UTC+01:00) Africa/Casablanca",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Africa/Douala",
    label: "(UTC+01:00) Africa/Douala",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Africa/El_Aaiun",
    label: "(UTC+01:00) Africa/El_Aaiun",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Africa/Kinshasa",
    label: "(UTC+01:00) Africa/Kinshasa",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Africa/Lagos",
    label: "(UTC+01:00) Africa/Lagos",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Africa/Libreville",
    label: "(UTC+01:00) Africa/Libreville",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Africa/Luanda",
    label: "(UTC+01:00) Africa/Luanda",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Africa/Malabo",
    label: "(UTC+01:00) Africa/Malabo",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Africa/Ndjamena",
    label: "(UTC+01:00) Africa/Ndjamena",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Africa/Niamey",
    label: "(UTC+01:00) Africa/Niamey",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Africa/Porto-Novo",
    label: "(UTC+01:00) Africa/Porto-Novo",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Africa/Sao_Tome",
    label: "(UTC+01:00) Africa/Sao_Tome",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Africa/Tunis",
    label: "(UTC+01:00) Africa/Tunis",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Atlantic/Canary",
    label: "(UTC+01:00) Atlantic/Canary",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Atlantic/Faroe",
    label: "(UTC+01:00) Atlantic/Faroe",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Atlantic/Madeira",
    label: "(UTC+01:00) Atlantic/Madeira",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Europe/Dublin",
    label: "(UTC+01:00) Europe/Dublin",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Europe/Guernsey",
    label: "(UTC+01:00) Europe/Guernsey",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Europe/Isle_of_Man",
    label: "(UTC+01:00) Europe/Isle_of_Man",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Europe/Jersey",
    label: "(UTC+01:00) Europe/Jersey",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Europe/Lisbon",
    label: "(UTC+01:00) Europe/Lisbon",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Europe/London",
    label: "(UTC+01:00) Europe/London",
    metadata: "",
    offset: "+0100",
  },
  {
    value: "Africa/Blantyre",
    label: "(UTC+02:00) Africa/Blantyre",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Africa/Bujumbura",
    label: "(UTC+02:00) Africa/Bujumbura",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Africa/Cairo",
    label: "(UTC+02:00) Africa/Cairo",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Africa/Ceuta",
    label: "(UTC+02:00) Africa/Ceuta",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Africa/Gaborone",
    label: "(UTC+02:00) Africa/Gaborone",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Africa/Harare",
    label: "(UTC+02:00) Africa/Harare",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Africa/Johannesburg",
    label: "(UTC+02:00) Africa/Johannesburg",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Africa/Khartoum",
    label: "(UTC+02:00) Africa/Khartoum",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Africa/Kigali",
    label: "(UTC+02:00) Africa/Kigali",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Africa/Lubumbashi",
    label: "(UTC+02:00) Africa/Lubumbashi",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Africa/Lusaka",
    label: "(UTC+02:00) Africa/Lusaka",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Africa/Maputo",
    label: "(UTC+02:00) Africa/Maputo",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Africa/Maseru",
    label: "(UTC+02:00) Africa/Maseru",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Africa/Mbabane",
    label: "(UTC+02:00) Africa/Mbabane",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Africa/Tripoli",
    label: "(UTC+02:00) Africa/Tripoli",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Africa/Windhoek",
    label: "(UTC+02:00) Africa/Windhoek",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Antarctica/Troll",
    label: "(UTC+02:00) Antarctica/Troll",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Arctic/Longyearbyen",
    label: "(UTC+02:00) Arctic/Longyearbyen",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Amsterdam",
    label: "(UTC+02:00) Europe/Amsterdam",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Andorra",
    label: "(UTC+02:00) Europe/Andorra",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Belgrade",
    label: "(UTC+02:00) Europe/Belgrade",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Berlin",
    label: "(UTC+02:00) Europe/Berlin",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Bratislava",
    label: "(UTC+02:00) Europe/Bratislava",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Brussels",
    label: "(UTC+02:00) Europe/Brussels",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Budapest",
    label: "(UTC+02:00) Europe/Budapest",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Busingen",
    label: "(UTC+02:00) Europe/Busingen",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Copenhagen",
    label: "(UTC+02:00) Europe/Copenhagen",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Gibraltar",
    label: "(UTC+02:00) Europe/Gibraltar",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Kaliningrad",
    label: "(UTC+02:00) Europe/Kaliningrad",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Ljubljana",
    label: "(UTC+02:00) Europe/Ljubljana",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Luxembourg",
    label: "(UTC+02:00) Europe/Luxembourg",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Madrid",
    label: "(UTC+02:00) Europe/Madrid",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Malta",
    label: "(UTC+02:00) Europe/Malta",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Monaco",
    label: "(UTC+02:00) Europe/Monaco",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Oslo",
    label: "(UTC+02:00) Europe/Oslo",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Paris",
    label: "(UTC+02:00) Europe/Paris",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Podgorica",
    label: "(UTC+02:00) Europe/Podgorica",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Prague",
    label: "(UTC+02:00) Europe/Prague",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Rome",
    label: "(UTC+02:00) Europe/Rome",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/San_Marino",
    label: "(UTC+02:00) Europe/San_Marino",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Sarajevo",
    label: "(UTC+02:00) Europe/Sarajevo",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Skopje",
    label: "(UTC+02:00) Europe/Skopje",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Stockholm",
    label: "(UTC+02:00) Europe/Stockholm",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Tirane",
    label: "(UTC+02:00) Europe/Tirane",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Vaduz",
    label: "(UTC+02:00) Europe/Vaduz",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Vatican",
    label: "(UTC+02:00) Europe/Vatican",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Vienna",
    label: "(UTC+02:00) Europe/Vienna",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Warsaw",
    label: "(UTC+02:00) Europe/Warsaw",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Zagreb",
    label: "(UTC+02:00) Europe/Zagreb",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Europe/Zurich",
    label: "(UTC+02:00) Europe/Zurich",
    metadata: "",
    offset: "+0200",
  },
  {
    value: "Africa/Addis_Ababa",
    label: "(UTC+03:00) Africa/Addis_Ababa",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Africa/Asmara",
    label: "(UTC+03:00) Africa/Asmara",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Africa/Dar_es_Salaam",
    label: "(UTC+03:00) Africa/Dar_es_Salaam",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Africa/Djibouti",
    label: "(UTC+03:00) Africa/Djibouti",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Africa/Juba",
    label: "(UTC+03:00) Africa/Juba",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Africa/Kampala",
    label: "(UTC+03:00) Africa/Kampala",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Africa/Mogadishu",
    label: "(UTC+03:00) Africa/Mogadishu",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Africa/Nairobi",
    label: "(UTC+03:00) Africa/Nairobi",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Antarctica/Syowa",
    label: "(UTC+03:00) Antarctica/Syowa",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Asia/Aden",
    label: "(UTC+03:00) Asia/Aden",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Asia/Amman",
    label: "(UTC+03:00) Asia/Amman",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Asia/Baghdad",
    label: "(UTC+03:00) Asia/Baghdad",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Asia/Bahrain",
    label: "(UTC+03:00) Asia/Bahrain",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Asia/Beirut",
    label: "(UTC+03:00) Asia/Beirut",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Asia/Damascus",
    label: "(UTC+03:00) Asia/Damascus",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Asia/Famagusta",
    label: "(UTC+03:00) Asia/Famagusta",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Asia/Gaza",
    label: "(UTC+03:00) Asia/Gaza",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Asia/Hebron",
    label: "(UTC+03:00) Asia/Hebron",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Asia/Jerusalem",
    label: "(UTC+03:00) Asia/Jerusalem",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Asia/Kuwait",
    label: "(UTC+03:00) Asia/Kuwait",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Asia/Nicosia",
    label: "(UTC+03:00) Asia/Nicosia",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Asia/Qatar",
    label: "(UTC+03:00) Asia/Qatar",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Asia/Riyadh",
    label: "(UTC+03:00) Asia/Riyadh",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Europe/Athens",
    label: "(UTC+03:00) Europe/Athens",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Europe/Bucharest",
    label: "(UTC+03:00) Europe/Bucharest",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Europe/Chisinau",
    label: "(UTC+03:00) Europe/Chisinau",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Europe/Helsinki",
    label: "(UTC+03:00) Europe/Helsinki",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Europe/Istanbul",
    label: "(UTC+03:00) Europe/Istanbul",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Europe/Kiev",
    label: "(UTC+03:00) Europe/Kiev",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Europe/Kirov",
    label: "(UTC+03:00) Europe/Kirov",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Europe/Mariehamn",
    label: "(UTC+03:00) Europe/Mariehamn",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Europe/Minsk",
    label: "(UTC+03:00) Europe/Minsk",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Europe/Moscow",
    label: "(UTC+03:00) Europe/Moscow",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Europe/Riga",
    label: "(UTC+03:00) Europe/Riga",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Europe/Simferopol",
    label: "(UTC+03:00) Europe/Simferopol",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Europe/Sofia",
    label: "(UTC+03:00) Europe/Sofia",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Europe/Tallinn",
    label: "(UTC+03:00) Europe/Tallinn",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Europe/Uzhgorod",
    label: "(UTC+03:00) Europe/Uzhgorod",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Europe/Vilnius",
    label: "(UTC+03:00) Europe/Vilnius",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Europe/Zaporozhye",
    label: "(UTC+03:00) Europe/Zaporozhye",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Indian/Antananarivo",
    label: "(UTC+03:00) Indian/Antananarivo",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Indian/Comoro",
    label: "(UTC+03:00) Indian/Comoro",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Indian/Mayotte",
    label: "(UTC+03:00) Indian/Mayotte",
    metadata: "",
    offset: "+0300",
  },
  {
    value: "Asia/Baku",
    label: "(UTC+04:00) Asia/Baku",
    metadata: "",
    offset: "+0400",
  },
  {
    value: "Asia/Dubai",
    label: "(UTC+04:00) Asia/Dubai",
    metadata: "",
    offset: "+0400",
  },
  {
    value: "Asia/Muscat",
    label: "(UTC+04:00) Asia/Muscat",
    metadata: "",
    offset: "+0400",
  },
  {
    value: "Asia/Tbilisi",
    label: "(UTC+04:00) Asia/Tbilisi",
    metadata: "",
    offset: "+0400",
  },
  {
    value: "Asia/Yerevan",
    label: "(UTC+04:00) Asia/Yerevan",
    metadata: "",
    offset: "+0400",
  },
  {
    value: "Europe/Astrakhan",
    label: "(UTC+04:00) Europe/Astrakhan",
    metadata: "",
    offset: "+0400",
  },
  {
    value: "Europe/Samara",
    label: "(UTC+04:00) Europe/Samara",
    metadata: "",
    offset: "+0400",
  },
  {
    value: "Europe/Saratov",
    label: "(UTC+04:00) Europe/Saratov",
    metadata: "",
    offset: "+0400",
  },
  {
    value: "Europe/Ulyanovsk",
    label: "(UTC+04:00) Europe/Ulyanovsk",
    metadata: "",
    offset: "+0400",
  },
  {
    value: "Europe/Volgograd",
    label: "(UTC+04:00) Europe/Volgograd",
    metadata: "",
    offset: "+0400",
  },
  {
    value: "Indian/Mahe",
    label: "(UTC+04:00) Indian/Mahe",
    metadata: "",
    offset: "+0400",
  },
  {
    value: "Indian/Mauritius",
    label: "(UTC+04:00) Indian/Mauritius",
    metadata: "",
    offset: "+0400",
  },
  {
    value: "Indian/Reunion",
    label: "(UTC+04:00) Indian/Reunion",
    metadata: "",
    offset: "+0400",
  },
  {
    value: "Asia/Kabul",
    label: "(UTC+04:30) Asia/Kabul",
    metadata: "",
    offset: "+0430",
  },
  {
    value: "Asia/Tehran",
    label: "(UTC+04:30) Asia/Tehran",
    metadata: "",
    offset: "+0430",
  },
  {
    value: "Antarctica/Mawson",
    label: "(UTC+05:00) Antarctica/Mawson",
    metadata: "",
    offset: "+0500",
  },
  {
    value: "Asia/Aqtau",
    label: "(UTC+05:00) Asia/Aqtau",
    metadata: "",
    offset: "+0500",
  },
  {
    value: "Asia/Aqtobe",
    label: "(UTC+05:00) Asia/Aqtobe",
    metadata: "",
    offset: "+0500",
  },
  {
    value: "Asia/Ashgabat",
    label: "(UTC+05:00) Asia/Ashgabat",
    metadata: "",
    offset: "+0500",
  },
  {
    value: "Asia/Atyrau",
    label: "(UTC+05:00) Asia/Atyrau",
    metadata: "",
    offset: "+0500",
  },
  {
    value: "Asia/Dushanbe",
    label: "(UTC+05:00) Asia/Dushanbe",
    metadata: "",
    offset: "+0500",
  },
  {
    value: "Asia/Karachi",
    label: "(UTC+05:00) Asia/Karachi",
    metadata: "",
    offset: "+0500",
  },
  {
    value: "Asia/Oral",
    label: "(UTC+05:00) Asia/Oral",
    metadata: "",
    offset: "+0500",
  },
  {
    value: "Asia/Samarkand",
    label: "(UTC+05:00) Asia/Samarkand",
    metadata: "",
    offset: "+0500",
  },
  {
    value: "Asia/Tashkent",
    label: "(UTC+05:00) Asia/Tashkent",
    metadata: "",
    offset: "+0500",
  },
  {
    value: "Asia/Yekaterinburg",
    label: "(UTC+05:00) Asia/Yekaterinburg",
    metadata: "",
    offset: "+0500",
  },
  {
    value: "Indian/Kerguelen",
    label: "(UTC+05:00) Indian/Kerguelen",
    metadata: "",
    offset: "+0500",
  },
  {
    value: "Indian/Maldives",
    label: "(UTC+05:00) Indian/Maldives",
    metadata: "",
    offset: "+0500",
  },
  {
    value: "Asia/Colombo",
    label: "(UTC+05:30) Asia/Colombo",
    metadata: "",
    offset: "+0530",
  },
  {
    value: "Asia/Kolkata",
    label: "(UTC+05:30) Asia/Kolkata",
    metadata: "",
    offset: "+0530",
  },
  {
    value: "Asia/Kathmandu",
    label: "(UTC+05:45) Asia/Kathmandu",
    metadata: "",
    offset: "+0545",
  },
  {
    value: "Antarctica/Vostok",
    label: "(UTC+06:00) Antarctica/Vostok",
    metadata: "",
    offset: "+0600",
  },
  {
    value: "Asia/Almaty",
    label: "(UTC+06:00) Asia/Almaty",
    metadata: "",
    offset: "+0600",
  },
  {
    value: "Asia/Bishkek",
    label: "(UTC+06:00) Asia/Bishkek",
    metadata: "",
    offset: "+0600",
  },
  {
    value: "Asia/Dhaka",
    label: "(UTC+06:00) Asia/Dhaka",
    metadata: "",
    offset: "+0600",
  },
  {
    value: "Asia/Omsk",
    label: "(UTC+06:00) Asia/Omsk",
    metadata: "",
    offset: "+0600",
  },
  {
    value: "Asia/Qyzylorda",
    label: "(UTC+06:00) Asia/Qyzylorda",
    metadata: "",
    offset: "+0600",
  },
  {
    value: "Asia/Thimphu",
    label: "(UTC+06:00) Asia/Thimphu",
    metadata: "",
    offset: "+0600",
  },
  {
    value: "Asia/Urumqi",
    label: "(UTC+06:00) Asia/Urumqi",
    metadata: "",
    offset: "+0600",
  },
  {
    value: "Indian/Chagos",
    label: "(UTC+06:00) Indian/Chagos",
    metadata: "",
    offset: "+0600",
  },
  {
    value: "Asia/Yangon",
    label: "(UTC+06:30) Asia/Yangon",
    metadata: "",
    offset: "+0630",
  },
  {
    value: "Indian/Cocos",
    label: "(UTC+06:30) Indian/Cocos",
    metadata: "",
    offset: "+0630",
  },
  {
    value: "Antarctica/Davis",
    label: "(UTC+07:00) Antarctica/Davis",
    metadata: "",
    offset: "+0700",
  },
  {
    value: "Asia/Bangkok",
    label: "(UTC+07:00) Asia/Bangkok",
    metadata: "",
    offset: "+0700",
  },
  {
    value: "Asia/Barnaul",
    label: "(UTC+07:00) Asia/Barnaul",
    metadata: "",
    offset: "+0700",
  },
  {
    value: "Asia/Ho_Chi_Minh",
    label: "(UTC+07:00) Asia/Ho_Chi_Minh",
    metadata: "",
    offset: "+0700",
  },
  {
    value: "Asia/Hovd",
    label: "(UTC+07:00) Asia/Hovd",
    metadata: "",
    offset: "+0700",
  },
  {
    value: "Asia/Jakarta",
    label: "(UTC+07:00) Asia/Jakarta",
    metadata: "",
    offset: "+0700",
  },
  {
    value: "Asia/Krasnoyarsk",
    label: "(UTC+07:00) Asia/Krasnoyarsk",
    metadata: "",
    offset: "+0700",
  },
  {
    value: "Asia/Novokuznetsk",
    label: "(UTC+07:00) Asia/Novokuznetsk",
    metadata: "",
    offset: "+0700",
  },
  {
    value: "Asia/Novosibirsk",
    label: "(UTC+07:00) Asia/Novosibirsk",
    metadata: "",
    offset: "+0700",
  },
  {
    value: "Asia/Phnom_Penh",
    label: "(UTC+07:00) Asia/Phnom_Penh",
    metadata: "",
    offset: "+0700",
  },
  {
    value: "Asia/Pontianak",
    label: "(UTC+07:00) Asia/Pontianak",
    metadata: "",
    offset: "+0700",
  },
  {
    value: "Asia/Tomsk",
    label: "(UTC+07:00) Asia/Tomsk",
    metadata: "",
    offset: "+0700",
  },
  {
    value: "Asia/Vientiane",
    label: "(UTC+07:00) Asia/Vientiane",
    metadata: "",
    offset: "+0700",
  },
  {
    value: "Indian/Christmas",
    label: "(UTC+07:00) Indian/Christmas",
    metadata: "",
    offset: "+0700",
  },
  {
    value: "Antarctica/Casey",
    label: "(UTC+08:00) Antarctica/Casey",
    metadata: "",
    offset: "+0800",
  },
  {
    value: "Asia/Brunei",
    label: "(UTC+08:00) Asia/Brunei",
    metadata: "",
    offset: "+0800",
  },
  {
    value: "Asia/Choibalsan",
    label: "(UTC+08:00) Asia/Choibalsan",
    metadata: "",
    offset: "+0800",
  },
  {
    value: "Asia/Hong_Kong",
    label: "(UTC+08:00) Asia/Hong_Kong",
    metadata: "",
    offset: "+0800",
  },
  {
    value: "Asia/Irkutsk",
    label: "(UTC+08:00) Asia/Irkutsk",
    metadata: "",
    offset: "+0800",
  },
  {
    value: "Asia/Kuala_Lumpur",
    label: "(UTC+08:00) Asia/Kuala_Lumpur",
    metadata: "",
    offset: "+0800",
  },
  {
    value: "Asia/Kuching",
    label: "(UTC+08:00) Asia/Kuching",
    metadata: "",
    offset: "+0800",
  },
  {
    value: "Asia/Macau",
    label: "(UTC+08:00) Asia/Macau",
    metadata: "",
    offset: "+0800",
  },
  {
    value: "Asia/Makassar",
    label: "(UTC+08:00) Asia/Makassar",
    metadata: "",
    offset: "+0800",
  },
  {
    value: "Asia/Manila",
    label: "(UTC+08:00) Asia/Manila",
    metadata: "",
    offset: "+0800",
  },
  {
    value: "Asia/Shanghai",
    label: "(UTC+08:00) Asia/Shanghai",
    metadata: "",
    offset: "+0800",
  },
  {
    value: "Asia/Singapore",
    label: "(UTC+08:00) Asia/Singapore",
    metadata: "",
    offset: "+0800",
  },
  {
    value: "Asia/Taipei",
    label: "(UTC+08:00) Asia/Taipei",
    metadata: "",
    offset: "+0800",
  },
  {
    value: "Asia/Ulaanbaatar",
    label: "(UTC+08:00) Asia/Ulaanbaatar",
    metadata: "",
    offset: "+0800",
  },
  {
    value: "Australia/Perth",
    label: "(UTC+08:00) Australia/Perth",
    metadata: "",
    offset: "+0800",
  },
  {
    value: "Australia/Eucla",
    label: "(UTC+08:45) Australia/Eucla",
    metadata: "",
    offset: "+0845",
  },
  {
    value: "Asia/Chita",
    label: "(UTC+09:00) Asia/Chita",
    metadata: "",
    offset: "+0900",
  },
  {
    value: "Asia/Dili",
    label: "(UTC+09:00) Asia/Dili",
    metadata: "",
    offset: "+0900",
  },
  {
    value: "Asia/Jayapura",
    label: "(UTC+09:00) Asia/Jayapura",
    metadata: "",
    offset: "+0900",
  },
  {
    value: "Asia/Khandyga",
    label: "(UTC+09:00) Asia/Khandyga",
    metadata: "",
    offset: "+0900",
  },
  {
    value: "Asia/Pyongyang",
    label: "(UTC+09:00) Asia/Pyongyang",
    metadata: "",
    offset: "+0900",
  },
  {
    value: "Asia/Seoul",
    label: "(UTC+09:00) Asia/Seoul",
    metadata: "",
    offset: "+0900",
  },
  {
    value: "Asia/Tokyo",
    label: "(UTC+09:00) Asia/Tokyo",
    metadata: "",
    offset: "+0900",
  },
  {
    value: "Asia/Yakutsk",
    label: "(UTC+09:00) Asia/Yakutsk",
    metadata: "",
    offset: "+0900",
  },
  {
    value: "Pacific/Palau",
    label: "(UTC+09:00) Pacific/Palau",
    metadata: "",
    offset: "+0900",
  },
  {
    value: "Australia/Adelaide",
    label: "(UTC+09:30) Australia/Adelaide",
    metadata: "",
    offset: "+0930",
  },
  {
    value: "Australia/Broken_Hill",
    label: "(UTC+09:30) Australia/Broken_Hill",
    metadata: "",
    offset: "+0930",
  },
  {
    value: "Australia/Darwin",
    label: "(UTC+09:30) Australia/Darwin",
    metadata: "",
    offset: "+0930",
  },
  {
    value: "Antarctica/DumontDUrville",
    label: "(UTC+10:00) Antarctica/DumontDUrville",
    metadata: "",
    offset: "+1000",
  },
  {
    value: "Asia/Ust-Nera",
    label: "(UTC+10:00) Asia/Ust-Nera",
    metadata: "",
    offset: "+1000",
  },
  {
    value: "Asia/Vladivostok",
    label: "(UTC+10:00) Asia/Vladivostok",
    metadata: "",
    offset: "+1000",
  },
  {
    value: "Australia/Brisbane",
    label: "(UTC+10:00) Australia/Brisbane",
    metadata: "",
    offset: "+1000",
  },
  {
    value: "Australia/Currie",
    label: "(UTC+10:00) Australia/Currie",
    metadata: "",
    offset: "+1000",
  },
  {
    value: "Australia/Hobart",
    label: "(UTC+10:00) Australia/Hobart",
    metadata: "",
    offset: "+1000",
  },
  {
    value: "Australia/Lindeman",
    label: "(UTC+10:00) Australia/Lindeman",
    metadata: "",
    offset: "+1000",
  },
  {
    value: "Australia/Melbourne",
    label: "(UTC+10:00) Australia/Melbourne",
    metadata: "",
    offset: "+1000",
  },
  {
    value: "Australia/Sydney",
    label: "(UTC+10:00) Australia/Sydney",
    metadata: "",
    offset: "+1000",
  },
  {
    value: "Pacific/Chuuk",
    label: "(UTC+10:00) Pacific/Chuuk",
    metadata: "",
    offset: "+1000",
  },
  {
    value: "Pacific/Guam",
    label: "(UTC+10:00) Pacific/Guam",
    metadata: "",
    offset: "+1000",
  },
  {
    value: "Pacific/Port_Moresby",
    label: "(UTC+10:00) Pacific/Port_Moresby",
    metadata: "",
    offset: "+1000",
  },
  {
    value: "Pacific/Saipan",
    label: "(UTC+10:00) Pacific/Saipan",
    metadata: "",
    offset: "+1000",
  },
  {
    value: "Australia/Lord_Howe",
    label: "(UTC+10:30) Australia/Lord_Howe",
    metadata: "",
    offset: "+1030",
  },
  {
    value: "Antarctica/Macquarie",
    label: "(UTC+11:00) Antarctica/Macquarie",
    metadata: "",
    offset: "+1100",
  },
  {
    value: "Asia/Magadan",
    label: "(UTC+11:00) Asia/Magadan",
    metadata: "",
    offset: "+1100",
  },
  {
    value: "Asia/Sakhalin",
    label: "(UTC+11:00) Asia/Sakhalin",
    metadata: "",
    offset: "+1100",
  },
  {
    value: "Asia/Srednekolymsk",
    label: "(UTC+11:00) Asia/Srednekolymsk",
    metadata: "",
    offset: "+1100",
  },
  {
    value: "Pacific/Bougainville",
    label: "(UTC+11:00) Pacific/Bougainville",
    metadata: "",
    offset: "+1100",
  },
  {
    value: "Pacific/Efate",
    label: "(UTC+11:00) Pacific/Efate",
    metadata: "",
    offset: "+1100",
  },
  {
    value: "Pacific/Guadalcanal",
    label: "(UTC+11:00) Pacific/Guadalcanal",
    metadata: "",
    offset: "+1100",
  },
  {
    value: "Pacific/Kosrae",
    label: "(UTC+11:00) Pacific/Kosrae",
    metadata: "",
    offset: "+1100",
  },
  {
    value: "Pacific/Norfolk",
    label: "(UTC+11:00) Pacific/Norfolk",
    metadata: "",
    offset: "+1100",
  },
  {
    value: "Pacific/Noumea",
    label: "(UTC+11:00) Pacific/Noumea",
    metadata: "",
    offset: "+1100",
  },
  {
    value: "Pacific/Pohnpei",
    label: "(UTC+11:00) Pacific/Pohnpei",
    metadata: "",
    offset: "+1100",
  },
  {
    value: "Antarctica/McMurdo",
    label: "(UTC+12:00) Antarctica/McMurdo",
    metadata: "",
    offset: "+1200",
  },
  {
    value: "Asia/Anadyr",
    label: "(UTC+12:00) Asia/Anadyr",
    metadata: "",
    offset: "+1200",
  },
  {
    value: "Asia/Kamchatka",
    label: "(UTC+12:00) Asia/Kamchatka",
    metadata: "",
    offset: "+1200",
  },
  {
    value: "Pacific/Auckland",
    label: "(UTC+12:00) Pacific/Auckland",
    metadata: "",
    offset: "+1200",
  },
  {
    value: "Pacific/Fiji",
    label: "(UTC+12:00) Pacific/Fiji",
    metadata: "",
    offset: "+1200",
  },
  {
    value: "Pacific/Funafuti",
    label: "(UTC+12:00) Pacific/Funafuti",
    metadata: "",
    offset: "+1200",
  },
  {
    value: "Pacific/Kwajalein",
    label: "(UTC+12:00) Pacific/Kwajalein",
    metadata: "",
    offset: "+1200",
  },
  {
    value: "Pacific/Majuro",
    label: "(UTC+12:00) Pacific/Majuro",
    metadata: "",
    offset: "+1200",
  },
  {
    value: "Pacific/Nauru",
    label: "(UTC+12:00) Pacific/Nauru",
    metadata: "",
    offset: "+1200",
  },
  {
    value: "Pacific/Tarawa",
    label: "(UTC+12:00) Pacific/Tarawa",
    metadata: "",
    offset: "+1200",
  },
  {
    value: "Pacific/Wake",
    label: "(UTC+12:00) Pacific/Wake",
    metadata: "",
    offset: "+1200",
  },
  {
    value: "Pacific/Wallis",
    label: "(UTC+12:00) Pacific/Wallis",
    metadata: "",
    offset: "+1200",
  },
  {
    value: "Pacific/Chatham",
    label: "(UTC+12:45) Pacific/Chatham",
    metadata: "",
    offset: "+1245",
  },
  {
    value: "Pacific/Apia",
    label: "(UTC+13:00) Pacific/Apia",
    metadata: "",
    offset: "+1300",
  },
  {
    value: "Pacific/Enderbury",
    label: "(UTC+13:00) Pacific/Enderbury",
    metadata: "",
    offset: "+1300",
  },
  {
    value: "Pacific/Fakaofo",
    label: "(UTC+13:00) Pacific/Fakaofo",
    metadata: "",
    offset: "+1300",
  },
  {
    value: "Pacific/Tongatapu",
    label: "(UTC+13:00) Pacific/Tongatapu",
    metadata: "",
    offset: "+1300",
  },
  {
    value: "Pacific/Kiritimati",
    label: "(UTC+14:00) Pacific/Kiritimati",
    metadata: "",
    offset: "+1400",
  },
];
