import styled, { keyframes, css as c } from "styled-components";
import { css } from "@emotion/core";
import PulseLoader from "react-spinners/PulseLoader";

const customCss = css`
  margin: 5px auto;
  text-align: center;
`;

const appearAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const LoaderContainer = styled.div`
  display: ${({ loading }) => !loading && "none"};
  padding: ${({ padding }) => padding};
  ${({ animated }) =>
    animated &&
    c`
      animation: ${appearAnimation} 0.8s ease;
    `}
`;

const DropdownLoader = (props) => {
  return (
    <LoaderContainer
      loading={props.loading}
      className="sweet-loading"
      padding={props.padding}
      animated={props.animated}
    >
      <PulseLoader
        size={props.size || 10}
        color={props.color || "#c2cce1"}
        loading={props.loading}
        css={customCss}
      />
    </LoaderContainer>
  );
};

export default DropdownLoader;
