import { GET_TRIAL_INFO } from "../../modules/constants";

// ------------------------------------
// Actions
// ------------------------------------
export function getTrialInfo(value) {
  return {
    type: GET_TRIAL_INFO,
  };
}

export const actions = {
  getTrialInfo,
};
