import styled, { css } from "styled-components";

export const TimesheetDataSectionContainer = styled.div`
  padding: 20px 0;
`;

export const MapAndAlertContainer = styled.div`
  position: relative;
`;

export const MapTopAlert = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ bgColor, theme }) =>
    bgColor ? bgColor : theme.colors.main.grayMedium};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.main.white};
  border-top-right-radius: ${({ theme }) => theme.borderRadii.card};
`;

export const UserDataContainer = styled.div`
  margin-top: 0px;
`;
export const SingleUserDataSection = styled.div`
  ${({ active }) =>
    active &&
    css`
      border-left: 2px solid ${({ theme }) => theme.colors.border.primary};
    `}
`;
export const ArrowIcon = styled.img`
  transform: ${({ open }) => (open ? `rotate(0deg)` : `rotate(-90deg)`)};
  transition: transform 0.35s ease;
`;

export const UserDataCollapseSection = styled.div`
  display: block;
  max-height: ${({ open, height }) =>
    open ? (height ? height : "400px") : "0"};
  overflow: hidden;
  transition: max-height 0.5s ease;
  padding: 10px 15px;
`;
export const UserTimesheetDataSection = styled.div`
  padding: 10px 15px;
`;

export const TimesheetDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  margin: 10px 0 0;
  background-color: ${({ theme }) => theme.colors.main.white};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  cursor: pointer;
  border: 1px solid
    ${({ selected, theme }) =>
      selected
        ? theme.colors.border.primary
        : theme.colors.border.secondaryLight};

  &:hover {
    border-color: ${({ selected, theme }) =>
      !selected && theme.colors.border.secondary};
  }
`;

export const MarkerInfoContainer = styled.div`
  // padding: 15px;
`;

export const TimeInfoDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: max-content;
  padding: 0 15px;
  color: ${({ theme }) => theme.colors.main.white};
  font-size: 13px;
  font-weight: 600;
  background-color: ${({ theme }) => theme.colors.main.primary};
  border-radius: ${({ theme }) => theme.borderRadii.button};
`;
