import {
  REQUEST_TO_JOIN_ORGANIZATION,
  GET_JOIN_REQUESTS,
  ACCEPT_ORG_JOIN_REQUEST,
  CLEAR_JOIN_REQUESTS,
} from "../../../modules/constants";

// ------------------------------------
// Actions
// ------------------------------------

// export function getOrganizationJoinRequest(value) {
//   return {
//     type: REQUEST_TO_JOIN_ORGANIZATION,
//     payload: value,
//   };
// }

export function getJoinRequests() {
  return {
    type: GET_JOIN_REQUESTS,
  };
}

export function requestToJoinOrganization(value) {
  return {
    type: REQUEST_TO_JOIN_ORGANIZATION,
    payload: value,
  };
}

export function acceptOrganizationJoinRequest(value) {
  return {
    type: ACCEPT_ORG_JOIN_REQUEST,
    payload: value,
  };
}

export function clearJoinRequests() {
  return {
    type: CLEAR_JOIN_REQUESTS,
  };
}
