import { useCallback } from "react";
import { AsyncPaginate, wrapMenuList } from "react-select-async-paginate";
import {
  DropdownIndicator,
  IndicatorSeparator,
  LoadingIndicator,
  LoadingMessage,
  ClearIndicator,
} from "./DropdownIndicator";
import { CustomMenuList } from "./MenuList";

const MenuList = wrapMenuList(CustomMenuList);

const loadPageOptions = async (
  q,
  prevOptions,
  { page, pageSize, url, getOptions }
) => {
  const { options, hasMore } = await getOptions(
    q,
    prevOptions,
    page,
    pageSize,
    url
  );
  return {
    options,
    hasMore,
    additional: {
      page: page + 1,
    },
  };
};

const defaultAdditional = {
  page: 1,
  pageSize: 10,
};

const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
  const bottomBorder = scrollHeight - clientHeight - 50;
  return bottomBorder < scrollTop;
};

const ReactSelectPaginatedDropdown = (props) => {
  const { url, getOptions, ...otherProps } = props;

  const handleLoadOptions = useCallback(
    (search, prevOptions, additional) => {
      return loadPageOptions(search, prevOptions, {
        ...additional,
        url,
        getOptions,
      });
    },
    [url]
  );
  return (
    <AsyncPaginate
      defaultOptions
      additional={{ ...defaultAdditional, url, getOptions }}
      {...otherProps}
      loadOptions={handleLoadOptions}
      components={{
        IndicatorSeparator,
        DropdownIndicator,
        MenuList,
        LoadingIndicator,
        LoadingMessage,
        ClearIndicator,
      }}
      cacheUniqs={[url]}
      shouldLoadMore={shouldLoadMore}
      debounceTimeout={500}
    />
  );
};

export default ReactSelectPaginatedDropdown;
