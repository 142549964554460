import { connect } from "react-redux";

// Component
import Leave from "./Leave";

// Actions
import {
  getLeavesList,
  approveOrRejectLeave,
  createLeave,
} from "./leaveActions";

import { getOrganizationMembersList } from "../Projects/projectsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  organizationMembersList: state.project.orgMembersList,

  isLoading: state.leave.isLoading,
  leavesList: state.leave.leavesList,
  approveOrRejectIsLoading: state.leave.approveOrRejectIsLoading,
  status: state.leave.status,
  createLeaveIsLoading: state.leave.createLeaveIsLoading,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getOrganizationMembersList: (payload) =>
    dispatch(getOrganizationMembersList(payload)),
  getLeavesList: (payload) => dispatch(getLeavesList(payload)),
  approveOrRejectLeave: (payload) => dispatch(approveOrRejectLeave(payload)),
  createLeave: (payload) => dispatch(createLeave(payload)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Leave);
