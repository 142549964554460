import { connect } from "react-redux";

// Component
import Admin from "./Admin";

// Actions

import { loginProfileCreatedReset } from "../../custom_modules/Login/loginActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  // orgDetails: state.currentSubscriptionPlan.orgDetails,
  profile: state.profile,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  loginProfileCreatedReset: () => dispatch(loginProfileCreatedReset()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Admin);
