import integrationsRoutes from "../../utils/integrationsRoutes";
import { PrivateRoute } from "../../modules/privateRoute";

const getViews = (parentPath, views) => {
  return views.map((view, key) => {
    return (
      <PrivateRoute
        path={`${parentPath}${view.path}`}
        component={view.component}
        key={key}
      />
    );
  });
};

const IntegrationLayout = ({ match, location }) => {
  const { path } = match;
  const getRoutes = (routes) => {
    return routes.map((route, key) => {
      if (route.views && route.views.length > 0) {
        return getViews(`${path}${route.path}`, route.views);
      } else if (route.component) {
        return (
          <PrivateRoute
            path={`${path}${route.path}`}
            component={route.component}
            exact={route.exact}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  return getRoutes(integrationsRoutes);
};

export default IntegrationLayout;
