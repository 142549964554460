//Effects
import { all, put, takeLatest, select } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT,
  GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT_SUCCESS,
  GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT_FAILURE,
  GET_MANUAL_TIME_REPORT,
  GET_MANUAL_TIME_REPORT_SUCCESS,
  GET_MANUAL_TIME_REPORT_FAILURE,
  GET_URL_USAGE_REPORT,
  GET_URL_USAGE_REPORT_SUCCESS,
  GET_URL_USAGE_REPORT_FAILURE,
  GET_APP_USAGE_REPORT,
  GET_APP_USAGE_REPORT_SUCCESS,
  GET_APP_USAGE_REPORT_FAILURE,
  GET_APP_TIME_INFO_REPORT,
  GET_APP_TIME_INFO_REPORT_SUCCESS,
  GET_APP_TIME_INFO_REPORT_FAILURE,
  GET_REPORT_NOTES,
  GET_REPORT_NOTES_SUCCESS,
  GET_REPORT_NOTES_FAILURE,
  GET_WEEKLY_ACTIVITY_AND_TIME_REPORT,
  GET_WEEKLY_ACTIVITY_AND_TIME_REPORT_SUCCESS,
  GET_WEEKLY_ACTIVITY_AND_TIME_REPORT_FAILURE,
  GET_CUSTOM_REPORT_SUCCESS,
  GET_CUSTOM_REPORT_FAILURE,
  GET_CUSTOM_REPORT,
  CREATE_CUSTOM_REPORT,
  CREATE_CUSTOM_REPORT_SUCCESS,
  CREATE_CUSTOM_REPORT_FAILURE,
  DELETE_CUSTOM_REPORT_SUCCESS,
  DELETE_CUSTOM_REPORT_FAILURE,
  DELETE_CUSTOM_REPORT,
  GET_CUSTOM_REPORT_DETAILS_SUCCESS,
  GET_CUSTOM_REPORT_DETAILS_FAILURE,
  GET_CUSTOM_REPORT_DETAILS,
} from "../../modules/constants";

export function* handleGetTasks({ payload }) {
  // const store = yield select();
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/reports/average-activity/`;
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT_SUCCESS,
      payload: {
        data: response.data,
        startDate: payload.start_date,
        endDate: payload.end_date,
      },
    });
  } catch (err) {
    yield put({
      type: GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT_FAILURE,
      payload: err,
    });
  }
}
export function* handleGetWeeklyTimeAndActivityReport({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/reports/average-activity/`;
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: GET_WEEKLY_ACTIVITY_AND_TIME_REPORT_SUCCESS,
      payload: {
        data: response.data,
        startDate: payload.start_date,
        endDate: payload.end_date,
      },
    });
  } catch (err) {
    yield put({
      type: GET_WEEKLY_ACTIVITY_AND_TIME_REPORT_FAILURE,
      payload: err,
    });
  }
}

export function* handleManualTimeReport({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/reports/manual-timesheet-reports/`;
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: GET_MANUAL_TIME_REPORT_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: GET_MANUAL_TIME_REPORT_FAILURE,
      payload: err,
    });
  }
}

export function* handleUrlUsageReport({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/reports/url-reports/`;
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: GET_URL_USAGE_REPORT_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: GET_URL_USAGE_REPORT_FAILURE,
      payload: err,
    });
  }
}

export function* handleAppUsageReport({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/reports/app-reports/`;
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: GET_APP_USAGE_REPORT_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: GET_APP_USAGE_REPORT_FAILURE,
      payload: err,
    });
  }
}

export function* handleAppTimeInfoReport({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/reports/app-time-info/`;
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: GET_APP_TIME_INFO_REPORT_SUCCESS,
      payload: {
        data: response.data,
        startDate: payload.start_date,
        endDate: payload.end_date,
      },
    });
  } catch (err) {
    yield put({
      type: GET_APP_TIME_INFO_REPORT_FAILURE,
      payload: err,
    });
  }
}

export function* getReportNotesRequest({ payload }) {
  if (!payload) return;
  const { organization_id, date, user_id } = payload;
  try {
    const url = `${organization_id}/reports/notes/?date=${date}&user_id=${user_id}`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_REPORT_NOTES_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: GET_REPORT_NOTES_FAILURE,
      payload: err,
    });
  }
}
export function* handleGetCustomReportList({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/reports/custom-reports/`;
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: GET_CUSTOM_REPORT_SUCCESS,
      payload: {
        data: response.data,
      },
    });
  } catch (err) {
    yield put({
      type: GET_CUSTOM_REPORT_FAILURE,
      payload: err,
    });
  }
}

export function* handleCreateCustomReport({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/reports/custom-reports/create/`;
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: CREATE_CUSTOM_REPORT_SUCCESS,
      payload: {
        data: response.data,
      },
    });
  } catch (err) {
    yield put({
      type: CREATE_CUSTOM_REPORT_FAILURE,
      payload: err,
    });
  }
}
export function* handleDeleteCustomReports({ payload }) {
  if (!payload) return;
  const { organization_id, report_ids } = payload;
  try {
    const url = `${organization_id}/reports/custom-reports/delete/`;
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: DELETE_CUSTOM_REPORT_SUCCESS,
      payload: {
        report_ids,
      },
    });
  } catch (err) {
    // console.log(
    //   "🚀 ~ file: reportsSagas.js:208 ~ function*handleDELETECustomReportList ~ err:",
    //   err
    // );
    yield put({
      type: DELETE_CUSTOM_REPORT_FAILURE,
      payload: err,
    });
  }
}

export function* handleGetCustomReportDetails({ payload }) {
  if (!payload) return;
  const { organization_id, report_id } = payload;
  try {
    const url = `${organization_id}/reports/custom-reports/${report_id}/`;
    const response = yield defaultApi(url, "POST", payload);

    yield put({
      type: GET_CUSTOM_REPORT_DETAILS_SUCCESS,
      payload: {
        data: response.data,
      },
    });
  } catch (err) {
    // console.log(
    //   "🚀 ~ file: reportsSagas.js:208 ~ function*handleDELETECustomReportList ~ err:",
    //   err
    // );
    yield put({
      type: GET_CUSTOM_REPORT_DETAILS_FAILURE,
      payload: err,
    });
  }
}
export default function* root() {
  yield all([
    takeLatest(GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT, handleGetTasks),
    takeLatest(
      GET_WEEKLY_ACTIVITY_AND_TIME_REPORT,
      handleGetWeeklyTimeAndActivityReport
    ),
    takeLatest(GET_CUSTOM_REPORT, handleGetCustomReportList),
    takeLatest(CREATE_CUSTOM_REPORT, handleCreateCustomReport),
    takeLatest(DELETE_CUSTOM_REPORT, handleDeleteCustomReports),
    takeLatest(GET_CUSTOM_REPORT_DETAILS, handleGetCustomReportDetails),
    takeLatest(GET_MANUAL_TIME_REPORT, handleManualTimeReport),
    takeLatest(GET_URL_USAGE_REPORT, handleUrlUsageReport),
    takeLatest(GET_APP_USAGE_REPORT, handleAppUsageReport),
    takeLatest(GET_APP_TIME_INFO_REPORT, handleAppTimeInfoReport),
    takeLatest(GET_REPORT_NOTES, getReportNotesRequest),
  ]);
}
