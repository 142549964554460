import {
  FORGOT_PASSWORD_REQUEST,
  CLEAR_FORGOT_PASSWORD_LINK_SENT,
} from "modules/constants";

// ------------------------------------
// Actions
// ------------------------------------
export function forgotPassword(value) {
  return {
    type: FORGOT_PASSWORD_REQUEST,
    payload: value,
  };
}

export function clearForgotPasswordLinkSent() {
  return {
    type: CLEAR_FORGOT_PASSWORD_LINK_SENT,
  };
}
