import { connect } from "react-redux";
import CreateInvoice from "./CreateInvoice";

// Actions
import { getClientList, createClient } from "../../Clients/clientsActions";
import {
  createInvoice,
  getInvoiceNumber,
  sendInvoiceEmail,
  createInvoiceUpdateSendLog,
  invoiceLinkProject,
  clearInvoiceLinkedProject,
} from "../invoiceActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  isLoading: state.invoice.isLoading,
  createInvoiceLoading: state.invoice.createInvoiceLoading,
  linkProjectLoading: state.invoice.linkProjectLoading,
  sendMailLoading: state.invoice.sendMailLoading,
  clientIsLoading: state.client.isLoading,
  clientList: state.client.clientList,
  projectsList: state.project.projectShortList,
  createdInvoiceInfo: state.invoice.createdInvoiceInfo,
  createdClientId: state.client.createdClientId,
  invoiceNumber: state.invoice.invoiceNumber,
  profileData: state.profile.profileData,
  sendInvoiceEmailInfo: state.invoice.sendInvoiceEmailInfo,
  log: state.invoice.updateInvoiceSendLog,
  linkedProjectState: state.invoice.linkedProjectState,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getClientList: (details) => dispatch(getClientList({ ...details })),
  createInvoice: (details) => dispatch(createInvoice({ ...details })),
  getInvoiceNumber: (details) => dispatch(getInvoiceNumber({ ...details })),
  sendInvoiceEmail: (details) => dispatch(sendInvoiceEmail({ ...details })),
  createInvoiceUpdateSendLog: (details) =>
    dispatch(createInvoiceUpdateSendLog({ ...details })),
  createClient: (details) => dispatch(createClient({ ...details })),
  invoiceLinkProject: (details) => dispatch(invoiceLinkProject({ ...details })),
  clearInvoiceLinkedProject: () => dispatch(clearInvoiceLinkedProject()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoice);
