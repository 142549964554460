import styled from "styled-components";

export const IntegrationNameContainer = styled.div``;

export const WorkflowsContainer = styled.div`
  margin-top: 15px;
`;

export const WorkflowCard = styled.div`
  padding: 20px;

  width: 70%;
  max-width: 700px;

  background-color: ${({ theme }) => theme.colors.main.white};
  border: 1px solid
    ${({ theme, enabled }) =>
      enabled
        ? theme.colors.border.primary
        : theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.borderRadii.card};

  &:not(:first-child) {
    margin-top: 12px;
  }
`;
