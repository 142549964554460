import { useState } from "react";

import styled from "styled-components";

const Tooltip = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: ${({ left }) => left || `-48px`};
  top: ${({ top }) => top || `-38px`};
  padding: ${({ padding }) => padding || "7px 15px"};
  border-radius: 8px;
  font-size: ${({ fontSize }) => fontSize || "12px"};
  color: ${({ textColor }) => textColor || `#fff`};
  border: ${({ border }) => border || `1px solid #1f2939`};
  background-color: ${({ bgColor }) => bgColor || `#1f2939`};
  min-width: ${({ width }) => width || `120px`};
  height: ${({ height }) => height || `30px`};
  z-index: 5;
  &::before,
  ::after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &::after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: ${({ bgColor }) => bgColor || `#1f2939`};
    border-width: 5px;
    margin-left: -5px;
  }
  &::before {
    border-color: rgba(194, 204, 225, 0);
    border-top-color: ${({ bgColor }) => bgColor || `#1f2939`};
    border-width: 6px;
    margin-left: -6px;
  }
`;

const CommonTooltip = (props) => {
  const { tooltipText, ...otherProps } = props;
  return <Tooltip {...otherProps}>{tooltipText}</Tooltip>;
};

export default CommonTooltip;
