//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  SIGN_UP_REQUEST,
  SIGN_UP_REQUEST_SUCCESS,
  SIGN_UP_REQUEST_FAILURE,
  CHECK_TOKEN,
  CHECK_TOKEN_SUCCESS,
} from "../../modules/constants";

import { loginProfileCreatedReset } from "../Login/loginActions";

//Handle login request
// This will be run when the SIGN_UP_REQUESTING
// Action is found by the watcher
export function* signUpRequest({ payload }) {
  try {
    let url = "authentication/signup/";
    let method = "POST";
    // pulls "calls" to our signUpApi with our email and password
    // from our dispatched signUp action, and will PAUSE
    // here until the API async function, is complete!
    const response = yield defaultApi(url, method, payload);
    // when the above api call has completed it will "put",
    // or dispatch, an action of type SIGN_UP_REQUEST_SUCCESS with
    // the successful response.
    yield put({
      type: SIGN_UP_REQUEST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    // if the api call fails, it will "put" the SIGN_UP_ERROR
    // into the dispatch along with the error.
    yield put({
      type: SIGN_UP_REQUEST_FAILURE,
      payload: err,
    });
  }
}

export function* checkTokenRequest({ payload }) {
  try {
    let url = `auth/check/`;
    const response = yield defaultApi(url, "GET");

    yield put({
      type: CHECK_TOKEN_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    localStorage.clear();
    window.location.href = "/auth/login";
    loginProfileCreatedReset();
  }
}

// Watches for the SIGN_UP_REQUESTING action type
// When it gets it, it will call signupFlow()
// WITH the action we dispatched
function* signUpWatcher() {
  // takeLatest() takes the LATEST call of that action and runs it
  // if we we're to use takeEvery, it would take every single
  // one of the actions and kick off a new task to handle it
  // CONCURRENTLY!!!
  yield all([
    takeLatest(SIGN_UP_REQUEST, signUpRequest),
    takeLatest(CHECK_TOKEN, checkTokenRequest),
  ]);
}

export default signUpWatcher;
