import {
  GET_LEAVES_LIST,
  APPROVE_OR_REJECT_LEAVE,
  CREATE_LEAVE,
} from "../../modules/constants";

export function getLeavesList(payload) {
  return {
    type: GET_LEAVES_LIST,
    payload,
  };
}

export function approveOrRejectLeave(payload) {
  return {
    type: APPROVE_OR_REJECT_LEAVE,
    payload,
  };
}

export function createLeave(payload) {
  return {
    type: CREATE_LEAVE,
    payload,
  };
}
