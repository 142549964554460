import { useState, useEffect } from "react";
import Select from "../../components/ReactSelectDropdown";
import FilterDropDownStyle from "../../components/DropdownStyle/FilterDropDownStyle";
import moment from "moment";
import AddSingleMemberDropdown from "../../components/CustomDropdown/AddSingleMemberDropdown";
import DateRangePicker from "../../components/ReactDateRangePicker/ReactDateRangePicker";

import ProjectDropdown from "../../components/CommonFilter/ProjectDropdown";

import { FilterContainer } from "../../styledComponents/reports";
import {
  InputWithLabelSection,
  FilterLabel,
} from "../../styledComponents/common";

import { PrimaryButton } from "../../styledComponents/buttons";

import "../../assets/scss/reportDateRange.css";
import avatar5 from "../../assets/img/avatar_5.svg";
import ReactWeekPicker from "components/ReactWeekPicker/ReactWeekPicker";
import { getDateLabel } from "../../utils/helper";

const ReportsFilter = ({
  title,
  sortType,
  changeSortType,
  selectedOrganization,
  organizationMembersList,
  projectAssigneeList,
  getDayWiseActivityAndTimeReport,
  getManualTimeReport,
  getUrlUsageReport,
  getAppUsageReport,
  getAppTimeInfoReport,
  getOrganizationMembersList,
  getProjectAssigneeList,
  changeProjectInTable,
  selectedTab,
  getWeeklyActivityAndTimeReport,
}) => {
  const [startDate, setStartDate] = useState(
    moment().startOf("isoWeek").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("isoWeek").format("YYYY-MM-DD")
  );

  const [memberOptions, setMemberOptions] = useState([]);

  const [selectedProject, setSelectedProject] = useState("");
  const [selectedMember, setSelectedMember] = useState("");

  const [filterPayload, setFilterPayload] = useState({});

  const sortOptions = [
    { label: "Date (Old to new)", value: "OldToNew" },
    { label: "Date (New to old)", value: "NewToOld" },
  ];

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        start_date: startDate,
        end_date: endDate,
      };
      if (selectedTab === "weekly" && title === "timeActivity") {
        const tmpEndDate =
          endDate && endDate !== "Invalid date"
            ? moment(endDate).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD");
        payload.start_date = moment(tmpEndDate)
          .startOf("isoWeek")
          .format("YYYY-MM-DD");

        payload.end_date = moment(tmpEndDate)
          .endOf("isoWeek")
          .format("YYYY-MM-DD");

        setEndDate(moment(tmpEndDate).endOf("isoWeek").format("YYYY-MM-DD"));
        setStartDate(
          moment(tmpEndDate).startOf("isoWeek").format("YYYY-MM-DD")
        );
      }
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
        changeProjectInTable(
          selectedProject && selectedProject.label
            ? selectedProject.label
            : "All Projects"
        );
      }
      if (selectedMember && selectedMember.id) {
        payload.user_ids = [selectedMember.id];
      }
      setFilterPayload(payload);
      actionsReq(payload);
    }
  }, [selectedOrganization, selectedTab]);
  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      setSelectedProject("");
      setSelectedMember("");
      getOrganizationMembersList({
        organization_id: selectedOrganization.id,
      });
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (organizationMembersList && organizationMembersList.length > 0) {
      setMemberOptions(organizationMembersList);
    } else if (
      organizationMembersList &&
      organizationMembersList.length === 0
    ) {
      setMemberOptions([]);
    }
  }, [organizationMembersList]);

  useEffect(() => {
    if (projectAssigneeList && projectAssigneeList.length > 0) {
      let assigneeList = [];
      projectAssigneeList.map((assignee) => {
        assigneeList.push({
          id: assignee.user.id,
          name: `${assignee.user.first_name} ${assignee.user.last_name}`,
          avatar: assignee.user.avatar ? assignee.user.avatar : avatar5,
        });
      });
      setMemberOptions(assigneeList);
    } else if (projectAssigneeList && projectAssigneeList.length === 0) {
      setMemberOptions([]);
    }
  }, [projectAssigneeList]);

  useEffect(() => {
    if ((selectedProject && !selectedProject.value) || !selectedProject) {
      setMemberOptions(organizationMembersList);
    }
  }, [selectedProject]);

  const actionsReq = (payload) => {
    if (title === "timeActivity") {
      selectedTab === "weekly"
        ? getWeeklyActivityAndTimeReport(payload)
        : getDayWiseActivityAndTimeReport(payload);
    } else if (title === "manualTime") {
      getManualTimeReport(payload);
    } else if (title === "appUrl") {
      getAppUsageReport(payload);
      getUrlUsageReport(payload);
      getAppTimeInfoReport(payload);
    }
  };

  const onProjectSelect = (e) => {
    if (selectedOrganization && selectedOrganization.id && e && e.value) {
      let payload = {
        organization_id: selectedOrganization.id,
        project_id: e.value,
      };
      if (selectedMember) {
        payload.user_id = selectedMember.id;
        getProjectAssigneeList(payload);
      } else {
        getProjectAssigneeList(payload);
      }
    }
    if (selectProject) {
      selectProject(e);
    }
  };

  const onMemberSelect = (person) => {
    if (selectMember) {
      selectMember(person);
    }
  };

  const onDateSelect = () => {
    let organizationId = selectedOrganization ? selectedOrganization.id : "";
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: startDate === "Invalid date" ? endDate : startDate,
        end_date: endDate === "Invalid date" ? startDate : endDate,
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
        changeProjectInTable(
          selectedProject && selectedProject.label
            ? selectedProject.label
            : "All Projects"
        );
      }
      if (selectedMember && selectedMember.id) {
        payload.user_ids = [selectedMember.id];
      }
      setFilterPayload(payload);
      actionsReq(payload);
    }
  };

  const selectProject = (e) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        start_date: startDate,
        end_date: endDate,
      };
      if (selectedMember && e && e.value) {
        payload.user_ids = [selectedMember.id];
      }
      if (e && e.value) {
        payload.project_id = e.value;
      }
      setFilterPayload(payload);
    }
    if ((e && !e.value) || e == null) {
      setSelectedMember("");
      setSelectedProject(e);
    } else {
      setSelectedProject(e);
    }
  };

  const selectMember = (e) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        start_date: startDate,
        end_date: endDate,
        user_ids: [e.id],
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      setSelectedMember(e);
      setFilterPayload(payload);
    }
  };

  const clearSelectedMember = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        start_date: startDate,
        end_date: endDate,
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      setSelectedMember("");
      setFilterPayload(payload);
    }
  };

  const changeDateRange = (e) => {
    const payload = { ...filterPayload };
    payload.start_date = moment(e.startDate).format("YYYY-MM-DD");
    payload.end_date = moment(e.endDate).format("YYYY-MM-DD");
    setStartDate(moment(e.startDate).format("YYYY-MM-DD"));
    setEndDate(moment(e.endDate).format("YYYY-MM-DD"));

    setFilterPayload(payload);
  };

  const selectLastWeek = () => {
    setStartDate(moment().subtract(6, "days").format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
  };
  const selectLastMonth = () => {
    setStartDate(moment().subtract(29, "days").format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
  };
  const selectWeeklyLastWeek = () => {
    setStartDate(
      moment().subtract(6, "days").startOf("isoWeek").format("YYYY-MM-DD")
    );
    setEndDate(
      moment().subtract(6, "days").endOf("isoWeek").format("YYYY-MM-DD")
    );
  };
  const selectWeeklyCurrentWeek = () => {
    setStartDate(moment().startOf("isoWeek").format("YYYY-MM-DD"));
    setEndDate(moment().endOf("isoWeek").format("YYYY-MM-DD"));
  };
  const applyFilter = () => {
    actionsReq(filterPayload);
    changeProjectInTable(
      selectedProject && selectedProject.label
        ? selectedProject.label
        : "All Projects"
    );
  };

  return (
    <FilterContainer>
      <InputWithLabelSection>
        <FilterLabel>Projects</FilterLabel>
        <ProjectDropdown
          selectedOrganization={selectedOrganization}
          selectedProject={selectedProject}
          selectProject={onProjectSelect}
          selectedMember={selectedMember}
          clearable
          style={FilterDropDownStyle({
            height: "40px",
            width: "210px",
          })}
        />
      </InputWithLabelSection>

      <InputWithLabelSection>
        <FilterLabel>For</FilterLabel>
        <div style={{ width: `40px` }}>
          <AddSingleMemberDropdown
            assigneeManage={selectedMember}
            updateState={onMemberSelect}
            membersList={memberOptions}
            clearAssignee={() => clearSelectedMember()}
          />
        </div>
      </InputWithLabelSection>

      {selectedTab !== "weekly" ? (
        <InputWithLabelSection>
          <FilterLabel>
            Date Range{getDateLabel({ startDate, endDate })}
          </FilterLabel>
          <DateRangePicker
            startDate={startDate ? moment(startDate) : null}
            startDateId="report-date-range-start-date"
            endDate={endDate ? moment(endDate) : null}
            endDateId="report-date-range-end-date"
            onDatesChange={changeDateRange}
            dateDisplayFormat="DD/MM/YYYY"
            onDateSelect={onDateSelect}
            selectLastWeek={selectLastWeek}
            selectLastMonth={selectLastMonth}
          />
        </InputWithLabelSection>
      ) : (
        <InputWithLabelSection>
          <FilterLabel>Select Date</FilterLabel>
          <ReactWeekPicker
            startDate={startDate ? moment(startDate) : null}
            startDateId="report-date-range-start-date"
            endDate={endDate ? moment(endDate) : null}
            endDateId="report-date-range-end-date"
            onDatesChange={changeDateRange}
            dateDisplayFormat="DD/MM/YYYY"
            onDateSelect={onDateSelect}
            selectLastWeek={selectWeeklyLastWeek}
            selectCurrentWeek={selectWeeklyCurrentWeek}
          />
        </InputWithLabelSection>
      )}
      {selectedTab !== "weekly" && title === "timeActivity" && (
        <InputWithLabelSection>
          <FilterLabel>Sort By</FilterLabel>
          <Select
            isSearchable={false}
            value={sortType}
            options={sortOptions}
            onChange={(e) => {
              changeSortType(e);
            }}
            styles={FilterDropDownStyle({
              height: "40px",
              width: "165px",
            })}
          />
        </InputWithLabelSection>
      )}

      <PrimaryButton
        fontSize="14px"
        style={{ marginTop: `auto` }}
        onClick={() => applyFilter()}
      >
        Apply
      </PrimaryButton>
    </FilterContainer>
  );
};

export default ReportsFilter;
