import { connect } from "react-redux";

// Component
import EditClient from "./EditClient";

// Actions
import { getClientDetails, updateClient } from "../clientsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  isLoading: state.client.isLoading,
  clientUpdateLoading: state.client.clientUpdateLoading,
  selectedOrganization: state.organization.selectedOrganization,
  clientDetails: state.client.clientDetails,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getClientDetails: (details) => dispatch(getClientDetails({ ...details })),
  updateClient: (details) => dispatch(updateClient({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(EditClient);
