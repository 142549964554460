import React, { useState, useEffect, useRef } from "react";
import CreateProjectDropdown from "../../../components/DropdownStyle/CreateProjectDropdown";
import Select from "../../../components/ReactSelectDropdown";
import moment from "moment";
import DatePicker from "../../../components/SingleDatePicker/SingleDatePicker";
import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import AddClientPopup from "../AddClientPopup";
import SendMailPopup from "../SendMailPopup";

import ProjectDropdown from "../../../components/CommonFilter/ProjectDropdown";

import {
  GeneralDetailsInputContainer,
  FormAlert,
  Cross,
  CrossIcon,
  BillingQues,
  BillingTooltip,
  BillingTooltipText,
} from "../../../styledComponents/createProject";
import {
  PageTitle,
  Container,
  CardTitle,
  CommonText,
  TableText,
  InputWithLabelSection,
  InputLabel,
  InputField,
  RedStar,
  CommonGrid,
  ColoredText,
} from "../../../styledComponents/common";
import {
  WhiteButton,
  ColoredButton,
  PrimaryButton,
} from "../../../styledComponents/buttons";
import {
  CreateInvoiceSubTitle,
  CreateInvoiceItemContainer,
  ToggleButton,
  ToggleButtonRound,
  AddItemContainer,
  AddItem,
  CalculationContainer,
  TaxTypeButton,
  ReorderSelectSection,
  SubmitButtonContainer,
} from "../../../styledComponents/invoice";

import {
  currenciesWithSymbols,
  currencySymbolMap,
} from "../../../utils/currencies";

import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";
import DotLoader from "../../../components/DropdownLoader/DropdownLoader";

import Plus from "../../../assets/img/icons/plus.svg";
import crossIcon from "../../../assets/img/icons/cross_black.svg";
import reorder from "../../../assets/img/reorder.svg";
import { getDateLabel } from "../../../utils/helper";

const CreateInvoice = (props) => {
  const [selectClient, setSelectClient] = useState("");
  const [clientOptions, setClientOptions] = useState([]);

  const [invoiceNumber, setInvoiceNumber] = useState("");

  const [issuedDate, setIssuedDate] = useState(moment(new Date()));

  const [dueDate, setDueDate] = useState(null);

  const [poValue, setPoValue] = useState("");

  const [currency, setCurrency] = useState({
    label: "USD ($) - United States Dollar",
    value: "USD",
  });

  const [itemArray, setItemArray] = useState([
    {
      type: { value: "service", label: "Service" },
      description: "",
      quantity: 0,
      unitPrice: 0,
      tax1: true,
      tax2: true,
      linkedProject: "",
    },
  ]);

  const [descriptionState, setDescriptionState] = useState({
    index: -1,
    value: "",
  });

  const [quantityState, setQuantityState] = useState({
    index: -1,
    value: 0,
  });

  const [unitPriceState, setUnitPriceState] = useState({
    index: -1,
    value: 0,
  });

  const [focusedIndex, setFocusedIndex] = useState(-1);

  const [tax1, setTax1] = useState({
    percent: true,
    name: "",
    value: 0,
  });

  const [tax2, setTax2] = useState({
    percent: true,
    name: "",
    value: 0,
  });

  const [discount, setDiscount] = useState({
    percent: true,
    value: 0,
  });

  const itemTypeOptions = [
    { value: "service", label: "Service" },
    { value: "product", label: "Product" },
  ];

  const [totalCalc, setTotalCalc] = useState(0);
  const [grandTotalValue, setGrandTotalValue] = useState(0);

  const [saveErrors, setSaveErrors] = useState({});

  const [popupOpen, setPopupOpen] = useState(false);
  const [sendEmailOpen, setSendEmailOpen] = useState(false);
  const [toolTipActive, setToolTipActive] = useState(-1);
  const [sentState, setSentState] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [linkedItemIndex, setLinkedItemIndex] = useState(null);

  // only for the button loader
  const [isDraft, setIsDraft] = useState(true);

  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();

  useEffect(() => {
    return () => {
      props.clearInvoiceLinkedProject();
    };
  }, []);

  useEffect(() => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      if (
        props.selectedOrganization.configuration &&
        props.selectedOrganization.configuration.currency
      ) {
        setCurrency({
          label: `${props.selectedOrganization.configuration.currency} (${
            currencySymbolMap[props.selectedOrganization.configuration.currency]
          })`,
          value: props.selectedOrganization.configuration.currency,
        });
      }
      let payload = {
        organization_id: props.selectedOrganization.id,
      };
      props.getClientList(payload);
      props.getInvoiceNumber(payload);
    }
  }, [props.selectedOrganization]);

  // useEffect(() => {
  //   if (props.clientList && props.clientList.length > 0) {
  //     let options = [];
  //     props.clientList.map((item) => {
  //       if (props.createdClientId && props.createdClientId === item.id) {
  //         setSelectClient({
  //           value: props.createdClientId,
  //           label: item.name,
  //         });
  //       }
  //       if (item.is_active) {
  //         options.push({
  //           value: item.id,
  //           label: item.name,
  //         });
  //       }
  //     });
  //     setClientOptions(options);
  //   }
  // }, [props.clientList]);

  useEffect(() => {
    if (props.clientList && props.clientList.length > 0) {
      let options = [];

      // Sort the clientList by name
      const sortedClientList = [...props.clientList].sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      sortedClientList.map((item) => {
        if (props.createdClientId && props.createdClientId === item.id) {
          setSelectClient({
            value: props.createdClientId,
            label: item.name,
          });
        }
        if (item.is_active) {
          options.push({
            value: item.id,
            label: item.name,
          });
        }
      });

      setClientOptions(options);
    }
  }, [props.clientList, props.createdClientId]);

  useEffect(() => {
    totalCalculation();
  }, [itemArray]);

  useEffect(() => {
    grandTotalCalculation();
  }, [totalCalc, tax1, tax2, discount]);

  useEffect(() => {
    if (
      props.createdClientId &&
      props.selectedOrganization &&
      props.selectedOrganization.id
    ) {
      let payload = {
        organization_id: props.selectedOrganization.id,
      };
      props.getClientList(payload);
    }
  }, [props.createdClientId]);

  useEffect(() => {
    if (props.createdInvoiceInfo && props.createdInvoiceInfo.id && isCreated) {
      if (props.createdInvoiceInfo.saveAndSend) {
        setSendEmailOpen(true);
      } else {
        props.history.push(
          `/user/invoice-preview?id=${props.createdInvoiceInfo.id}`
        );
      }
    }
  }, [props.createdInvoiceInfo]);

  useEffect(() => {
    if (props.invoiceNumber && props.invoiceNumber.invoice_number) {
      setInvoiceNumber(props.invoiceNumber.invoice_number);
    }
  }, [props.invoiceNumber]);

  useEffect(() => {
    if (
      props.sendInvoiceEmailInfo &&
      props.sendInvoiceEmailInfo.log_id &&
      sentState
    ) {
      props.history.push(
        `/user/invoice-preview?id=${props.createdInvoiceInfo.id}`
      );
    }
  }, [props.sendInvoiceEmailInfo]);

  useEffect(() => {
    let newArr = [...itemArray];
    if (
      props.linkedProjectState &&
      linkedItemIndex !== null &&
      props.linkedProjectState.linked_project_id ==
        itemArray[linkedItemIndex].linkedProject.value
    ) {
      newArr[linkedItemIndex].unitPrice = parseFloat(
        props.linkedProjectState.unit_price
      ).toFixed(2);
      setItemArray(newArr);
    } else if (!props.linkedProjectState && linkedItemIndex !== null) {
      newArr[linkedItemIndex].unitPrice = 0;
      setItemArray(newArr);
    }
  }, [props.linkedProjectState, linkedItemIndex]);

  const onClientChange = (e) => {
    let errors = { ...saveErrors };
    delete errors["client"];
    setSaveErrors(errors);

    setSelectClient(e);
  };

  const onInvoiceNumberChange = (e) => {
    setInvoiceNumber(e.target.value);
  };

  const onIssuedDateChange = (date) => {
    setIssuedDate(date);
  };

  const onDueDateChange = (date) => {
    setDueDate(date);
  };

  const onPoValueChange = (e) => {
    setPoValue(e.target.value);
  };

  const onCurrencyChange = (e) => {
    setCurrency(e);
  };

  const onItemTypeChange = (e, index) => {
    let newArr = [...itemArray];
    newArr[index].type = e;
    setItemArray(newArr);
  };

  const onItemDescFocus = (index) => {
    setSaveErrors({});
    setDescriptionState({ index: index, value: itemArray[index].description });
  };

  const onItemDescChange = (e, index) => {
    let errors = { ...saveErrors };
    delete errors[`itemDescription_${index}`];
    setSaveErrors(errors);
    setDescriptionState({
      ...descriptionState,
      value: e.target.value,
    });
  };

  const onItemDescBlur = (index) => {
    let newArr = [...itemArray];
    newArr[index].description = descriptionState.value;
    setItemArray(newArr);
    setDescriptionState({
      active: -1,
      value: "",
    });
  };

  const onItemQuantityChange = (e, index) => {
    let errors = { ...saveErrors };
    delete errors[`itemQuantity_${index}`];
    setSaveErrors(errors);

    let newArr = [...itemArray];
    newArr[index].quantity = e.target.value;
    setItemArray(newArr);
  };

  const onItemUnitPriceChange = (e, index) => {
    let errors = { ...saveErrors };
    delete errors[`itemUnitPrice_${index}`];
    setSaveErrors(errors);

    let newArr = [...itemArray];
    newArr[index].unitPrice = e.target.value;
    setItemArray(newArr);
  };

  const onItemTax1Change = (index) => {
    let newArr = [...itemArray];
    newArr[index].tax1 = !newArr[index].tax1;
    setItemArray(newArr);
    grandTotalCalculation();
  };

  const onItemTax2Change = (index) => {
    let newArr = [...itemArray];
    newArr[index].tax2 = !newArr[index].tax2;
    setItemArray(newArr);
    grandTotalCalculation();
  };

  const onItemLinkedProjectChange = (e, index) => {
    let newArr = [...itemArray];
    newArr[index].linkedProject = e;
    newArr[index].description = e.label;
    newArr[index].quantity = 1;
    setItemArray(newArr);
    setLinkedItemIndex(index);

    if (props.selectedOrganization && props.selectedOrganization.id) {
      props.invoiceLinkProject({
        organization_id: props.selectedOrganization.id,
        linked_project_id: e.value,
      });
    }
  };

  const addItemToarray = () => {
    let newArray = [...itemArray];
    let newItem = {
      type: { value: "service", label: "Service" },
      description: "",
      quantity: 0,
      unitPrice: 0,
      tax1: true,
      tax2: true,
      linkedProject: "",
    };
    newArray.push(newItem);
    setItemArray(newArray);
    setSaveErrors({});
  };

  const handleDeleteItem = (index) => {
    let newArray = [...itemArray];
    newArray.splice(index, 1);
    setItemArray(newArray);
  };

  const onTax1NameChange = (e) => {
    setTax1({ ...tax1, name: e.target.value });
  };

  const onTax1ValueChange = (e) => {
    let errors = { ...saveErrors };
    delete errors[`tax1Value`];
    setSaveErrors(errors);

    setTax1({ ...tax1, value: e.target.value });
  };

  const toggleTax1CalcType = (value) => {
    setTax1({
      ...tax1,
      percent: value,
    });
  };

  const onTax2NameChange = (e) => {
    setTax2({ ...tax2, name: e.target.value });
  };

  const onTax2ValueChange = (e) => {
    let errors = { ...saveErrors };
    delete errors[`tax2Value`];
    setSaveErrors(errors);

    setTax2({ ...tax2, value: e.target.value });
  };

  const toggleTax2CalcType = (value) => {
    setTax2({
      ...tax2,
      percent: value,
    });
  };

  const onDiscountValueChange = (e) => {
    let errors = { ...saveErrors };
    delete errors[`discountValue`];
    delete errors[`discountFixed`];
    setSaveErrors(errors);

    setDiscount({ ...discount, value: e.target.value });
  };

  const toggleDiscountCalcType = (value) => {
    setDiscount({
      ...discount,
      percent: value,
    });
  };

  const totalCalculation = () => {
    if (itemArray && itemArray.length) {
      let total = 0;
      itemArray.map((item) => {
        total += item.quantity * item.unitPrice;
      });
      setTotalCalc(total.toFixed(2));
    }
  };

  const grandTotalCalculation = () => {
    const calcaulateTaxDiscount = (object, cost) => {
      if (object.percent) {
        return (object.value * cost) / 100;
      } else if (object.value) {
        return object.value;
      } else {
        return 0;
      }
    };

    if (itemArray && itemArray.length) {
      let totalTax = 0;
      itemArray.map((item, index) => {
        let itemCost = item.quantity * item.unitPrice;
        if (item.tax1) {
          totalTax += parseFloat(calcaulateTaxDiscount(tax1, itemCost));
        }
        if (item.tax2) {
          totalTax += parseFloat(calcaulateTaxDiscount(tax2, itemCost));
        }
      });

      let grandTotal =
        parseFloat(totalCalc) +
        totalTax -
        parseFloat(calcaulateTaxDiscount(discount, totalCalc));
      setGrandTotalValue(grandTotal.toFixed(2));
    }
  };

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };
  const sendEmailToggle = () => {
    setSendEmailOpen(!sendEmailOpen);
  };

  const onSend = () => {
    setSentState(true);
  };

  const checkError = () => {
    let formIsValid = true;
    let saveErrors = {};
    if (selectClient === "") {
      saveErrors["client"] = "Client can not be empty";
      formIsValid = false;
      executeScroll();
    }

    if (itemArray && itemArray.length > 0) {
      itemArray.forEach((item, index) => {
        if (item.description && item.description.length > 512) {
          saveErrors[`itemDescription_${index}`] =
            "Item Description can have maximum 512 characters";
          formIsValid = false;
        }
        if (parseFloat(item.quantity) < 1) {
          saveErrors[`itemQuantity_${index}`] =
            "Item Quantity can't be less than 1.";
          formIsValid = false;
        }
        if (parseFloat(item.unitPrice) < 0) {
          saveErrors[`itemUnitPrice_${index}`] =
            "Item unit-price can't be negative.";
          formIsValid = false;
        }
      });
    } else {
      saveErrors["noItem"] = "You have to add at least one item";
      formIsValid = false;
    }

    if (tax1 && parseFloat(tax1.value) < 0) {
      saveErrors["tax1Value"] = "Tax 1 value can't be negative.";
      formIsValid = false;
    }
    if (tax2 && parseFloat(tax2.value) < 0) {
      saveErrors["tax2Value"] = "Tax 2 value can't be negative.";
      formIsValid = false;
    }

    if (discount && discount.percent && parseFloat(discount.value) > 100) {
      saveErrors["discountValue"] = "Discount can't be greater than 100%.";
      formIsValid = false;
    } else if (
      discount &&
      !discount.percent &&
      parseFloat(discount.value) > parseFloat(totalCalc)
    ) {
      saveErrors["discountFixed"] =
        "Discount can't be greater than total amount";
      formIsValid = false;
    }

    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleSave = (saveAndSend) => {
    if (checkError()) {
      if (props.selectedOrganization && props.selectedOrganization.id) {
        let newItems = [];

        itemArray &&
          itemArray.length > 0 &&
          itemArray.map((item, index) => {
            if (item.quantity > 0) {
              let newItem = {};
              newItem.type = item.type.value;
              newItem.description = item.description;
              newItem.linked_project_id = item.linkedProject.value;
              newItem.unit_price = item.unitPrice;
              newItem.quantity = item.quantity;
              newItem.tax1 = item.tax1;
              newItem.tax2 = item.tax2;
              newItems.push(newItem);
            }
          });

        let payload = {
          organization_id: props.selectedOrganization.id,
          client_id: selectClient.value,
          invoice_number:
            invoiceNumber === ""
              ? `${props.selectedOrganization.name.substring(
                  0,
                  props.selectedOrganization.name.indexOf(" ")
                )}_${Math.ceil(Math.random() * 10000)}`
              : invoiceNumber,

          issued_date: issuedDate.format("YYYY-MM-DD"),
          due_date: dueDate !== null ? dueDate.format("YYYY-MM-DD") : dueDate,
          po_number: poValue === "" ? null : poValue,
          currency: (currency && currency.value) || "USD",
          tax1: {
            type: tax1.percent ? `percent` : `fixed`,
            name: tax1.name === "" ? `tax 1` : tax1.name,
            [tax1.percent ? `percent_amount` : `fixed_amount`]: tax1.value || 0,
          },
          tax2: {
            type: tax2.percent ? `percent` : `fixed`,
            name: tax2.name === "" ? `tax 2` : tax2.name,
            [tax2.percent ? `percent_amount` : `fixed_amount`]: tax2.value || 0,
          },
          discount: {
            type: discount.percent ? `percent` : `fixed`,
            [discount.percent ? `percent_amount` : `fixed_amount`]:
              discount.value || 0,
          },
          items: newItems,
          saveAndSend,
        };
        props.createInvoice(payload);
        setIsCreated(true);
        if (saveAndSend) {
          setIsDraft(false);
        }
      }
    }
    // props.history.push("/user/invoice-preview");
  };

  const cancelFunction = () => {
    props.history.push("/user/invoices");
  };

  return (
    <div className="content">
      <PageTitle>Invoices</PageTitle>
      <div
        style={{
          display: `grid`,
          gridTemplateColumns: `auto`,
          justifyContent: `start`,
          marginTop: "30px",
        }}
      >
        <BackButtonComponent
          onClick={() => props.history.push("/user/invoices")}
          subTitle="Back to all invoices"
        />
      </div>
      <CreateInvoiceSubTitle>
        Detail information of your Invoice
      </CreateInvoiceSubTitle>

      {props.selectedOrganization && props.selectedOrganization.id && (
        <AddClientPopup
          isOpen={popupOpen}
          toggle={popupToggle}
          selectedOrganization={props.selectedOrganization}
          createClient={props.createClient}
          isLoading={props.clientIsLoading}
        />
      )}

      {props.selectedOrganization &&
        props.selectedOrganization.id &&
        props.createdInvoiceInfo &&
        props.createdInvoiceInfo.id &&
        props.profileData && (
          <SendMailPopup
            isOpen={sendEmailOpen}
            isLoading={props.sendMailLoading}
            toggle={sendEmailToggle}
            organization_id={props.selectedOrganization.id}
            invoice_id={props.createdInvoiceInfo.id}
            senderName={props.profileData.first_name}
            invoiceNumber={props.createdInvoiceInfo.invoice_number}
            issuedDate={props.createdInvoiceInfo.issued_date}
            dueDate={props.createdInvoiceInfo.due_date}
            email={props.createdInvoiceInfo.client.email}
            log={props.log}
            createInvoiceUpdateSendLog={props.createInvoiceUpdateSendLog}
            sendInvoiceEmail={props.sendInvoiceEmail}
            onSend={onSend}
            history={props.history}
            createInvoice
          />
        )}

      <Container padding="30px 0" ref={myRef}>
        {props.isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            <CardTitle style={{ paddingLeft: "30px" }} fontSize="16px">
              {invoiceNumber}
            </CardTitle>
            <CommonText margin="10px 30px 15px">
              {props.selectedOrganization &&
                props.selectedOrganization.id &&
                props.selectedOrganization.name}
            </CommonText>
            <GeneralDetailsInputContainer>
              <InputWithLabelSection>
                <CommonGrid>
                  <InputLabel>
                    Client <RedStar>*</RedStar>
                  </InputLabel>
                  <AddItem
                    columns="18px auto"
                    gap="5px"
                    onClick={() => popupToggle()}
                  >
                    <img src={Plus} alt="plus" />
                    <ColoredText fontSize="12px" primary hover>
                      Add Client
                    </ColoredText>
                  </AddItem>
                </CommonGrid>
                <Select
                  isSearchable
                  value={selectClient}
                  options={clientOptions}
                  placeholder="Select client..."
                  onChange={(e) => {
                    onClientChange(e);
                  }}
                  styles={CreateProjectDropdown({
                    error: saveErrors["client"],
                  })}
                />
                {saveErrors && saveErrors["client"] && (
                  <FormAlert>{saveErrors["client"]}</FormAlert>
                )}
              </InputWithLabelSection>
              <InputWithLabelSection>
                <InfoTooltip
                  redStar
                  padding="0 0 8px 0"
                  label
                  fontSize="12px"
                  top="-75px"
                  mainText="Invoice Number "
                  toolTipText="The invoice number is a unique number. You can customize this as you need."
                />
                <InputField
                  type="text"
                  placeholder="Type invoice number"
                  value={invoiceNumber}
                  onChange={(e) => onInvoiceNumberChange(e)}
                />
              </InputWithLabelSection>
              <InputWithLabelSection>
                <InputLabel>PO Number</InputLabel>
                <InputField
                  type="text"
                  placeholder="Type..."
                  value={poValue}
                  onChange={(e) => onPoValueChange(e)}
                />
              </InputWithLabelSection>
              <InputWithLabelSection>
                <InputLabel>
                  Issued Date{" "}
                  {getDateLabel({
                    startDate: issuedDate,
                    endDate: issuedDate,
                    onlyToday: true,
                  })}
                  <RedStar>*</RedStar>
                </InputLabel>
                <DatePicker
                  id="createIssuedDate"
                  date={issuedDate}
                  onDateChange={onIssuedDateChange}
                  dateDisplayFormat={"DD/MM/YYYY"}
                  placeholder={"Select issued date"}
                  allowPreviousDates
                />
              </InputWithLabelSection>
              <InputWithLabelSection>
                <InputLabel>
                  Due Date
                  <span style={{ color: "red" }}>
                    {getDateLabel({
                      startDate: dueDate,
                      endDate: dueDate,
                      onlyToday: true,
                    })}
                  </span>
                </InputLabel>
                <DatePicker
                  id="createDueDate"
                  date={dueDate}
                  onDateChange={onDueDateChange}
                  dateDisplayFormat={"DD/MM/YYYY"}
                  placeholder={"Select due date"}
                  allowPreviousDates
                />
              </InputWithLabelSection>
              <InputWithLabelSection>
                <InputLabel>Currency</InputLabel>
                <Select
                  isSearchable
                  value={currency}
                  options={currenciesWithSymbols}
                  placeholder="Select Currency..."
                  onChange={(e) => {
                    onCurrencyChange(e);
                  }}
                  styles={CreateProjectDropdown({
                    error: saveErrors["currency"],
                  })}
                />
                {saveErrors && saveErrors["currency"] && (
                  <FormAlert>{saveErrors["currency"]}</FormAlert>
                )}
              </InputWithLabelSection>
            </GeneralDetailsInputContainer>

            <CreateInvoiceItemContainer heading>
              <ReorderSelectSection heading>
                <div></div>
                <TableText>No.</TableText>
              </ReorderSelectSection>
              <TableText>Item Type</TableText>
              <TableText>Description</TableText>
              <TableText>Quantity</TableText>
              <TableText>Unit Price</TableText>
              <TableText>Amount</TableText>
              <TableText>Tax 1</TableText>
              <TableText>Tax 2</TableText>
              <div></div>
            </CreateInvoiceItemContainer>
            {itemArray &&
              itemArray.length > 0 &&
              itemArray.map((item, index) => (
                <React.Fragment key={index}>
                  <CreateInvoiceItemContainer>
                    <ReorderSelectSection heading>
                      <div>
                        <img src={reorder} />
                      </div>
                      <CommonText> {index + 1} </CommonText>
                    </ReorderSelectSection>
                    <Select
                      isSearchable
                      value={item.type}
                      options={itemTypeOptions}
                      placeholder="Select type..."
                      onChange={(e) => {
                        onItemTypeChange(e, index);
                      }}
                      indicatorIconSize="16px"
                      styles={CreateProjectDropdown({ height: "34px" })}
                    />

                    {item.linkedProject == "" ? (
                      <React.Fragment>
                        <InputField
                          height="34px"
                          type="text"
                          placeholder="Type description"
                          value={
                            descriptionState.index === index
                              ? descriptionState.value
                              : item.description
                          }
                          onFocus={() => onItemDescFocus(index)}
                          onChange={(e) => onItemDescChange(e, index)}
                          onBlur={() => onItemDescBlur(index)}
                        />
                        <InputField
                          height="34px"
                          type="number"
                          min="0"
                          value={item.quantity}
                          onChange={(e) => onItemQuantityChange(e, index)}
                          onWheel={(e) => e.target.blur()}
                          style={
                            saveErrors &&
                            saveErrors[`itemQuantity_${index}`] && {
                              border: `1px solid #fe5969`,
                              backgroundColor: `rgba(252, 87, 104, 0.05)`,
                            }
                          }
                        />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <CommonText> {item.description} </CommonText>
                        <CommonText> {item.quantity} </CommonText>
                      </React.Fragment>
                    )}
                    <InputField
                      height="34px"
                      type="number"
                      min="0"
                      value={item.unitPrice}
                      onChange={(e) => onItemUnitPriceChange(e, index)}
                      onWheel={(e) => e.target.blur()}
                      style={
                        saveErrors &&
                        saveErrors[`itemUnitPrice_${index}`] && {
                          border: `1px solid #fe5969`,
                          backgroundColor: `rgba(252, 87, 104, 0.05)`,
                        }
                      }
                    />
                    {(quantityState.active || unitPriceState.active) &&
                    focusedIndex === index ? (
                      <CommonText style={{ opacity: `0.4` }}>
                        {`${
                          currency &&
                          currency.value &&
                          currencySymbolMap[currency.value]
                        } ${(item.quantity * item.unitPrice).toFixed(2)}`}
                      </CommonText>
                    ) : (
                      <CommonText>
                        {`${
                          currency &&
                          currency.value &&
                          currencySymbolMap[currency.value]
                        } ${(item.quantity * item.unitPrice).toFixed(2)}`}
                      </CommonText>
                    )}
                    <ToggleButton
                      onClick={() => onItemTax1Change(index)}
                      active={item.tax1}
                    >
                      <ToggleButtonRound active={item.tax1} />
                    </ToggleButton>
                    <ToggleButton
                      onClick={() => onItemTax2Change(index)}
                      active={item.tax2}
                    >
                      <ToggleButtonRound active={item.tax2} />
                    </ToggleButton>
                    <Cross onClick={() => handleDeleteItem(index)} size="26px">
                      <CrossIcon src={crossIcon} alt="" size="18px" />
                    </Cross>
                  </CreateInvoiceItemContainer>
                  {saveErrors && saveErrors[`itemDescription_${index}`] && (
                    <>
                      <CreateInvoiceItemContainer
                        style={{ padding: `0 30px 10px 30px` }}
                      >
                        <div style={{ gridColumn: `3 / span 4` }}>
                          <FormAlert>
                            {saveErrors[`itemDescription_${index}`]}
                          </FormAlert>
                        </div>
                      </CreateInvoiceItemContainer>
                    </>
                  )}
                  <CreateInvoiceItemContainer
                    style={{ padding: `0 30px 10px 30px` }}
                    marginTop="10px"
                  >
                    <CommonGrid
                      justifyContent="start"
                      alignItems="center"
                      columns="auto 200px auto"
                      alignItem="center"
                      style={{ gridColumn: `3 / span 4` }}
                    >
                      <CommonText fontSize="13px">Linked Project</CommonText>
                      {props.linkProjectLoading &&
                      linkedItemIndex !== null &&
                      linkedItemIndex === index ? (
                        <DotLoader loading />
                      ) : (
                        <ProjectDropdown
                          selectedOrganization={props.selectedOrganization}
                          selectedProject={item.linkedProject}
                          selectProject={(e) =>
                            onItemLinkedProjectChange(e, index)
                          }
                          indicatorIconSize="18px"
                          style={CreateProjectDropdown({ height: "36px" })}
                        />
                      )}
                      <BillingQues
                        onMouseEnter={() => setToolTipActive(index)}
                        onMouseLeave={() => setToolTipActive(-1)}
                      >
                        ?
                        {toolTipActive === index && (
                          <BillingTooltip top="-75px">
                            <BillingTooltipText>
                              The invoice can be billed for specific project.
                              You can see this details in the project analysis
                              page.
                            </BillingTooltipText>
                          </BillingTooltip>
                        )}
                      </BillingQues>
                    </CommonGrid>
                  </CreateInvoiceItemContainer>
                </React.Fragment>
              ))}
            <AddItemContainer>
              <AddItem onClick={() => addItemToarray()}>
                <img src={Plus} alt="plus" />
                <ColoredText fontSize="14px" primary hover>
                  Add More items
                </ColoredText>
              </AddItem>
              {saveErrors && saveErrors["noItem"] && (
                <FormAlert>{saveErrors["noItem"]}</FormAlert>
              )}
            </AddItemContainer>

            <CalculationContainer style={{ margin: `20px 0` }}>
              <div />
              <TableText>Total</TableText>
              <ColoredText primary semibold>
                {" "}
                {totalCalc}{" "}
              </ColoredText>
            </CalculationContainer>
            <CalculationContainer style={{ padding: `0 10px` }}>
              <div />
              <InputLabel>Tax Name</InputLabel>
              <InputLabel style={{ gridColumn: `4 / span 3` }}>
                In percent or fixed amount?
              </InputLabel>
            </CalculationContainer>
            <CalculationContainer>
              <CommonText>Tax 1</CommonText>
              <InputField
                type="text"
                placeholder="Type name"
                value={tax1.name}
                onChange={(e) => onTax1NameChange(e)}
              />
              <InputField
                type="number"
                min="0"
                value={tax1.value}
                onChange={(e) => onTax1ValueChange(e)}
                onWheel={(e) => e.target.blur()}
                style={
                  saveErrors &&
                  saveErrors["tax1Value"] && {
                    border: `1px solid #fe5969`,
                    backgroundColor: `rgba(252, 87, 104, 0.05)`,
                  }
                }
              />
              <TaxTypeButton
                percent
                active={tax1.percent}
                onClick={() => toggleTax1CalcType(true)}
              >
                %
              </TaxTypeButton>
              {currency && currency.value && (
                <TaxTypeButton
                  fontSize="12px"
                  active={!tax1.percent}
                  onClick={() => toggleTax1CalcType(false)}
                >
                  {currencySymbolMap[currency.value]}
                </TaxTypeButton>
              )}
              <div />
              <CommonText>Tax 2</CommonText>
              <InputField
                type="text"
                placeholder="Type name"
                value={tax2.name}
                onChange={(e) => onTax2NameChange(e)}
              />
              <InputField
                type="number"
                min="0"
                value={tax2.value}
                onChange={(e) => onTax2ValueChange(e)}
                onWheel={(e) => e.target.blur()}
                style={
                  saveErrors &&
                  saveErrors["tax2Value"] && {
                    border: `1px solid #fe5969`,
                    backgroundColor: `rgba(252, 87, 104, 0.05)`,
                  }
                }
              />
              <TaxTypeButton
                percent
                active={tax2.percent}
                onClick={() => toggleTax2CalcType(true)}
              >
                %
              </TaxTypeButton>
              {currency && currency.value && (
                <TaxTypeButton
                  fontSize="12px"
                  active={!tax2.percent}
                  onClick={() => toggleTax2CalcType(false)}
                >
                  {currencySymbolMap[currency.value]}
                </TaxTypeButton>
              )}
              <div />
              <div />
              <CommonText>Discount</CommonText>
              <InputField
                type="number"
                min="0"
                value={discount.value}
                onChange={(e) => onDiscountValueChange(e)}
                onWheel={(e) => e.target.blur()}
                style={
                  saveErrors &&
                  saveErrors["discountValue"] && {
                    border: `1px solid #fe5969`,
                    backgroundColor: `rgba(252, 87, 104, 0.05)`,
                  }
                }
              />
              <TaxTypeButton
                percent
                active={discount.percent}
                onClick={() => toggleDiscountCalcType(true)}
              >
                %
              </TaxTypeButton>
              {currency && currency.value && (
                <TaxTypeButton
                  fontSize="12px"
                  active={!discount.percent}
                  onClick={() => toggleDiscountCalcType(false)}
                >
                  {currencySymbolMap[currency.value]}
                </TaxTypeButton>
              )}
              {saveErrors && saveErrors["discountFixed"] ? (
                <FormAlert>{saveErrors["discountFixed"]}</FormAlert>
              ) : (
                <div />
              )}
            </CalculationContainer>
            <CalculationContainer grand>
              <div />
              <TableText>Grand Total</TableText>
              <ColoredText primary semibold>
                {currency.value} ({currencySymbolMap[currency.value]}){" "}
                {grandTotalValue}
              </ColoredText>
            </CalculationContainer>
            <CalculationContainer style={{ marginTop: `30px` }} padding="0">
              <SubmitButtonContainer>
                <WhiteButton type="cancel" onClick={() => cancelFunction()}>
                  Cancel
                </WhiteButton>
                <ColoredButton
                  type="grayMedium"
                  padding={props.createInvoiceLoading && isDraft && "0 30px"}
                  onClick={() => handleSave(false)}
                >
                  {props.createInvoiceLoading && isDraft ? (
                    <ButtonTextLoader loadingText="Saving" fontSize="13px" />
                  ) : (
                    "Save As Draft"
                  )}
                </ColoredButton>
                <PrimaryButton
                  padding={props.createInvoiceLoading && !isDraft && "0 30px"}
                  onClick={() => handleSave(true)}
                >
                  {props.createInvoiceLoading && !isDraft ? (
                    <ButtonTextLoader loadingText="Saving" fontSize="13px" />
                  ) : (
                    "Save & Send"
                  )}
                </PrimaryButton>
              </SubmitButtonContainer>
            </CalculationContainer>
          </>
        )}
      </Container>
    </div>
  );
};

export default CreateInvoice;
