import { connect } from "react-redux";

// Component
import SelectPlan from "./SelectPlan";

// Actions
import { getOrganizationPlans } from "../../Organization/OrganizationActions";

import { getOrganizationSubscription } from "../../Subscription/subscriptionActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  subscriptionPlans: state.organization.allPlans,
  currentPlan: state.subscription.currentSubscription,
  selectedOrganization: state.organization.selectedOrganization,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getOrganizationPlans: () => dispatch(getOrganizationPlans()),
  getOrganizationSubscription: (payload) =>
    dispatch(getOrganizationSubscription(payload)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(SelectPlan);
