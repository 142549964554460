import { connect } from "react-redux";

import TimesheetApproval from "./TimesheetApproval";

import {
  getTimesheetApprovalAwaitingList,
  getTimesheetApprovalHistory,
  loadMoreTimesheetApprovalList,
  clearLoadMoreTimesheetApprovalList,
} from "./timesheetApprovalActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,

  // pagination
  paginationSize: state.timesheetApproval.paginationSize,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getTimesheetApprovalAwaitingList: (payload) =>
    dispatch(getTimesheetApprovalAwaitingList(payload)),
  getTimesheetApprovalHistory: (payload) =>
    dispatch(getTimesheetApprovalHistory(payload)),
  loadMoreTimesheetApprovalList: (payload) =>
    dispatch(loadMoreTimesheetApprovalList(payload)),
  clearLoadMoreTimesheetApprovalList: () =>
    dispatch(clearLoadMoreTimesheetApprovalList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetApproval);
