import styled from "styled-components";

export const LearnMoreDiv = styled.div`
  width: 60%;
  height: 66px;
  padding: 0 20px;
  border-radius: ${({ theme }) => theme.borderRadii.card};
  margin-top: 30px;
  background: ${({ theme }) => theme.colors.main.primaryLight};
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 85%;
    height: 50px;
  }

  @media screen and (max-width: 500px) {
    width: 90%;
    height: 100px;
  }
`;
export const HelpIcon = styled.img``;
export const LearnMoreLink = styled.a`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.main.primary};
  margin-left: auto;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.hover.primary};
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
export const JoinOrganizationButton = styled.a`
  height: 50px;
  width: fit-content;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  background: ${({ theme }) => theme.button.primary.bgColor};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  color: ${({ theme }) => theme.button.primary.textColor};
  border-radius: ${({ theme }) => theme.button.borderRadius};
  margin-top: 45px;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.button.primary.textColor};
    background-color: ${({ theme }) => theme.button.primary.hoverBgColor};
  }
  &:focus {
    color: ${({ theme }) => theme.button.primary.textColor};
  }
`;

export const LogOutButton = styled.div`
  font-size: 16px;
  color: #20bead;
  cursor: pointer;
  margin-top: 35px;
  &:hover {
    text-decoration: underline;
  }
`;
