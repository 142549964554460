import { useState, useEffect } from "react";
import styled from "styled-components";

const FloatingLabelInput = styled.div`
  width: ${({ width }) => width || "100%"};
  margin-top: 25px;
`;
const FloatingLabelInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  height: 35px;
`;
const FloatingLabel = styled.label`
  padding: 0;
  margin: 0;
  border: 0;
  position: absolute;
  color: ${({ theme }) => theme.colors.main.labelText};
  bottom: 10px;
  transition: all 0.2s ease-in-out;
  transform-origin: left top;
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  cursor: text;
  pointer-events: none;
  width: 66.6%;
  transform: ${(props) =>
    props.active ? "translate3d(0, -85%, 0) scale(0.90)" : "none"};
`;
const FloatingInput = styled.input`
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.colors.main.textMain};
  border: none;
  border-bottom: 1px solid #ddd;
  outline: none;
  font-size: 14px;
  transition: font-size 0.3s ease-in-out;
  &:focus {
    border-bottom: 1px solid
      ${({ validationBorder, isValid, theme }) =>
        validationBorder
          ? isValid
            ? theme.colors.border.primary
            : theme.colors.main.error
          : theme.colors.border.primary};
    font-size: 20px;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

const TextInput = (props) => {
  const [active, setActive] = useState(props.value && props.value.length > 0);
  useEffect(() => {
    if (!props.id && !props.name) {
      throw new Error("expected id but none present");
    }
  }, []);

  const onInputFocus = (event) => {
    setActive(true);
    if (props.onFocus) {
      props.onFocus(event);
    }
  };

  const onInputBlur = (event) => {
    setActive(event.target.value.length !== 0);
    if (props.onBlur) {
      props.onBlur(event);
    }
  };
  const {
    id,
    width,
    label,
    onBlur,
    onFocus,
    type,
    refs,
    className,
    required,
    invitedEmail,
    ...otherProps
  } = props;
  return (
    <FloatingLabelInput width={width}>
      <FloatingLabelInputContainer className={className}>
        <FloatingLabel
          className={className}
          htmlFor={id}
          active={active || invitedEmail}
        >
          {label}
          {props.required && <span style={{ color: "#fe5969" }}> *</span>}
        </FloatingLabel>
        <FloatingInput
          active={active}
          className={className}
          id={id}
          onBlur={onInputBlur}
          onFocus={onInputFocus}
          ref={refs}
          type={type}
          {...otherProps}
        />
      </FloatingLabelInputContainer>
    </FloatingLabelInput>
  );
};

export default TextInput;
