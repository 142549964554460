import { Route, Switch, Redirect } from "react-router-dom";

import routes from "../../routes";

const Auth = (props) => {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  return (
    <Switch>
      {getRoutes(routes)}
      <Route path="/auth/*">
        <Redirect to="/auth/login" />
      </Route>
    </Switch>
  );
};

export default Auth;
