import { useTheme } from "styled-components";
import {
  commonControlStyles,
  commonOptionStyles,
  commonMenuListStyles,
  commonMenuStyles,
  commonSingleValueStyles,
  commonPlaceholderStyles,
  commonDropdownIndicatorStyles,
  commonInputStyles,
} from "./commonDropdownStyles";

const DropdownStyles = () => {
  const theme = useTheme();
  return {
    control: (styles, state) => {
      return {
        ...commonControlStyles(styles, state, theme),
        height: "40px",
        "@media only screen and (min-width: 1400px)": {
          ...styles["@media only screen and (min-width: 1400px)"],
          width: "80%",
        },
      };
    },
    option: (styles, state) => {
      return {
        ...commonOptionStyles(styles, state, theme),
        fontSize: "14px",
      };
    },
    menuList: (styles) => {
      return {
        ...commonMenuListStyles(styles, theme),
      };
    },
    menu: (styles, state) => {
      return {
        ...commonMenuStyles(styles, state, theme),
        textAlign: "left",
      };
    },

    singleValue: (styles) => ({
      ...commonSingleValueStyles(styles, theme),
      fontSize: "14px",
    }),
    placeholder: (styles) => ({
      ...commonPlaceholderStyles(styles, theme),
      fontSize: "14px",
    }),
    dropdownIndicator: (styles, state) => {
      return {
        ...commonDropdownIndicatorStyles(styles, state, theme),
      };
    },
    input: (styles, state) => ({
      ...commonInputStyles(styles, state, theme),
    }),
  };
};

export default DropdownStyles;
