import { useEffect, useMemo, useState } from "react";
import moment from "moment";

import Select from "../../components/ReactSelectDropdown";
import createProjectDropdown from "../../components/DropdownStyle/CreateProjectDropdown";
import Badge from "../../assets/img/icons/manager_badge.svg";
import CommonTooltip from "../../components/Tooltip/CommonTooltip";
import {
  Container,
  RoundLetterSection,
  TableText,
  AssigneeMoreText,
  CommonFlex,
  ManagerBadge,
  CommonText,
  CommonGrid,
  CenteredTooltip,
} from "../../styledComponents/common";
import {
  TeamTableHeadContainer,
  TeamTableContainer,
  TableItem,
  AssignedProjectBox,
} from "../../styledComponents/teams";
import ProjectListPopup from "../../components/ProjectListPopup/ProjectListPopup";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import ComponentCircleLoader from "../../components/Loaders/ComponentCircleLoader";
import ManagerListPopUp from "../../components/ManagerListPopUp/ManagerListPopUp";
import FirstRoundLetterComp from "../../components/FirstRoundLetter/FirstRoundLetter";

const TeamsTable = (props) => {
  const [teamProjectList, setTeamProjectList] = useState(null);
  const actionOptions = [
    { value: "details", label: "Team Details" },
    { value: "delete", label: "Delete Team" },
  ];
  const [managerTooltip, setManagerTooltip] = useState(false);
  const [selectIndex, setSelectIndex] = useState(-1);
  const [selectManager, setSelectManager] = useState(-1);
  const [selectProject, setSelectProject] = useState(-1);
  const [showPopup, setShowPopup] = useState(false);

  const {
    history,
    toggleDeleteModal,
    isLoading,
    teamList,
    selectedOrganization,
    searchTerm,
  } = props;

  const onHoverChange = (index) => {
    setSelectIndex(index);
  };

  const onHoverChangeManager = (index) => {
    setSelectManager(index);
  };

  const onHoverChangeProject = (index) => {
    setSelectProject(index);
  };

  const handleMouseLeave = (index) => {
    setSelectProject(-1);
    setShowPopup(false);
  };

  const onActionChange = (e, teamId, index) => {
    if (e.value === "details") {
      history.push(`/user/team-details?id=${teamId}&index=${index}`);
    } else if (e.value === "delete") {
      toggleDeleteModal(teamId);
    }
  };

  const setLocalTime = (time) => {
    const timeZone = localStorage.getItem("user_timezone");
    const createdOn = moment
      .tz(time, "America/Chicago")
      .tz(timeZone?.tz ?? "UTC")
      .format("MMM DD, YYYY");
    return createdOn;
  };

  return (
    <Container padding="0 0 10px 0">
      {isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <>
          {teamList && teamList.length > 0 && (
            <>
              <TeamTableHeadContainer>
                <TableItem>
                  <TableText>Team Name</TableText>
                </TableItem>
                <TableItem centerContent>
                  <TableText>Managers</TableText>
                </TableItem>
                <TableItem centerContent>
                  <TableText>Members</TableText>
                </TableItem>
                <TableItem centerContent>
                  <TableText>Projects</TableText>
                </TableItem>
                <TableItem padding="0 0 0 40px">
                  <TableText>Created On</TableText>
                </TableItem>
                {selectedOrganization &&
                  (selectedOrganization.role === "owner" ||
                    selectedOrganization.role === "admin") && (
                    <TableItem>
                      <TableText>Action</TableText>
                    </TableItem>
                  )}
              </TeamTableHeadContainer>
              {teamList.map((team, index) => (
                <TeamTableContainer key={index}>
                  <TableItem
                    onClick={() =>
                      history.push(
                        `/user/team-details?id=${team.id}&index=${index}`
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <CommonFlex gap="0px">
                      <CommonGrid maxWidth="250px">
                        <FirstRoundLetterComp
                          text={team.name}
                          backColor={index}
                          style={{ marginRight: "10px" }}
                          size="40px"
                          fontSize="14px"
                          color={team.color || null}
                        />
                        <TableText
                          style={{
                            maxWidth: "450px",
                            textAlign: "justify",
                            whiteSpace: "wrap",
                          }}
                          name
                          title={team.name}
                          hoverUnderline
                        >
                          {team.name}
                        </TableText>
                      </CommonGrid>
                      {team.is_manager && (
                        <ManagerBadge
                          src={Badge}
                          alt="manager-badge"
                          width="25px"
                          // style={{ position: "relative" }}
                          onMouseOver={() => onHoverChange(index)}
                          onMouseLeave={() => onHoverChange(-1)}
                        />
                      )}
                      {selectIndex === index && (
                        <CommonTooltip
                          style={{ position: "relative" }}
                          tooltipText="You are Manager"
                          left="-70px"
                          top="-35px"
                          width="130px"
                          height="25px"
                          padding="7px 10px"
                        />
                      )}
                    </CommonFlex>
                  </TableItem>
                  <TableItem centerContent>
                    <TableText>
                      {team.managers && team.managers.length > 0 ? (
                        <div
                          style={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                          }}
                          onMouseEnter={() => onHoverChangeManager(index)}
                          onMouseLeave={() => onHoverChangeManager(-1)}
                        >
                          <AssigneeMoreText fontSize="13px">
                            {team.managers.length}
                          </AssigneeMoreText>

                          {selectManager === index && (
                            <ManagerListPopUp List={team.managers} />
                            // <ProjectListPopup projectList={team.managers} />
                          )}
                        </div>
                      ) : (
                        <AssigneeMoreText fontSize="13px">0</AssigneeMoreText>
                      )}
                    </TableText>
                  </TableItem>
                  <TableItem centerContent>
                    <TableText>{team?.members?.length}</TableText>
                  </TableItem>
                  <TableItem centerContent>
                    {team.projects && team.projects.length > 0 ? (
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                          padding: "10px",
                        }}
                        onMouseEnter={() => onHoverChangeProject(index)}
                        onMouseLeave={() => onHoverChangeProject(-1)}
                      >
                        <AssigneeMoreText fontSize="13px">
                          {team.projects.length}
                        </AssigneeMoreText>

                        {selectProject === index && (
                          <ProjectListPopup projectList={team.projects} />
                        )}
                      </div>
                    ) : (
                      <AssigneeMoreText fontSize="13px">0</AssigneeMoreText>
                    )}
                  </TableItem>
                  <TableItem padding="0 0 0 40px">
                    <TableText name>{setLocalTime(team.created_at)}</TableText>
                  </TableItem>
                  {selectedOrganization &&
                    (selectedOrganization.role === "owner" ||
                      selectedOrganization.role === "admin") && (
                      <TableItem>
                        <Select
                          isSearchable={false}
                          value={null}
                          options={actionOptions}
                          placeholder="Actions"
                          onChange={(e) => {
                            onActionChange(e, team.id, index);
                          }}
                          styles={createProjectDropdown(null)}
                        />
                      </TableItem>
                    )}
                </TeamTableContainer>
              ))}
            </>
          )}
          {teamList && teamList.length === 0 && (
            <NoDataComponent
              title={
                searchTerm
                  ? `No team found with this name!`
                  : selectedOrganization &&
                    (selectedOrganization.role === "owner" ||
                      selectedOrganization.role === "admin")
                  ? `Seems like you have not added a team. Please add one.`
                  : `Seems like you are not assigned to any team`
              }
            />
          )}
        </>
      )}
    </Container>
  );
};

export default TeamsTable;
