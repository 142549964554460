import styled from "styled-components";

export const FilterContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || `55px 280px 230px`};
  grid-column-gap: 20px;
  justify-content: start;
  align-items: center;
  margin-top: 30px;
`;
