import { connect } from "react-redux";
import IntegrationSetup from "./IntegrationSetup";

// Actions
import {
  getIntegrationWorkflowsList,
  getEnabledIntegrationsList,
  enableIntegrationWorkflow,
  runIntegrationWorkflow,
} from "../integrationsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,

  isLoading: state.integrations.isLoading,
  allIntegrationsList: state.integrations.allIntegrationsList,
  integrationWorkflowsList: state.integrations.integrationWorkflowsList,

  enabledIntegrationsList: state.integrations.enabledIntegrationsList,
  enabledWorkflowData: state.integrations.enabledWorkflowData,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getIntegrationWorkflowsList: (payload) =>
    dispatch(getIntegrationWorkflowsList(payload)),
  getEnabledIntegrationsList: (payload) =>
    dispatch(getEnabledIntegrationsList(payload)),
  enableIntegrationWorkflow: (payload) =>
    dispatch(enableIntegrationWorkflow(payload)),
  runIntegrationWorkflow: (payload) =>
    dispatch(runIntegrationWorkflow(payload)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(IntegrationSetup);
