//Effects
import { put, takeLatest, all, call } from "redux-saga/effects";

//Service
import { defaultApi } from "../../../utils/axiosApi";

//Constants
import {
  GET_TIMESHEET_SETTINGS,
  GET_TIMESHEET_SETTINGS_SUCCESS,
  GET_TIMESHEET_SETTINGS_FAILURE,
  UPDATE_TIMESHEET_SETTINGS,
  UPDATE_TIMESHEET_SETTINGS_SUCCESS,
  UPDATE_TIMESHEET_SETTINGS_FAILURE,
  GET_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS,
  GET_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_SUCCESS,
  GET_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_FAILURE,
  UPDATE_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS,
  UPDATE_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_SUCCESS,
  UPDATE_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_FAILURE,
  UPDATE_ORGANIZATION_TIMESHEET_APPROVAL_SETTINGS,
  UPDATE_ORGANIZATION_TIMESHEET_APPROVAL_SETTINGS_SUCCESS,
  UPDATE_ORGANIZATION_TIMESHEET_APPROVAL_SETTINGS_FAILURE,
} from "../../../modules/constants";

export function* getTimesheetSettingsRequest({ payload }) {
  if (!payload) return;
  try {
    const { organization_id, configuration_id } = payload;
    const url = `${organization_id}/features/timesheet-setting/${configuration_id}/`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_TIMESHEET_SETTINGS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_TIMESHEET_SETTINGS_FAILURE,
      payload: err,
    });
  }
}
export function* updateTimesheetSettingsRequest({ payload }) {
  if (!payload) return;
  try {
    const { organization_id, configuration_id, ...details } = payload;
    const url = `${organization_id}/features/timesheet-setting/${configuration_id}/`;
    const response = yield defaultApi(url, "PATCH", details);
    yield put({
      type: UPDATE_TIMESHEET_SETTINGS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: UPDATE_TIMESHEET_SETTINGS_FAILURE,
      payload: err,
    });
  }
}

export function* getMemberwiseTimesheetApprovalSettings({ payload }) {
  if (!payload) return;
  try {
    const { organization_id, page } = payload;
    const url = `${organization_id}/features/timesheet-approval-settings/${
      page ? `?page=${page}` : ``
    }`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_FAILURE,
      payload: err,
    });
  }
}
export function* updateMemberwiseTimesheetApprovalSettings({ payload }) {
  if (!payload) return;
  try {
    const { organization_id, settings_id, ...details } = payload;
    const url = `${organization_id}/features/timesheet-approval-settings/${settings_id}/`;
    const response = yield defaultApi(url, "PATCH", details);
    yield put({
      type: UPDATE_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: UPDATE_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_FAILURE,
      payload: err,
    });
  }
}
export function* updateOrganizationTimesheetApprovalSettings({ payload }) {
  if (!payload) return;
  try {
    const { organization_id, ...details } = payload;
    const url = `${organization_id}/features/timesheet-approval-settings/`;
    const response = yield defaultApi(url, "POST", details);
    // yield call(getMemberwiseTimesheetApprovalSettings, {
    //   payload: { organization_id },
    // });
    yield put({
      type: UPDATE_ORGANIZATION_TIMESHEET_APPROVAL_SETTINGS_SUCCESS,
      payload: { data: response, payloadData: details },
    });
  } catch (err) {
    yield put({
      type: UPDATE_ORGANIZATION_TIMESHEET_APPROVAL_SETTINGS_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_TIMESHEET_SETTINGS, getTimesheetSettingsRequest),
    takeLatest(UPDATE_TIMESHEET_SETTINGS, updateTimesheetSettingsRequest),
    takeLatest(
      GET_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS,
      getMemberwiseTimesheetApprovalSettings
    ),
    takeLatest(
      UPDATE_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS,
      updateMemberwiseTimesheetApprovalSettings
    ),
    takeLatest(
      UPDATE_ORGANIZATION_TIMESHEET_APPROVAL_SETTINGS,
      updateOrganizationTimesheetApprovalSettings
    ),
  ]);
}
