import { connect } from "react-redux";

import RequestJoinOrg from "./RequestJoinOrg";
import {
  getJoinRequests,
  requestToJoinOrganization,
  acceptOrganizationJoinRequest,
  clearJoinRequests,
} from "./requestJoinOrgActions";

const mapStateToProps = (state) => ({
  isLoading: state.requestJoinOrg.isLoading,
  redirect: state.requestJoinOrg.redirect,
  joinRequest: state.requestJoinOrg.joinRequest,
  errorMessage: state.requestJoinOrg.errorMessage,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getJoinRequests: () => dispatch(getJoinRequests()),
  requestToJoinOrganization: (payload) =>
    dispatch(requestToJoinOrganization(payload)),
  acceptOrganizationJoinRequest: (payload) =>
    dispatch(acceptOrganizationJoinRequest(payload)),
  clearJoinRequests: () => dispatch(clearJoinRequests()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(RequestJoinOrg);
