//Effects
import { put, takeLatest, all, take } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  VERIFY_CODE,
  VERIFY_CODE_SUCCESS,
  VERIFY_CODE_FAILURE,
  RESEND_VERIFICATION_CODE,
  RESEND_VERIFICATION_CODE_SUCCESS,
  RESEND_VERIFICATION_CODE_FAILURE,
} from "../../modules/constants";

export function* verifyCodeRequest({ payload }) {
  try {
    let url = "authentication/confirm/";
    let method = "POST";
    const response = yield defaultApi(url, method, payload);
    yield put({
      type: VERIFY_CODE_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: VERIFY_CODE_FAILURE,
      payload: err,
    });
  }
}

export function* resendVerificationCodeRequest({ payload }) {
  try {
    let url = "authentication/confirm/resend/";
    let method = "POST";

    const response = yield defaultApi(url, method, payload);
    yield put({
      type: RESEND_VERIFICATION_CODE_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: RESEND_VERIFICATION_CODE_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(VERIFY_CODE, verifyCodeRequest),
    takeLatest(RESEND_VERIFICATION_CODE, resendVerificationCodeRequest),
  ]);
}
