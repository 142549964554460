import { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import TimePicker from "../../components/TimePicker";

const TimeRangeContainer = styled.div`
  display: grid;
  grid-template-columns: 45% 5% 45%;
  justify-content: space-between;
  align-items: center;
`;
const DashDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TimeDash = styled.div`
  width: 8px;
  height: 1px;
  background: ${({ theme }) => theme.colors.main.textSecondary};
`;
const TimePickerError = styled.div`
  min-width: 210px;
  position: absolute;
  font-size: 11px;
  top: 44px;
  color: ${({ theme }) => theme.colors.main.error};
`;

const TimeRangeComponent = ({
  startTime,
  setStartTime,
  endTime,
  setEndTime,
}) => {
  const [startValue, setStartValue] = useState(moment().format("HH:mm"));
  const [endValue, setEndValue] = useState(moment().format("HH:mm"));
  const [startError, setStartError] = useState(false);
  const [endError, setEndError] = useState(false);
  const [focusEnd, setFocusEnd] = useState(false);

  const [timesheetDate, setTimesheetDate] = useState(null);

  // useEffect(() => {
  //   console.log(startTime);
  //   console.log(endTime);
  //   if (startTime) {
  //     setStartValue(startTime.format("HH:mm"));
  //     setTimesheetDate(startTime.format("YYYY-MM-DD"));
  //   }
  //   if (endTime) {
  //     setEndValue(endTime.format("HH:mm"));
  //   }
  // }, [startTime, endTime]);

  useEffect(() => {
    if (startTime) {
      setStartValue(startTime.format("HH:mm"));
      setTimesheetDate(startTime.format("YYYY-MM-DD"));
    }
  }, [startTime]);

  useEffect(() => {
    if (endTime) {
      setEndValue(endTime.format("HH:mm"));
    }
  }, [endTime]);

  const onStartChange = (value) => {
    setStartValue(value);
    setStartTime(timesheetDate, value);
  };

  const onEndChange = (value) => {
    setEndValue(value);
    setEndTime(timesheetDate, value);
  };

  const onStartBlur = () => {
    setFocusEnd(true);
  };
  return (
    <TimeRangeContainer style={{ position: "relative" }}>
      <TimePicker
        hour12Format
        value={startValue}
        onChange={(value) => onStartChange(value)}
        onFinishInput={onStartBlur}
      />
      <DashDiv>
        <TimeDash />
      </DashDiv>
      <TimePicker
        hour12Format
        value={endValue}
        onChange={(value) => onEndChange(value)}
        focusInput={focusEnd}
        setFocusInput={setFocusEnd}
      />
      {(startError || endError) && (
        <TimePickerError>
          Please enter a valid time. (e.g: 09:00 am)
        </TimePickerError>
      )}
    </TimeRangeContainer>
  );
};

export default TimeRangeComponent;
