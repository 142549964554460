import {
  DONT_SHOW_ORGANIZATION_WIZARD,
  ADD_MULTIPLE_PROJECTS,
  INVITE_MULTIPLE_MEMBERS,
  CHECK_ONBOARDING_APPLICABLE,
  SAVE_ONBOARDING_DATA,
} from "../../../modules/constants";

// ------------------------------------
// Actions
// ------------------------------------
export function dontShowWizardAgain(value) {
  return {
    type: DONT_SHOW_ORGANIZATION_WIZARD,
    payload: value,
  };
}

export function addMultipleProjects(value) {
  return {
    type: ADD_MULTIPLE_PROJECTS,
    payload: value,
  };
}

export function inviteMultipleMembers(value) {
  return {
    type: INVITE_MULTIPLE_MEMBERS,
    payload: value,
  };
}

export function checkOnboardingApplicable() {
  return {
    type: CHECK_ONBOARDING_APPLICABLE,
  };
}

export function saveOnboardingData(value) {
  return {
    type: SAVE_ONBOARDING_DATA,
    payload: value,
  };
}
