import { connect } from "react-redux";

// Component
import AddOrganization from "./AddOrganization";

// Actions
import {
  createOrganization,
  getOrganizationTypes,
  getOrganizationPlans,
  updateOrganization,
  clearOrgCreated,
} from "../Organization/OrganizationActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  organizations: state.organization,
  organization: state.organization.selectedOrganization,
  subscriptionPlans: state.organization.allPlans,
  isLoading: state.organization.isLoading,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  createOrganization: (details) => dispatch(createOrganization({ ...details })),
  getOrganizationTypes: () => dispatch(getOrganizationTypes()),
  getOrganizationPlans: () => dispatch(getOrganizationPlans()),
  updateOrganization: (details) => dispatch(updateOrganization({ ...details })),
  clearOrgCreated: () => dispatch(clearOrgCreated()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(AddOrganization);
