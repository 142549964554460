import { useState, useEffect } from "react";
import queryString from "query-string";

import PaginatedDropdown from "../ReactSelectPaginatedDropdown";

import FilterDropDownStyle from "../DropdownStyle/FilterDropDownStyle";

import { defaultApi } from "../../utils/axiosApi";

const TaskDropdown = (props) => {
  const [requestUrl, setRequestUrl] = useState("");
  const {
    selectedOrganization,
    selectedTask,
    selectTask,
    selectedProject,
    selectedMember,
    style,
    addAllTasksOption = false,
  } = props;
  const getTaskOptions = async (search, prevOptions, page, pageSize, url) => {
    try {
      if (url) {
        const queryParams = { page };
        if (search.trim()) {
          queryParams.name = search;
        }

        const finalUrl = queryString.stringifyUrl({ url, query: queryParams });

        const response = await defaultApi(finalUrl, "GET");
        const { status, data } = response;

        const options =
          addAllTasksOption && page === 1
            ? [{ value: "", label: "(All Tasks)" }]
            : [];
        let hasMore = false;

        if (status === 200 && data && data.results && data.results.length > 0) {
          data.results.forEach((item) => {
            options.push({
              value: item.id,
              label: item.name,
            });
          });
          if (data.next) {
            hasMore = true;
          }
        }
        return { options, hasMore };
      }
    } catch (err) {
      // console.log(err);
      return {
        options: [],
        hasMore: false,
      };
    }
  };
  useEffect(() => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      selectedProject &&
      selectedProject.value
    ) {
      const url = `${selectedOrganization.id}/projects/${selectedProject.value}/tasks/`;
      if (selectedMember && selectedMember.id) {
        setRequestUrl(
          queryString.stringifyUrl({
            url,
            query: { members: selectedMember.id },
          })
        );
      } else {
        setRequestUrl(url);
      }
    }
  }, [selectedOrganization, selectedProject]);

  useEffect(() => {
    if (requestUrl) {
      if (selectedMember && selectedMember.id) {
        setRequestUrl(
          queryString.stringifyUrl({
            url: requestUrl,
            query: { members: selectedMember.id },
          })
        );
      } else {
        const parsedUrl = queryString.parseUrl(requestUrl);
        setRequestUrl(parsedUrl.url);
      }
    }
  }, [selectedMember]);

  return (
    <PaginatedDropdown
      isSearchable
      isClearable={selectedTask?.value}
      isDisabled={
        !selectedProject || (selectedProject && !selectedProject.value)
      }
      placeholder="Select Task"
      value={selectedTask}
      url={requestUrl}
      getOptions={getTaskOptions}
      onChange={(e) => selectTask(e)}
      styles={style || FilterDropDownStyle({ minHeight: "40px" })}
    />
  );
};

export default TaskDropdown;
