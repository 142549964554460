import { useState, useEffect } from "react";
import moment from "moment";
import { toHHMMSS } from "../../../../utils/helper";

const processTimesheetData = (timesheet) => {
  const list = [];
  const timeDurations = [];
  const idleTimeDurations = [];

  timesheet.forEach((time) => {
    // Process main timesheet entry
    const timeSlot = processMainTimeSlot(time);
    timeDurations.push(time.duration);

    // Process idle times if they exist
    if (time.idle_times?.length > 0) {
      timeSlot.idleTimes = processIdleTimes(time.idle_times, timeSlot.top);
      // Add idle durations to total
      time.idle_times.forEach((idle) => {
        idleTimeDurations.push(idle.duration);
      });
    }

    list.push(timeSlot);
  });

  return { list, timeDurations, idleTimeDurations };
};

const processMainTimeSlot = (time) => {
  const compareTime = moment("00:00", "HH:mm");
  const startTime = time.start_timestamp.format("HH:mm");

  // Calculate position and height
  const startTimeDuration = moment.utc(
    moment(startTime, "HH:mm").diff(moment(compareTime))
  );
  const startTimeHour = parseInt(moment.duration(startTimeDuration).asHours());
  const startTimeMinutes = parseInt(
    moment.duration(startTimeDuration).asMinutes() % 60
  );

  const hours = parseInt(toHHMMSS(time.duration).split(":")[0]);
  const minutes = parseInt(toHHMMSS(time.duration).split(":")[1]);

  return {
    id: time.id,
    start_time: time.start_timestamp,
    end_time: time.end_timestamp,
    duration: time.duration,
    project: time.project,
    task: time.task || null,
    top: parseInt((startTimeHour + startTimeMinutes / 60) * 75),
    height: calculateHeight(hours, minutes),
  };
};

const processIdleTimes = (idleTimes, timeslotTop) => {
  const compareTime = moment("00:00", "HH:mm");

  return idleTimes.map((idle) => {
    const idleStartTime = idle.start_timestamp.format("HH:mm");
    const idleStartDuration = moment.utc(
      moment(idleStartTime, "HH:mm").diff(moment(compareTime))
    );
    const idleStartHour = parseInt(
      moment.duration(idleStartDuration).asHours()
    );
    const idleStartMinutes = parseInt(
      moment.duration(idleStartDuration).asMinutes() % 60
    );

    const idleHours = parseInt(toHHMMSS(idle.duration).split(":")[0]);
    const idleMinutes = parseInt(toHHMMSS(idle.duration).split(":")[1]);

    return {
      id: idle.id,
      start_timestamp: idle.start_timestamp,
      end_timestamp: idle.end_timestamp,
      duration: idle.duration,
      top: parseInt((idleStartHour + idleStartMinutes / 60) * 75),
      height: calculateHeight(idleHours, idleMinutes),
    };
  });
};

const calculateHeight = (hours, minutes) => {
  const totalHeight = parseInt((hours + minutes / 60) * 75);
  return totalHeight === 0 ? 1 : totalHeight;
};

export const useTimesheetData = (timesheet) => {
  const [timesheetList, setTimesheetList] = useState([]);
  const [durations, setDurations] = useState([]);
  const [idleDurations, setIdleDurations] = useState([]);

  useEffect(() => {
    if (!timesheet?.length) {
      setTimesheetList([]);
      setDurations([]);
      setIdleDurations([]);
      return;
    }

    const processedData = processTimesheetData(timesheet);
    setTimesheetList(processedData.list);
    setDurations(processedData.timeDurations);
    setIdleDurations(processedData.idleTimeDurations);
  }, [timesheet]);

  return { timesheetList, durations, idleDurations };
};
