import {
  GET_JOIN_REQUESTS,
  GET_JOIN_REQUESTS_SUCCESS,
  GET_JOIN_REQUESTS_FAILURE,
  REQUEST_TO_JOIN_ORGANIZATION,
  REQUEST_TO_JOIN_ORGANIZATION_SUCCESS,
  REQUEST_TO_JOIN_ORGANIZATION_FAILURE,
  ACCEPT_ORG_JOIN_REQUEST,
  ACCEPT_ORG_JOIN_REQUEST_SUCCESS,
  ACCEPT_ORG_JOIN_REQUEST_FAILURE,
  CLEAR_JOIN_REQUESTS,
  LOGIN_PROFILE_CREATED_RESET,
} from "../../../modules/constants";
import { createNotification } from "../../../modules/notificationManager";

// ------------------------------------
// Action handler methods
// ------------------------------------
export const handleGetJoinRequests = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const handleGetJoinRequestsSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    joinRequest: data.data,
  };
};
export const handleGetJoinRequestsFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};

export const handleOrganizationJoinRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    errorMessage: "",
  };
};
export const handleOrganizationJoinRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    joinRequest: state.joinRequest
      ? [...state.joinRequest, data.data]
      : [data.data],
  };
};
export const handleOrganizationJoinRequestFailure = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (
    response.data &&
    response.data.code &&
    response.data.code[0] === "no organization found with provided code"
  ) {
    return {
      ...state,
      isLoading: false,
      errorMessage: "You may have got it wrong! No organization with the given code exists."
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const handleClearJoinRequests = (state, action) => {
  return {
    ...state,
    joinRequest: null,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_JOIN_REQUESTS]: handleGetJoinRequests,
  [GET_JOIN_REQUESTS_SUCCESS]: handleGetJoinRequestsSuccess,
  [GET_JOIN_REQUESTS_FAILURE]: handleGetJoinRequestsFailure,

  [REQUEST_TO_JOIN_ORGANIZATION]: handleOrganizationJoinRequest,
  [REQUEST_TO_JOIN_ORGANIZATION_SUCCESS]: handleOrganizationJoinRequestSuccess,
  [REQUEST_TO_JOIN_ORGANIZATION_FAILURE]: handleOrganizationJoinRequestFailure,

  [CLEAR_JOIN_REQUESTS]: handleClearJoinRequests,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  isLoading: false,
  joinRequest: null,
  errorMessage: "",
};

export default function requestToJoinReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
