import styled from "styled-components";

export const SelectSection = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) =>
    columns || `1fr 40px 1.4fr 1fr 140px`};
  justify-content: start;
  grid-column-gap: ${({ gap }) => gap || `35px`};
  margin: 20px 0 0;
  max-width: 1200px;
`;

export const UpperTableSection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 30px;
  justify-content: space-between;
  // margin-bottom: 20px;
  padding: 0 30px;
`;
export const SelectAllDateMemberSection = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || `24px auto 1fr 1fr`};
  grid-column-gap: 15px;
  align-items: center;
`;
export const ButtonContainer = styled.div`
  display: grid;
  justify-content: start;
  margin: ${({ index }) => (index === 0 ? "10px" : "15px")} 0 20px;
  padding-left: 30px;
`;
export const CheckBox = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  grid-template-columns: auto;
  border: 1px solid
    ${({ bgColor, theme }) =>
      bgColor
        ? theme.colors.border.primary
        : theme.colors.border.secondaryLight};
  border-radius: 4px;
  width: 22px;
  height: 22px;
  cursor: pointer;
  background-color: ${({ bgColor, theme }) =>
    bgColor && theme.colors.main.primary};
  &:hover {
    border-color: ${({ theme }) => theme.colors.border.primary};
  }
`;

export const UpperColorRecognition = styled.div`
  display: grid;
  justify-content: start;
  align-content: center;
  grid-template-columns: 4px auto;
  grid-column-gap: 12px;
`;
export const UpperColorRecognitionSection = styled.div`
  display: grid;
  align-content: center;
  grid-template-columns: auto auto;
  grid-column-gap: 30px;
  padding-right: 30px;
`;
export const ColoredBar = styled.div`
  width: 4px;
  height: 100%;
  border-radius: ${(props) => (props.MoreRadius ? `10px` : `4px`)};
  background-color: ${(props) => (props.green ? `#4FD186` : `#7BA7FF`)};
`;

export const PayrollInfoTable = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.hourly ? `2fr 1fr 1fr  1fr 260px` : `2fr 1fr 1fr  1fr 2fr 125px`};
  grid-column-gap: 10px;
  justify-content: space-between;
  padding: 0 30px;
  :nth-of-type(even) {
    background-color: #f8f9fc;
  }
  border-top: ${(props) => props.header && `1px solid #C2CCE1`};
  border-bottom: ${(props) => props.header && `1px solid #C2CCE1`};
`;

export const CheckBoxTextContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 24px auto;
  grid-column-gap: 15px;
`;
export const PaymentButtonContainer = styled.div`
  display: grid;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
`;

export const FixedPaymentContainer = styled.div`
  display: grid;
  justify-content: start;
  grid-template-columns: 1fr;
  grid-row-gap: 15px;
  min-width: 220px;
  max-width: 330px;
`;

export const PayrollHistoryTabContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  grid-column-gap: 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  padding: 0;
  margin: 20px 0px 0px;
`;
export const PayrollHistoryTab = styled.div`
  position: relative;
  top: 1px;
  padding: 8px 0;
  color: ${({ theme }) => theme.colors.main.textMain};
  cursor: pointer;
  white-space: nowrap;
  border-bottom: ${({ index, payrollHistoryType, theme }) =>
    index === payrollHistoryType && `2px solid ${theme.colors.border.primary}`};

  &:hover {
    border-bottom: ${({ index, payrollHistoryType, theme }) =>
      index !== payrollHistoryType &&
      `2px solid ${theme.colors.border.secondary}`};
  }
`;

export const FixedAmountHistoryContainer = styled.div`
  display: grid;
  grid-template-columns: 70%;
  grid-row-gap: 10px;
  padding: 0 30px;
`;

export const FixedAmountHistorySingleItemContainer = styled.div`
  background: ${({ theme }) => theme.colors.main.whiteBg};
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  padding: 20px;
`;

export const FixedAmountUpperSection = styled.div`
  display: grid;
  grid-template-columns: 30% 30% auto;
  grid-column-gap: 20px;
  align-items: center;
  margin-bottom: 20px;
`;

export const FixedAmountNoteSection = styled.div`
  display: grid;
  grid-row-gap: 8px;
  margin-top: 10px;
`;

// Payroll Summary

export const SummaryContainer = styled.div`
  width: ${({ width }) => width || "65%"};
  overflow-x: auto;
  background: ${({ theme }) => theme.colors.main.whiteBg};
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.borderRadii.card};
  margin: 20px 30px;
  padding: 20px;
`;

export const SummarySectionContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: ${({ columns }) => columns || "auto auto auto"};
`;
export const SummaryTextSection = styled.div`
  display: grid;
  grid-row-gap: ${({ multiple }) => (multiple ? `10px` : `8px`)};
`;
export const SummaryText = styled.div`
  font-size: ${({ multiple }) => (multiple ? "14px" : "15px")};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ color, total, theme }) =>
    color
      ? color
      : total
      ? theme.colors.main.primary
      : theme.colors.main.textMain};
`;
export const SummaryLabel = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.main.labelText};
`;

export const SummaryMultipleTableSection = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: ${({ columns }) => columns || "2fr 1fr 1fr"};
`;

// require approval
export const RequireTooltipContainer = styled.div`
  position: relative;
  display: none;
`;
export const RequireApprovalDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, isRequired }) =>
    isRequired ? theme.colors.main.success : theme.colors.main.grayMedium};
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  height: 16px;
  width: 16px;

  &:hover {
    ${RequireTooltipContainer} {
      display: flex;
    }
  }
`;
