import { memo, useRef, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { CommonText } from "../../../../styledComponents/common";
import { ActivityTextSection } from "../../../../styledComponents/members";
import {
  TimeSlotCard,
  IdleTimeDetailsDiv,
  IdleTimeDetailsRow,
  IdleTimePopupHeader,
  IdleTimePopupTotalSection,
  TimesheetTooltipContainer,
  IdleDeleteButton,
} from "../../../../styledComponents/timesheet";

import {
  getZeroHourTime,
  getDurationInMinutes,
  getDuration,
} from "../helperFunctions";

import CommonTooltip from "../../../../components/Tooltip/CommonTooltip";

import deleteWhite from "../../../../assets/img/icons/delete_white.svg";

export const IdleTimeSlot = memo(
  ({
    index,
    timesheetIndex,
    timesheetDuration,
    idleTimeData,
    startPosition,
  }) => (
    <TimeSlotCard
      key={index}
      index={timesheetIndex}
      top={idleTimeData.top}
      height={idleTimeData.height}
      startPosition={startPosition}
      bgColor={
        getDurationInMinutes(timesheetDuration) < 20 ? "#FE5969" : "#FAEAEA"
      }
    />
  )
);

const SingleIdleTimeRow = ({
  idleTimeData,
  project,
  task,
  handleDeleteIdleTime,
}) => {
  const { start_timestamp, end_timestamp, duration } = idleTimeData;
  return (
    <IdleTimeDetailsRow>
      <ActivityTextSection>
        <CommonText name fontSize="12px" fontWeight="600">
          {project.name}
        </CommonText>
        <CommonText fontSize="12px">{task ? task.name : "No task"}</CommonText>
      </ActivityTextSection>
      <ActivityTextSection>
        <CommonText name fontSize="12px" fontWeight="600">
          {start_timestamp.format("h:mm A")}
        </CommonText>
      </ActivityTextSection>
      <ActivityTextSection>
        <CommonText name fontSize="12px" fontWeight="600">
          {end_timestamp.format("h:mm A")}
        </CommonText>
      </ActivityTextSection>
      <ActivityTextSection>
        <CommonText name fontSize="12px" fontWeight="600">
          {getZeroHourTime(duration)}
        </CommonText>
      </ActivityTextSection>
      <IdleDeleteButton
        onClick={() =>
          handleDeleteIdleTime(start_timestamp, end_timestamp, duration)
        }
      >
        <img
          src={deleteWhite}
          style={{ width: "14px", height: "14px" }}
          alt="delete"
        />
        <TimesheetTooltipContainer>
          <CommonTooltip
            tooltipText="Delete"
            left="-50px"
            top="-46px"
            width="85px"
            height="25px"
          />
        </TimesheetTooltipContainer>
      </IdleDeleteButton>
    </IdleTimeDetailsRow>
  );
};

export const IdleTimeDetailsPopup = memo(
  ({
    index,
    idleTimes,
    timesheetId,
    project,
    task,
    timesheetTop,
    height,
    startPosition,
    toggleDeleteTimeModal,
    setIdleTimePopup,
  }) => {
    const popUpRef = useRef(null);

    useEffect(() => {
      function handleClickOutside(event) {
        if (popUpRef.current && !popUpRef.current.contains(event.target)) {
          setIdleTimePopup(-1);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }, [setIdleTimePopup]);

    const totalIdleTime = useMemo(() => {
      if (!idleTimes?.length) return 0;

      return idleTimes.reduce(
        (total, idleTime) => total + idleTime.duration,
        0
      );
    }, [idleTimes]);

    const handleDeleteIdleTime = (startTime, endTime, duration) => {
      const deleteTimeData = {
        id: timesheetId,
        start_time: startTime,
        end_time: endTime,
        duration: duration,
      };
      toggleDeleteTimeModal(deleteTimeData);
    };

    return (
      <TimeSlotCard
        index={index}
        top={timesheetTop - height - 15}
        height={height}
        startPosition={startPosition}
        bgColor="#F5F9FD"
        borderColor="#7F9DBF"
        style={{
          left: "200px",
          zIndex: "5",
        }}
        ref={popUpRef}
      >
        <IdleTimePopupHeader>
          <IdleTimePopupTotalSection>
            <CommonText name fontWeight="600">
              Total Idle Time
            </CommonText>
            <CommonText color="#FE5969" fontWeight="600" margin="0 0 0 8px">
              {getZeroHourTime(totalIdleTime)}
            </CommonText>
          </IdleTimePopupTotalSection>
        </IdleTimePopupHeader>
        <IdleTimeDetailsDiv>
          {idleTimes.map((idleTimeData, index) => (
            <SingleIdleTimeRow
              key={index}
              idleTimeData={idleTimeData}
              project={project}
              task={task}
              handleDeleteIdleTime={handleDeleteIdleTime}
            />
          ))}
        </IdleTimeDetailsDiv>
      </TimeSlotCard>
    );
  }
);

// PropTypes
IdleTimeSlot.propTypes = {
  index: PropTypes.number.isRequired,
  timesheetIndex: PropTypes.number.isRequired,
  idleTimeData: PropTypes.shape({
    top: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  startPosition: PropTypes.number.isRequired,
};

IdleTimeDetailsPopup.propTypes = {
  index: PropTypes.number.isRequired,
  idleTimes: PropTypes.array.isRequired,
  project: PropTypes.object,
  task: PropTypes.object,
  timesheetTop: PropTypes.number.isRequired,
  startPosition: PropTypes.number.isRequired,
  setIdleTimePopup: PropTypes.func.isRequired,
};

// Display names for debugging
IdleTimeSlot.displayName = "IdleTimeSlot";
IdleTimeDetailsPopup.displayName = "IdleTimeDetailsPopup";
