import { useState, useEffect } from "react";
import Select from "../../components/ReactSelectDropdown";
import FilterDropDownStyle from "../../components/DropdownStyle/FilterDropDownStyle";
import InvoiceTable from "./InvoiceTable";
import { FilterContainer } from "../../styledComponents/invoice";
import {
  PageTitle,
  InputWithLabelSection,
  FilterLabel,
  InputField,
} from "../../styledComponents/common";
import {
  ColoredButtonWithIcon,
  ButtonIcon,
} from "../../styledComponents/buttons";

import TopPagination from "../../components/Pagination/TopPagination/TopPagination";
import BottomPagination from "../../components/Pagination/BottomPagination/BottomPagination";

import Plus from "../../assets/img/icons/plus_white.svg";

const Invoice = ({
  selectedOrganization,
  getClientList,
  clientList,
  getInvoiceList,
  sendInvoiceEmailInfo,
  recordPaymentInfo,
  invoiceList,
  deletedInvoiceCallback,
  history,
  isLoading,
  sendMailLoading,
  recordPaymentLoading,
  deleteInvoiceLoading,
  recordPaymentInvoice,
  createInvoiceUpdateSendLog,
  updateInvoiceSendLog,
  sendInvoiceEmail,
  profileData,
  getClientDetails,
  clientDetails,
  deleteInvoice,
  noData,

  // pagination
  totalInvoicesCount,
  invoicePageSize,

  reloadInvoiceList,
}) => {
  const [clientOptions, setClientOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  const [selectedStatus, setSelectedStatus] = useState({
    value: "",
    label: "All",
  });
  const statusOptions = [
    { value: "", label: "All" },
    { value: "draft", label: "Draft" },
    { value: "sent", label: "Sent" },
    { value: "paid", label: "Paid" },
    { value: "partial", label: "Partially Paid" },
  ];
  const [sentState, setSentState] = useState(false);
  const [recordPaymentState, setRecordPaymentState] = useState(false);
  const [deleted, setDeleted] = useState(false);

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      setCurrentPageNumber(1);
      const payload = {
        organization_id: selectedOrganization.id,
      };
      // getInvoiceList(payload);
      getClientList(payload);
    }
  }, [selectedOrganization]);

  const callGetInvoiceList = () => {
    const payload = {
      organization_id: selectedOrganization.id,
      page: currentPageNumber,
    };
    if (selectedClient && selectedClient.value) {
      payload.client_id = selectedClient.value;
    }
    if (selectedStatus && selectedStatus.value) {
      payload.status = selectedStatus.value;
    }
    getInvoiceList(payload);
  };

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      callGetInvoiceList();
    }
  }, [
    selectedClient,
    selectedStatus,
    sentState,
    recordPaymentState,
    deleted,
    currentPageNumber,
    selectedOrganization,
    reloadInvoiceList,
  ]);

  useEffect(() => {
    if (clientList && clientList.length > 0) {
      const options = clientList
        .filter((item) => item.is_active)
        .map((item) => ({
          value: item.id,
          label: item.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
        
      options.unshift({ label: "All Clients", value: "" });
      setClientOptions(options);
    } else if (clientList && clientList.length === 0) {
      setClientOptions([]);
    }
  }, [clientList]);

  // useEffect(() => {
  //   if (
  //     selectedOrganization &&
  //     selectedOrganization.id &&
  //     sendInvoiceEmailInfo &&
  //     sendInvoiceEmailInfo.log_id &&
  //     sentState
  //   ) {
  //     const payload = {
  //       organization_id: selectedOrganization.id,
  //       background: true,
  //     };
  //     const timer = setTimeout(() => {
  //       getInvoiceList(payload);
  //     }, 4000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [sendInvoiceEmailInfo]);

  useEffect(() => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      recordPaymentInfo &&
      recordPaymentInfo.id &&
      recordPaymentState
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        background: true,
        page: currentPageNumber,
      };
      getInvoiceList(payload);
    }
  }, [recordPaymentInfo]);

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
  };

  const onClientSelect = (e) => {
    setCurrentPageNumber(1);
    setSelectedClient(e);
  };

  // useEffect(() => {
  //   if (
  //     selectedOrganization &&
  //     selectedOrganization.id &&
  //     deletedInvoiceCallback &&
  //     deleted
  //   ) {
  //     const payload = {
  //       organization_id: selectedOrganization.id,
  //     };
  //     getInvoiceList(payload);
  //   }
  // }, [deletedInvoiceCallback]);

  const onStatusSelect = (e) => {
    setCurrentPageNumber(1);
    setSelectedStatus(e);
  };

  const onSend = () => {
    setSentState(true);
  };

  const onRecordPay = () => {
    setRecordPaymentState(true);
  };

  const onDelete = () => {
    setDeleted(true);
  };

  return (
    <div className="content">
      <PageTitle>Invoices</PageTitle>
      <FilterContainer>
        <InputWithLabelSection>
          <FilterLabel>Client</FilterLabel>
          <Select
            value={selectedClient}
            options={clientOptions}
            onChange={(e) => onClientSelect(e)}
            placeholder="Select Client..."
            styles={FilterDropDownStyle({ height: "40px" })}
          />
        </InputWithLabelSection>
        <InputWithLabelSection>
          <FilterLabel>Status</FilterLabel>
          <Select
            isSearchable
            value={selectedStatus}
            options={statusOptions}
            onChange={(e) => {
              onStatusSelect(e);
            }}
            styles={FilterDropDownStyle({ height: "40px" })}
          />
        </InputWithLabelSection>
        <ColoredButtonWithIcon
          style={{ alignSelf: "flex-end" }}
          margin="0 0 0 auto"
          onClick={() => history.push("/user/create-invoice")}
        >
          <ButtonIcon src={Plus} />
          Create New Invoice
        </ColoredButtonWithIcon>
      </FilterContainer>

      {totalInvoicesCount && totalInvoicesCount > 0 && invoicePageSize ? (
        <TopPagination
          itemName="invoices"
          totalCount={totalInvoicesCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={invoicePageSize}
        />
      ) : null}

      {selectedOrganization && selectedOrganization.id && profileData && (
        <InvoiceTable
          isLoading={isLoading}
          sendMailLoading={sendMailLoading}
          recordPaymentLoading={recordPaymentLoading}
          deleteInvoiceLoading={deleteInvoiceLoading}
          selectedOrganization={selectedOrganization}
          invoiceList={invoiceList}
          history={history}
          recordPaymentInvoice={recordPaymentInvoice}
          createInvoiceUpdateSendLog={createInvoiceUpdateSendLog}
          updateInvoiceSendLog={updateInvoiceSendLog}
          sendInvoiceEmail={sendInvoiceEmail}
          senderName={profileData && profileData.first_name}
          getClientDetails={getClientDetails}
          clientDetails={clientDetails}
          deleteInvoice={deleteInvoice}
          currentPageNumber={currentPageNumber}
          onSend={onSend}
          onRecordPay={onRecordPay}
          onDelete={onDelete}
          noData={noData}
          selectedClient={selectedClient}
          selectedStatus={selectedStatus}
        />
      )}

      {totalInvoicesCount && totalInvoicesCount > 0 && invoicePageSize ? (
        <BottomPagination
          totalCount={totalInvoicesCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={invoicePageSize}
        />
      ) : null}
    </div>
  );
};

export default Invoice;
