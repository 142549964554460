import { connect } from "react-redux";
import AuthLoader from "./AuthLoader";

import { getAllIntegrationsList } from "../integrationsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,

  // isLoading: state.integrations.isLoading,
  allIntegrationsList: state.integrations.allIntegrationsList,
  // integrationWorkflowsList: state.integrations.integrationWorkflowsList,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getAllIntegrationsList: (payload) =>
    dispatch(getAllIntegrationsList(payload)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(AuthLoader);
