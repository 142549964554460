import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { AuthContainer } from "./authLoaderStyles";

import { CommonText } from "../../../styledComponents/common";

import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";

import { AuthHandler } from "../integrationHandler/authHandler/authHandlerService";

const AuthLoader = (props) => {
  const { name } = useParams();

  const {
    history,
    getAllIntegrationsList,
    allIntegrationsList,
    selectedOrganization,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = { organization_id: selectedOrganization.id };
      getAllIntegrationsList(payload);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      allIntegrationsList &&
      allIntegrationsList.length > 0
    ) {
      let integrationId = null;
      let integrationName = null;
      let stepsData = null;
      allIntegrationsList.some((integration) => {
        if (integration.name === name) {
          integrationId = integration.id;
          integrationName = integration.name;
          stepsData = integration.frontend_steps;
          return true;
        }
        return false;
      });
      if (integrationId && stepsData && stepsData.length > 0) {
        const data = {
          organization_id: selectedOrganization.id,
          integration_id: integrationId,
          integration_name: integrationName,
        };
        const authHandler = new AuthHandler(stepsData, data);
        (async () => {
          const result = await authHandler.run();
          if (result.isInstalled) {
            history.push(`/user/integrations/${integrationId}/setup/`);
          } else {
            history.push("/user/integrations");
          }
        })();
      } else {
        history.push("/user/integrations");
      }
    }
  }, [allIntegrationsList]);

  return (
    <div className="content">
      <AuthContainer>
        <ComponentCircleLoader padding="15px 0" />
        <CommonText fontSize="20px" fontWeight="600" margin="10px 0">
          Sit tight, installation might take a while
        </CommonText>
        <CommonText $label margin="0" fontSize="14px" fontWeight="500">
          we are preparing things ready for you
        </CommonText>
      </AuthContainer>
    </div>
  );
};

export default AuthLoader;
