import { connect } from "react-redux";
import InvoiceClientView from "./InvoiceClientView";

// Actions
import {
    getInvoiceDetails,
  } from "../invoiceActions";
  
  // Store props to map with current state
  const mapStateToProps = (state) => ({
    selectedOrganization: state.organization.selectedOrganization,
    isLoading: state.invoice.isLoading,
    invoiceDetails: state.invoice.invoiceDetails,
  });

  // Actions prop to dispatch
  const mapDispatchToProps = (dispatch) => ({
    getInvoiceDetails: (details) => dispatch(getInvoiceDetails({ ...details })),
  });

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceClientView);