//Effects
import { put, takeLatest } from "redux-saga/effects"; //axios
// import axios from 'axios';
//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  LOGIN_REQUEST,
  LOGIN_REQUEST_SUCCESS,
  LOGIN_REQUEST_FAILURE
} from "../../modules/constants";

//Handle login request
export function* loginRequest({ payload }) {
  try {
    let url = "auth/login/";
    let details = {
      username_or_email: payload.username,
      password: payload.password
    };
    const response = yield defaultApi(url, "POST", details);
    yield put({
      type: LOGIN_REQUEST_SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put({
      type: LOGIN_REQUEST_FAILURE,
      payload: err
    });
  }
}

// Watches for the LOGIN_REQUEST action type
// WITH the action we dispatched
function* loginRequestWatcher() {
  // takeLatest() takes the LATEST call of that action and runs it
  // if we we're to use takeEvery, it would take every single
  // one of the actions and kick off a new task to handle it
  // CONCURRENTLY!!!
  yield takeLatest(LOGIN_REQUEST, loginRequest);
}

export default loginRequestWatcher;
