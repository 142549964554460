import IntegrationsHome from "../custom_modules/IntegrationsNew";
import IntegrationSetup from "../custom_modules/IntegrationsNew/Setup";
import IntegrationDetails from "../custom_modules/IntegrationsNew/Details";

// auth
import AuthLoader from "../custom_modules/IntegrationsNew/Auth";

const integrationsRoutes = [
  {
    name: "Integrations",
    path: "/",
    component: IntegrationsHome,
    exact: true,
  },
  {
    name: "Integration Authorization",
    path: "/:name/auth-setup",
    component: AuthLoader,
    exact: true,
  },
  {
    name: "Integration Setup",
    path: "/:id/setup",
    component: IntegrationSetup,
  },
  {
    name: "Integration Details",
    path: "/:enabled_integration_id/details",
    component: IntegrationDetails,
  },
];

export default integrationsRoutes;
