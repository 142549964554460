import queryString from "query-string";

import { defaultApi } from "../../../../utils/axiosApi";

import { createNotification } from "../../../../modules/notificationManager";

const httpExtract = async () => {
  const url = window.location;
  const result = {};
  const parsed = queryString.parse(url.search);

  if (parsed && parsed.code) {
    result.code = parsed.code;
  }

  return result;
};

const installIntegrationRequest = async (code, data) => {
  const { organization_id, integration_id } = data;
  const url = `${organization_id}/integrations/${integration_id}/install/`;
  const payload = { data: { code } };

  try {
    const response = await defaultApi(url, "POST", payload);
    if (response && response.status === 200) {
      return true;
    }
  } catch (err) {
    createNotification(
      "error",
      "Sorry! Could not install clickup integration. Please try again.",
      3000
    );
    return false;
  }
};

export const executeClickupSteps = async (data) => {
  const { code } = await httpExtract();
  const isInstalled = await installIntegrationRequest(code, data);

  return { isInstalled };
};
