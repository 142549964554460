import { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";
import moment from "moment";

import { ModalButtonSection } from "../../styledComponents/members";
import { CommonText } from "../../styledComponents/common";
import { PrimaryButton, WhiteButton } from "../../styledComponents/buttons";

import { currencySymbolMap } from "../../utils/currencies";

import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";

const PaymentDataSection = styled.div`
  display: flex;
  justify-content: space-between;
  grid-column-gap: 25px;
  align-items: center;
  border-bottom: 1px solid #e4e7eb;
  padding: 20px;
`;

const TextSection = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: rgba(47, 57, 78, 0.7);
`;

const ConfirmPaymentModal = ({
  isOpen,
  isLoading,
  toggle,
  paymentType,
  singlePaymentId,
  multiplePaymentIndex,
  sendSingleHourlyPayment,
  sendSingleDateMultiplePayment,
  markAllAsPaid,
  payrollData,
  payablesData,
  startDate,
  endDate,
}) => {
  const [paymentData, setPaymentData] = useState(null);

  useEffect(() => {
    if (
      paymentType === "single" &&
      singlePaymentId &&
      payablesData &&
      payablesData.length > 0
    ) {
      const singlePayment = payablesData.find(
        (item) => item.id === singlePaymentId
      );
      if (singlePayment) {
        setPaymentData({
          date: moment(singlePayment.date).format("ddd, MMM DD, YYYY"),
          totalPay: `${singlePayment.currency} (${
            currencySymbolMap[singlePayment.currency]
          }) ${(singlePayment.total_pay / 100).toFixed(2)}`,
        });
      }
    } else if (
      paymentType === "multiple" &&
      multiplePaymentIndex !== null &&
      payrollData &&
      payrollData.length > 0
    ) {
      const list = [...payrollData];
      let currency = "";
      let totalPay = 0;
      if (
        list[multiplePaymentIndex].memberList &&
        list[multiplePaymentIndex].memberList.length > 0
      ) {
        currency = list[multiplePaymentIndex].memberList[0].currency;
        list[multiplePaymentIndex].memberList.map((item) => {
          if (item.selectMember === 1) {
            totalPay += parseFloat(item.totalPay);
          }
        });
      }
      setPaymentData({
        date: list[multiplePaymentIndex].date,
        totalPay: `${currency} (${
          currencySymbolMap[currency]
        }) ${totalPay.toFixed(2)}`,
      });
    } else if (
      paymentType === "all" &&
      payablesData &&
      payablesData.length > 0
    ) {
      let totalPay = 0;
      payablesData.map((item) => {
        totalPay += item.total_pay;
      });
      setPaymentData({
        date: `${moment(startDate).format("ddd, MMM DD, YYYY")} - ${moment(
          endDate
        ).format("ddd, MMM DD, YYYY")}`,
        totalPay: `${payablesData[0].currency} (${
          currencySymbolMap[payablesData[0].currency]
        }) ${(totalPay / 100).toFixed(2)}`,
      });
    }
  }, [
    payrollData,
    payablesData,
    paymentType,
    singlePaymentId,
    multiplePaymentIndex,
  ]);

  const confirmPayment = () => {
    if (paymentType === "single" && singlePaymentId) {
      sendSingleHourlyPayment(singlePaymentId);
    } else if (paymentType === "multiple" && multiplePaymentIndex !== null) {
      sendSingleDateMultiplePayment(multiplePaymentIndex);
    } else if (paymentType === "all") {
      markAllAsPaid();
    }
    setTimeout(() => {
      toggle("");
    }, 400);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggle("")}
      style={{ marginTop: `25vh` }}
    >
      <ModalBody style={{ padding: `5px 0px` }}>
        {paymentData && (
          <PaymentDataSection>
            <CommonText>{paymentData.date}</CommonText>
            <CommonText>
              Total Pay:{" "}
              <span style={{ color: "#20bead", fontWeight: "600" }}>
                {paymentData.totalPay}
              </span>
            </CommonText>
          </PaymentDataSection>
        )}
        <CommonText style={{ padding: "20px" }}>
          Are you sure you want to confirm this payment?
        </CommonText>
        <ModalButtonSection style={{ padding: "15px 20px" }}>
          <WhiteButton type="cancel" onClick={() => toggle("")}>
            Cancel
          </WhiteButton>
          <PrimaryButton
            padding={isLoading ? "0 15px" : "0 35px"}
            onClick={() => confirmPayment()}
          >
            {isLoading ? (
              <ButtonTextLoader loadingText="Confirming" fontSize="13px" />
            ) : (
              "Confirm"
            )}
          </PrimaryButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmPaymentModal;
