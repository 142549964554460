import styled, { css } from "styled-components";

export const HistoryTableRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 280px 300px auto;
  grid-column-gap: 30px;
  justify-content: flex-start;
  padding: 0 15px;
  :nth-of-type(even) {
    background-color: #f8f9fc;
  }
  border-top: ${({ header, theme }) =>
    header && `1px solid ${theme.colors.border.secondary}`};
  border-bottom: ${({ header, theme }) =>
    header && `1px solid ${theme.colors.border.secondary}`};
`;
