import { useState, useEffect } from "react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import queryString from "query-string";

import { RedStar } from "../../../styledComponents/members";
import {
  PageTitle,
  Container,
  CardTitle,
  InputWithLabelSection,
  InputLabel,
  InputField,
  ContentHeader,
  CommonFlex,
  FormAlert,
} from "../../../styledComponents/common";
import { WhiteButton, PrimaryButton } from "../../../styledComponents/buttons";
import { AddClientContainer } from "../../../styledComponents/clients";

import { validateEmail, checkUrl, checkHtml } from "../../../utils/helper";

import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";

const EditClient = (props) => {
  const [clientId, setClientId] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [officeLocation, setOfficeLocation] = useState("");
  const [saveErrors, setSaveErrors] = useState({});

  useEffect(() => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      if (props.location.search) {
        const queryParsed = queryString.parse(props.location.search);
        setClientId(queryParsed.id);
        let payload = {
          organization_id: props.selectedOrganization.id,
          client_id: queryParsed.id,
        };
        props.getClientDetails(payload);
      }
    }
  }, [props.selectedOrganization]);

  useEffect(() => {
    if (props.clientDetails && props.clientDetails !== null) {
      setClientName(props.clientDetails.name);
      if (props.clientDetails.email) {
        setClientEmail(props.clientDetails.email);
      } else if (!props.clientDetails.email) {
        setClientEmail("");
      }
      if (props.clientDetails.phone_number) {
        setPhoneNumber(props.clientDetails.phone_number);
      } else if (!props.clientDetails.phone_number) {
        setPhoneNumber("");
      }
      if (props.clientDetails.office_location) {
        setOfficeLocation(props.clientDetails.office_location);
      } else if (!props.clientDetails.office_location) {
        setOfficeLocation("");
      }
    }
  }, [props.clientDetails]);

  const onClientNameChange = (e) => {
    const { value } = e.target;
    setClientName(value);
    if (saveErrors && saveErrors["clientName"]) {
      delete saveErrors["clientName"];
    }
  };
  const onEmailChange = (e) => {
    const { value } = e.target;
    setClientEmail(value);
    if (saveErrors && saveErrors["clientEmail"]) {
      delete saveErrors["clientEmail"];
    }
  };
  const onAddressChange = (e) => {
    const { value } = e.target;
    setOfficeLocation(value);
  };

  const cancelFunction = () => {
    props.history.push("/user/clients");
  };

  const handleSave = () => {
    if (checkError()) {
      if (props.selectedOrganization && props.selectedOrganization.id) {
        let payload = {
          organization_id: props.selectedOrganization.id,
          client_id: clientId,
          name: clientName,
          phone_number: phoneNumber
            ? phoneNumber.startsWith("+")
              ? phoneNumber
              : `+${phoneNumber}`
            : null,
          email: clientEmail ? clientEmail : null,
          office_location: officeLocation ? officeLocation : null,
          action: "update"
        };
        props.updateClient(payload);
        setTimeout(() => {
          props.history.push("/user/clients");
        }, 1200);
      }
    }
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!clientName.trim()) {
      formIsValid = false;
      saveErrors["clientName"] = "Client name is required";
    } else if (checkUrl(clientName)) {
      formIsValid = false;
      saveErrors["clientName"] = "Client name can not contain url.";
    } else if (checkHtml(clientName)) {
      formIsValid = false;
      saveErrors["clientName"] = "Invalid client name";
    }
    if (!validateEmail(clientEmail)) {
      formIsValid = false;
      saveErrors["clientEmail"] = "Please enter a valid email";
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  return (
    <div className="content">
      <PageTitle>Clients</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => props.history.push("/user/clients")}
          subTitle="Back to all clients"
        />
      </ContentHeader>
      <Container style={{ padding: `30px` }}>
        {props.isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            <CardTitle>Client Details</CardTitle>
            <AddClientContainer>
              <InputWithLabelSection>
                <InputLabel>
                  Client name <RedStar>*</RedStar>
                </InputLabel>
                <InputField
                  type="text"
                  placeholder="Write client name..."
                  value={clientName}
                  onChange={(e) => onClientNameChange(e)}
                  error={
                    saveErrors &&
                    saveErrors["clientName"] &&
                    saveErrors["clientName"] !== undefined
                  }
                />
                {saveErrors &&
                  saveErrors["clientName"] &&
                  saveErrors["clientName"] !== undefined && (
                    <FormAlert margin="0 0 0 5px">
                      {saveErrors["clientName"]}
                    </FormAlert>
                  )}
              </InputWithLabelSection>
              <InputWithLabelSection>
                <InputLabel>Email Address</InputLabel>
                <InputField
                  type="text"
                  placeholder="Write email..."
                  value={clientEmail}
                  onChange={(e) => onEmailChange(e)}
                  error={
                    saveErrors &&
                    saveErrors["clientEmail"] &&
                    saveErrors["clientEmail"] !== undefined
                  }
                />
                {saveErrors &&
                  saveErrors["clientEmail"] &&
                  saveErrors["clientEmail"] !== undefined && (
                    <FormAlert margin="0 0 0 5px">
                      {saveErrors["clientEmail"]}
                    </FormAlert>
                  )}
              </InputWithLabelSection>

              <InputWithLabelSection>
                <InputLabel>Phone Number</InputLabel>
                <PhoneInput
                  enableSearch
                  country={"us"}
                  value={phoneNumber}
                  onChange={(phone) => setPhoneNumber(phone)}
                  inputClass="phone-number-input"
                  buttonClass="phone-number-flag-dropdown"
                />
              </InputWithLabelSection>

              <InputWithLabelSection>
                <InputLabel>Office Location</InputLabel>
                <InputField
                  type="text"
                  placeholder="Write official address..."
                  value={officeLocation}
                  onChange={(e) => onAddressChange(e)}
                />
              </InputWithLabelSection>

              <CommonFlex gap="15px" margin="20px 0 0 0">
                <WhiteButton type="cancel" onClick={() => cancelFunction()}>
                  Cancel
                </WhiteButton>
                <PrimaryButton
                  padding={props.clientUpdateLoading ? "0 15px" : "0 35px"}
                  onClick={() => handleSave()}
                >
                  {props.clientUpdateLoading ? (
                    <ButtonTextLoader loadingText="Saving" fontSize="13px" />
                  ) : (
                    "Save"
                  )}
                </PrimaryButton>
              </CommonFlex>
            </AddClientContainer>
          </>
        )}
      </Container>
    </div>
  );
};

export default EditClient;
