import styled from "styled-components";

export const OrgSettingsSection = styled.div`
  padding: 30px;
`;
export const SettingPropertySection = styled.div`
  margin: ${({ margin }) => margin || "0"};
`;

export const ToggleButton = styled.div`
  display: grid;
  align-items: center;
  width: 31px;
  height: 18px;
  background-color: ${(props) => (props.active ? `#20BEAD` : `#C2CCE1`)};
  padding: 0 4px;
  border-radius: 10px;
  cursor: pointer;
`;
export const ToggleButtonRound = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  margin-left: ${(props) => props.active && `auto`};
`;

export const WarningDiv = styled.div`
  margin-top: 30px;
  width: max-content;
  background: ${({ theme }) => theme.colors.main.white};
  border: 1px solid ${({ theme }) => theme.colors.main.warning};
  border-radius: ${({ theme }) => theme.borderRadii.card};
  display: flex;
  padding: 10px;
  height: 50px;
  align-items: center;
  justify-content: start;
`;

export const ScreenshotFrequencyButton = styled.div`
  width: ${({ width }) => width || "120px"};
  height: ${({ height }) => height || "40px"};
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.main.primary : theme.colors.main.primaryLight};
  border-radius: ${({ theme }) => theme.button.borderRadius};
  cursor: ${({ isActive }) => (isActive ? "not-allowed" : "pointer")};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.main.white : theme.colors.main.textSecondary};
  font-size: ${({ fontSize }) => fontSize || "13px"};
  padding: ${({ padding }) => padding || "0"};
  border: 1px solid
    ${({ isActive, theme }) =>
      isActive ? theme.colors.main.primary : theme.colors.main.primaryLight};
  &:hover {
    border-color: ${({ theme }) => theme.colors.border.primary};
  }

  & span {
    color: ${({ theme, isActive }) => !isActive && theme.colors.main.labelText};
    font-size: 11px;
    margin: 3px 0 0 2px;
  }
`;

// idle time
export const IdleTimeCollapseContent = styled.div`
  height: ${({ isActive }) => (isActive ? "auto" : "0")};
  overflow: ${({ isActive }) => (isActive ? "visible" : "hidden")};
  transition: all 0.5s ease;
`;
export const IdleTimeDescription = styled.div`
  background-color: ${({ theme }) => theme.colors.main.warningLight};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  width: max-content;
  padding: 10px 20px;
  margin: 20px 0 0;
`;
export const IdleTimeInput = styled.input`
  height: 36px;
  max-width: 50px;
  text-align: center;
  background-color: ${({ theme, error }) =>
    error ? theme.colors.main.errorLight : theme.colors.main.white};
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors.border.error : theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.borderRadii.input};
  padding: 0 10px;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.main.textSecondary};
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: ${({ fontSize, theme }) =>
      fontSize || theme.input.placeholder.size};
    color: ${({ theme }) => theme.input.placeholder.color};
  }
  :-ms-input-placeholder {
    font-size: ${({ fontSize, theme }) =>
      fontSize || theme.input.placeholder.size};
    color: ${({ theme }) => theme.input.placeholder.color};
  }

  &:hover {
    border-color: ${({ hoverBorderColor, theme, error }) =>
      !error && (hoverBorderColor || theme.input.borderColor.hover)};
    box-shadow: ${({ theme, error }) => !error && theme.input.focusShadow};
  }
  &:focus {
    outline: none;
    border-color: ${({ focusBorderColor, theme, error }) =>
      !error && (focusBorderColor || theme.input.borderColor.focus)};
    box-shadow: ${({ theme, error }) => !error && theme.input.focusShadow};
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;
