import { useState, useEffect, useRef, useCallback } from "react";
import queryString from "query-string";
import styled from "styled-components";
import base64 from "base-64";
import { Helmet } from "react-helmet";
import TagManager from "react-gtm-module";
import {
  validateEmail,
  password_validate,
  checkUrl,
  checkHtml,
} from "../../utils/helper";
import { createNotification } from "../../modules/notificationManager";
import api from "../../utils/api";
import {
  AuthPageContainer,
  AuthPageRightContainer,
  AuthCard,
  LoginTabContainer,
  LoginActiveTab,
  LoginInactiveTab,
  AuthForm,
  AuthInputContainer,
  ShowPasswordIcon,
  AuthSubmitButton,
  GoogleSignInButton,
  GoogleIcon,
  GoogleButtonText,
  SignUpNameContainer,
  TermsAndConditionsSection,
  AuthFormAlert,
  TermsAndConditionsLink,
} from "../../styledComponents/authLayout";
import { LoginLoaderContainer } from "../../styledComponents/login";
import LoginLoader from "../Login/LoginLoader";

import PasswordStrengthChecker from "../../components/PasswordStrengthChecker/PasswordStrengthChecker";

import TextInput from "../../components/FloatingLabelInput/FloatingLabelInput";

import AuthPageLeftSection from "../../components/AuthLayout/AuthPageLeftSection";

import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";

import GoogleLogo from "../../assets/img/icons/google_logo.svg";
import viewPassword from "../../assets/img/common/password_show.svg";
import hidePassword from "../../assets/img/common/password_hide.svg";

const Card = styled(AuthCard)`
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 576px) {
    padding: 0;
  }
`;

const CardUpperContainer = styled.div`
  padding: 80px 7% 25px;
  @media screen and (max-width: 576px) {
    padding: 50px 5% 25px;
  }
`;

const SignUp = (props) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [timezone, setTimezone] = useState("");
  const [invitedEmail, setInvitedEmail] = useState("");
  const [emailGiven, setEmailGiven] = useState(false);
  const [invitedKey, setInvitedKey] = useState("");
  const [invitedRequireSignUp, setInvitedRequireSignUp] = useState(false);
  const [saveErrors, setSaveErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [passwordIsFocused, setPasswordIsFocused] = useState(false);
  const passwordFieldRef = useRef(null);
  const [advertiseState, setAdvertiseState] = useState(null);
  const [query, setQuery] = useState(null);
  const methods = {
    setFirstName,
    setLastName,
    setEmail,
    setPassword,
  };
  const {
    location,
    history,
    signup,
    signUp,
    resetSignUp,
    fromAdvertise,
    advertise,
  } = props;

  const tagManagerArgs = {
    gtmId: "GTM-NH9X5RJV",
  };

  const loadRecaptcha = () => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    script.id = "recaptcha-script";
    script.async = true;

    document.body.append(script);
  };

  const removeRecaptcha = () => {
    const script = document.getElementById("recaptcha-script");
    if (script) {
      script.remove();
    }

    const recaptchaElements =
      document.getElementsByClassName("grecaptcha-badge");
    if (recaptchaElements.length > 0) {
      recaptchaElements[0].remove();
    }
  };

  useEffect(() => {
    let token = localStorage.getItem("access_token");
    if (token != null) {
      setIsLoaderOpen(true);
      api({
        url: `onboarding/check/`,
        method: "GET",
      })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.show_onboard) {
              history.push("/setup/add-or-join");
            } else {
              history.push("/user/dashboard");
            }
          } else if (response.status === 401) {
            localStorage.clear();
            history.push("/auth/login");
          }
          setIsLoaderOpen(false);
        })
        .catch((err) => {
          history.push("/user/dashboard");
          setIsLoaderOpen(false);
        });
    } else {
      if (location && location.search && location.search.length > 0) {
        const queryParsed = queryString.parse(location.search);
        setQuery(queryParsed);
        if (queryParsed.key) {
          try {
            let key = queryParsed.key;
            let payload = JSON.parse(base64.decode(key.split(".")[1]));
            if (payload && payload.signup) {
              //window.location.href = "/activate-membership/" + encodedSring;
              history.push(`/accept-invitation?key=${key}`);
            } else {
              setInvitedRequireSignUp(true);
              setInvitedEmail(payload.email);
              setEmail(payload.email);
              setInvitedKey(key);
            }
          } catch (error) {
            createNotification(
              "error",
              "Malformed url. You will not be able to join your desired organization.",
              5000
            );
          }
        } else if (queryParsed.email) {
          setEmail(queryParsed.email);
          setEmailGiven(true);
        }

        // check if user is coming from google ads
        if (queryParsed.gclid || queryParsed.utmSource) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "signupPage",
            page: location.pathname + location.search, // Send the current URL as part of the event
          });
          setAdvertiseState("google_ads");
        }
      }
      setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);

      // load recaptcha
      loadRecaptcha();
    }

    return () => {
      removeRecaptcha();
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    if (signup.redirect) {
      if (invitedRequireSignUp) {
        if (signup.token && signup.userData) {
          localStorage.setItem("access_token", signup.token);
          localStorage.setItem("user_id", signup.userData.user_id);
          localStorage.setItem("user_profile", signup.userData.profile_id);
          localStorage.setItem(
            "user_fullName",
            `${signup.userData.first_name} ${signup.userData.last_name}`.trim()
          );
          localStorage.setItem("email", signup.userData.email);
          if (signup.userData.color) {
            localStorage.setItem("user_profile_color", signup.userData.color);
          }
          setTimeout(() => {
            history.push({
              pathname: "/user/dashboard",
              state: { message: "user-onboard" },
            });
          }, 1000);
        } else {
          history.push(`/auth/login`);
          createNotification("success", "Please login to continue.", 3000);
        }

        resetSignUp();
      } else {
        if (query && (query.gclid || query.utmSource)) {
          history.push(
            `/auth/verify-code?email=${encodeURIComponent(
              email
            )}&name=${firstName}&advertise=google`
          );
        } else {
          history.push(
            `/auth/verify-code?email=${encodeURIComponent(
              email
            )}&name=${firstName}`
          );
        }
        resetSignUp();
      }
    }
    // eslint-disable-next-line
  }, [signup]);

  useEffect(() => {
    // Moving cursor to the end
    passwordFieldRef.current.selectionStart =
      passwordFieldRef.current.value.length;
    passwordFieldRef.current.selectionEnd =
      passwordFieldRef.current.value.length;
  }, [showPassword]);

  const onTextChange = (e) => {
    const { name, value } = e.target;
    let saveErrors = {};
    if (name === "email") {
      if (!invitedRequireSignUp) {
        if (!validateEmail(value.trim())) {
          saveErrors["email"] = "Please enter a valid email address";
        }
      } else {
        return;
      }
    }
    methods[name](value);
    setSaveErrors(saveErrors);
  };
  const checkError = useCallback(() => {
    let saveErrors = {};
    let formIsValid = true;
    if (!firstName.trim()) {
      formIsValid = false;
      saveErrors["name"] = "First Name Required";
    } else if (firstName.length > 128) {
      formIsValid = false;
      saveErrors["name"] = "First name can not be more than 128 characters.";
    } else if (checkUrl(firstName)) {
      formIsValid = false;
      saveErrors["name"] = "First name can not be url.";
    } else if (checkHtml(firstName)) {
      formIsValid = false;
      saveErrors["name"] = "Invalid first name.";
    }
    if (lastName && lastName.length > 128) {
      formIsValid = false;
      saveErrors["name"] = "Last name can not be more than 128 characters.";
    } else if (checkUrl(lastName)) {
      formIsValid = false;
      saveErrors["name"] = "Last name can not be url.";
    } else if (checkHtml(lastName)) {
      formIsValid = false;
      saveErrors["name"] = "Invalid last name.";
    }
    if (!password) {
      formIsValid = false;
      saveErrors["password"] = "Password Required";
    } else if (password.length > 32) {
      formIsValid = false;
      saveErrors["password"] = "Password can not be more than 32 characters.";
    } else if (password_validate(password) === false) {
      formIsValid = false;
    }
    if (!email && !invitedRequireSignUp) {
      formIsValid = false;
      saveErrors["email"] = "Email Required";
    } else if (!invitedEmail) {
      if (!validateEmail(email.trim())) {
        formIsValid = false;
        saveErrors["email"] = "Please enter a valid email address";
      }
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  }, [
    firstName,
    lastName,
    email,
    password,
    invitedRequireSignUp,
    invitedEmail,
  ]);

  const handleSignUp = useCallback(
    async (e) => {
      e.preventDefault();
      if (checkError()) {
        grecaptcha.ready(function () {
          grecaptcha
            .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
              action: "signup",
            })
            .then(function (token) {
              let payload = {
                first_name: firstName,
                email: invitedRequireSignUp ? invitedEmail : email,
                password: password,
                recaptcha_token: token,
              };
              if (lastName) {
                payload.last_name = lastName;
              }
              if (timezone) {
                payload.timezone = timezone;
              }
              if (invitedRequireSignUp) {
                payload.key = invitedKey;
              }
              if (advertiseState) {
                payload.sign_up_source = advertiseState;
              }
              signUp(payload);
            })
            .catch((err) => {
              const errors = { ...saveErrors };
              errors["email"] = "Please try again!";
              setSaveErrors({ ...errors });
            });
        });
      }
    },
    [
      checkError,
      firstName,
      lastName,
      email,
      password,
      invitedRequireSignUp,
      invitedEmail,
      invitedKey,
      timezone,
      signUp,
    ]
  );

  const toggleShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
    passwordFieldRef.current.focus();
  }, [showPassword, passwordFieldRef]);

  if (isLoaderOpen) {
    return (
      <LoginLoaderContainer>
        <LoginLoader />
      </LoginLoaderContainer>
    );
  }
  return (
    <AuthPageContainer>
      <Helmet>
        <script type="text/javascript">
          {(function () {
            var cdx = document.createElement("script");
            cdx.type = "text/javascript";
            cdx.async = true;
            cdx.src = "https://trk.crozdesk.com/RhfdaLENEesgxi_-YrXo";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(cdx, s);
          })()}
        </script>
      </Helmet>
      <AuthPageLeftSection />
      <AuthPageRightContainer>
        <Card>
          <CardUpperContainer>
            <LoginTabContainer>
              <LoginInactiveTab to="/auth/login">Sign In</LoginInactiveTab>
              <LoginActiveTab>Sign Up</LoginActiveTab>
            </LoginTabContainer>
            <AuthForm page="signUp" onSubmit={(e) => handleSignUp(e)}>
              <AuthInputContainer>
                <SignUpNameContainer>
                  <TextInput
                    width="45%"
                    id="firstName"
                    name="setFirstName"
                    label="First name"
                    type="text"
                    onChange={(e) => onTextChange(e)}
                    value={firstName}
                    required
                    autoFocus
                  />
                  <TextInput
                    width="45%"
                    id="lastName"
                    name="setLastName"
                    label="Last name"
                    type="text"
                    onChange={(e) => onTextChange(e)}
                    value={lastName}
                  />
                </SignUpNameContainer>
              </AuthInputContainer>
              <TextInput
                id="email"
                name="setEmail"
                label="Email"
                type="text"
                disabled={invitedEmail ? true : false}
                onChange={(e) => onTextChange(e)}
                value={invitedEmail ? invitedEmail : email}
                invitedEmail={invitedEmail || emailGiven}
              />
              <AuthInputContainer>
                <TextInput
                  refs={passwordFieldRef}
                  id="password"
                  name="setPassword"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => onTextChange(e)}
                  onFocus={() => setPasswordIsFocused(true)}
                  onBlur={() => setPasswordIsFocused(false)}
                  value={password}
                />
                <ShowPasswordIcon
                  src={showPassword ? hidePassword : viewPassword}
                  alt=""
                  active={passwordIsFocused}
                  onClick={(e) => toggleShowPassword(e)}
                />
              </AuthInputContainer>
              {((password && password.length > 0) || passwordIsFocused) && (
                <PasswordStrengthChecker password={password} />
              )}
              {saveErrors &&
                Object.keys(saveErrors).length !== 0 &&
                (saveErrors.name ||
                  saveErrors.email ||
                  saveErrors.password) && (
                  <AuthFormAlert style={{ marginTop: "15px" }}>
                    {saveErrors.name || saveErrors.email || saveErrors.password}
                  </AuthFormAlert>
                )}
              <AuthSubmitButton
                type="submit"
                disabled={!firstName || !email || !password_validate(password)}
                isDisabled={
                  !firstName || !email || !password_validate(password)
                }
                marginTop={
                  saveErrors &&
                  Object.keys(saveErrors).length !== 0 &&
                  (saveErrors.name || saveErrors.email || saveErrors.password)
                    ? "11px"
                    : null
                }
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src="https://ct.capterra.com/capterra_tracker.gif?vid=2141233&vkey=cc9a1795f4d22f3d1da1023cae876a05" />
                {signup.isLoading ? (
                  <ButtonTextLoader loadingText="Creating your account" />
                ) : (
                  "Let's Go!"
                )}
              </AuthSubmitButton>
              {/* <GoogleSignInButton>
                <GoogleIcon src={GoogleLogo} alt="" />
                <GoogleButtonText>Sign Up With Google</GoogleButtonText>
              </GoogleSignInButton> */}
            </AuthForm>
          </CardUpperContainer>
          <TermsAndConditionsSection>
            <p style={{ margin: "0" }}>
              By clicking the button above, you agree to our{" "}
              <TermsAndConditionsLink
                href="https://apploye.com/terms-of-service/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                terms of services{" "}
              </TermsAndConditionsLink>{" "}
              and{" "}
              <TermsAndConditionsLink
                href="https://apploye.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                privacy policies{" "}
              </TermsAndConditionsLink>
            </p>
          </TermsAndConditionsSection>
        </Card>
      </AuthPageRightContainer>
    </AuthPageContainer>
  );
};

export default SignUp;
