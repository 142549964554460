import moment from "moment-timezone";
import {
  GET_APPS_LIST,
  GET_APPS_LIST_SUCCESS,
  GET_APPS_LIST_FAILURE,
  GET_URL_LIST,
  GET_URL_LIST_SUCCESS,
  GET_URL_LIST_FAILURE,
  GET_SCREENSHOT_LIST,
  GET_SCREENSHOT_LIST_SUCCESS,
  GET_SCREENSHOT_LIST_FAILURE,
  GET_LOCATION_LIST,
  GET_LOCATION_LIST_SUCCESS,
  GET_LOCATION_LIST_FAILURE,
  GET_ALL_NOTES,
  GET_ALL_NOTES_SUCCESS,
  GET_ALL_NOTES_FAILURE,
  GET_SCREENSHOT_NOTES,
  GET_SCREENSHOT_NOTES_SUCCESS,
  GET_SCREENSHOT_NOTES_FAILURE,
  DELETE_SCREENSHOT,
  DELETE_SCREENSHOT_SUCCESS,
  DELETE_SCREENSHOT_FAILURE,
  SORT_SCREENSHOTS,
  SORT_SCREENSHOTS_SUCCESS,
  SORT_SCREENSHOTS_FAILURE,
  ADD_NOTES,
  ADD_NOTES_SUCCESS,
  ADD_NOTES_FAILURE,
  LOGIN_PROFILE_CREATED_RESET,
  GET_USER_ACTIVITY,
  GET_USER_ACTIVITY_SUCCESS,
  GET_USER_ACTIVITY_FAILURE,
} from "../../modules/constants";
import { createNotification } from "../../modules/notificationManager";

// ------------------------------------
// Action handler methods
// ------------------------------------

const noInternetMsg =
  "No Internet Connection! Please connect to the internet and try again";

export const handleAppsListRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const handleAppsListRequestSuccess = (state, action) => {
  const {
    payload: { data, timezone },
  } = action;

  if (data.status === 200 && data.data) {
    const appsList = [...data.data];
    if (appsList && appsList.length > 0) {
      appsList.sort((a, b) => a.start_timestamp - b.start_timestamp);
      appsList.forEach((app) => {
        app.duration = app.end_timestamp - app.start_timestamp;
        if (timezone) {
          app.start_timestamp = moment.unix(app.start_timestamp).tz(timezone);
          app.end_timestamp = moment.unix(app.end_timestamp).tz(timezone);
        } else {
          app.start_timestamp = moment.unix(app.start_timestamp);
          app.end_timestamp = moment.unix(app.end_timestamp);
        }
      });
    }
    return {
      ...state,
      isLoading: false,
      apps: [...appsList],
    };
  }
  return {
    ...state,
    isLoading: false,
    apps: [],
  };
};
export const handleAppsListRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};

export const handleSortTypeChangeRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const handleSortTypeChangeRequestSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    sortType: action.payload.value,
  };
};
export const handleSortTypeChangeRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};

export const handleUrlListRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const handleUrlListRequestSuccess = (state, action) => {
  const {
    payload: { data, timezone },
  } = action;
  if (data.status === 200 && data.data) {
    const urlsList = [...data.data];
    if (urlsList && urlsList.length > 0) {
      urlsList.sort((a, b) => a.start_timestamp - b.start_timestamp);
      urlsList.forEach((url) => {
        url.duration = url.end_timestamp - url.start_timestamp;
        if (timezone) {
          url.start_timestamp = moment.unix(url.start_timestamp).tz(timezone);
          url.end_timestamp = moment.unix(url.end_timestamp).tz(timezone);
        } else {
          url.start_timestamp = moment.unix(url.start_timestamp);
          url.end_timestamp = moment.unix(url.end_timestamp);
        }
      });
    }
    return {
      ...state,
      isLoading: false,
      urls: [...urlsList],
    };
  }
  return {
    ...state,
    isLoading: false,
    urls: [],
  };
};
export const handleUrlListRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};

export const handleScreenshotListaRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    screenshots: [],
  };
};
export const handleScreenshotListaRequestSuccess = (state, action) => {
  const {
    payload: { data, timezone },
  } = action;
  if (data.status === 200 && data.data) {
    const screenshotsList = [...data.data];
    if (screenshotsList && screenshotsList.length > 0) {
      screenshotsList.sort((a, b) => a.start_timestamp - b.start_timestamp);
      screenshotsList.forEach((screenshot) => {
        if (timezone) {
          screenshot.start_timestamp = moment
            .unix(screenshot.start_timestamp)
            .tz(timezone);
          screenshot.end_timestamp = moment
            .unix(screenshot.end_timestamp)
            .tz(timezone);
        } else {
          screenshot.start_timestamp = moment.unix(screenshot.start_timestamp);
          screenshot.end_timestamp = moment.unix(screenshot.end_timestamp);
        }
        if (screenshot.screenshots && screenshot.screenshots.length > 0) {
          screenshot.screenshots.forEach((item) => {
            if (timezone) {
              item.screenshot_timestamp = moment
                .unix(item.screenshot_timestamp)
                .tz(timezone);
            } else {
              item.screenshot_timestamp = moment.unix(
                item.screenshot_timestamp
              );
            }
          });
        }
      });
    }
    return {
      ...state,
      isLoading: false,
      screenshots: [...screenshotsList],
    };
  }
  return {
    ...state,
    isLoading: false,
    screenshots: [],
  };
};
export const handleScreenshotListaRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};

export const handleScreenshotDeleteRequest = (state, action) => {
  return {
    ...state,
    deleteScreenshotIsLoading: true,
    getScreenshotList: false,
  };
};
export const handleScreenshotDeleteRequestSuccess = (state, action) => {
  createNotification("success", "Screenshot deleted successfully.", 2000);
  // let newScreenshots = state.screenshots.filter(
  //   (screenshot) => screenshot.id !== action.payload.deletedScreenshotId
  // );
  return {
    ...state,
    deleteScreenshotIsLoading: false,
    getScreenshotList: true,
    // screenshots: [...newScreenshots],
  };
};
export const handleScreenshotDeleteRequestFailure = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification("error", noInternetMsg, 3000);
  } else {
    let errorMsg;
    switch (response.status) {
      case 400:
        if (response.data.reason) {
          switch (response.data.reason[0]) {
            case "This field may not be blank.":
              errorMsg = "Reason can not be empty.";
              break;
            default:
              errorMsg = response.data.reason[0];
              break;
          }
        } else {
          errorMsg = "Could not delete screenshot! Please try again.";
        }
        break;
      default:
        errorMsg = "Could not delete screenshot! Please try again.";
    }
    if (errorMsg) {
      createNotification("error", errorMsg, 4000);
    }
  }
  return {
    ...state,
    deleteScreenshotIsLoading: false,
    getScreenshotList: false,
  };
};

export const handleLocationListaRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const handleLocationListRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;

  return {
    ...state,
    isLoading: false,
    locationsMap: data,
  };
};
export const handleLocationListRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};

export const allNotesRequest = (state, action) => {
  return {
    ...state,
    allNotesIsLoading: true,
  };
};
export const allNotesRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200 && data.data && data.data.results) {
    return {
      ...state,
      allNotesIsLoading: false,
      allNotes: data.data.results,
    };
  }
  return {
    ...state,
    allNotesIsLoading: false,
  };
};
export const allNotesRequestFailure = (state, action) => {
  return {
    ...state,
    allNotesIsLoading: false,
  };
};

export const screenshotNotesRequest = (state, action) => {
  return {
    ...state,
    allNotesIsLoading: true,
  };
};
export const screenshotNotesRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;

  return {
    ...state,
    allNotesIsLoading: false,
    screenshotNotes: data,
  };
};
export const screenshotNotesRequestFailure = (state, action) => {
  return {
    ...state,
    allNotesIsLoading: false,
  };
};

export const addNotesRequest = (state, action) => {
  return {
    ...state,
    addNoteIsLoading: true,
  };
};
export const addNotesRequestSuccess = (state, action) => {
  createNotification("success", "Note added successfully.", 3000);
  return {
    ...state,
    addNoteIsLoading: false,
    notes: "success",
  };
};
export const addNotesRequestFailure = (state, action) => {
  const {
    payload: { response },
  } = action;

  if (response === undefined) {
    createNotification("error", noInternetMsg, 3000);
  } else {
    let errorMsg;
    switch (response.status) {
      case 400:
        if (response.data.content) {
          switch (response.data.content[0]) {
            case "This field may not be blank.":
              errorMsg = "Note can not be empty.";
              break;
            default:
              errorMsg = response.data.content[0];
              break;
          }
        } else {
          errorMsg = "Something went wrong! Please try again.";
        }
        break;
      default:
        errorMsg = "Something went wrong! Please try again.";
    }
    if (errorMsg) {
      createNotification("error", errorMsg, 4000);
    }
  }
  return {
    ...state,
    addNoteIsLoading: false,
  };
};

export const handleUserActivityRequest = (state, action) => {
  return {
    ...state,
    //isLoading: true,
  };
};
export const handleUserActivityRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;

  return {
    ...state,
    //isLoading: false,
    userActivity: data,
  };
};
export const handleUserActivityRequestFailure = (state, action) => {
  return {
    ...state,
    //isLoading: false,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_APPS_LIST]: handleAppsListRequest,
  [GET_APPS_LIST_SUCCESS]: handleAppsListRequestSuccess,
  [GET_APPS_LIST_FAILURE]: handleAppsListRequestFailure,

  [GET_URL_LIST]: handleUrlListRequest,
  [GET_URL_LIST_SUCCESS]: handleUrlListRequestSuccess,
  [GET_URL_LIST_FAILURE]: handleUrlListRequestFailure,

  [GET_SCREENSHOT_LIST]: handleScreenshotListaRequest,
  [GET_SCREENSHOT_LIST_SUCCESS]: handleScreenshotListaRequestSuccess,
  [GET_SCREENSHOT_LIST_FAILURE]: handleScreenshotListaRequestFailure,

  [GET_LOCATION_LIST]: handleLocationListaRequest,
  [GET_LOCATION_LIST_SUCCESS]: handleLocationListRequestSuccess,
  [GET_LOCATION_LIST_FAILURE]: handleLocationListRequestFailure,

  [GET_ALL_NOTES]: allNotesRequest,
  [GET_ALL_NOTES_SUCCESS]: allNotesRequestSuccess,
  [GET_ALL_NOTES_FAILURE]: allNotesRequestFailure,

  [GET_SCREENSHOT_NOTES]: screenshotNotesRequest,
  [GET_SCREENSHOT_NOTES_SUCCESS]: screenshotNotesRequestSuccess,
  [GET_SCREENSHOT_NOTES_FAILURE]: screenshotNotesRequestFailure,

  [DELETE_SCREENSHOT]: handleScreenshotDeleteRequest,
  [DELETE_SCREENSHOT_SUCCESS]: handleScreenshotDeleteRequestSuccess,
  [DELETE_SCREENSHOT_FAILURE]: handleScreenshotDeleteRequestFailure,

  [SORT_SCREENSHOTS]: handleSortTypeChangeRequest,
  [SORT_SCREENSHOTS_SUCCESS]: handleSortTypeChangeRequestSuccess,
  [SORT_SCREENSHOTS_FAILURE]: handleSortTypeChangeRequestFailure,

  [ADD_NOTES]: addNotesRequest,
  [ADD_NOTES_SUCCESS]: addNotesRequestSuccess,
  [ADD_NOTES_FAILURE]: addNotesRequestFailure,

  [GET_USER_ACTIVITY]: handleUserActivityRequest,
  [GET_USER_ACTIVITY_SUCCESS]: handleUserActivityRequestSuccess,
  [GET_USER_ACTIVITY_FAILURE]: handleUserActivityRequestFailure,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  apps: [],
  urls: [],
  allNotes: [],
  screenshots: [],
  locationsMap: [],
  screenshotNotes: [],
  sortType: "OldToNew",
  isLoading: false,
  allNotesIsLoading: false,
  addNoteIsLoading: false,
  deleteScreenshotIsLoading: false,
  notes: "",
  userActivity: {},

  getScreenshotList: false,
};

export default function appsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
