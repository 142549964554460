import moment from "moment";

import {
  GET_LEAVES_LIST,
  GET_LEAVES_LIST_SUCCESS,
  GET_LEAVES_LIST_FAILURE,
  APPROVE_OR_REJECT_LEAVE,
  APPROVE_OR_REJECT_LEAVE_SUCCESS,
  APPROVE_OR_REJECT_LEAVE_FAILURE,
  CREATE_LEAVE,
  CREATE_LEAVE_SUCCESS,
  CREATE_LEAVE_FAILURE,
  LOGIN_PROFILE_CREATED_RESET,
} from "../../modules/constants";

import { createNotification } from "../../modules/notificationManager";

const getLeavesListData = (leavesDataMap, leavesList) => {
  leavesList.forEach((leave) => {
    const leaveData = {
      id: leave.id,
      createdAt: leave.created_at,
      start: leave.start,
      end: leave.end,
      status: leave.status,
      duration: leave.duration,
      message: leave.message,
      halfDay: leave.half_day,
      statusChangedAt: leave.status_changed_at,
      statusChangedBy: leave.status_changed_by,
      user: leave.user,
    };

    const date = leave.created_at;

    if (leavesDataMap.has(date)) {
      let currentData = leavesDataMap.get(date);

      currentData = [...currentData, leaveData];

      leavesDataMap.set(date, currentData);
    } else {
      leavesDataMap.set(date, [leaveData]);
    }
  });
  return leavesDataMap;
};

export const leaveListRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const leaveListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    const list = JSON.parse(JSON.stringify(data.data));
    const leavesListMap = new Map();
    const leavesListData = getLeavesListData(leavesListMap, list);

    return {
      ...state,
      isLoading: false,
      leavesList: leavesListData,
    };
  }
  return {
    ...state,
    isLoading: false,
    leavesList: null,
  };
};
export const leaveListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    leavesList: null,
  };
};

export const approveOrRejectLeaveRequestHandler = (state, action) => {
  return {
    ...state,
    approveOrRejectIsLoading: true,
  };
};
export const approveOrRejectLeaveRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    if (
      data.data.status &&
      (data.data.status === "accepted" || data.data.status === "rejected")
    ) {
      createNotification(
        "success",
        `Leave request ${data.data.status} successfully!`,
        3000
      );
    }

    const currentList = new Map(state.leavesList);

    const leaves = currentList.get(data.data.created_at);

    leaves.some((leave, index) => {
      if (leave.id === data.data.id) {
        leaves.splice(index, 1);
        return true;
      }
      return false;
    });

    if (leaves.length === 0) {
      currentList.delete(data.data.created_at);
    } else {
      currentList.set(data.data.created_at, [...leaves]);
    }

    return {
      ...state,
      approveOrRejectIsLoading: false,
      leavesList: currentList,
    };
  }
  return {
    ...state,
    approveOrRejectIsLoading: false,
  };
};
export const approveOrRejectLeaveRequestFailureHandler = (state, action) => {
  createNotification("error", "An error occured! Please try again.", 2500);
  return {
    ...state,
    approveOrRejectIsLoading: false,
  };
};

export const createLeaveRequestHandler = (state, action) => {
  return {
    ...state,
    createLeaveIsLoading: true,
  };
};
export const createLeaveRequestSuccessHandler = (state, action) => {
  const {
    payload: { data, filterStart, filterEnd },
  } = action;
  if (data.status === 201 && data.data.id) {
    createNotification(
      "success",
      `Leave application submitted successfully!`,
      3000
    );
    const isWithinRange = moment(data.data.created_at).isBetween(
      moment(filterStart),
      moment(filterEnd),
      "days",
      "[]"
    );

    const currentList = new Map(state.leavesList);

    if (isWithinRange) {
      const leaves = currentList.get(data.data.created_at);

      const leave = data.data;
      const leaveData = {
        id: leave.id,
        createdAt: leave.created_at,
        start: leave.start,
        end: leave.end,
        status: leave.status,
        duration: leave.duration,
        message: leave.message,
        halfDay: leave.half_day,
        statusChangedAt: leave.status_changed_at,
        statusChangedBy: leave.status_changed_by,
        user: leave.user,
      };

      if (leaves) {
        leaves.unshift(leaveData);
        currentList.set(data.data.created_at, leaves);
      } else {
        currentList.set(data.data.created_at, [leaveData]);
      }
    }

    return {
      ...state,
      createLeaveIsLoading: false,
      leavesList: currentList,
    };
  }
  return {
    ...state,
    createLeaveIsLoading: false,
  };
};

export const createLeaveRequestFailureHandler = (state, action) => {
  createNotification("error", "An error occured! Please try again.", 2500);
  return {
    ...state,
    createLeaveIsLoading: false,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_LEAVES_LIST]: leaveListRequestHandler,
  [GET_LEAVES_LIST_SUCCESS]: leaveListRequestSuccessHandler,
  [GET_LEAVES_LIST_FAILURE]: leaveListRequestFailureHandler,

  [APPROVE_OR_REJECT_LEAVE]: approveOrRejectLeaveRequestHandler,
  [APPROVE_OR_REJECT_LEAVE_SUCCESS]: approveOrRejectLeaveRequestSuccessHandler,
  [APPROVE_OR_REJECT_LEAVE_FAILURE]: approveOrRejectLeaveRequestFailureHandler,

  [CREATE_LEAVE]: createLeaveRequestHandler,
  [CREATE_LEAVE_SUCCESS]: createLeaveRequestSuccessHandler,
  [CREATE_LEAVE_FAILURE]: createLeaveRequestFailureHandler,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  isLoading: false,
  leavesList: null,
  approveOrRejectIsLoading: false,
};

export default function leaveReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
