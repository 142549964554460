import {
  GET_TRIAL_INFO,
  GET_TRIAL_INFO_SUCCESS,
  GET_TRIAL_INFO_FAILURE,
} from "../../modules/constants";
import { createNotification } from "../../modules/notificationManager";
// ------------------------------------
// Action handler methods
// ------------------------------------
export const getTrialInfoHandler = (state, action) => {
  return {
    ...state,
  };
};

export const getTrialInfoSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    let trialInfo = null;
    let trialEnded = false;
    if (
      data.data &&
      data.data.trial &&
      data.data.trial !== null &&
      data.data.trial.subscription &&
      data.data.trial.subscription.trial_till
    ) {
      trialInfo = data.data.trial.subscription.trial_till;
      // return {
      //   ...state,
      //   trialInfo: data.data.trial.subscription.trial_till,
      // };
    }
    if (
      data.data &&
      data.data.trial &&
      data.data.trial !== null &&
      data.data.trial.subscription &&
      (data.data.trial.subscription.status === "suspended" ||
        data.data.trial.subscription.status === "cancelled")
    ) {
      trialEnded = true;
    }
    return {
      ...state,
      trialInfo: trialInfo,
      trialEnded: trialEnded,
    };
  }
  return {
    ...state,
  };
};

export const getTrialInfoFailureHandler = (state, action) => {
  return {
    ...state,
    trialInfo: null,
    trialEnded: false,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_TRIAL_INFO]: getTrialInfoHandler,
  [GET_TRIAL_INFO_SUCCESS]: getTrialInfoSuccessHandler,
  [GET_TRIAL_INFO_FAILURE]: getTrialInfoFailureHandler,
};

// default initial state
const initialState = {
  trialInfo: null,
  trialEnded: false,
};

export default function verifyReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
