import {
  GET_GEOFENCE_CLOCK_IN_OUT_LIST,
  GET_GEOFENCE_CLOCK_IN_OUT_LIST_SUCCESS,
  GET_GEOFENCE_CLOCK_IN_OUT_LIST_FAILURE,
  LOGIN_PROFILE_CREATED_RESET,
} from "../../../modules/constants";

export const geofenceClockInOutListRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const geofenceClockInOutListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;

  return {
    ...state,
    isLoading: false,
    // geofenceClockInOutListCount: data.data.count,
    // geofenceClockInOutPageSize: data.data.page_size,
    geofenceClockInOutList: data.data,
  };
};
export const geofenceClockInOutListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    // geofenceClockInOutListCount: null,
    // geofenceClockInOutPageSize: null,
    geofenceClockInOutList: [],
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...initialState,
  };
};

const ACTION_HANDLERS = {
  [GET_GEOFENCE_CLOCK_IN_OUT_LIST]: geofenceClockInOutListRequestHandler,
  [GET_GEOFENCE_CLOCK_IN_OUT_LIST_SUCCESS]:
    geofenceClockInOutListRequestSuccessHandler,
  [GET_GEOFENCE_CLOCK_IN_OUT_LIST_FAILURE]:
    geofenceClockInOutListRequestFailureHandler,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

const initialState = {
  isLoading: false,
  geofenceClockInOutList: [],

  // pagination
  // geofenceClockInOutListCount: null,
  // geofenceClockInOutPageSize: null,
};

export default function clockInOutReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
