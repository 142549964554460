//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../../utils/axiosApi";

//Constants
import {
  GET_JOB_SITES_LIST,
  GET_JOB_SITES_LIST_SUCCESS,
  GET_JOB_SITES_LIST_FAILURE,
  ADD_JOB_SITE,
  ADD_JOB_SITE_SUCCESS,
  ADD_JOB_SITE_FAILURE,
  UPDATE_JOB_SITE,
  UPDATE_JOB_SITE_SUCCESS,
  UPDATE_JOB_SITE_FAILURE,
  DELETE_JOB_SITE,
  DELETE_JOB_SITE_SUCCESS,
  DELETE_JOB_SITE_FAILURE,
  ADD_JOB_SITE_ASSIGNEE,
  ADD_JOB_SITE_ASSIGNEE_SUCCESS,
  ADD_JOB_SITE_ASSIGNEE_FAILURE,
  REMOVE_JOB_SITE_ASSIGNEE,
  REMOVE_JOB_SITE_ASSIGNEE_SUCCESS,
  REMOVE_JOB_SITE_ASSIGNEE_FAILURE,
} from "../../../modules/constants";

export function* getJobSitesListRequest({ payload }) {
  try {
    const { organization_id } = payload;
    let url = `${organization_id}/geofence/jobsites/`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_JOB_SITES_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_JOB_SITES_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* addJobSiteRequest({ payload }) {
  try {
    const { organization_id, ...details } = payload;

    let url = `${organization_id}/geofence/jobsites/`;
    const response = yield defaultApi(url, "POST", details);
    yield put({
      type: ADD_JOB_SITE_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: ADD_JOB_SITE_FAILURE,
      payload: err,
    });
  }
}
export function* updateJobSiteRequest({ payload }) {
  try {
    const { organization_id, jobsite_id, ...details } = payload;

    const url = `${organization_id}/geofence/jobsites/${jobsite_id}/`;
    const response = yield defaultApi(url, "PATCH", details);

    yield put({
      type: UPDATE_JOB_SITE_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: UPDATE_JOB_SITE_FAILURE,
      payload: err,
    });
  }
}
export function* deleteJobSiteRequest({ payload }) {
  try {
    const { organization_id, jobsite_id } = payload;

    const url = `${organization_id}/geofence/jobsites/${jobsite_id}/`;
    const response = yield defaultApi(url, "DELETE");
    yield put({
      type: DELETE_JOB_SITE_SUCCESS,
      payload: { data: response, jobsite_id },
    });
  } catch (err) {
    yield put({
      type: DELETE_JOB_SITE_FAILURE,
      payload: err,
    });
  }
}

export function* addJobSiteAssigneeRequest({ payload }) {
  try {
    const { organization_id, jobsite_id, userData, ...details } = payload;

    let url = `${organization_id}/geofence/jobsites/${jobsite_id}/assignees/`;
    const response = yield defaultApi(url, "POST", details);
    yield put({
      type: ADD_JOB_SITE_ASSIGNEE_SUCCESS,
      payload: { data: response, jobsite_id, user: userData },
    });
  } catch (err) {
    yield put({
      type: ADD_JOB_SITE_ASSIGNEE_FAILURE,
      payload: err,
    });
  }
}
export function* removeJobSiteAssigneeRequest({ payload }) {
  try {
    const { organization_id, jobsite_id, user_id } = payload;

    let url = `${organization_id}/geofence/jobsites/${jobsite_id}/assignees/${user_id}/`;
    const response = yield defaultApi(url, "DELETE");
    yield put({
      type: REMOVE_JOB_SITE_ASSIGNEE_SUCCESS,
      payload: { data: response, jobsite_id, user_id },
    });
  } catch (err) {
    yield put({
      type: REMOVE_JOB_SITE_ASSIGNEE_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_JOB_SITES_LIST, getJobSitesListRequest),
    takeLatest(ADD_JOB_SITE, addJobSiteRequest),
    takeLatest(UPDATE_JOB_SITE, updateJobSiteRequest),
    takeLatest(DELETE_JOB_SITE, deleteJobSiteRequest),
    takeLatest(ADD_JOB_SITE_ASSIGNEE, addJobSiteAssigneeRequest),
    takeLatest(REMOVE_JOB_SITE_ASSIGNEE, removeJobSiteAssigneeRequest),
  ]);
}
