import { connect } from "react-redux";

import GpsLocationSettings from "./GpsLocationSettings";

import { updateLocationSettings } from "../settingsActions";

import {
  getMembersList,
  updateMembersList,
} from "../ActivitySettings/activitySettingsActions";

const mapStateToProps = (state) => ({
  isLoading: state.settings.isLoading,
  membersListLoading: state.activitySettings.membersListLoading,

  selectedOrganization: state.organization.selectedOrganization,

  membersSettingsList: state.activitySettings.membersTrackingList,

  // pagination
  totalMembersCount: state.activitySettings.totalMembersCount,
});

const mapDispatchToProps = (dispatch) => ({
  updateLocationSettings: (payload) =>
    dispatch(updateLocationSettings(payload)),

  getMembersSettingsList: (payload) => dispatch(getMembersList(payload)),
  updateMembersSettingsList: (payload) => dispatch(updateMembersList(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GpsLocationSettings);
