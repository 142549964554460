import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
//Parent Layouts
import AuthLayout from "./layouts/Auth/Auth.jsx";
import AdminLayout from "./layouts/Admin";
import SetupLayout from "./layouts/Onboard/Onboard.jsx";

import AcceptInvitation from "./custom_modules/AcceptInvitation/AcceptInvitation";
import AcceptJoinRequest from "./custom_modules/AcceptJoinRequest/AcceptJoinRequest";
import RedeemAppsumo from "./custom_modules/RedeemAppsumo";
import axios from "axios";

//add the following lines to any file where you import axios
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Switch>
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route path="/user" render={(props) => <AdminLayout {...props} />} />
        <Route path="/setup" render={(props) => <SetupLayout {...props} />} />
        <Route path="/accept-invitation" component={AcceptInvitation} />
        <Route path="/accept-request" component={AcceptJoinRequest} />
        <Redirect from="/" to="/auth/login" />
        <Route path="*">
          <Redirect to="/auth/login" />
        </Route>
      </Switch>
    );
  }
}

export default App;
