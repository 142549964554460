import { ToggleButton, ToggleButtonRound } from "./toggleButtonStyles";

const ToggleButtonComponent = ({
  width,
  height,
  margin,
  circleSize,
  handleClick,
  active,
}) => {
  return (
    <ToggleButton
      width={width}
      height={height}
      margin={margin}
      onClick={() => handleClick()}
      active={active}
    >
      <ToggleButtonRound size={circleSize} active={active} />
    </ToggleButton>
  );
};

export default ToggleButtonComponent;
