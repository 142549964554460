//Effects
import { all, call, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  GET_LEAVES_LIST,
  GET_LEAVES_LIST_SUCCESS,
  GET_LEAVES_LIST_FAILURE,
  APPROVE_OR_REJECT_LEAVE,
  APPROVE_OR_REJECT_LEAVE_SUCCESS,
  APPROVE_OR_REJECT_LEAVE_FAILURE,
  CREATE_LEAVE,
  CREATE_LEAVE_SUCCESS,
  CREATE_LEAVE_FAILURE,
} from "../../modules/constants";

export function* getLeavesList({ payload }) {
  try {
    const { organization_id, status, start_date, end_date, user_id } = payload;

    let url = `${organization_id}/leave/?status=${status}&start_date=${start_date}&end_date=${end_date}`;
    if (user_id) {
      url = `${organization_id}/leave/?status=${status}&start_date=${start_date}&end_date=${end_date}&user_id=${user_id}`;
    }

    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_LEAVES_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_LEAVES_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* approveOrRejectLeave({ payload }) {
  try {
    const { organization_id, leave_id, ...data } = payload;
    let url = `${organization_id}/leave/${leave_id}/status/`;
    const response = yield defaultApi(url, "POST", data);
    yield put({
      type: APPROVE_OR_REJECT_LEAVE_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: APPROVE_OR_REJECT_LEAVE_FAILURE,
      payload: err,
    });
  }
}

export function* createLeave({ payload }) {
  try {
    const { organization_id, filterStart, filterEnd, ...data } = payload;
    let url = `${organization_id}/leave/`;
    const response = yield defaultApi(url, "POST", data);
    yield put({
      type: CREATE_LEAVE_SUCCESS,
      payload: { data: response, filterStart, filterEnd },
    });
  } catch (err) {
    yield put({
      type: CREATE_LEAVE_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_LEAVES_LIST, getLeavesList),
    takeLatest(APPROVE_OR_REJECT_LEAVE, approveOrRejectLeave),
    takeLatest(CREATE_LEAVE, createLeave),
  ]);
}
