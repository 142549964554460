import styled from "styled-components";

export const ConfigureIntegrationTitleDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ marginTop }) => marginTop || "0"};
`;
export const ConfigureIntegrationTitleIntegrationIcon = styled.img`
  height: 35px;
  width: 35px;
`;
export const ConfigureIntegrationTitleIntegrationName = styled.span`
  font-size: 18px;
  margin-left: 15px;
  font-weight: 600;
`;
export const ConfigureCardText = styled.div`
  font-size: ${({ fontSize }) => fontSize || "14px"};
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  margin: ${({ margin }) => margin || "0"};
  color: ${({ color }) => color || "#2f394e"};
`;
export const ConfigureLinksSection = styled.div`
  margin-top: 30px;
`;

export const ConfigureSingleLinkContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) =>
    columns || "300px 60px 300px 30px 120px"};
  align-items: flex-end;
  margin: 30px 0 0;
`;

export const SingleLinkDropdownSection = styled.div`
  display: grid;
  grid-row-gap: 10px;
`;
export const SingleLinkDropdownSectionTitleDiv = styled.div`
  display: flex;
  align-items: center;
`;
export const SingleLinkDropdownSectionIcon = styled.img`
  height: 22px;
  width: 22px;
`;

export const SingleLinkConnectorLine = styled.div`
  width: 60px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.border.secondaryLight};
  margin: 0 0 19px 0;
`;

export const ApployeOrganizationDiv = styled.div`
  height: ${({ height }) => height || "40px"};
  background-color: ${({ theme }) => theme.colors.main.white};
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.borderRadii.input};
  padding: 0 0 0 10px;
  color: ${({ theme }) => theme.colors.main.textSecondary};
  display: flex;
  align-items: center;
`;
