import { connect } from "react-redux";

// Component
import CreateCustomReport from "./CreateCustomReport";
import {
  getOrganizationMembersList,
  getProjectAssigneeList,
  getProjectShortList,
  getTaskAssigneeList,
} from "custom_modules/Projects/projectsActions";
import { createCustomReport } from "custom_modules/ReportsNew/reportsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  organizationMembersList: state.project.orgMembersList,
  organization: state.organization.selectedOrganization,
  isLoading: state.report.isLoading,
  createReportFailed: state.report.createReportFailed,
  createdReport: state.report.createdReport,
  projectAssigneeList: state.project.projectAssigneeList,
  taskAssigneeList: state.project.taskAssigneeList,
});

const mapDispatchToProps = (dispatch) => ({
  getOrganizationMembersList: (params) =>
    dispatch(getOrganizationMembersList(params)),
  getProjectShortList: (details) =>
    dispatch(getProjectShortList({ ...details })),
  createCustomReport: (payload) => dispatch(createCustomReport(payload)),
  getProjectAssigneeList: (details) =>
    dispatch(getProjectAssigneeList({ ...details })),
  getTaskAssigneeList: (details) =>
    dispatch(getTaskAssigneeList({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(CreateCustomReport);
