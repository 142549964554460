import { connect } from "react-redux";

import GeofenceSettings from "./GeofenceSettings";

import { updateGeofenceSettings } from "../settingsActions";

import {
  getMembersList,
  updateMembersList,
} from "../ActivitySettings/activitySettingsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  isLoading: state.settings.isLoading,
  membersListLoading: state.activitySettings.membersListLoading,

  selectedOrganization: state.organization.selectedOrganization,

  membersSettingsList: state.activitySettings.membersTrackingList,

  // pagination
  totalMembersCount: state.activitySettings.totalMembersCount,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  updateGeofenceSettings: (payload) =>
    dispatch(updateGeofenceSettings(payload)),
  getMembersSettingsList: (payload) => dispatch(getMembersList(payload)),
  updateMembersSettingsList: (payload) => dispatch(updateMembersList(payload)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(GeofenceSettings);
