import { useState, useEffect } from "react";

import {
  DropdownWrapper,
  DropdownContainer,
  DropdownOptionsContainer,
  DropdownBackgroundOverlay,
  DropdownOptionsWrapper,
  DropdownContent,
  DropdownItemsContainer,
  SelectedTimezoneDiv,
  SelectedTimezoneText,
  ArrowIcon,
  SingleOptionDiv,
  SingleOptionTitle,
  SingleOptionText,
} from "../../../../components/TimezoneDropdown/timezoneDropdownStyles";

import arrowIcon from "../../../../assets/img/Sidebar/sidebar-arrow-down.svg";

const TimezoneDropdownOptions = (props) => {
  return (
    <DropdownOptionsContainer>
      <DropdownBackgroundOverlay
        onClick={() => props.closeDropdown()}
        dropdownOpen={props.dropdownOpen}
      />
      <DropdownOptionsWrapper dropdownOpen={props.dropdownOpen}>
        <DropdownContent>
          <DropdownItemsContainer style={{ width: "100%" }}>
            {props.timezoneOptions && props.timezoneOptions.length > 0 ? (
              props.timezoneOptions.map((option, index) => (
                <SingleOptionDiv
                  key={index}
                  onClick={() => props.selectTimezone(option)}
                  selected={
                    props.selectedTimezone &&
                    props.selectedTimezone.type &&
                    props.selectedTimezone.type === option.type
                  }
                >
                  <SingleOptionTitle>{`${option.type}'s Timezone`}</SingleOptionTitle>
                  <SingleOptionText>{`UTC (${option.offset}) ${option.timezone}`}</SingleOptionText>
                </SingleOptionDiv>
              ))
            ) : (
              <SingleOptionDiv>No Options</SingleOptionDiv>
            )}
          </DropdownItemsContainer>
        </DropdownContent>
      </DropdownOptionsWrapper>
    </DropdownOptionsContainer>
  );
};

const TimezoneDropdown = (props) => {
  const [timezoneDropdownOpen, setTimezoneDropdownOpen] = useState(false);
  const [timezoneOptions, setTimezoneOptions] = useState([]);

  useEffect(() => {
    if (props.selectedOrganization && props.selectedOrganization.timezone) {
      let userTimezone;

      if (localStorage.getItem("user_timezone")) {
        userTimezone = JSON.parse(localStorage.getItem("user_timezone"));
      }
      setTimezoneOptions([
        {
          type: "Organization",
          timezone: props.selectedOrganization.timezone,
          offset: props.selectedOrganization.timezoneoffset,
        },
        {
          type: "Member",
          timezone: userTimezone ? userTimezone.tz : "UTC",
          offset: userTimezone ? userTimezone.offset : "+0000",
        },
      ]);
    }
  }, [props.selectedOrganization]);

  useEffect(() => {
    if (props.selectedMember && timezoneOptions && timezoneOptions.length > 0) {
      let options = [...timezoneOptions];
      options.map((item) => {
        if (item.type === "Member") {
          item.timezone =
            props.selectedMember.timezone && props.selectedMember.timezone.tz
              ? props.selectedMember.timezone.tz
              : "UTC";
          item.offset =
            props.selectedMember.timezone && props.selectedMember.timezone.tz
              ? props.selectedMember.timezone.offset
              : "+0000";
        }
      });
      setTimezoneOptions([...options]);
    } else if (!props.selectedMember) {
      if (props.selectedOrganization && props.selectedOrganization.timezone) {
        let userTimezone;

        if (localStorage.getItem("user_timezone")) {
          userTimezone = JSON.parse(localStorage.getItem("user_timezone"));
        }
        setTimezoneOptions([
          {
            type: "Organization",
            timezone: props.selectedOrganization.timezone,
            offset: props.selectedOrganization.timezoneoffset,
          },
          {
            type: "Member",
            timezone: userTimezone ? userTimezone.tz : "UTC",
            offset: userTimezone ? userTimezone.offset : "+0000",
          },
        ]);
      }
    }
  }, [props.selectedMember]);

  const selectTimezone = (timezone) => {
    if (props.selectTimezone) {
      props.selectTimezone(timezone);
    }
    setTimezoneDropdownOpen(false);
  };

  const closeDropdown = () => {
    setTimezoneDropdownOpen(false);
  };

  return (
    <DropdownWrapper>
      <DropdownContainer onClick={() => setTimezoneDropdownOpen(!props.isDisabled)} disabled = {props.isDisabled}>
        <SelectedTimezoneDiv disabled = {props.isDisabled}>
          <SelectedTimezoneText>
            {props.selectedTimezone &&
              props.selectedTimezone.type &&
              `${props.selectedTimezone.type}'s Timezone
                `}
          </SelectedTimezoneText>
          <ArrowIcon
            src={arrowIcon}
            alt=""
            dropdownOpen={timezoneDropdownOpen}
          />
        </SelectedTimezoneDiv>
      </DropdownContainer>
      <TimezoneDropdownOptions
        closeDropdown={closeDropdown}
        dropdownOpen={timezoneDropdownOpen}
        timezoneOptions={timezoneOptions}
        selectedTimezone={props.selectedTimezone}
        selectTimezone={selectTimezone}
      />
    </DropdownWrapper>
  );
};

export default TimezoneDropdown;
