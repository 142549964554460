import {
  VERIFY_CODE,
  RESEND_VERIFICATION_CODE,
  VERIFY_CODE_RESET,
} from "../../modules/constants";

export function verifyCode(value) {
  return {
    type: VERIFY_CODE,
    payload: value,
  };
}

export function resendVerificationCode(value) {
  return {
    type: RESEND_VERIFICATION_CODE,
    payload: value,
  };
}

export function resetVerifyCode() {
  return {
    type: VERIFY_CODE_RESET,
  };
}

export const actions = {
  verifyCode,
  resendVerificationCode,
  resetVerifyCode,
};
