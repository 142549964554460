import React, { useState, useEffect } from "react";
import moment from "moment";
import backgorund from "../../assets/img/invoice_background.svg";
import Logo from "../../assets/img/logo/logo.svg";
import Paid from "../../assets/img/paid.svg";

import {
  TableText,
  CommonText,
  CommonGrid,
} from "../../styledComponents/common";
import {
  TypeSpan,
  PreviewInfo,
  PreviewDetailsContainer,
  PreviewDetailsElement,
  PreviewInfoTextContainer,
  PreviewInfoTotalContainer,
} from "../../styledComponents/invoice";
import { currencySymbolMap } from "../../utils/currencies";

const PreviewPDFView = (props) => {
  const {
    invoice_number,
    currency,
    client_name,
    issued_date,
    due_date,
    discounted_amount,
    paid_amount,
    total_amount,
    items,
    tax1,
    tax2,
    discount,
    status,
    total_tax1,
    total_tax2,
  } = props.invoiceDetails;
  const [totalCalc, setTotalCalc] = useState(0);

  useEffect(() => {
    if (items && items.length) {
      let total = 0;
      items.map((item) => {
        total += parseFloat(item.quantity) * parseFloat(item.unit_price);
      });
      setTotalCalc(total.toFixed(2));
    }
  }, [props.invoiceDetails]);

  return (
    <div id="invoice" style={{ padding: "30px 15px" }}>
      <CommonGrid
        columns="auto minmax(auto, 250px)"
        alignItem="start"
        style={{ paddingBottom: `25px` }}
      >
        <CommonText fontSize="28px" fontWeight="600" name margin="0">
          {`Invoice ${invoice_number}`}{" "}
          {!props.PDFView &&
            (status === "paid" ? (
              <img src={Paid} style={{ paddingLeft: `20px` }} />
            ) : (
              <TypeSpan status={status}>
                {status === "partial" ? "Partially Paid" : status}
              </TypeSpan>
            ))}
        </CommonText>
        <div style={{ textAlign: `end` }}>
          <CommonText fontSize="16px" name>
            {props.selectedOrganization.name}
          </CommonText>
          <CommonText>{props.selectedOrganization.address}</CommonText>
        </div>
      </CommonGrid>
      <div>
        <img src={backgorund} width="100%" />
      </div>
      <PreviewDetailsContainer>
        <PreviewDetailsElement>
          <TableText>
            {" "}
            {`Amount Due (${currencySymbolMap[currency]})`}
          </TableText>
          <CommonText name title fontSize="20px">
            {currency} ({currencySymbolMap[currency]}){" "}
            {(parseFloat(discounted_amount) - parseFloat(paid_amount)).toFixed(
              2
            ) > 0
              ? (
                  parseFloat(discounted_amount) - parseFloat(paid_amount)
                ).toFixed(2)
              : 0}
          </CommonText>
        </PreviewDetailsElement>
        <PreviewDetailsElement>
          <TableText>Invoice Number</TableText>
          <TableText name> {invoice_number} </TableText>
        </PreviewDetailsElement>
        <PreviewDetailsElement>
          <TableText>Billed To</TableText>
          <TableText name style={{ maxWidth: `150px` }}>
            {client_name}
          </TableText>
        </PreviewDetailsElement>
        <PreviewDetailsElement>
          <TableText>Issued Date</TableText>
          <TableText name>{moment(issued_date).format("DD-MM-YYYY")}</TableText>
        </PreviewDetailsElement>
        <PreviewDetailsElement>
          <TableText>Due Date</TableText>
          <TableText name>
            {due_date !== null ? moment(due_date).format("DD-MM-YYYY") : "-"}
          </TableText>
        </PreviewDetailsElement>
      </PreviewDetailsContainer>
      <PreviewInfo>
        <PreviewInfoTextContainer>
          <TableText>No.</TableText>
          <TableText>Type</TableText>
          <TableText>Item / Description</TableText>
          <TableText>Quantity</TableText>
          <TableText>Unit Price</TableText>
          <TableText>Amount</TableText>
        </PreviewInfoTextContainer>
        <PreviewInfoTextContainer>
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <TableText name>{index + 1}.</TableText>
              <TableText name>{item.type}</TableText>
              <TableText name>{item.description}</TableText>
              <TableText name>{item.quantity}</TableText>
              <TableText name>
                {parseFloat(item.unit_price).toFixed(2)}
              </TableText>
              <TableText name>
                {`${(
                  parseFloat(item.quantity) * parseFloat(item.unit_price)
                ).toFixed(2)}`}
              </TableText>
            </React.Fragment>
          ))}
        </PreviewInfoTextContainer>
        <PreviewInfoTotalContainer>
          <CommonText $label>Subtotal</CommonText>
          <CommonText
            title
          >{`${currencySymbolMap[currency]} ${totalCalc}`}</CommonText>

          {(parseFloat(discount.percent_amount) > 0 ||
            parseFloat(discount.fixed_amount) > 0) && (
            <>
              <CommonText $label>
                {discount.type === "percent"
                  ? `Discount (${parseFloat(discount.percent_amount).toFixed(
                      2
                    )}%)`
                  : `Discount (${currencySymbolMap[currency]} ${parseFloat(
                      discount.fixed_amount
                    ).toFixed(2)})`}
              </CommonText>
              <CommonText>
                {discount.type === "percent"
                  ? `- ${currencySymbolMap[currency]} ${(
                      parseFloat(total_amount) - parseFloat(discounted_amount)
                    ).toFixed(2)}`
                  : `- ${currencySymbolMap[currency]} ${parseFloat(
                      discount.fixed_amount
                    ).toFixed(2)}`}
              </CommonText>
            </>
          )}
          {(parseFloat(tax1.percent_amount) > 0 ||
            parseFloat(tax1.fixed_amount) > 0) && (
            <>
              <CommonText $label>
                {tax1.name.toLowerCase() !== "tax 1" && `Tax 1 - `}
                {tax1.type === "percent"
                  ? `${tax1.name} (${parseFloat(tax1.percent_amount).toFixed(
                      2
                    )}%)`
                  : `${tax1.name} (${currencySymbolMap[currency]} ${parseFloat(
                      tax1.fixed_amount
                    ).toFixed(2)})`}
              </CommonText>
              <CommonText>
                {currencySymbolMap[currency]} {total_tax1.toFixed(2)}
              </CommonText>
            </>
          )}
          {tax2 &&
            (parseFloat(tax2.percent_amount) > 0 ||
              parseFloat(tax2.fixed_amount) > 0) && (
              <>
                <CommonText $label>
                  {tax2 && tax2.name.toLowerCase() !== "tax 2" && `Tax 2 - `}
                  {tax2.type === "percent"
                    ? `${tax2.name} (${parseFloat(tax2.percent_amount).toFixed(
                        2
                      )}%)`
                    : `${tax2.name} (${
                        currencySymbolMap[currency]
                      } ${parseFloat(tax2.fixed_amount).toFixed(2)})`}
                </CommonText>
                <CommonText>
                  {currencySymbolMap[currency]} {total_tax2.toFixed(2)}
                </CommonText>
              </>
            )}
          <CommonText $label>Grand Total</CommonText>
          <CommonText>
            {`${currencySymbolMap[currency]} ${parseFloat(
              discounted_amount
            ).toFixed(2)}`}
          </CommonText>
          <CommonText $label>Paid</CommonText>
          <CommonText>{`${currencySymbolMap[currency]} ${parseFloat(
            paid_amount
          ).toFixed(2)}`}</CommonText>
        </PreviewInfoTotalContainer>
        <PreviewInfoTotalContainer style={{ border: `none` }}>
          <CommonText $label>Amount Due</CommonText>
          <CommonText title>
            {currency} ({currencySymbolMap[currency]}){" "}
            {(parseFloat(discounted_amount) - parseFloat(paid_amount)).toFixed(
              2
            ) > 0
              ? (
                  parseFloat(discounted_amount) - parseFloat(paid_amount)
                ).toFixed(2)
              : 0}
          </CommonText>
        </PreviewInfoTotalContainer>
      </PreviewInfo>
      {props.PDFView && (
        <CommonGrid justifyContent="start" padding="10px 0 0 0">
          <CommonText fontSize="13px">Powered by</CommonText>
          <div>
            <img src={Logo} width="100px" />
          </div>
        </CommonGrid>
      )}
    </div>
  );
};

export default PreviewPDFView;
